<script setup lang="ts">
import { onMounted } from 'vue';
import router from '@/common/router';
import LoadingScreen from '@/common/components/organisms/loading/LoadingScreen.vue';
import { webStorageController } from '@/common/utils/webStorage.util';

const LOADING_TO_PATH_KEY = 'loadingToPath';

router.beforeEach(async (to, from, next) => {
  if (to.fullPath !== '/loading') {
    webStorageController.setItem({ type: 'session', key: LOADING_TO_PATH_KEY, value: to.fullPath });
  }

  next();
});

onMounted(async () => {
  await router.replace(
    webStorageController.getItem({ type: 'session', key: LOADING_TO_PATH_KEY }) || '/',
  );
});
</script>

<template>
  <loading-screen />
</template>
