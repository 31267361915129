import { ref, inject } from 'vue';
import { DATA_FIELD_KEY } from './useDataFieldProvide';

export const useDataFieldInject = () => {
  return inject(DATA_FIELD_KEY, {
    dataFieldInfo: ref(new Map()),
    setWidgetDataField: () => {},
    getWidgetDataField: () => {
      return undefined;
    },
    deleteWidgetDataField: () => {},
  });
};
