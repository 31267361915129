<script lang="ts" setup>
import {
  setup,
  Props,
  Emit,
} from '@/common/components/organisms/alertListDetail/alertCountList.setup';

const props = withDefaults(defineProps<Props>(), {
  title: 'Alert Group List',
});
const emit = defineEmits<Emit>();

const { selectedId, onClickItem, t } = setup(props, emit);
</script>

<template>
  <div class="alert-count-list">
    <div class="alert-count-list__header">
      <p>{{ props.title }}</p>
    </div>
    <div class="alert-count-list__body">
      <div
        v-if="!props.list?.length"
        class="alert-count-item no-records"
      >
        <p>{{ t('MESSAGE.NO_DATA', 'No data') }}</p>
      </div>
      <ol v-else>
        <li
          v-for="item in props.list"
          :key="item.id"
          :class="['alert-count-item', { selected: selectedId === item.id }]"
          @click="() => onClickItem(item)"
        >
          <div class="alert-count-item__count critical">
            {{ item.critical }}
          </div>
          <div class="alert-count-item__count warning">
            {{ item.warning }}
          </div>
          <p
            class="alert-count-item__name"
            :title="item.name"
          >
            {{ item.name }}
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<style lang="scss">
.alert-count-list {
  width: 300px;
  height: 100%;
  padding: 8px 10px;
  border-right: 1px solid var(--alert-detail-divider);
  &__header {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 2px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
  &__body {
    flex: 1;
    max-height: calc(100% - 40px);
    overflow-y: auto;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
  }
}
.alert-count-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  height: 64px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid var(--alert-count-item-border);
  border-radius: 4px;
  background-color: var(--alert-count-item-bg);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    border-color: var(--alert-count-item-hover-border);
  }
  &.selected {
    border-color: var(--alert-count-item-selected-bg);
    background-color: var(--alert-count-item-selected-bg);
    color: var(--alert-count-item-selected-font);
  }
  &.no-records {
    justify-content: center;
    border-color: transparent;
    background-color: transparent;
    color: var(--alert-detail-item-no-records-color);
  }
  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 1px solid var(--alert-count-item-warning-border);
    border-radius: 50%;
    background-color: var(--alert-count-item-warning-bg);

    @include shortening(flex, 26px);

    &.warning {
      border: 1px solid var(--alert-count-item-warning-border);
      background-color: var(--alert-count-item-warning-bg);
      color: var(--alert-count-item-warning-font);
    }
    &.critical {
      border: 1px solid var(--alert-count-item-critical-border);
      background-color: var(--alert-count-item-critical-bg);
      color: var(--alert-count-item-critical-font);
    }
  }
  &__name {
    flex: 1;
    padding-left: 2px;

    @include shortening();
  }
}
</style>
