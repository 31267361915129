import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';
import { getEnabledInstance, getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { DEFAULT_FOLDER_INFO, FolderInfo } from '@/config/store/monitoringFolder';
import { Ref, unref } from 'vue';
import { postV1UserEnvsAxios } from '@/openapi/meta/api/xm-user-env-controller-api';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';

interface State {
  selectedFolderId: string | null;
  selectedFolderInfo: FolderInfo;
  dbMetricNames: string[];
  // 실패한 API의 frame 정보 저장 (key: frameName, value: errorStatusText)
  framesByFailedApi: Map<string, string | Ref<string>>;
}

const METRIC_CHART_METRIC_NAMES_KEY = 'ORACLE_METRIC_CHART_METRIC_NAMES';
const SELECTED_ID_KEY = 'ORACLE_MULTI_VIEW_SELECTED_ID';

export const multiViewEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedFolderId: null,
    selectedFolderInfo: structuredClone(DEFAULT_FOLDER_INFO),
    dbMetricNames: [],

    framesByFailedApi: new Map(),
  },
  mutations: {
    setFramesByFailedApi: (state: State, failedApiInfo) => {
      state.framesByFailedApi.set(failedApiInfo.frameName, failedApiInfo.statusText);
    },
    deleteFramesByFailedApi: (state: State, frameNameOfFailedApi: string) => {
      if (state.framesByFailedApi.has(frameNameOfFailedApi)) {
        state.framesByFailedApi.delete(frameNameOfFailedApi);
      }
    },
    setSelectedFolderId: (state: State, id: string) => {
      state.selectedFolderId = id;
    },
    setSelectedFolderInfo(state: State, info: State['selectedFolderInfo']) {
      state.selectedFolderInfo = info;
    },
    setDbMetricName: (state: State, { idx, metricName }: { idx: number; metricName: string }) => {
      state.dbMetricNames[idx] = metricName;
    },
    setDbMetricNames: (state: State, metricNames: string[]) => {
      state.dbMetricNames = metricNames;
    },
    initSelectedInfo: (state: State) => {
      state.selectedFolderId = null;
      state.selectedFolderInfo = structuredClone(DEFAULT_FOLDER_INFO);
    },
  },
  actions: {
    getMetricChartMetricNames: async ({ commit, rootGetters }) => {
      const DEFAULT_NAMES = [
        'db_oracle_os.cpu_usage',
        'db_oracle_activesession',
        'db_oracle_execute_count',
        'db_oracle_session_logical_reads',
        'db_oracle_physical_reads',
      ];
      const metricNamesInUserEnv = rootGetters['userEnv/getUserEnvMap'].get(
        METRIC_CHART_METRIC_NAMES_KEY,
      );
      if (!metricNamesInUserEnv) {
        commit('setDbMetricNames', DEFAULT_NAMES);
        return;
      }

      const metricNames = JSON.parse(metricNamesInUserEnv);

      if (metricNames?.length > 0) {
        commit(
          'setDbMetricNames',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.ORACLE,
            metricNames,
          }),
        );
        return;
      }

      commit('setDbMetricNames', DEFAULT_NAMES);
    },
    saveMetricChartMetricNames: async ({ commit }, metricNames) => {
      await postV1UserEnvsAxios({
        request: {
          key: METRIC_CHART_METRIC_NAMES_KEY,
          value: JSON.stringify(metricNames),
        },
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: METRIC_CHART_METRIC_NAMES_KEY, value: JSON.stringify(metricNames) },
        { root: true },
      );
    },
    fetchLastSelectedId: async ({ commit, dispatch, rootGetters }) => {
      try {
        const data = rootGetters['userEnv/getUserEnvMap'].get(SELECTED_ID_KEY);
        const idInfo = JSON.parse(data ?? '{}');
        const folderList = rootGetters['monitoringFolder/getFolders'];
        const { folderId, selectedInfo } = getRtmSelectedInfo({
          idInfo,
          folderList,
          dbType: DB_TYPE.ORACLE,
          hasInstanceId: false,
        });

        // userEnv에서 조회한 folderId와 실제 화면에 보여져야 할 folderId가 다른 경우
        // e.g. userEnv에서는 A라는 폴더가 저장되어 있지만 A라는 폴더가 삭제된 경우
        if (idInfo.folderId !== folderId) {
          await dispatch('saveLastSelectedId', {
            folderId,
          });
        }

        commit('setSelectedFolderId', folderId);
        commit('setSelectedFolderInfo', selectedInfo);
      } catch (e: any) {
        console.log(e);
      }
    },
    saveLastSelectedId: async ({ commit, rootGetters }, selectedInfo: Record<string, string>) => {
      if (!selectedInfo.folderId) {
        return;
      }
      try {
        await commit('setSelectedFolderId', selectedInfo.folderId);
        const selectedFolderInfo =
          rootGetters['monitoringFolder/getFolders'].find(
            (item) => item.folderId === selectedInfo.folderId,
          ) ?? structuredClone(DEFAULT_FOLDER_INFO);
        await commit('setSelectedFolderInfo', selectedFolderInfo);

        await postV1UserEnvsAxios({
          request: {
            key: SELECTED_ID_KEY,
            value: JSON.stringify(selectedInfo),
          },
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SELECTED_ID_KEY, value: JSON.stringify(selectedInfo) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getSelectedFolderInfo: (state) => state.selectedFolderInfo,
    isExistFramesByFailedApi: (state: State): boolean => {
      let isExist = false;
      state.framesByFailedApi.forEach((value) => {
        if (unref(value)) {
          isExist = true;
        }
      });
      return isExist;
    },
    getFramesByFailedApi: (state: State) => state.framesByFailedApi,
    getInstanceIds: (state) =>
      state.selectedFolderInfo?.instances
        ?.filter(getEnabledInstance(DB_TYPE.ORACLE))
        .map((v) => v.instanceId)
        .slice(0, 15) ?? [],
    getInstances: (state) =>
      state.selectedFolderInfo?.instances
        ?.filter(getEnabledInstance(DB_TYPE.ORACLE))
        .slice(0, 15) ?? [],
    getDbMetricNames: (state: State) => state.dbMetricNames,
    getDbMetricDisplayNames: (state, getters, rootState, rootGetters) => {
      const displayDbMetricNamesMap = rootGetters['dbMetric/getMetricDisplayNames'];
      const { dbMetricNames } = state;

      const init = [] as { metricName: string; displayName: string }[];
      return dbMetricNames.reduce(
        (acc, cur) => [
          ...acc,
          {
            metricName: cur,
            displayName: displayDbMetricNamesMap.get(cur),
          },
        ],
        init,
      );
    },
  },
};
