import { listViewGridOption, listViewTreeGridOption } from '@/common/define/grid.define';
import { CustomColumn, DB_TYPE, DBType, DynamicGridOption } from '@/common/utils';
import { GridOption, TreeGridOption } from '@/common/utils/types';

export type InstanceMonitoringType = 'Single View' | 'Multi View' | 'Detail';
type UnambiguousDBType = Exclude<DBType, null>;

type DisplayDbType = {
  [Key in keyof typeof DB_TYPE]?: string;
};

export const DISPLAY_DB_TYPE: DisplayDbType = {
  POSTGRESQL: 'PostgreSQL',
  ORACLE: 'Oracle',
  MYSQL: 'MySQL',
  SQLSERVER: 'SQL Server',
  REDIS: 'Redis',
  MONGODB: 'MongoDB',
} as const;

export const checkBoxGridOption: GridOption = {
  ...listViewGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

export const checkBoxTreeGridOption: TreeGridOption = {
  ...listViewTreeGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

// 데모환경 정보나 판단 flag를 환경변수에 넣는것을 고민해봐야 할 듯
const isDemoEnv = ['demo3.exem.io', 'localhost:8080'].some((url) =>
  window.location.origin.includes(url),
);

export const DatabaseDetailViewUrlMap: Partial<Record<UnambiguousDBType, string>> = isDemoEnv
  ? {
      // redis 데모용 임시로 사용한다.
      redis: '/dashboard/view/94',
    }
  : {};

const DetailViewTypes = Object.entries(DatabaseDetailViewUrlMap).reduce((acc, [key, value]) => {
  if (value) acc[key] = 'Detail';
  return acc;
}, {});

export const DatabaseSingleViewTypes: Partial<Record<UnambiguousDBType, InstanceMonitoringType>> = {
  mysql: 'Single View',
  postgresql: 'Single View',
  sqlserver: 'Single View',
  ...DetailViewTypes,
} as const;

export const DatabaseMultiViewTypes: Partial<Record<UnambiguousDBType, InstanceMonitoringType>> = {
  oracle: 'Multi View',
  mysql: 'Multi View',
  postgresql: 'Multi View',
  ...DetailViewTypes, // LINK - BreadCrumbs
} as const;

export const INSTANCE_GRID_COLUMNS: CustomColumn[] = [
  {
    caption: 'Instance Name',
    field: 'name',
    type: 'string',
    width: 257,
    hiddenDisplay: false,
  },
  {
    caption: 'Cluster:Role',
    field: 'clusterRole',
    type: 'string',
    align: 'center',
    width: 120,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Host IP',
    field: 'hostIp',
    type: 'string',
    align: 'center',
    width: 120,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Version',
    field: 'version',
    type: 'string',
    width: 120,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Status',
    field: 'status',
    type: 'string',
    rendererType: 'label',
    width: 100,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Alert',
    field: 'alert',
    type: 'string',
    rendererType: 'label',
    width: 100,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'CPU Usage (%)',
    field: 'cpuUsage',
    type: 'string',
    rendererType: 'ratio',
    width: 130,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Memory Usage (%)',
    field: 'memoryUsage',
    type: 'string',
    rendererType: 'ratio',
    width: 130,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Active Session Count',
    field: 'activeSessionCount',
    type: 'number',
    width: 120,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Monitoring',
    field: 'monitoring',
    type: 'string',
    align: 'center',
    width: 120,
    searchable: false,
    hiddenDisplay: false,
  },
  {
    caption: 'Cluster Id',
    field: 'clusterId',
    type: 'string',
    hide: true,
    searchable: false,
    hiddenDisplay: true,
  },
  {
    caption: 'Business',
    field: 'services',
    type: 'string',
    hide: true,
    searchable: false,
    hiddenDisplay: true,
  },
  {
    caption: 'User Tags',
    field: 'userTags',
    type: 'string',
    hide: true,
    searchable: false,
    hiddenDisplay: true,
  },
] as const;

export const InstanceDynamicGridOption: DynamicGridOption = {
  excludeFields: ['host', 'alertLevel'],
  includeFields: ['hostIp'],
} as const;
