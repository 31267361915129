import { WidgetUUID } from '@/common/utils';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { createWidgetReferenceRelation } from '../utils/reference.utils';
import { Tree } from '../utils/tree';
import { GridLayoutItem } from '../utils/types';

export const useWidgetRelationStore = defineStore('widgetRelationStore', () => {
  // label이 widgetUUID인 Tree
  const relation = ref(new Tree(null, 'root'));

  const initWidgetRelation = (gridLayoutItems: GridLayoutItem[]) => {
    const widgetInfoList = gridLayoutItems.reduce<
      Parameters<typeof createWidgetReferenceRelation>[0]
    >((acc, { i, chartOption, children }) => {
      acc.push({ widgetId: i, referenceInfo: chartOption?.referenceInfo });
      children?.forEach((child) => {
        acc.push({ widgetId: child.i, referenceInfo: child.chartOption?.referenceInfo });
      });
      return acc;
    }, []);
    relation.value = createWidgetReferenceRelation(widgetInfoList);
  };

  const isDescendantWidget = (parentWidgetId: WidgetUUID, childWidgetId: WidgetUUID) => {
    if (parentWidgetId === childWidgetId) {
      return false;
    }
    const currentWidgetTree = relation.value.findChild(parentWidgetId);
    const destWidgetTree = relation.value.findChild(childWidgetId);

    return (
      destWidgetTree && currentWidgetTree && Tree.isDescendant(currentWidgetTree, destWidgetTree)
    );
  };

  const hasChildWidget = (widgetId: WidgetUUID) => {
    if (!relation.value) {
      return false;
    }
    const currentWidgetTree = relation.value.findChild(widgetId);
    return !!currentWidgetTree && currentWidgetTree.children.length > 0;
  };

  /*
   ** for widget setting
   */
  const currentWidgetId = ref<WidgetUUID | null>(null);
  const relationCopy = ref<Tree | null>(null);

  const updateCurrentWidgetId = (newWidgetUUID: WidgetUUID) => {
    currentWidgetId.value = newWidgetUUID;
  };

  const restoreRelation = () => {
    relationCopy.value = cloneDeep(relation.value);
  };

  watch(
    relation,
    () => {
      restoreRelation();
    },
    {
      immediate: true,
    },
  );

  const syncRelation = () => {
    if (relationCopy.value) {
      relation.value = cloneDeep(relationCopy.value);
    }
  };

  const addRelation = (parent: WidgetUUID) => {
    if (!currentWidgetId.value || !relationCopy.value) {
      return;
    }
    let parentWidgetTree = relationCopy.value.findChild(parent);
    if (!parentWidgetTree) {
      parentWidgetTree = relationCopy.value.addChild(parent);
    }
    const currentWidgetTree = relationCopy.value.findChild(currentWidgetId.value);
    if (
      !currentWidgetTree ||
      parentWidgetTree.children.findIndex((child) => child.label === currentWidgetId.value) === -1
    ) {
      parentWidgetTree.addChild(currentWidgetId.value);
    }
  };

  const removeRelation = (parent: WidgetUUID) => {
    if (!currentWidgetId.value || !relationCopy.value) {
      return;
    }
    const parentWidgetTree = relationCopy.value.findChild(parent);
    const toDelete = parentWidgetTree?.findChild(currentWidgetId.value);
    if (toDelete) {
      parentWidgetTree?.removeChild(toDelete);
    }
  };

  const disableToReference = (widgetId: WidgetUUID) => {
    if (!currentWidgetId.value || !relationCopy.value) {
      return false;
    }
    const currentWidgetTree = relationCopy.value.findChild(currentWidgetId.value);
    const destWidgetTree = relationCopy.value.findChild(widgetId);
    return (
      destWidgetTree && currentWidgetTree && Tree.isDescendant(currentWidgetTree, destWidgetTree)
    );
  };

  const getChildren = (widgetId: WidgetUUID): Tree[] | undefined => {
    if (!relationCopy.value) {
      return undefined;
    }
    const currentWidgetTree = relationCopy.value.findChild(widgetId);
    return currentWidgetTree?.children;
  };

  const route = useRoute();
  watch(
    () => route.params.id,
    async () => {
      relation.value = new Tree(null, 'root');
      currentWidgetId.value = null;
      relationCopy.value = null;
    },
  );

  return {
    currentWidgetId,
    updateCurrentWidgetId,
    initWidgetRelation,
    addRelation,
    removeRelation,
    disableToReference,
    isDescendantWidget,
    syncRelation,
    hasChildWidget,
    getChildren,
  };
});
