import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import type { InstanceType } from '@/common/stores/instance';
import type { MenuMouseEvent } from './breadCrumbs.types';

export interface Props {
  breadCrumbId?: string;
  isActive?: boolean;
  index?: number;
  instanceType?: InstanceType;
  to?: RouteLocationRaw;
}

export interface Emit {
  (e: 'mouseover', payload: MenuMouseEvent): void;
  (e: 'click', payload: MenuMouseEvent): void;
  (e: 'mouseleave', event: MouseEvent): void;
}

export const setup = (props: Props, emit: Emit) => {
  const buttonClass = computed(() => [
    'path-button',
    'path-name',
    { 'path-button--active': props.isActive },
  ]);

  const onMouseEnter = (e: MouseEvent) => {
    emit('mouseover', { e, i: props.index, instanceType: props.instanceType });
  };

  const onMouseClick = (e: MouseEvent) => {
    emit('click', { e, i: props.index, instanceType: props.instanceType });
  };

  const onMouseLeave = (event: MouseEvent) => {
    emit('mouseleave', event);
  };

  return {
    buttonClass,
    onMouseEnter,
    onMouseClick,
    onMouseLeave,
  };
};
