import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getCurrentByServiceAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { PLATFORM } from '@/common/define/common.define';

export interface Alerts {
  collectTime: string;
  description: string;
  level: number;
  name: string;
  type: string;
  value: string;
  unit?: string;
}

export interface AlertsState {
  alerts: Alerts[];
  errorStatusText: string;
  errorStatus: number;
}

export const instanceAlerts: Module<AlertsState, RootState> = {
  namespaced: true,
  state: {
    alerts: [],
    errorStatusText: '',
    errorStatus: 0,
  },
  mutations: {
    setInstanceAlerts(state: AlertsState, data: Alerts[]) {
      state.alerts = data;
    },
    setErrorStatusText: (
      state: AlertsState,
      { errorStatusText, errorStatus }: Omit<AlertsState, 'alerts'>,
    ) => {
      state.errorStatusText = errorStatusText;
      state.errorStatus = errorStatus;
    },
    initInstanceAlerts: (state: AlertsState) => {
      state.alerts.length = 0;
      state.errorStatusText = '';
    },
  },
  actions: {
    async fetchInstanceAlerts({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.ADMIN_REFERENCE_VACUUM;
      try {
        const { data } = await getCurrentByServiceAlertCommonControllerAxios({
          targetIds: [instanceId],
          targetKind: PLATFORM.DB,
          frameName,
        });
        commit('setInstanceAlerts', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        const status = e?.response?.status;
        commit('setErrorStatusText', { errorStatusText: statusText, errorStatus: status });
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async fetchMysqlInstanceAlerts({ commit }, instanceId) {
      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.ADMIN_REFERENCE_ALERT_LOGS;

      try {
        const { data } = await getCurrentByServiceAlertCommonControllerAxios({
          targetIds: [instanceId],
          targetKind: PLATFORM.DB,
          frameName,
        });
        commit('setInstanceAlerts', data.data);

        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);

        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getInstanceAlerts: (state: AlertsState) => state.alerts || [],
    getErrorStatusText: (state: AlertsState) => state.errorStatusText,
    getErrorStatus: (state: AlertsState) => state.errorStatus,
  },
};
