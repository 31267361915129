export const LicenseTypeName = [
  'APPLICATION',
  'CLOUD',
  'CONTAINER',
  'DATABASE',
  'INFRASTRUCTURE',
] as const;

export const LicenseLevel = ['PERM', 'TERM', 'TRIAL'] as const;

export const LicenseTargetDisplayName = {
  host: 'Host',
  ndm: 'NDM',
  kafka: 'Kafka',
  log: 'Log',
  container: 'Container',
  appContainer: 'Application Container',
  apm: 'APM',
  redis: 'Redis',
  postgresql: 'PostgreSQL',
  mysql: 'MySQL',
  oracle: 'Oracle',
  sqlServer: 'SQL Server',
  allDimension: 'Total Dimension',
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'GCP',
  ncp: 'NCP',
} as const;
