import { CustomColumn } from '@/common/utils/types';
import { mergeWith } from 'lodash-es';

const PROPERTIES_FOR_SAVE = ['index', 'field', 'width', 'hiddenDisplay', 'sortOption'];
export const getPickedColumn = (col: CustomColumn, isIncludeIndex: boolean = true) => {
  return PROPERTIES_FOR_SAVE.reduce((acc, cur) => {
    if (col[cur] === undefined || (!isIncludeIndex && cur === 'index')) {
      return acc;
    }
    acc[cur] = col[cur];
    return acc;
  }, {} as CustomColumn);
};

export const getColumnsToSave = <ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>>(
  columns: ColumnData,
) => {
  return columns?.map((col) => getPickedColumn(col, true)) ?? [];
};

const mergeFn = (base, stored, key) => {
  return key === 'index' ? base ?? stored : stored ?? base;
};
export const getMergedColumns = <ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>>({
  baseColumns,
  storedColumns,
  dynamicColumnFields,
}: {
  baseColumns: ColumnData;
  storedColumns: ColumnData;
  dynamicColumnFields?: string[];
}) => {
  if (!storedColumns?.length) {
    return baseColumns;
  }

  const indexedBaseColumn = baseColumns.map((col, index) => ({ ...col, index }));
  const baseColumnsMap = new Map<string, CustomColumn>(
    indexedBaseColumn.map((col: CustomColumn) => [col.field, col]),
  );
  const storedColumnsSet = new Set(storedColumns.map((col) => col.field));
  const dynamicFieldsSet = new Set(dynamicColumnFields ?? []);

  const filteredStoredColumns = storedColumns
    .filter((col) => {
      if (dynamicFieldsSet.size) {
        return baseColumnsMap.has(col.field) || dynamicFieldsSet.has(col.field);
      }
      return baseColumnsMap.has(col.field);
    })
    .map((storedCol) => {
      const baseCol = baseColumnsMap.get(storedCol.field) ?? {};
      return baseCol ? mergeWith({}, baseCol, storedCol, mergeFn) : storedCol;
    });

  const addedColumns = indexedBaseColumn.filter((col) => !storedColumnsSet.has(col.field));

  if (dynamicFieldsSet.size) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return [...filteredStoredColumns, ...addedColumns].map(({ index, ...rest }) => ({ ...rest }));
  }

  return [...filteredStoredColumns, ...addedColumns];
};
