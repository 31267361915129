import { I18nTranslation } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { CurrentChartType } from '../../widgets.types';
import { defaultCheckChartDataStatus } from '../checkChartDataStatus';
import { checkScoreboardStyle2ChartData } from './checkScoreboardChartData';

export const checkCurrentChartTypeDataStatus =
  (t: I18nTranslation) =>
  (
    chartType: CurrentChartType,
  ): ((
    rawData: CurrentRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ) => WidgetChartDataStatusInfo[]) => {
    switch (chartType) {
      case 'scoreboard-style2':
        return checkScoreboardStyle2ChartData({
          diffUnitError: t('MESSAGE.UI.DASHBOARD.SCOREBOARD_STAT_UNIT_DIFF'),
        });
      case 'gauge':
      case 'scoreboard':
      case 'stackedProportionBar':
        return defaultCheckChartDataStatus<CurrentRawData>;
      default:
        throw new Error(`Unsupported table chartDataType: ${chartType}`);
    }
  };
