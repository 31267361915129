import { WidgetUUID } from '@/common/utils';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { DASHBOARD } from '@/common/define/userEnv/dashboard.env.define';
import { storeToRefs } from 'pinia';

export type DashboardUserEnvKeys = (typeof DASHBOARD)[keyof typeof DASHBOARD];

export const useDashboardUserEnv = () => {
  const { monitoringDashboard } = storeToRefs(useDashboardViewStore());

  const getWidgetUserEnvKey = (envKey: DashboardUserEnvKeys, widgetUUID: WidgetUUID) => {
    const currentDashboardId = monitoringDashboard.value.dashboardId ?? -1;
    return `${envKey}_${currentDashboardId}_${widgetUUID}`;
  };

  return { getWidgetUserEnvKey };
};
