<script lang="ts" setup>
import {
  setup,
  Props,
  Emit,
} from '@/common/components/molecules/filterSearch/filterSearchDropdownList.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,
  filterHistoryList,
  getDropdownListTitle,
  getElapsedTimeSinceHistoryCreated,
  onMouseenter,
  onClickListItem,
  onClickHistoryItem,
} = setup(props, emit);
</script>

<template>
  <div class="filter-search-dropdown-list">
    <div class="filter-search-dropdown-list__items">
      <div class="filter-search-dropdown-list__sub-title">
        {{ getDropdownListTitle(props.dropdownListInfo.category) }}
      </div>

      <template v-if="!props.dropdownListInfo?.items?.length">
        <ul class="filter-search-dropdown-list__no-data">
          <li>{{ t('WORD.SEARCH', 'Search') }}</li>
        </ul>
      </template>

      <template v-else-if="!props.dropdownListInfo?.filteredItems?.length">
        <ul class="filter-search-dropdown-list__no-data">
          <li>{{ t('MESSAGE.NO_RECORDS', 'No records') }}</li>
        </ul>
      </template>

      <template v-else>
        <ul class="filter-search-dropdown-list__main-list-wrapper">
          <li
            v-for="(listItem, listIndex) in props.dropdownListInfo.filteredItems"
            :key="listItem.id"
            :class="[
              'filter-search-dropdown-list__item',
              {
                hovered: props.hoveredDropdownItemIndex === listIndex,
                selected: listItem.checked,
                disabled: listItem?.disabled,
              },
            ]"
            @mouseenter="onMouseenter"
            @click="() => onClickListItem(listItem)"
          >
            <ev-checkbox
              v-if="props.dropdownListInfo.multi"
              v-model="listItem.checked"
            />
            <span>{{ listItem.name }}</span>
          </li>
        </ul>
      </template>
    </div>

    <div
      v-if="props.showFilterHistoryList"
      class="filter-search-dropdown-list__history-wrapper"
    >
      <div class="filter-search-dropdown-list__sub-title">
        {{ t('WORD.RECENT_SEARCHES', 'Recent Searches') }}
      </div>

      <ul>
        <li
          v-for="(history, historyIndex) in filterHistoryList"
          :key="`${historyIndex}-${history.token.key}`"
          :class="[
            'filter-search-dropdown-list__item',
            'filter-search-dropdown-list__history-item',
            {
              hovered:
                props.hoveredDropdownItemIndex ===
                props.dropdownListInfo.filteredItems?.length + historyIndex,
            },
          ]"
          @click="() => onClickHistoryItem(history)"
        >
          <span class="filter-search-dropdown-list__history-icon">
            <ev-icon icon="ev-icon-time-line" />
          </span>
          <span class="filter-search-dropdown-list__history-name">
            {{
              `${history.token.key.name}
               ${history.token.operator?.name ?? ''}
               ${history.token.values?.map(({ name }) => name).join(',')}`
            }}
          </span>
          <span class="filter-search-dropdown-list__history-created-time">
            {{ getElapsedTimeSinceHistoryCreated(history?.createdTime) }}
          </span>
        </li>
      </ul>
    </div>

    <div class="filter-search-dropdown-list__guide-wrapper">
      <template v-if="props.dropdownListInfo.filteredItems?.length">
        <span>
          <span class="filter-search-dropdown-list__keyboard-guide arrow-key">
            <ev-icon icon="ev-icon-allow2-up" />
          </span>
          <span class="filter-search-dropdown-list__keyboard-guide arrow-key">
            <ev-icon icon="ev-icon-allow2-down" />
          </span>
          {{ t('WORD.TO_NAVIGATE', 'to navigate') }}
        </span>

        <span>
          <span class="filter-search-dropdown-list__keyboard-guide"> Enter </span>
          {{
            props.dropdownListInfo.multi
              ? t('WORD.TO_SELECT', 'to select')
              : t('WORD.TO_SELECT_AND_COMPLETE', 'to select and complete')
          }}
        </span>

        <template v-if="props.dropdownListInfo.multi">
          <span>
            <span class="filter-search-dropdown-list__keyboard-guide"> Control </span>
            +
            <span class="filter-search-dropdown-list__keyboard-guide"> Enter </span>
            {{ t('WORD.TO_COMPLETE', 'to complete') }}
          </span>
        </template>

        <span>
          <span class="filter-search-dropdown-list__keyboard-guide"> Esc </span>
          {{
            props.dropdownListInfo.isEditing
              ? t('WORD.TO_CANCEL', 'to cancel')
              : t('WORD.TO_CLOSE', 'to close')
          }}
        </span>
      </template>

      <template v-else-if="!props.dropdownListInfo.items?.length">
        <span>
          <span class="filter-search-dropdown-list__keyboard-guide"> Enter </span>
          {{ t('WORD.TO_COMPLETE', 'to complete') }}
        </span>

        <span>
          <span class="filter-search-dropdown-list__keyboard-guide"> Esc </span>
          {{
            props.dropdownListInfo.isEditing
              ? t('WORD.TO_CANCEL', 'to cancel')
              : t('WORD.TO_CLOSE', 'to close')
          }}
        </span>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-search-dropdown-list {
  --filter-search-dropdown-list-item-padding: 6.5px 12px;

  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 450px;

  &__items {
    flex: auto;
    overflow-y: auto;
  }

  &__history-wrapper {
    flex-shrink: 0;
    overflow-y: auto;
  }

  &__guide-wrapper {
    display: flex;
    flex: 30px 0 0;
    flex-flow: row wrap;
    gap: 32px;
    padding: 4px 12px;
    cursor: default;
    border-top: 1px solid var(--filter-search-dropdown-list-guide-border-color);
    font-size: 12px;
    font-weight: 500;
    color: var(--filter-search-dropdown-list-guide-font-color);
  }

  &__item {
    padding: var(--filter-search-dropdown-list-item-padding);
    cursor: pointer;
    font-size: 13px;
    color: var(--filter-search-dropdown-list-item-font-color);

    .ev-checkbox {
      margin-right: 5px !important;
    }

    &.selected {
      background-color: var(--filter-search-dropdown-list-selected-item-bg-color);
    }

    &.hovered,
    &:hover {
      background-color: var(--filter-search-dropdown-list-hover-item-bg-color);
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: transparent;
      color: var(--filter-search-dropdown-list-disabled-item-font-color);
    }
  }

  &__sub-title {
    padding: var(--filter-search-dropdown-list-item-padding);
    cursor: default;
    font-size: 11px;
    color: var(--filter-search-list-sub-title-color);
  }

  &__no-data {
    padding: var(--filter-search-dropdown-list-item-padding);
    cursor: default;
    font-size: 11px;
    color: var(--filter-search-dropdown-list-no-data-font-color);
  }

  &__history-item {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  &__history-icon {
    flex: 20px 0 0;
    padding-top: 1.5px;
    margin-right: 3px;
    text-align: center;
  }

  &__history-name {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__history-created-time {
    flex-grow: 0;
    flex-shrink: 0;
    color: var(--filter-search-dropdown-list-history-created-time-font-color);
    text-align: right;
  }

  &__keyboard-guide {
    padding: 1px 6px 1px 8px;
    margin-right: 5px;
    border: 1px solid var(--filter-search-dropdown-list-guide-border-color);
    border-radius: 2px;
    background-color: var(--filter-search-dropdown-list-guide-key-bg-color);
    line-height: 20px;
    color: var(--filter-search-dropdown-list-guide-font-color);

    &.arrow-key {
      padding: 2px 3.5px 1px;
    }

    [class^='ev-icon-'] {
      color: var(--filter-search-dropdown-list-guide-font-color) !important;
    }
  }
}
</style>
