import { RouteRecordRaw } from 'vue-router';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { VIEW_MODE } from '@/common/define/common.define';

const PREFIX_FAULT_ANALYSIS = 'fault_';

const faultAnalysisMenu: RouteRecordRaw[] = [
  {
    path: '/fault-analysis/',
    // TODO 사용안하는 메뉴인지?
    name: `${PREFIX_FAULT_ANALYSIS}Fault Analysis`,
    component: PaViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_APPLICATION,
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [
      {
        path: 'transaction-trend',
        name: `${PREFIX_FAULT_ANALYSIS}Transaction Trend`,
        component: () =>
          import(
            /* webpackChunkName: "fault-transaction-trend" */ '@/application/views/transactionTrend/TransactionTrend.vue'
          ),
      },
      {
        path: 'exception-summary',
        name: `${PREFIX_FAULT_ANALYSIS}Exception Summary`,
        component: () =>
          import(
            /* webpackChunkName: "fault-exception-summary" */ '@/application/views/exceptionSummary/ExceptionSummary.vue'
          ),
      },
      {
        path: 'trend-analysis',
        name: `${PREFIX_FAULT_ANALYSIS}Trend Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "fault-exception-summary" */ '@/application/views/trendAnalysis/TrendAnalysis.vue'
          ),
      },
    ],
  },
];

export { faultAnalysisMenu };
