<script lang="ts" setup>
import BaseGrid from '@/common/components/molecules/grid/BaseGrid.vue';
import { Emit, Props, PROPS_DEFAULTS, setup } from '@/alert/components/alertGrid/alertGrid.setup';
import { isEventType } from '@/infrastructure/views/networkDeviceView/networkDeviceDetail/networkDeviceAlert/networkDeviceAlert.setup';
import AlertTargetList from '@/alert/components/alertTargetList/AlertTargetList.vue';
import { makeRowToObj } from '@/common/utils/gridUtils';
import { ALERT_RULE_NAME_ICON } from '@/alert/utils/tagsAndTargets.uses';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import SetupDashBoardPopup from '@/common/components/organisms/setupDashBoardPopup/SetupDashBoardPopup.vue';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import AlertStatusButtonRow from '@/alert/components/alertStatusButtonRow/AlertStatusButtonRow.vue';

const props = withDefaults(defineProps<Props>(), { ...PROPS_DEFAULTS });
const emit = defineEmits<Emit>();

const {
  gridOption,
  searchBarOption,
  checkedRows,
  searchWord,
  exportExcelOption,
  columnIdx,
  getAlertValue,
  t,

  criteriaTooltipMouseEvent,
  criteriaTooltipText,
  onCriteriaMouse,

  isVisibleSetupDashBoard,
  dashboardEnvKey,
  setupDashboardPopupClose,
  viewDashboardList,
  dashboardName,
  dashboardPopupListCnt,
  menuRef,
  menuItems,
  dashboardTooltipRef,
  onShowDashboardTooltip,
  onHideDashboardTooltip,
} = setup(props, emit);
</script>

<template>
  <base-grid
    v-bind="$attrs"
    v-model:checkedRows="checkedRows"
    v-model:searchWord="searchWord"
    :type="props.type === 'main' ? 'page' : 'widget'"
    :columns="props.gridColumns"
    :option="gridOption"
    :search-bar-option="searchBarOption"
    :rows="props.gridData"
    class="alert-grid"
    :export-excel-option="exportExcelOption"
    :column-env-key="`${props.envKey}_grid`"
    @click-cell="
      (e) =>
        emit('on-click-cell', {
          ...e,
          row: makeRowToObj(e.row, props.gridColumns),
        })
    "
    @export-to-excel-from-server="emit('export-to-excel-from-server', exportExcelOption)"
  >
    <template #ruleName="{ item }">
      <div
        v-if="item.row[2][item.column.index] === ALERT_RULE_NAME_ICON"
        class="alert-grid__child-icon"
      >
        <ev-icon :icon="ALERT_RULE_NAME_ICON" />
      </div>
      <div
        v-else
        :class="[
          'alert-grid__rule-name',
          props.gridColumns[columnIdx.ruleName].clickable ? 'base-grid__cell--link' : '',
        ]"
        @click.stop="
          () => {
            if (props.gridColumns[columnIdx.ruleName].clickable) {
              emit('on-click-cell', {
                field: 'ruleName',
                value: item.row[2][item.column.index],
                row: makeRowToObj(item.row[2], props.gridColumns),
                cellIndex: item.column.index,
              });
            }
          }
        "
      >
        <template v-if="columnIdx.ruleNameIcon && item.row[2][columnIdx.ruleNameIcon]">
          <ev-icon
            :icon="item.row[2][columnIdx.ruleNameIcon].iconClass"
            @mouseenter="
              (e) => onCriteriaMouse({ text: item.row[2][columnIdx.ruleNameIcon].tooltip, e })
            "
            @mouseleave="(e) => onCriteriaMouse({ text: '', e })"
          />
        </template>
        <span :title="item.row[2][item.column.index]">{{ item.row[2][item.column.index] }}</span>
      </div>
    </template>
    <template #tags="{ item }">
      <alert-target-list
        v-if="item.row[2][item.column.index]?.length"
        :target-list="item.row[2][item.column.index]"
      />
    </template>
    <template #targets="{ item }">
      <alert-target-list
        v-if="item.row[2][item.column.index]?.length"
        :target-list="item.row[2][item.column.index]"
        :is-clickable="props.isTargetClickable"
      />
    </template>
    <template #threshold="{ item }">
      <div v-if="item.row[2][item.column.index]">
        {{ item.row[2][item.column.index].evaluateValue }}
        <span
          v-if="item.row[2][item.column.index].duringTimeInterval"
          class="alert-grid__support-word"
          >{{ t('WORD.DURING_THE') }}</span
        >
        {{ item.row[2][item.column.index].duringTimeInterval }}
        {{ item.row[2][item.column.index].comparison }}
        {{ item.row[2][item.column.index].value }}
      </div>
    </template>
    <template #value="{ item }">
      <template v-if="isEventType(item.row[2][item.column.index])">
        <div
          class="base-grid__cell--link"
          @click.stop="
            () =>
              emit('on-click-cell', {
                field: 'value',
                value: item.row[2][item.column.index],
                row: makeRowToObj(item.row[2], props.gridColumns),
                cellIndex: item.column.index,
              })
          "
        >
          <span
            v-if="item.row[2][item.column.index]"
            class="cell-text"
          >
            {{ item.row[2][item.column.index] }}
          </span>
        </div>
      </template>
      <template v-else>
        <span :title="getAlertValue(item.row[2][item.column.index])">{{
          getAlertValue(item.row[2][item.column.index])
        }}</span>
      </template>
    </template>
    <template #detail="{ item }">
      <ev-button
        size="small"
        shape="border"
        :disabled="dashboardPopupListCnt === 0"
        @click="(e) => viewDashboardList(e, makeRowToObj(item.row[2], props.gridColumns))"
        @mouseenter="(e) => onShowDashboardTooltip(e)"
        @mouseleave="(e) => onHideDashboardTooltip(e)"
      >
        {{ dashboardName === '' ? t('WORD.VIEW_DETAIL') : dashboardName }}
        <ev-context-menu
          ref="menuRef"
          custom-class="action-cell-context"
          :items="menuItems"
        />
      </ev-button>
    </template>
    <template #notiStatus="{ item }">
      <alert-status-button-row
        :status-list="item.row[2][item.column.index]"
        :row-index="item.row[0]"
      />
    </template>
  </base-grid>
  <simple-text-tooltip
    :mouse-event="criteriaTooltipMouseEvent"
    :text="criteriaTooltipText"
    base-pos="bottom"
  />
  <base-custom-tooltip
    ref="dashboardTooltipRef"
    custom-class="dashboard-msg-tooltip"
    use-deselect
    use-hover-event
  >
    <p>{{ t('MESSAGE.NO_PAGE_DASHBOARD') }}</p>
  </base-custom-tooltip>
  <setup-dash-board-popup
    v-if="props.type === 'detail' && isVisibleSetupDashBoard"
    v-model:is-show="isVisibleSetupDashBoard"
    :env-key="dashboardEnvKey"
    :is-alert="true"
    @close="setupDashboardPopupClose"
  />
</template>

<style lang="scss">
.alert-grid {
  .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    line-height: 20px;

    &__title {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .base-grid__chips {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &.alert-current,
  &.alert-history {
    .status-tag {
      min-width: 48px;
      padding: 2px 6px 1px;
    }
  }
  .target-list {
    gap: 4px;
    justify-content: center;
    .target-item-wrapper {
      width: auto;
    }
    .target-item:last-child {
      margin-right: 0;
    }
    .more-btn {
      margin-left: 0;
    }
  }
  &__child-icon {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
  }
  &__rule-name {
    display: inline-block !important;
    vertical-align: middle;
    i {
      vertical-align: middle;
      background-color: var(--alert-detail-item-rule-criteria-color);
    }
  }
  &__support-word {
    color: var(--color-gray-02-10);
  }
  &.base-tree-grid .ev-checkbox.disabled {
    display: none !important;
  }

  .ev-grid-toggle {
    margin-right: 4px;
  }
}
.alert-detail .alert-grid {
  .grid-toolbar {
    flex-direction: row;
    &__row.search {
      width: calc(100% - 36px);
    }
    &__row.custom.with-grid-setting {
      width: auto !important;
    }
    /* stylelint-disable */
    .ev-text-field {
      width: 100% !important;
    }
  }
  .tree-grid-toolbar {
    .grid-toolbar {
      align-items: center;

      &__row {
        display: flex;
        align-items: center;
      }

      .ev-input {
        height: 24px !important;
      }
    }
  }
  &.base-tree-grid.has-option-h2,
  &.base-grid.has-option-h2 {
    .toolbar-wrapper + div {
      height: calc(100% - var(--option-height-h1)) !important;
    }
  }
  /* stylelint-enable */
}
.dashboard-msg-tooltip {
  width: 150px;
  max-height: 290px;
  padding: 6px 8px;
  margin-top: 20px;
  margin-left: 20px;
  background-color: var(--alert-grid-tooltip-bg);
  font-size: 11px;
  color: var(--alert-grid-tooltip-color);
}
</style>
