import { Ref, StyleValue, computed, nextTick, ref, watch } from 'vue';
import FloatingSubMenu from './FloatingSubMenu.vue';
import { MenuInfo } from '../types';

export interface Props {
  menuInfo: MenuInfo | null;
  dashboardMenuList?: MenuInfo[];
  analysisBoardMenuList?: MenuInfo[];
}

export const setup = (props: Props) => {
  const subMenuInfo = ref<MenuInfo | null>(null);

  const floatingMenuRef = ref<HTMLDivElement | null>(null);
  const floatingSubMenuRef = ref<InstanceType<typeof FloatingSubMenu> | null>(null);

  const hoveredItemInfo = ref({ top: 0, height: 0 });
  const floatingSubMenuStyle: Ref<StyleValue> = ref({ top: '', height: '', overflowY: 'visible' });

  const computedDashboardMenu = computed(() => {
    if (!props.menuInfo?.dashboardList) return null;
    return {
      ...props.menuInfo.dashboardList,
      subMenuList: props.dashboardMenuList,
    };
  });
  const computedAnalysisBoardMenu = computed(() => {
    if (!props.menuInfo?.analysisBoardList) return null;
    return {
      ...props.menuInfo.analysisBoardList,
      subMenuList: props.analysisBoardMenuList,
    };
  });

  const clearSubMenu = () => {
    subMenuInfo.value = null;
  };

  const onHoverSubMenu = (e: MouseEvent, menuInfo: MenuInfo) => {
    if (!menuInfo.subMenuList?.length) {
      clearSubMenu();
      return;
    }

    const el = e.target as HTMLElement;
    const { offsetTop: top, offsetHeight: height } = el;
    floatingSubMenuStyle.value = { top: '', height: '', overflowY: 'visible' };

    subMenuInfo.value = menuInfo;
    hoveredItemInfo.value = { top, height };
  };

  watch(hoveredItemInfo, async (hoverInfo) => {
    await nextTick();

    const floatingMenuEl = floatingMenuRef.value;
    const floatingSubMenuEl = floatingSubMenuRef.value?.$el ?? floatingSubMenuRef.value;

    if (!floatingMenuEl || !floatingSubMenuEl) {
      return;
    }

    const { top, height } = hoverInfo;

    // 1DEPTH 메뉴
    const floatingMenuRect = floatingMenuEl.getBoundingClientRect();

    // 2DEPTH 메뉴
    const floatingSubMenuRect = floatingSubMenuEl.getBoundingClientRect();
    const { clientHeight } = document.documentElement;

    // 윈도우 화면에서, 메뉴의 위치까지
    const menuItemTop = top + floatingMenuRect.top;

    // 메뉴 아래 부분 잘림 여부
    const isSliceMenuBottom: boolean = menuItemTop + floatingSubMenuRect.height > clientHeight;
    // 메뉴 상단 부분 잘림 여부
    const isSliceMenuTop: boolean = floatingSubMenuRect.height > menuItemTop;
    // 메뉴가 윈도우 화면 보다 큼 여부
    const isSubMenuMoreBigger: boolean = floatingSubMenuRect.height > clientHeight;

    if (isSliceMenuBottom) {
      floatingSubMenuStyle.value = isSliceMenuTop
        ? {
            top: `${-floatingMenuRect.top}px`,
            height: isSubMenuMoreBigger ? `${clientHeight}px` : `${floatingSubMenuRect.height}px`,
            overflowY: isSubMenuMoreBigger ? 'scroll' : 'visible',
          }
        : {
            top: `${top + height - floatingSubMenuRect.height}px`,
            height: '',
            overflowY: 'visible',
          };
    } else {
      floatingSubMenuStyle.value = {
        top: `${top}px`,
        height: '',
        overflowY: 'visible',
      };
    }
  });

  watch(
    () => props.menuInfo,
    (menuInfo) => {
      if (!menuInfo) {
        clearSubMenu();
      }
    },
  );

  return {
    floatingMenuRef,
    floatingSubMenuRef,

    floatingSubMenuStyle,
    subMenuInfo,
    computedDashboardMenu,
    computedAnalysisBoardMenu,

    onHoverSubMenu,
    clearSubMenu,
  };
};
