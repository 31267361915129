import {
  axiosInstance,
  PromiseAxiosResponse,
  timeout5SecAxiosInstance,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import { CreateUserEnvRequest, UserEnvResponse } from '../model';
// 미사용
// 사용자 환경 단일 삭제
const deleteV1UserEnvsAxios = ({
  key,
  tenantId,
  userId,
}: {
  key: string;
  tenantId: string;
  userId: number;
}): PromiseAxiosResponse<string> =>
  axiosInstance.delete('/api/v1/user-envs', {
    params: {
      key,
      tenantId,
      userId,
    },
  });

// 미사용
const getV1UserEnvsAxios = ({
  tenantId,
  userId,
  key,
}: {
  tenantId: string;
  userId: number;
  key?: string;
}): PromiseAxiosResponse<UserEnvResponse> =>
  axiosInstance.get('/api/v1/user-envs', {
    params: {
      tenantId,
      userId,
      key,
    },
  });

// 사용자 환경 전체 조회
const getV2UserEnvsAxios = (): PromiseAxiosResponse<UserEnvResponse> =>
  axiosInstance.get('/api/v2/user-envs');

// 사용자 환경 등록
const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
  /* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};
const postV1UserEnvsAxios = ({
  request,
  frameName,
  isTimeout,
}: {
  request: CreateUserEnvRequest;
  frameName?: string;
  isTimeout?: boolean;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v1/user-envs';
  const httpMethod = 'post';

  const data: any = {
    ...request,
  };
  const config: any = {
    params: {
      request,
      frameName,
    },
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByKeysXmUserEnvControllerAxios = ({
  tenantId,
  userId,
  keys,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  tenantId: string;
  userId: number;
  keys?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserEnvResponse> => {
  const localVarPath = '/api/v2/user-envs';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      keys,
      tenantId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deleteV1UserEnvsAxios,
  getV1UserEnvsAxios,
  getV2UserEnvsAxios,
  postV1UserEnvsAxios,
  getByKeysXmUserEnvControllerAxios,
};
