/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  FieldResponse,
  InfraContainerInfoV7Response,
  InfraContainerRelationV7Response,
  InfraContainerV7Filter,
  InfraContainerV7Response,
  LogResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getContainerListFieldsInfraContainerV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FieldResponse> => {
  const localVarPath = '/api/v7/infra/containers/fields';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainerInfoInfraContainerV7ControllerAxios = ({
  containerId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  containerId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraContainerInfoV7Response> => {
  const localVarPath = '/api/v7/infra/containers/{containerId}/info'.replace(
    `{${'containerId'}}`,
    encodeURIComponent(String(containerId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainerLogInfraContainerV7ControllerAxios = ({
  containerId,
  server,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  containerId: string;
  server?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LogResponse> => {
  const localVarPath = '/api/v7/infra/containers/{containerId}/log'.replace(
    `{${'containerId'}}`,
    encodeURIComponent(String(containerId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      server,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainerRelationInfoInfraContainerV7ControllerAxios = ({
  containerId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  containerId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraContainerRelationV7Response> => {
  const localVarPath = '/api/v7/infra/containers/{containerId}/relation-info'.replace(
    `{${'containerId'}}`,
    encodeURIComponent(String(containerId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainersInfraContainerV7ControllerAxios = ({
  containerFilter,
  column,
  isReport,
  pageNum,
  pageSize,
  reportColumnList,
  search,
  sortType,
  timezone,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  containerFilter: InfraContainerV7Filter;
  column?: string;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  reportColumnList?: Array<string>;
  search?: string;
  sortType?: 'ASC' | 'DESC';
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraContainerV7Response> => {
  const localVarPath = '/api/v7/infra/containers';
  const httpMethod = 'post';

  const data: any = containerFilter;
  const config: any = {
    params: {
      column,
      isReport,
      pageNum,
      pageSize,
      reportColumnList,
      search,
      sortType,
      timezone,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getContainerListFieldsInfraContainerV7ControllerAxios,
  getContainerInfoInfraContainerV7ControllerAxios,
  getContainerLogInfraContainerV7ControllerAxios,
  getContainerRelationInfoInfraContainerV7ControllerAxios,
  getContainersInfraContainerV7ControllerAxios,
};
