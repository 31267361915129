<script setup lang="ts">
import { computed, onMounted } from 'vue';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { DetailInfoData, DetailInfoRenderType } from '@/common/utils/types';
import BaseGridRatioBarCell from '@/common/components/molecules/BaseGridRatioBarCell.vue';
import { useInternational } from '@/common/locale';
import SetupDashBoardPopup from '@/common/components/organisms/setupDashBoardPopup/SetupDashBoardPopup.vue';
import DashBoardListPopup from '@/common/components/organisms/dashBoardListPopup/DashBoardListPopup.vue';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { DashboardKey, useDashboardJump } from '@/common/uses/useDashboardJump';

interface Props {
  envKey?: string;
  title?: string;
  infoDataList?: DetailInfoData[];
  useTooltip?: {
    label: boolean;
  };
  keyInfo?: DashboardKey;
}
interface Emit {
  (
    e: 'click-link',
    value: { field: string; value: string; namespace: string; lists: DetailInfoData[] },
  ): void;
}

const props = withDefaults(defineProps<Props>(), {
  envKey: '',
  title: '',
  infoDataList: () => [],
  useTooltip: () => ({
    label: false,
  }),
  keyInfo: () => ({
    target: '',
    targetId: '',
    namespace: '',
  }),
});

const emit = defineEmits<Emit>();

const getValueContainerClass = (renderType: DetailInfoRenderType): string => {
  const defaultClass = 'information-row__value';
  const isFullType = renderType === 'custom' || renderType === 'grid';
  const fullTypeClass = isFullType ? ` ${defaultClass}--full` : '';
  const renderTypeClass = renderType ? ` ${defaultClass}--${renderType}` : '';

  return `${defaultClass}${fullTypeClass}${renderTypeClass}`;
};

const getNamespaceValue = computed<string>(
  () => props.infoDataList.find(({ field }) => field === 'namespace')?.values[0] ?? '',
);

const isValid = (value: string) => value || value !== '-';

const infoDataListExceptHide = computed(() =>
  (props?.infoDataList ?? []).filter((info) => !info.hide),
);

const { fetchDashboardPopupEnvInfoForKey } = useDashboardPopupEnvStore();
const { t } = useInternational();

const {
  isOpenDashboardSettingPopup,
  isOpenDashboardListPopup,
  dashboardPopupListCnt,
  dashboardName,
  dashboardPopupList,
  moveDashboard,
  viewDashboardList,
} = useDashboardJump();

const onClickSetting = () => {
  isOpenDashboardSettingPopup.value = !isOpenDashboardSettingPopup.value;
};
const setupDashboardPopupClose = async () => {
  isOpenDashboardListPopup.value = false;
  await fetchDashboardPopupEnvInfoForKey(props.envKey);
};

onMounted(async () => {
  await fetchDashboardPopupEnvInfoForKey(props.envKey);
});
</script>

<template>
  <div class="detail-information">
    <div class="header">
      <h3
        v-if="props.title"
        class="detail-information__header"
      >
        {{ props.title }}

        <slot name="header-icon-suffix" />
      </h3>
      <div
        v-if="$slots['header-action']"
        class="header__action"
      >
        <slot name="header-action" />
      </div>
    </div>

    <div class="detail-information__content">
      <slot name="content" />
      <template
        v-for="data in infoDataListExceptHide"
        :key="data.field"
      >
        <dl
          v-if="data.renderType !== 'customRow'"
          class="information-row"
        >
          <dt
            :title="props.useTooltip.label ? data.label : ''"
            class="information-row__label"
          >
            <slot
              v-if="data.useIconInLabel"
              :name="data.field"
              :data="data"
              :label="data.label"
            />
            <span v-else> {{ data.label }}</span>
          </dt>
          <dd :class="getValueContainerClass(data.renderType!)">
            <slot
              v-if="data.renderType === 'grid' || data.renderType === 'grid-fill'"
              :name="data.field"
              :data="data"
            />
            <template v-else>
              <span
                v-for="(value, index) in data.values"
                :key="`${value}-${index}`"
                class="information-row__value--value"
              >
                <span
                  v-if="data.renderType === 'link' && isValid(value)"
                  class="information-row__value--link"
                  @click="
                    () =>
                      emit('click-link', {
                        field: data.field,
                        value,
                        namespace: getNamespaceValue,
                        lists: props.infoDataList,
                      })
                  "
                >
                  {{ value }}
                  <slot
                    v-if="value"
                    name="link-suffix"
                  />
                </span>
                <div
                  v-else-if="data.field === 'name' && data.values[0] && props.keyInfo.targetId"
                  class="detail-name"
                >
                  <span> {{ value }} </span>
                  <div class="detail-name__btn-area">
                    <ev-button
                      size="medium"
                      shape="border"
                      class="detail-name__button"
                      :disabled="dashboardPopupListCnt === 0"
                      @click="() => viewDashboardList(infoDataList, props.keyInfo)"
                    >
                      {{ dashboardName === '' ? t('WORD.VIEW_DETAIL') : dashboardName }}
                    </ev-button>
                    <dash-board-list-popup
                      v-if="isOpenDashboardListPopup"
                      :dashboard-list="dashboardPopupList"
                      :info-data-list="props.infoDataList"
                      :move-dashboard="moveDashboard"
                      :key-info="props.keyInfo"
                    />
                  </div>
                  <div class="detail-name__setting-button">
                    <img
                      src="@/common/assets/icon-setting-fill-dark.svg"
                      width="16"
                      height="16"
                      :title="t('WORD.SETUP_DETAIL')"
                      alt="clickIcon"
                      @click="onClickSetting"
                    />
                    <setup-dash-board-popup
                      v-model:is-show="isOpenDashboardSettingPopup"
                      :env-key="envKey"
                      @close="setupDashboardPopupClose"
                    />
                  </div>
                </div>
                <status-tag
                  v-else-if="data.renderType === 'status' && value"
                  :type="value"
                >
                  {{ value }}
                </status-tag>
                <tag-item
                  v-else-if="data.renderType === 'label' && value"
                  size="xsmall"
                >
                  {{ value }}
                </tag-item>
                <base-grid-ratio-bar-cell
                  v-else-if="data.renderType === 'ratio'"
                  :ratio="+value"
                  has-unit
                />
                <slot
                  v-else-if="data.renderType === 'customValue'"
                  :name="data.field"
                  :data="data"
                  :value="value"
                />
                <span v-else>
                  {{ value }}
                </span>
              </span>
            </template>
          </dd>
        </dl>
        <slot
          v-else
          :name="data.field"
          :data="data"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  position: relative;

  &__action {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
.information-row__value--value {
  width: 100% !important;
  .detail-name {
    display: flex;
    gap: 8px;
    align-items: center;
    &__btn-area {
      height: 20px !important;
      cursor: pointer;
    }
    &__button {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;
      height: 20px !important;
      padding: 4px 8px 4px 10px !important;
      cursor: pointer;
    }
    &__setting-button {
      height: 16px;
      cursor: pointer;
    }
  }
}
</style>
