import { nextTick, ref, watch } from 'vue';

const useTooltipPosition = (props) => {
  const tooltipWrapper = ref<HTMLDivElement>();
  const tooltipPosition = ref({
    left: '0px',
    top: '0px',
  });

  const DISTANCE_TOOLTIP_AND_CURSOR = 20;
  const setTooltipPosition = () => {
    let left;
    let top;

    if (props?.position) {
      left = props.position?.left;
      top = props.position?.top;
    } else if (props?.evt) {
      left = props.evt?.pageX;
      top = props.evt?.pageY;
    }

    const tooltipWrapperSize = tooltipWrapper.value?.getBoundingClientRect();
    if (tooltipWrapperSize) {
      const { width: wrapperWidth, height: wrapperHeight } = tooltipWrapperSize;

      const { scrollLeft, scrollTop } = document.documentElement;

      const expectedPosX = left + DISTANCE_TOOLTIP_AND_CURSOR;
      const maximumPosX =
        document.body.clientWidth + scrollLeft - wrapperWidth - DISTANCE_TOOLTIP_AND_CURSOR;
      if (expectedPosX > maximumPosX) {
        const reversedPosX = left - (wrapperWidth + DISTANCE_TOOLTIP_AND_CURSOR);
        left = Math.max(reversedPosX, 0);
      } else {
        left = expectedPosX;
      }

      const expectedPosY = top + DISTANCE_TOOLTIP_AND_CURSOR;
      const maximumPosY =
        document.body.clientHeight + scrollTop - wrapperHeight - DISTANCE_TOOLTIP_AND_CURSOR;
      if (expectedPosY > maximumPosY) {
        const reversedPosY = top - (wrapperHeight + DISTANCE_TOOLTIP_AND_CURSOR);
        top = Math.max(reversedPosY, 0);
      } else {
        top = expectedPosY;
      }
    }

    tooltipPosition.value = {
      left: `${left}px`,
      top: `${top}px`,
    };
  };

  watch(
    () => [props.position, props.evt],
    async () => {
      if (!props.isShow) {
        return;
      }

      await nextTick();
      setTooltipPosition();
    },
    { immediate: true },
  );

  return {
    tooltipWrapper,
    tooltipPosition,
  };
};

const useTooltipModel = () => {
  const mouseoverEvt = ref<MouseEvent | null>(null);
  const getDynamicEvtType = (isNotUsedEvt = true) => {
    if (!isNotUsedEvt) {
      return {
        mouseoverEvtName: 'mouseover',
        mousemoveEvtName: 'mousemove',
        mouseleaveEvtName: 'mouseleave',
      };
    }
    return {
      mouseoverEvtName: null,
      mousemoveEvtName: null,
      mouseleaveEvtName: null,
    };
  };
  const onMouseover = (e: MouseEvent) => {
    mouseoverEvt.value = e;
  };
  const onMouseleave = () => {
    mouseoverEvt.value = null;
  };

  return {
    mouseoverEvt,
    getDynamicEvtType,
    onMouseover,
    onMouseleave,
  };
};

export { useTooltipModel, useTooltipPosition };
