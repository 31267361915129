import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { StatData, FetchParam, oneMinuteIntervalList } from '@/postgresql/store/singleView/dbStats';
import { MetricResponse } from '@/openapi/data/model';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { MetricRequest } from '@/common/utils';
import { checkValidMetric } from '@/database/utils/utils';

export interface State {
  stats: StatData;
}

export const previewStats: Module<State, RootState> = {
  namespaced: true,
  state: {
    stats: {},
  },
  mutations: {
    setStats(state: State, data: MetricResponse[]) {
      const tempObj = {};
      if (!data) {
        state.stats = tempObj;
        return;
      }

      data.forEach((item) => {
        tempObj[item.dataDefinition?.dataId ?? ''] = transformMetricChartValues(
          item.metrics?.[0].values,
        );
      });

      state.stats = tempObj;
    },
  },
  actions: {
    async fetchDBStats({ commit }, { instanceId, statNames, frameId }: FetchParam) {
      const deduplicationNames: string[] = [...new Set(statNames)];
      const oneMinuteInterval = deduplicationNames.filter((name) =>
        oneMinuteIntervalList.includes(name),
      );
      const category = frameId?.startsWith('mysql') ? 'mysql' : 'postgresql';

      if (oneMinuteInterval.length) {
        const allStat: MetricResponse[] = [];

        const metricRequests = oneMinuteInterval.map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p1h',
          interval: 'I1m',
          category,
          dataId: name.startsWith('db') ? name : `db_${category}_${name}`,
          targetIds: [instanceId],
          summaryType: 'current',
        }));
        const promises = [
          getMetricData({
            metricV7Requests: metricRequests,
            isTimeout: true,
          }),
        ];
        const others = deduplicationNames.filter((name) => !oneMinuteIntervalList.includes(name));
        if (others.length) {
          const othersMetricRequests = others.map<MetricRequest>((name) => ({
            aggregationType: 'byTarget',
            period: 'p3m',
            interval: 'I5s',
            category,
            dataId: name.startsWith('db') ? name : `db_${category}_${name}`,
            targetIds: [instanceId],
            summaryType: 'current',
          }));
          promises.push(
            getMetricData({
              metricV7Requests: othersMetricRequests,
              isTimeout: true,
            }),
          );
        }

        const result = await Promise.allSettled(promises);
        result.forEach((value) => {
          if (value.status === 'fulfilled') {
            const { data } = value.value;
            checkValidMetric(data?.data ?? []);
            allStat.push(...(data?.data ?? []));
          }
        });
        commit('setStats', allStat);
      } else {
        const metricRequests = deduplicationNames.map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p3m',
          interval: 'I5s',
          category,
          dataId: name.startsWith('db') ? name : `db_${category}_${name}`,
          targetIds: [instanceId],
          summaryType: 'current',
        }));

        const { data } = await getMetricData({
          metricV7Requests: metricRequests,
          isTimeout: true,
        });
        checkValidMetric(data?.data ?? []);
        commit('setStats', data?.data ?? []);
      }
    },
    async fetchDBStatsBy10Min({ commit }, { instanceId, statNames, fromTime, toTime, frameId }) {
      const deduplicationNames: string[] = [...new Set(statNames as string[])];
      const category = frameId?.startsWith('mysql') ? 'mysql' : 'postgresql';

      const metricRequests = deduplicationNames.map<MetricRequest>((name) => ({
        aggregationType: 'byTarget',
        interval: 'I10m',
        fromTime,
        toTime,
        category,
        dataId: name.startsWith('db') ? name : `db_${category}_${name}`,
        targetIds: [instanceId],
        summaryType: 'current',
      }));
      const { data } = await getMetricData({
        metricV7Requests: metricRequests,
      });
      checkValidMetric(data?.data ?? []);
      commit('setStats', data?.data ?? []);
    },
    async fetchDBStatsByMin({ commit }, { instanceId, statNames, fromTime, toTime, frameId }) {
      const deduplicationNames: string[] = [...new Set(statNames as string[])];
      const category = frameId?.startsWith('mysql') ? 'mysql' : 'postgresql';

      const metricRequests = deduplicationNames.map<MetricRequest>((name) => ({
        aggregationType: 'byTarget',
        interval: 'I1m',
        fromTime,
        toTime,
        category,
        dataId: name.startsWith('db') ? name : `db_${category}_${name}`,
        targetIds: [instanceId],
        summaryType: 'current',
      }));
      const { data } = await getMetricData({
        metricV7Requests: metricRequests,
      });
      checkValidMetric(data?.data ?? []);
      commit('setStats', data?.data ?? []);
    },
  },
  getters: {
    getAllStatData: (state: State) => state.stats,
  },
};
