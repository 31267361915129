<script setup lang="ts">
import {
  setup,
  Props,
  Emit,
} from '@/common/components/molecules/timePeriod/timePeriodV2Calendar.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,
  datePickerValues,
  timePickerValues,
  timeValidationInfo,
  isOverLimit,
  onClickResetDateTimePicker,
  getDisabledDate,
  onClickCancel,
  onClickOk,
} = setup(props, emit);
</script>

<template>
  <div class="time-period-v2-calendar">
    <ev-calendar
      v-model="datePickerValues"
      mode="dateRange"
      :options="{
        disabledDate: getDisabledDate,
      }"
    />
    <div class="time-period-v2-calendar__time-picker-wrapper">
      <div class="time-period-v2-calendar__time-picker">
        <span class="time-period-v2-calendar__time-picker-label">{{ t('WORD.START') }}</span>
        <span class="time-period-v2-calendar__time-picker-date">{{ datePickerValues[0] }}</span>
        <ev-text-field
          v-model="timePickerValues[0]"
          :class="{ error: !timeValidationInfo.fromTime || isOverLimit }"
        >
          <template #icon-prefix>
            <ev-icon icon="ev-icon-time" />
          </template>
        </ev-text-field>
      </div>
      <div class="time-period-v2-calendar__time-picker">
        <span class="time-period-v2-calendar__time-picker-label">{{ t('WORD.END') }}</span>
        <span class="time-period-v2-calendar__time-picker-date">{{ datePickerValues[1] }}</span>
        <ev-text-field
          v-model="timePickerValues[1]"
          :class="{ error: !timeValidationInfo.toTime || isOverLimit }"
        >
          <template #icon-prefix>
            <ev-icon icon="ev-icon-time" />
          </template>
        </ev-text-field>
      </div>
    </div>
    <div class="time-period-v2-calendar__button-wrapper">
      <button
        class="time-period-v2-calendar__reset-button"
        @click="onClickResetDateTimePicker"
      >
        {{ t('WORD.RESET_TO_TODAY') }}
      </button>
      <div>
        <ev-button
          type="default"
          size="small"
          @click="onClickCancel"
        >
          {{ t('WORD.CANCEL') }}
        </ev-button>
        <ev-button
          type="primary"
          size="small"
          :disabled="!timeValidationInfo.fromTime || !timeValidationInfo.toTime || isOverLimit"
          @click="onClickOk"
        >
          {{ t('WORD.OK') }}
        </ev-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.time-period-v2-calendar {
  display: flex;
  position: absolute;
  top: 100%;
  right: var(--time-period-select-width);
  z-index: $on-base-sub-menu;
  flex-direction: column;
  height: auto;
  padding: 20px;
  margin: 4px 2px 0 0;
  border-radius: 2px;
  background-color: var(--dropdown-bg-color);
  box-shadow: var(--dropdown-shadow);

  .ev-calendar-date-area:not(:first-child) {
    border: none !important;
  }

  &__time-picker-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid black;

    .time-period-v2-calendar__time-picker {
      display: flex;
      flex: auto;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      width: auto;

      &:nth-last-child(1) {
        justify-content: flex-end;
      }
    }

    .time-period-v2-calendar__time-picker-label {
      font-size: 12px;
      color: var(--time-period-v2-time-picker-label-font-color);
    }

    .time-period-v2-calendar__time-picker-date {
      font-size: 13px;
      color: var(--time-period-v2-time-picker-date-font-color);
    }
  }

  .ev-input {
    height: 20px !important;
    color: var(--time-period-v2-time-picker-label-font-color) !important;

    &.error {
      border-color: var(--input-text-error-border-color) !important;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;

    .time-period-v2-calendar__reset-button {
      cursor: pointer;
      background-color: transparent;
      font-size: 10px;
      color: var(--time-period-v2-reset-date-font-color);
    }

    > div {
      display: flex;
      gap: 8px;
    }

    .ev-button {
      width: 60px !important;
    }
  }
}
</style>
