import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import {
  getRedoMysqlV7ControllerAxios,
  getReplicationMysqlV7ControllerAxios,
  getSortByIntervalMysqlV7ControllerAxios,
} from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import {
  RedoItemV7,
  ReplicationInfoItemV7,
  ReplicationItemV7,
  SortByIntervalItemV7,
} from '@/openapi/mysqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface StatusState {
  sorting: SortByIntervalItemV7[];
  sortingErrorStatusText: string;
  replication: ReplicationItemV7;
  replicationList: ReplicationItemV7['replicationList'];
  replicationType: 'REPLICA' | 'SLAVE';
  recentReplication: ReplicationInfoItemV7;
  replicationErrorStatusText: string;
  redoLogFileSize: RedoItemV7;
  redoLogFileSizeErrorStatusText: string;
}
export const status: Module<StatusState, RootState> = {
  namespaced: true,
  state: {
    sorting: [],
    sortingErrorStatusText: '',
    replication: {},
    replicationList: [],
    replicationType: 'REPLICA',
    recentReplication: {},
    replicationErrorStatusText: '',
    redoLogFileSize: {},
    redoLogFileSizeErrorStatusText: '',
  },
  mutations: {
    setSorting: (state: StatusState, data: SortByIntervalItemV7[]) => {
      state.sorting = data;
    },
    setSortingErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.sortingErrorStatusText = errorStatusText;
    },
    setReplication: (state: StatusState, data: ReplicationItemV7) => {
      state.replication = data;
    },
    setReplicationArr: (state: StatusState, data: ReplicationItemV7['replicationList']) => {
      state.replicationList = data;
    },
    setReplicationType: (state: StatusState, data: StatusState['replicationType']) => {
      state.replicationType = data;
    },
    setReplicationList: (state: StatusState, data: ReplicationInfoItemV7) => {
      state.recentReplication = data;
    },
    setReplicationErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.replicationErrorStatusText = errorStatusText;
    },
    setRedoLogFileSize: (state: StatusState, data: RedoItemV7) => {
      state.redoLogFileSize = data;
    },
    setRedoLogFileSizeErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.redoLogFileSizeErrorStatusText = errorStatusText;
    },
  },
  actions: {
    async dispatchSorting({ commit }, instanceId) {
      const frameName = 'Sorting';
      try {
        const { data } = await getSortByIntervalMysqlV7ControllerAxios({
          instanceId,
          interval: 'I5s',
          frameName: FRAME_NAMES.MYSQL_SINGLE_VIEW.OVERVIEW_SORTING,
        });
        commit('setSorting', data?.data);
        commit('setSortingErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setSortingErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async dispatchReplication({ commit }, instanceId) {
      const frameName = 'Replication';
      try {
        const { data } = await getReplicationMysqlV7ControllerAxios({
          instanceId,
          frameName: FRAME_NAMES.MYSQL_SINGLE_VIEW.OVERVIEW_REPLICATION,
        });

        if (data?.data?.[0]) {
          const replicationData = data.data[0];
          const { replicationList = [], replicationTypeV7: replicationType = '' } = replicationData;

          commit('setReplication', replicationData);
          commit('setReplicationArr', replicationData.replicationList);

          if (replicationList?.length) {
            commit('setReplicationList', replicationList[replicationList.length - 1]);
          }

          commit('setReplicationType', replicationType);
        }
        commit('setReplicationErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setReplicationErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async dispatchRedoLogFileSize({ commit }, instanceId) {
      const frameName = 'RedoLogFileSize';
      try {
        const { data } = await getRedoMysqlV7ControllerAxios({
          instanceId,
          frameName: FRAME_NAMES.MYSQL_SINGLE_VIEW.OVERVIEW_REDO_LOG,
        });

        if (data?.data?.[0]) {
          commit('setRedoLogFileSize', data.data[0]);
        }
        commit('setRedoLogFileSizeErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setRedoLogFileSizeErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getSorting: (state: StatusState) => state.sorting || [],
    getRecentSorting: (state: StatusState) => state.sorting[state.sorting.length - 1] || {},
    getSortingErrorStatusText: (state: StatusState) => state.sortingErrorStatusText || '',
    getReplication: (state: StatusState) => state.replication,
    getReplicationList: (state: StatusState) => state.replicationList || [],
    getReplicationType: (state: StatusState) => state.replicationType,
    getRecentReplication: (state: StatusState) => state.recentReplication,
    getReplicationErrorStatusText: (state: StatusState) => state.replicationErrorStatusText || '',
    getRedoLogFileSize: (state: StatusState) => state.redoLogFileSize || {},
    getRedoLogFileSizeErrorStatusText: (state: StatusState) =>
      state.redoLogFileSizeErrorStatusText || '',
  },
};
