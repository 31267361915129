import { computed } from 'vue';
import { Store } from 'vuex';
import { useInternational } from '@/common/locale';
import { RootState, useStore } from '@/common/store';
import { MenuInfo } from '../types';

export interface Props {
  info: MenuInfo;
  selected?: boolean;
}

export const setup = () => {
  const { t } = useInternational();
  const store: Store<RootState> = useStore();

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  return { t, userInfo };
};
