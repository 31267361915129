import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import {
  TimeFormat,
  TimePeriodInfo,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface InitTimeOption {
  isPaused?: boolean;
  timePeriod?: string;
  timeFormat?: TimeFormat;
}

export const useTimePeriodStore = defineStore('mainViewTimePeriod', () => {
  const timePeriodInfo = ref<Record<string, TimePeriodInfo>>({});
  const initTimePeriod = (path: string, initTimeOption: InitTimeOption = {}) => {
    timePeriodInfo.value[path] = getInitPeriodInfo(initTimeOption);
  };
  const updateTimePeriod = (path: string, timePeriodData: TimePeriodInfo) => {
    timePeriodInfo.value[path] = timePeriodData;
  };
  const getPagePeriodInfo = (path: string) => {
    if (!timePeriodInfo.value?.[path]) {
      initTimePeriod(path);
    }
    return timePeriodInfo.value?.[path];
  };

  return {
    timePeriodInfo,
    initTimePeriod,
    updateTimePeriod,
    getPagePeriodInfo,
  };
});
