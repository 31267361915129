<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import { type Props, setup } from './floatingMenuElement.setup';
import { BASE_MENU_VALUE } from '../baseMenu.define';

const props = defineProps<Props>();
const { isSelected, computedChildrenLength, onClickElement } = setup(props);
</script>

<template>
  <li
    :class="['floating-element', { selected: isSelected }]"
    @click="onClickElement"
  >
    <div class="floating-element__inner-text">
      <span>{{ menuInfo.text }}</span>
      <badge-item
        v-if="
          menuInfo.value === BASE_MENU_VALUE.DASHBOARD ||
          menuInfo.value === BASE_MENU_VALUE.ANALYSISBOARD
        "
        html-type="div"
        size="xsmall"
        >{{ computedChildrenLength }}
      </badge-item>
    </div>
    <i
      v-if="menuInfo.subMenuList?.length"
      class="floating-element__arrow-icon"
    />
  </li>
</template>

<style scoped lang="scss">
.floating-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--lnb-menu-height);
  padding: 0 16px 0 32px;
  cursor: pointer;
  color: var(--lnb-menu-depth2-font-color);
  transition: background-color $animate-base;

  &:hover {
    background-color: var(--lnb-menu-hover-bg-color) !important;
    color: var(--lnb-menu-depth2-font-color);
  }

  &.selected {
    background-color: var(--lnb-menu-selected-bg-color);
    color: var(--lnb-menu-depth2-selected-font-color);
  }

  &__inner-text {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    span {
      display: inline-block;
      max-width: 100%;
      margin-right: 6px;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .badge-item {
      height: 16px;
      background-color: var(--gnb-menu-badge-color) !important;
      color: var(--gnb-text-color) !important;

      &:hover {
        background-color: var(--gnb-menu-badge-color) !important;
      }
    }
  }

  &__arrow-icon {
    width: 12px;
    height: 12px;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 12px 12px;
    background-color: var(--icon-base-color);
    mask-image: url('@/common/assets/icon-triangle-right.svg');
  }
}
</style>
