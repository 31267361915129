import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useViewManagerStore = defineStore('viewManagerStore', () => {
  const isPageLoading = ref(false);
  const setLoading = (isLoading: boolean) => {
    isPageLoading.value = isLoading;
  };

  const isLoadingRouterForEach = ref(false);
  const setIsLoadingRouterForEach = (isLoading: boolean) => {
    isLoadingRouterForEach.value = isLoading;
  };

  return {
    isPageLoading,
    setLoading,
    isLoadingRouterForEach,
    setIsLoadingRouterForEach,
  };
});
