import { computed } from 'vue';
import { SelectboxItem } from '@/common/utils/types';

export interface BasePaginationProps<T extends number[] = number[]> {
  current?: number;
  total: number;
  visiblePage?: number;
  align?: 'left' | 'center' | 'right';
  showSetting?: boolean;
  showPageInfo?: boolean;
  items?: T;
  perData?: T[number];
}

const DEFAULT_ITEMS = [20, 30, 40, 50] as const;

export const setup = <T extends number[]>(props: BasePaginationProps<T>, emit) => {
  const selectedPerData = computed({
    get: () => props.perData ?? (props.items ?? DEFAULT_ITEMS)[0],
    set: (val) => emit('update:per-data', val),
  });
  const selectorItems: SelectboxItem[] = (props.items ?? DEFAULT_ITEMS).map((v) => ({
    name: v.toString(),
    value: v,
  }));

  const currentPage = computed({
    get: () => props.current ?? 1,
    set: (val) => emit('update:current', val),
  });

  const currentRowRange = computed(() => {
    let fromRowNum = selectedPerData.value * (currentPage.value - 1) + 1;
    if (fromRowNum < 0 || fromRowNum > props.total) {
      fromRowNum = 0;
    }

    let toRowNum = selectedPerData.value * currentPage.value;
    if (toRowNum > props.total) {
      toRowNum = props.total;
    }

    return `${fromRowNum}-${toRowNum}`;
  });

  return {
    selectedPerData,
    selectorItems,
    currentPage,
    currentRowRange,
  };
};
