import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { TenantInfo } from '@/common/api/resTypes/tenantRes';
import { getV1TenantsAxios } from '@/openapi/meta/api/xm-tenant-controller-api';

export interface State {
  tenants: TenantInfo[];
}

export const tenant: Module<State, RootState> = {
  namespaced: true,
  state: {
    tenants: [],
  },
  mutations: {
    setTenants(state: State, tenants: TenantInfo[]) {
      state.tenants = tenants;
    },
  },
  actions: {
    async getTenants({ commit }): Promise<void> {
      const { data } = await getV1TenantsAxios();
      await commit('setTenants', data || []);
    },
  },
  getters: {
    getTenants: (state: State): TenantInfo[] => state.tenants,
  },
};
