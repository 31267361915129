import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { VIEW_MODE } from '@/common/define/common.define';

const PREFIX_BUSINESS = 'business_';

const businessMenu: RouteRecordRaw[] = [
  {
    path: '/business/',
    name: `${PREFIX_BUSINESS}Business`,
    component: RtmViewport,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS,
    },
    children: [
      {
        path: 'list',
        name: `${PREFIX_BUSINESS}Business List`,
        component: () =>
          import(
            /* webpackChunkName: "business-view" */ '@/service/views/serviceView/ServiceView.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone],
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_LIST,
        },
      },
      {
        path: 'map',
        name: `${PREFIX_BUSINESS}Business Map`,
        component: () =>
          import(
            /* webpackChunkName: "service-view" */ '@/service/views/businessMap/BusinessMap.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone],
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_MAP,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
    ],
  },
];

const getBusinessNaviMenu = () => {
  const filteredBusinessMenu = extractAccessibleRoutes(businessMenu);

  return [...filteredBusinessMenu];
};

export { businessMenu, getBusinessNaviMenu };
