/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseActiveTxnItemDto,
  ApiResponseApplicationGroupConfigDetail,
  ApiResponseApplicationListItem,
  ApiResponseHeapDump,
  ApiResponseHeapDumpHistory,
  ApiResponseLibraryDao,
  ApiResponseLoadedClass,
  ApiResponsePoolStateDao,
  ApiResponseThreadDump,
  ApiResponseThreadDumpItem,
  ApiResponseTransactionInfoItem,
  ApiResponseWasDetailInfo,
  ApiResponseWasGcInfo,
  ClassSourceResponse,
  FieldResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getApplicationListApplicationWasControllerAxios = ({
  applicationGroupIds,
  column,
  isReport,
  pageNum,
  pageSize,
  report,
  reportColumnList,
  search,
  sortType,
  timezone,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  applicationGroupIds?: Array<string>;
  column?: string;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  report?: boolean;
  reportColumnList?: Array<string>;
  search?: string;
  sortType?: 'ASC' | 'DESC';
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseApplicationListItem> => {
  const localVarPath = '/api/v7/application';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationGroupIds,
      column,
      isReport,
      pageNum,
      pageSize,
      report,
      reportColumnList,
      search,
      sortType,
      timezone,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasActiveTransactionsApplicationWasControllerAxios = ({
  wasId,
  activeTxnName,
  isReport,
  report,
  reportColumnList,
  timezone,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  activeTxnName?: string;
  isReport?: boolean;
  report?: boolean;
  reportColumnList?: Array<string>;
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseActiveTxnItemDto> => {
  const localVarPath = '/api/v7/application/{wasId}/active-transactions'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      activeTxnName,
      isReport,
      report,
      reportColumnList,
      timezone,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasInfoApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseWasDetailInfo> => {
  const localVarPath = '/api/v7/application/{wasId}'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasConfigApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseApplicationGroupConfigDetail> => {
  const localVarPath = '/api/v7/application/{wasId}/config'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getConnectionPoolApplicationWasControllerAxios = ({
  wasId,
  isReport,
  pageNum,
  pageSize,
  report,
  reportColumnList,
  timezone,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  report?: boolean;
  reportColumnList?: Array<string>;
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponsePoolStateDao> => {
  const localVarPath = '/api/v7/application/{wasId}/connection-pool'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      isReport,
      pageNum,
      pageSize,
      report,
      reportColumnList,
      timezone,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationListFieldsApplicationWasControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FieldResponse> => {
  const localVarPath = '/api/v7/application/fields';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasGcApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseWasGcInfo> => {
  const localVarPath = '/api/v7/application/{wasId}/garbage-collection'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHeapDumpApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseHeapDump> => {
  const localVarPath = '/api/v7/application/{wasId}/heapdump'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHeapDumpHistoryApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseHeapDumpHistory> => {
  const localVarPath = '/api/v7/application/{wasId}/heapdump/history'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasLibrariesApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseLibraryDao> => {
  const localVarPath = '/api/v7/application/{wasId}/libraries'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasLoadedClassApplicationWasControllerAxios = ({
  wasId,
  searchName,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  searchName?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseLoadedClass> => {
  const localVarPath = '/api/v7/application/{wasId}/loadedClasses'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      searchName,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasLoadedClassViewApplicationWasControllerAxios = ({
  className,
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  className: string;
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ClassSourceResponse> => {
  const localVarPath = '/api/v7/application/{wasId}/loadedClasses/class-source'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      className,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationListStatusApplicationWasControllerAxios = ({
  applicationGroupIds,
  search,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  applicationGroupIds?: Array<string>;
  search?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseApplicationListItem> => {
  const localVarPath = '/api/v7/application/status';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationGroupIds,
      search,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasThreadDumpsApplicationWasControllerAxios = ({
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseThreadDump> => {
  const localVarPath = '/api/v7/application/{wasId}/threadDumpList'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasThreadDumpDetailApplicationWasControllerAxios = ({
  threadId,
  wasId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  threadId: number;
  wasId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseThreadDumpItem> => {
  const localVarPath = '/api/v7/application/{wasId}/threadDumpList/{threadId}'
    .replace(`{${'threadId'}}`, encodeURIComponent(String(threadId)))
    .replace(`{${'wasId'}}`, encodeURIComponent(String(wasId)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWasTransactionsApplicationWasControllerAxios = ({
  fromTime,
  toTime,
  wasId,
  isReport,
  pageNum,
  pageSize,
  report,
  reportColumnList,
  timezone,
  txnName,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  wasId: string;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  report?: boolean;
  reportColumnList?: Array<string>;
  timezone?: string;
  txnName?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseTransactionInfoItem> => {
  const localVarPath = '/api/v7/application/{wasId}/transactions'.replace(
    `{${'wasId'}}`,
    encodeURIComponent(String(wasId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isReport,
      pageNum,
      pageSize,
      report,
      reportColumnList,
      timezone,
      toTime,
      txnName,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getApplicationListApplicationWasControllerAxios,
  getWasActiveTransactionsApplicationWasControllerAxios,
  getWasInfoApplicationWasControllerAxios,
  getWasConfigApplicationWasControllerAxios,
  getConnectionPoolApplicationWasControllerAxios,
  getApplicationListFieldsApplicationWasControllerAxios,
  getWasGcApplicationWasControllerAxios,
  getHeapDumpApplicationWasControllerAxios,
  getHeapDumpHistoryApplicationWasControllerAxios,
  getWasLibrariesApplicationWasControllerAxios,
  getWasLoadedClassApplicationWasControllerAxios,
  getWasLoadedClassViewApplicationWasControllerAxios,
  getApplicationListStatusApplicationWasControllerAxios,
  getWasThreadDumpsApplicationWasControllerAxios,
  getWasThreadDumpDetailApplicationWasControllerAxios,
  getWasTransactionsApplicationWasControllerAxios,
};
