import { RouteRecordRaw } from 'vue-router';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';

const PREFIX_LOGS = 'logs_';

const logsMenu: RouteRecordRaw[] = [
  {
    path: '/logs/',
    name: `${PREFIX_LOGS}Logs`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS,
    },
    children: [
      {
        path: 'logging',
        name: `${PREFIX_LOGS}Logging`,
        component: () =>
          import(/* webpackChunkName: "logs-logging-view" */ '../views/LoggingView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS_LOGGING,
        },
      },
      {
        path: 'liveTail',
        name: `${PREFIX_LOGS}Live Tail`,
        component: () =>
          import(/* webpackChunkName: "logs-liveTail-view" */ '../views/LiveTailView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS_LIVE_TAIL,
        },
      },
    ],
  },
];

const getLogsNaviMenu = () => {
  const filteredLogsMenu = extractAccessibleRoutes(logsMenu);

  return [...filteredLogsMenu];
};

export { logsMenu, getLogsNaviMenu };
