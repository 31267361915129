import { Module } from 'vuex';
import { RootState } from '@/common/store';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { MyInfo } from '@/common/api/resTypes/userInfoRes';
import { USER_PROFILE_IMAGE, USER_PROFILE_IMAGE_DEFAULT, USER_ROLE } from '@/common/utils/define';
import { webStorageController } from '@/common/utils/webStorage.util';
import { setUserLanguage } from '@/common/locale';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getMyInfoUserV7ControllerAxios } from '@/openapi/metaV6/api/user-v7-controller-api';

export type BaseInfo = Pick<MyInfo, 'email' | 'profileImage' | 'rolePermissionId' | 'activeId'>;
export interface TimezoneInfo {
  name: string;
  value: string;
  utcTimeOffset: string;
}

export interface State {
  myInfo: MyInfo;
  timezone: MyInfo['timezone'];
  isShowWindow: boolean;
}

const INIT_MY_INFO: MyInfo = {
  userId: -1,
  name: '',
  activeId: '',
  email: '',
  company: '',
  department: '',
  position: '',
  language: 'NONE',
  phone: '',
  timezone: 'Asia/Seoul',
  utcTimeOffset: '',
  slack: '',
  profileImage: '',
  tenantOwned: null,
  groups: [],
  theme: 'light',
  role: 1,
  rolePermissionId: '',
  roleName: '',
  onSiteEnabled: true,
  emailEnabled: false,
  smsEnabled: false,
  passwordChangeType: '',
  loginPopup: true,
  employeeId: '',
  ssoId: '',
  enablePassword: false,
  rolePermissionName: '',
};

export const myInfo: Module<State, RootState> = {
  namespaced: true,
  state: { myInfo: cloneDeep(INIT_MY_INFO), timezone: 'Asia/Seoul', isShowWindow: false },
  mutations: {
    initAccountInfo: (state: State) => {
      state.myInfo = cloneDeep(INIT_MY_INFO);
      state.timezone = 'Asia/Seoul';
      state.isShowWindow = false;
    },
    setAccountInfo: (state: State, info: MyInfo) => {
      state.myInfo = info;
      state.timezone = info.timezone;
      if (info) {
        if (!USER_PROFILE_IMAGE.includes(state.myInfo.profileImage)) {
          state.myInfo.profileImage = USER_PROFILE_IMAGE_DEFAULT;
        }
        if (info?.timezone) {
          dayjs.tz.setDefault(info.timezone);
        }
      }
    },
    setIsShowWindow: (state: State, flag: boolean) => {
      state.isShowWindow = flag;
    },
    setInviteInfo: (state: State, { userId, userName }: { userId: number; userName: string }) => {
      state.myInfo.userId = userId;
      state.myInfo.name = userName;
    },
  },
  actions: {
    dispatchMyInfo: async ({ commit }) => {
      const { data } = await getMyInfoUserV7ControllerAxios({
        frameName: FRAME_NAMES.CONFIG_MY_INFO.INFORMATION,
      });
      commit('setAccountInfo', data);
      webStorageController.setItem({
        type: 'local',
        key: 'language',
        value: data.language,
      });
      setUserLanguage(data.language ?? '');
    },
  },
  getters: {
    getAccountInfo: (state: State) => state.myInfo,
    getTimeZone: (state: State) => state.timezone,
    getSelectedBaseInformation: (state: State) => ({
      email: state.myInfo.email,
      profileImage: state.myInfo.profileImage,
      rolePermissionId: state.myInfo.rolePermissionId, // NOTE: 정보 수정시 필요한 값
      activeId: state.myInfo.activeId, // NOTE: 정보 수정시 필요한 값
    }),
    getIsShowWindow: (state: State) => state.isShowWindow,
    getInviteInfo: (state: State) => ({
      userId: state.myInfo.userId,
      userName: state.myInfo.name,
    }),
    getMyRole: (state: State) => {
      const myRole = USER_ROLE.find((item) => item.value === state.myInfo.role);
      return myRole?.name.toLowerCase() ?? USER_ROLE[1].name.toLowerCase();
    },
  },
};
