import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { TopEventStatV7Item } from '@/openapi/mysqlV7/model';
import { topEventStatEventStatV7ControllerAxios } from '@/openapi/mysqlV7/api/event-stat-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface TopEventState {
  topEvent: TopEventStatV7Item[];
  errorStatusText: string;
}

export const topEvent: Module<TopEventState, RootState> = {
  namespaced: true,
  state: {
    topEvent: [],
    errorStatusText: '',
  },
  mutations: {
    setTopEvent(state: TopEventState, data: TopEventStatV7Item[]) {
      state.topEvent = data;
    },
    setErrorStatusText: (state: TopEventState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchTopEvent({ commit }, instanceId) {
      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.STATICS_EVENTS_TOP_EVENT;
      try {
        const { data } = await topEventStatEventStatV7ControllerAxios({
          instanceId,
          size: 5,
          frameName,
        });
        commit('setTopEvent', data.data);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopEvent: (state: TopEventState) => state.topEvent,
    getEventName: (state: TopEventState) => state.topEvent.map((v) => v.eventName).reverse(),
    getTimerWait: (state: TopEventState) =>
      setEvBarChartData(state.topEvent.map((v) => v.timerWait)),
    getErrorStatusText: (state: TopEventState) => state.errorStatusText,
  },
};
