/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  CreateUserEnvRequest,
  UserEnvResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const removeXmUserEnvControllerAxios = ({
  key,
  all,
  global,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  key: string;
  all?: boolean;
  global?: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v8/user-envs';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      all,
      global,
      key,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByKeysXmUserEnvControllerAxios = ({
  global,
  keys,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  global?: boolean;
  keys?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserEnvResponse> => {
  const localVarPath = '/api/v8/user-envs';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      global,
      keys,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addXmUserEnvControllerAxios = ({
  request,
  global,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: Array<CreateUserEnvRequest>;
  global?: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v8/user-envs';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      global,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  removeXmUserEnvControllerAxios,
  getByKeysXmUserEnvControllerAxios,
  addXmUserEnvControllerAxios,
};
