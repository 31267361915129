import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-toml';
import 'prismjs/components/prism-xml-doc';
import 'prismjs/components/prism-properties';
import 'prismjs/components/prism-ini';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';
import 'vue-json-pretty/lib/styles.css';
import {
  CSSProperties,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  watch,
  watchEffect,
} from 'vue';
import { copyTextToClipboard } from '@/common/utils/textCopyUtils';
import type { TextType } from '@/common/components/molecules/textViewer/textViewer.type';
import { useInternational } from '@/common/locale';

export interface Option {
  useSeeMore?: boolean;
  useScrollTo?: boolean;
}

export interface Style {
  [key: string]: any;
}

export interface Props {
  type?: TextType;
  useLineNumbers?: boolean;
  useBorder?: boolean;
  title?: string;
  text?: string;
  copy?: boolean;
  option?: Option;
  style?: Style;
  isShow?: boolean;
  noDataMsg?: string;
}

type JsonType = Object | null;

const isScrollable = (el: Element | undefined) => {
  if (!el) return false;
  const hasScrollableContent = el.scrollHeight > el.clientHeight;
  return hasScrollableContent;
};

export const setup = (props: Required<Props>) => {
  const { t } = useInternational();

  const ctx = getCurrentInstance()!.appContext.config.globalProperties;

  const preWrapper = ref<HTMLPreElement>();
  const clickCopy = () => {
    copyTextToClipboard(ctx, props.text);
  };

  const jsonObj = ref<any>(null);

  const convertTextToObj = (text: string): JsonType => {
    let result: JsonType;

    try {
      result = JSON.parse(text);
    } catch (e) {
      console.log(e);
      result = null;
    }

    return result;
  };

  const more = ref({
    isExist: false,
    isExpand: true,
    isFold: false,
    text: 'More',
  });

  const containerStyle = ref<CSSProperties>({
    height: '',
  });

  const expandMessage = () => {
    if (more.value.isExpand) {
      containerStyle.value = { height: '100%' };
      more.value.isExpand = false;
      more.value.isFold = true;
      more.value.text = 'Less';
    } else {
      containerStyle.value = { height: props?.style.height };
      more.value.isExpand = true;
      more.value.isFold = false;
      more.value.text = 'More';
    }
    if (props.option?.useScrollTo) {
      nextTick(() => {
        const el = preWrapper.value?.closest('.text-viewer');
        el?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      });
    }
  };

  const highLightPrism = async () => {
    if (props.type === 'json' || !props.isShow) {
      return;
    }

    await nextTick();
    Prism.highlightAll();
  };

  watch(
    () => props?.style.height,
    (newHeight) => {
      containerStyle.value = { height: newHeight };
    },
    { immediate: true },
  );

  watchEffect(() => {
    more.value.isExist = (props?.option?.useSeeMore ?? false) && isScrollable(preWrapper.value);
    more.value.isExpand = more.value.isExist;
  });

  watch(
    () => [props.type, props.text] as const,
    ([type, text]) => {
      if (type === 'json') {
        jsonObj.value = text ? convertTextToObj(text) : null;
      } else {
        highLightPrism();
      }
    },
    { immediate: true },
  );

  watch(
    () => props.isShow,
    () => {
      highLightPrism();
    },
    { immediate: true },
  );

  onMounted(() => {
    if (document.documentElement.dataset.theme === 'light') {
      import('prismjs/themes/prism.css');
    }
  });

  return {
    jsonObj,
    preWrapper,
    containerStyle,
    more,
    clickCopy,
    expandMessage,
    t,
  };
};
