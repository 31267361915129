import { PieChartOption } from './pieSettingsChartOption.setup';

export const DefaultPieWidgetChartOption: Omit<
  Required<PieChartOption>,
  'chartTheme' | 'sortByFieldName'
> = {
  displayName: '',
  displayValue: '',
  fieldNameList: [],
  showLegend: true,
  markerInfo: {
    show: true,
    showTotal: true,
  },
  orderBy: 'DESC',
  limitNum: 50,
  legendStatus: {},
};
