import { NotificationType } from '@/alert/utils/notification.types';
import { NotificationMethod } from '@/openapi/alert/model';
import { computed, getCurrentInstance } from 'vue';

export interface Props {
  statusList?: NotificationMethod[];
  rowIndex: number;
}

export interface Emit {
  (e: 'click-status-button', iconType: NotificationType): void;
}

export const setup = (emit: Emit) => {
  const instance = getCurrentInstance();
  const isClickable = computed<boolean>(() => !!instance?.vnode?.props?.onClickStatusButton);

  const getIconType = (methodType?: string) =>
    ({
      slack: 'slack',
      email: 'mail',
      telegram: 'telegram',
      message: 'sms',
      sms: 'sms',
      webhook: 'webhook',
    })[methodType ?? ''] || methodType;

  const onClickStatusButton = (status: NotificationMethod) => {
    if (!status.methodType) {
      return;
    }
    emit('click-status-button', status.methodType as NotificationType);
  };

  return { isClickable, getIconType, onClickStatusButton };
};
