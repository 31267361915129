import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE, DEFAULT_STAT_CHART_OPTION } from '@/common/utils/define';
import { getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { SlowQueryOption } from '@/common/components/organisms/slowQuery/useSlowQuery';
import { postV1UserEnvsAxios } from '@/openapi/meta/api/xm-user-env-controller-api';
import { InstanceV2Item } from '@/openapi/meta/model';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

type ChartType = 'oneDay' | 'realTime' | 'pa';
export interface StatChartOption {
  common: {
    type: 'line' | 'bar';
    showLegend: boolean;
    tooltipSorting: boolean;
    stack: boolean;
    fill: boolean;
  };
  line: {
    lineWidth: number;
    point: boolean;
  };
  bar: {
    thickness: number;
    borderRadius: number;
  };
}
export interface FrameOption {
  name: string;
  chartType?: ChartType;
  comparisonDate?: string;
  stats: string[];
  chartOption: StatChartOption;
}
export interface StatInfo extends FrameOption {
  frameId: string;
  displayName?: string;
}

interface State {
  selectedInstanceId: string | null;
  selectedInstanceInfo: InstanceV2Item;
  statInfoForPostgresql: StatInfo[];
  slowQueryOption: SlowQueryOption;

  // 실패한 API의 frame 정보 저장 (key: frameName, value: errorStatusText)
  framesByFailedApi: Map<string, string>;
}

const STAT_INFO_KEY = 'PG_STAT_INFO';
const SELECTED_ID_KEY = 'PG_SINGLE_VIEW_SELECTED_ID';
const SLOW_QUERY_OPTION_KEY = 'PG_SINGLE_VIEW_SLOW_QUERY_OPTION';
const SLOW_QUERY_DEFAULT_OPTION: SlowQueryOption = {
  isSetMaxValue: true,
  pointSize: 2,
  maxValue: 60,
};
export const DEFAULT_STAT_INFO: StatInfo[] = [
  {
    frameId: 'statChart0',
    name: 'Logical + Physical IO',
    displayName: 'Logical + Physical IO',
    stats: ['db_postgresql_blkshit', 'db_postgresql_blksread'],
    chartOption: {
      common: {
        type: 'line',
        showLegend: false,
        tooltipSorting: false,
        stack: true,
        fill: true,
      },
      line: {
        lineWidth: 2,
        point: false,
      },
      bar: {
        thickness: 1,
        borderRadius: 0,
      },
    },
  },
  {
    frameId: 'statChart1',
    name: 'db_postgresql_tps',
    displayName: 'Tps (count/sec)',
    stats: ['db_postgresql_tps'],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
  {
    frameId: 'statChart2',
    name: 'db_postgresql_rowshitratio',
    displayName: 'Rows Hit Ratio (%)',
    stats: ['db_postgresql_rowshitratio'],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
  {
    frameId: 'statChart3',
    name: 'Dml Rows',
    displayName: 'Dml Rows',
    stats: [
      'db_postgresql_tupreturned',
      'db_postgresql_tupfetched',
      'db_postgresql_tupinserted',
      'db_postgresql_tupupdated',
      'db_postgresql_tupdeleted',
    ],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
];

export const singleViewEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedInstanceId: null,
    selectedInstanceInfo: {},
    statInfoForPostgresql: [],
    slowQueryOption: {} as SlowQueryOption,
    framesByFailedApi: new Map(),
  },
  mutations: {
    setSelectedInstanceId(state: State, id: string) {
      state.selectedInstanceId = id;
    },
    setSelectedInstanceInfo(state: State, info: State['selectedInstanceInfo']) {
      state.selectedInstanceInfo = info;
    },
    setStatInfoForPostgresql: (state: State, statInfoArr: StatInfo[]) => {
      state.statInfoForPostgresql = statInfoArr;
    },
    setFramesByFailedApi: (state: State, failedApiInfo) => {
      state.framesByFailedApi.set(failedApiInfo.frameName, failedApiInfo.statusText);
    },
    setSlowQueryOption: (state: State, slowQueryOption: SlowQueryOption) => {
      state.slowQueryOption = slowQueryOption;
    },
    deleteFramesByFailedApi: (state: State, frameNameOfFailedApi: string) => {
      if (state.framesByFailedApi.has(frameNameOfFailedApi)) {
        state.framesByFailedApi.delete(frameNameOfFailedApi);
      }
    },
    initFramesByFailedApi: (state: State) => {
      state.framesByFailedApi = new Map();
    },
    initSelectedInfo: (state: State) => {
      state.selectedInstanceId = null;
      state.selectedInstanceInfo = {};
    },
  },
  actions: {
    fetchStatInfoForPostgresql: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(STAT_INFO_KEY);

      if (data) {
        const statInfo = JSON.parse(data).map((info) => ({
          ...info,
          stats: mapMetricNamesToDataIds({ dbType: DB_TYPE.POSTGRESQL, metricNames: info.stats }),
        }));
        commit('setStatInfoForPostgresql', statInfo);
        return;
      }

      commit('setStatInfoForPostgresql', data ? JSON.parse(data) : DEFAULT_STAT_INFO);
    },
    saveStatInfoForPostgresql: async ({ commit }, statInfoArr: StatInfo[]) => {
      try {
        await commit('setStatInfoForPostgresql', statInfoArr);
        await postV1UserEnvsAxios({
          request: {
            key: STAT_INFO_KEY,
            value: JSON.stringify(statInfoArr),
          },
          frameName: FRAME_NAMES.USER_ENVIRONMENT.STAT_INFO,
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: STAT_INFO_KEY, value: JSON.stringify(statInfoArr) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchLastSelectedId: async ({ commit, dispatch, rootGetters }) => {
      try {
        const data = rootGetters['userEnv/getUserEnvMap'].get(SELECTED_ID_KEY);
        const idInfo = JSON.parse(data ?? '{}');
        const folderList = rootGetters['monitoringFolder/getFolders'];
        const { folderId, instanceId, selectedInfo } = getRtmSelectedInfo({
          idInfo,
          folderList,
          dbType: DB_TYPE.POSTGRESQL,
        });

        // userEnv에서 조회한 instanceId와 실제 화면에 보여져야 할 instanceId가 다른 경우
        // e.g. userEnv에서는 A라는 인스턴스가 저장되어 있지만 A라는 인스턴스가 disable로 변경된 상태인 경우
        if (idInfo.instanceId !== instanceId) {
          await dispatch('saveLastSelectedId', {
            folderId,
            instanceId,
          });
        }

        commit('setSelectedInstanceId', instanceId);
        commit('setSelectedInstanceInfo', selectedInfo);
      } catch (e: any) {
        console.log(e);
      }
    },
    saveLastSelectedId: async ({ commit, rootGetters }, selectedInfo: Record<string, string>) => {
      if (!selectedInfo.folderId || !selectedInfo.instanceId) {
        return;
      }
      try {
        await commit('setSelectedInstanceId', selectedInfo.instanceId);
        const selectedInstanceInfo = rootGetters['monitoringFolder/getFolders']
          .find((item) => item.folderId === selectedInfo.folderId)
          .instances.find((item) => item.instanceId === selectedInfo.instanceId);
        await commit('setSelectedInstanceInfo', selectedInstanceInfo);
        await postV1UserEnvsAxios({
          request: {
            key: SELECTED_ID_KEY,
            value: JSON.stringify(selectedInfo),
          },
          frameName: FRAME_NAMES.USER_ENVIRONMENT.SELECTED_INSTANCE_ID,
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SELECTED_ID_KEY, value: JSON.stringify(selectedInfo) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchSlowQueryOption: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(SLOW_QUERY_OPTION_KEY);
      commit('setSlowQueryOption', data ? JSON.parse(data) : SLOW_QUERY_DEFAULT_OPTION);
    },
    saveSlowQueryOption: async ({ commit }, slowQueryOption: SlowQueryOption) => {
      try {
        await commit('setSlowQueryOption', slowQueryOption);
        await postV1UserEnvsAxios({
          request: {
            key: SLOW_QUERY_OPTION_KEY,
            value: JSON.stringify(slowQueryOption),
          },
          frameName: FRAME_NAMES.USER_ENVIRONMENT.SLOW_QUERY_OPTION,
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SLOW_QUERY_OPTION_KEY, value: JSON.stringify(slowQueryOption) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getSelectedInstanceId: (state: State) => state.selectedInstanceId,
    getSelectedInstanceInfo: (state: State) => state.selectedInstanceInfo,
    getSelectedDbType: (state: State) => state.selectedInstanceInfo.type,
    getSelectedDbVersion: (state: State) => state.selectedInstanceInfo.instanceState?.version,

    getStatInfoForPostgresql: (state: State) => state.statInfoForPostgresql,

    isExistFramesByFailedApi: (state: State): boolean => !!state.framesByFailedApi.size,
    getFramesByFailedApi: (state: State) => state.framesByFailedApi,
    getSlowQueryOption: (state: State) => state.slowQueryOption,
  },
};
