import { store } from '@/common/store';
import router from '@/common/router';
import { i18n } from '@/common/locale';
import { useAuthStore } from '@/common/stores/auth';
import { useApiTraceStore } from '@/common/stores/api-trace';

export const setInterceptors = (instance, getTokenStr?) => {
  instance.interceptors.request.use(
    (config) => {
      const token = getTokenStr
        ? store.getters[getTokenStr]
        : useAuthStore().getToken('accessToken');
      config.headers.Authorization = token ? `Bearer ${token}` : null;

      if (config?.params?.frameName) {
        config.headers.frameName = config.params.frameName;
        delete config.params.frameName;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (res) => {
      const { frameName } = res.config?.headers ?? {};
      if (frameName) {
        useApiTraceStore().setTraceInfo({
          frameName,
          apiResponse: res,
        });
      }

      return res;
    },
    (e) => {
      const { frameName } = e.config?.headers ?? {};
      if (frameName) {
        useApiTraceStore().setTraceInfo({
          frameName,
          apiResponse: e?.response,
          isError: true,
        });
      }

      if (e?.response) {
        const { status } = e.response;
        if (status === 401) {
          console.warn(i18n.global.t('NOTI.API.NOT_PERMISSION_LOGIN_PAGE'));
          router.push('/logout');
        } else if (status === 403) {
          console.warn(i18n.global.t('NOTI.API.NOT_PERMISSION'));
        } else if (status === 400) {
          console.warn(i18n.global.t('NOTI.API.INVALID_REQUEST'));
        } else if (status === 405) {
          console.warn(i18n.global.t('NOTI.API.NOT_ALLOW_METHOD'));
        } else if (status === 500) {
          console.warn(i18n.global.t('NOTI.API.SERVER_ERROR'));
        } else if (status === 503) {
          console.warn(i18n.global.t('NOTI.API.NOT_AVAILABLE_SERVICE'));
        }
      } else if (e?.request) {
        console.log(i18n.global.t('NOTI.API.NOT_RECEIVE')); // 취소 시 로그 => 빌드시엔 안보이게
      } else {
        console.warn(i18n.global.t('NOTI.API.REQUEST_ERROR'));
      }
      return Promise.reject(e);
    },
  );

  return instance;
};
