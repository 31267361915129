import { Module } from 'vuex';
import { RootState } from '@/common/store';

export interface State {
  isShowWindow: boolean;
}

export const presetStatGroup: Module<State, RootState> = {
  namespaced: true,
  state: {
    isShowWindow: false,
  },
  mutations: {
    setIsShowWindow(state: State, isShowWindow: boolean) {
      state.isShowWindow = isShowWindow;
    },
  },
  getters: {
    getIsShowWindow: (state: State) => state.isShowWindow,
  },
};
