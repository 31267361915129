import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, ActionContext } from 'vuex';
import { auth } from '@/common/store/auth';

// PG - Common
import { metric as postgresqlMetric } from '@/postgresql/store/metric';

// PG - Single View
import { activeBackends as postgresqlActiveBackends } from '@/postgresql/store/singleView/activeBackends';
import { slowQuery as postgresqlSlowQuery } from '@/postgresql/store/singleView/slowQuery';
import { overview as postgresqlOverview } from '@/postgresql/store/singleView/overview';
import { status as postgresqlStatus } from '@/postgresql/store/singleView/status';
import { adminRefVacuum as postgresqlAdminRefVacuum } from '@/postgresql/store/singleView/adminRefVacuum';
import { adminRefAdditionalInfo as postgresqlAdminRefAdditionalInfo } from '@/postgresql/store/singleView/adminRefAdditionalInfo';
import { instanceAlerts as postgresqlInstanceAlerts } from '@/postgresql/store/singleView/instanceAlerts';
import { topDiffStatistics as postgresqlTopDiffStatistics } from '@/postgresql/store/singleView/topDiffStatistics';
import { topEvent as postgresqlTopEvent } from '@/postgresql/store/singleView/topEvent';
import { topSql as postgresqlTopSql } from '@/postgresql/store/singleView/topSql';
import { topFunction as postgresqlTopFunction } from '@/postgresql/store/singleView/topFunction';
import { objectScanRatio as postgresqlObjectScanRatio } from '@/postgresql/store/singleView/objectScanRatio';
import { topObject as postgresqlTopObject } from '@/postgresql/store/singleView/topObject';
import { trendSummary as postgresqlTrendSummary } from '@/postgresql/store/singleView/trendSummary';
import { dbStats as postgresqlDbStats } from '@/postgresql/store/singleView/dbStats';
import { singleViewEnv as postgresqlSingleViewEnv } from '@/postgresql/store/singleView/singleViewEnv';
import { lockTree as postgresqlLockTree } from '@/postgresql/store/singleView/lockTree';

// PG - Multi View
import { multiViewEnv as postgresqlMultiViewEnv } from '@/postgresql/store/multiView/multiViewEnv';
import { overview as postgresqlMultiViewOverview } from '@/postgresql/store/multiView/overview';
import { dbSessionType as postgresqlDbSessionType } from '@/postgresql/store/multiView/dbSessionType';
import { slowQuery as postgresqlMultiViewSlowQuery } from '@/postgresql/store/multiView/slowQuery';
import { rankMetric as postgresqlRankMetric } from '@/postgresql/store/multiView/rankMetric';

// Dashboard View
import { statChartOption } from '@/postgresql/store/singleView/statChartOption';
import { previewStats } from '@/postgresql/store/singleView/preview';
import { presetStatGroup } from '@/postgresql/store/singleView/presetStatGroup';

// Common
import { dbStatsBy10Min } from '@/postgresql/store/singleView/dbStatsBy10Min';
import { dbMetric } from '@/common/store/dbMetric/dbMetric';
import { userEnv } from '@/common/store/userEnv/userEnv';

// Config
import { myInfo } from '@/config/store/myInfo';
import { tenant } from '@/config/store/tenant';
import { alert } from '@/config/store/alert';
import { monitoringFolder } from '@/config/store/monitoringFolder';
import { monitoringInstance } from '@/config/store/monitoringInstance';
import { agentManagement } from '@/config/store/agentManagement';
import { webhook } from '@/config/store/webhook';

// PA
import { postgresqlPaCondition } from '@/postgresql/store/postgresqlPaCondition';
import { oraclePaCondition } from '@/oracle/store/oraclePaCondition';
import { mysqlPaCondition } from '@/mysql/store/mysqlPaCondition';
import { sqlserverPaCondition } from '@/sqlServer/store/sqlserverPaCondition';
import { paStatChartInfo } from '@/common/store/paStatChartInfo/paStatChartInfo';

// Oracle - Multi View
import { dbMetric as oracleMetric } from '@/oracle/store/multiView/dbMetric';
import { multiViewEnv as oracleMultiViewEnv } from '@/oracle/store/multiView/multiViewEnv';
import { dbSessionType as oracleDbSessionType } from '@/oracle/store/multiView/dbSessionType';
import { overview as oracleOverview } from '@/oracle/store/multiView/overview';
import { multiViewSessions as oracleMultiViewSessions } from '@/oracle/store/multiView/multiViewSessions';

// MySQL - Common
import { metric as mysqlMetric } from '@/mysql/store/metric';

// MySQL - Single View
import { singleViewEnv as mysqlSingleViewEnv } from '@/mysql/store/singleView/singleViewEnv';

import { dbStats as mysqlDbStats } from '@/mysql/store/singleView/dbStats';
import { slowQuery as mysqlSlowQuery } from '@/mysql/store/singleView/slowQuery';
import { lockTree as mysqlLockTree } from '@/mysql/store/singleView/lockTree';
import { scanType as mysqlScanType } from '@/mysql/store/singleView/scanType';
import { thread as mysqlThread } from '@/mysql/store/singleView/thread';
import { overview as mysqlOverview } from '@/mysql/store/singleView/overview';
import { status as mysqlStatus } from '@/mysql/store/singleView/status';

import { mysqlAdminRef } from '@/mysql/store/singleView/adminRef';
import { trendSummary as mysqlTrendSummary } from '@/mysql/store/singleView/trendSummary';
import { topDiffStatistics as mysqlTopDiffStatistics } from '@/mysql/store/singleView/topDiffStatistics';
import { topEvent as mysqlTopEvent } from '@/mysql/store/singleView/topEvent';
import { topSql as mysqlTopSql } from '@/mysql/store/singleView/topSql';
import { topSqlQueryError as mysqlTopSqlQueryError } from '@/mysql/store/singleView/topSqlQueryError';

// MySQL - Multi View
import { multiViewEnv as mysqlMultiViewEnv } from '@/mysql/store/multiView/multiViewEnv';
import { overview as mysqlMultiViewOverview } from '@/mysql/store/multiView/overview';
import { dbSessionType as mysqlDbSessionType } from '@/mysql/store/multiView/dbSessionType';
import { slowQuery as mysqlMultiViewSlowQuery } from '@/mysql/store/multiView/slowQuery';
import { rankMetric as mysqlRankMetric } from '@/mysql/store/multiView/rankMetric';

// SQLSERVER - Single View
import { singleViewEnv as sqlserverSingleViewEnv } from '@/sqlServer/store/singleView/singleViewEnv';

export interface RootState {
  token: string;
  isLoading: boolean;
}
// define injection key
export const key: InjectionKey<Store<RootState>> = Symbol('Root');

export const store = createStore<RootState>({
  state: {
    token: '',
    isLoading: false,
  },
  mutations: {
    setIsLoading: (state: RootState, flag: boolean) => {
      state.isLoading = flag;
    },
  },
  actions: {
    setA: ({ commit }: ActionContext<RootState, RootState>, payload: number) => {
      commit('setA', payload);
    },
  },
  getters: {
    getIsLoading: (state: RootState) => state.isLoading,
  },
  modules: {
    auth,
    statChartOption,
    previewStats,
    presetStatGroup,
    myInfo,
    tenant,
    agentManagement,
    alert,
    dbMetric,
    userEnv,
    monitoringFolder,
    monitoringInstance,
    webhook,
    postgresqlActiveBackends,
    postgresqlOverview,
    postgresqlStatus,
    postgresqlAdminRefVacuum,
    postgresqlAdminRefAdditionalInfo,
    postgresqlSlowQuery,
    postgresqlInstanceAlerts,
    postgresqlTopDiffStatistics,
    postgresqlTopEvent,
    postgresqlTopSql,
    postgresqlTopFunction,
    postgresqlObjectScanRatio,
    postgresqlTopObject,
    postgresqlTrendSummary,
    postgresqlDbStats,
    postgresqlSingleViewEnv,
    postgresqlLockTree,
    postgresqlPaCondition,
    postgresqlMetric,
    postgresqlMultiViewEnv,
    postgresqlMultiViewOverview,
    postgresqlDbSessionType,
    postgresqlMultiViewSlowQuery,
    postgresqlRankMetric,
    dbStatsBy10Min,
    paStatChartInfo,
    oraclePaCondition,
    oracleMultiViewEnv,
    oracleMetric,
    oracleDbSessionType,
    oracleOverview,
    oracleMultiViewSessions,
    mysqlMetric,
    mysqlDbStats,
    mysqlSingleViewEnv,
    mysqlMultiViewEnv,
    mysqlAdminRef,
    mysqlSlowQuery,
    mysqlPaCondition,
    mysqlLockTree,
    mysqlThread,
    mysqlScanType,
    mysqlStatus,
    mysqlTrendSummary,
    mysqlOverview,
    mysqlTopDiffStatistics,
    mysqlTopEvent,
    mysqlTopSql,
    mysqlTopSqlQueryError,
    mysqlMultiViewOverview,
    mysqlDbSessionType,
    mysqlMultiViewSlowQuery,
    mysqlRankMetric,
    sqlserverSingleViewEnv,
    sqlserverPaCondition,
  },
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
