import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetChartDataStatusInfo } from '@/common/utils';

export const checkOneDayCompareChartData =
  () =>
  (
    rawData: MetricRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    return [chartDataStatus[0]];
  };
