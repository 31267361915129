import { ref, UnwrapRef } from 'vue';
import WorkerManager from '@/worker/WorkerManager';
import { ApiTraceInfo } from '@/worker/utils';
import { Command } from '@/worker/commands';
import { useAsync, UseAsyncOptions } from '@/worker/composables/useAsync';
import { RepeatOptions, useRepeat } from '@/worker/composables/useRepeat';
import { useAuthStore } from '@/common/stores/auth';
import { assign, isObject } from 'lodash-es';
import { getUtcOffset } from '@/common/utils/commonUtils';
import { WidgetChartDataStatusInfo } from '@/common/utils';

interface RepeatInfo extends RepeatOptions {
  isRepeat: boolean;
  timeout?: number;
}
interface CommandOptions extends UseAsyncOptions {
  repeatInfo?: RepeatInfo;
}

export interface DashboardCommand extends Command<'dashboard'> {
  recallParams?: any;
}

const DEFAULT_DASHBOARD_OPTION = {
  timeout: 5_000,
  isRepeat: true,
  isImmediate: false,
  isSetup: true,
};

const isValidParams = (params) => {
  if (!isObject(params)) {
    return false;
  }
  return !!Object.keys(params).length;
};

const useApiExecuteSettings = <T>(command: DashboardCommand, options?: CommandOptions) => {
  const { getToken } = useAuthStore();
  const data = ref<T>(options?.initialData);
  const chartDataStatus = ref<WidgetChartDataStatusInfo[]>([]);
  const apiTraceInfo = ref<ApiTraceInfo>();
  const workerManager = new WorkerManager();

  let executeCommand = command;
  let storedParams;

  const promise = (params?, cmd?) => {
    const paramsInfo = isValidParams(params) ? { params } : {};
    const cmdInfo = cmd || {};
    const additionalInfo = {
      token: getToken('accessToken'),
      utcOffset: getUtcOffset(),
    };
    executeCommand = assign(executeCommand, paramsInfo, cmdInfo, additionalInfo);

    if (!options?.repeatInfo?.isRepeat || params?.isResetRecall) {
      executeCommand.params.recall = null;
    } else if (storedParams) {
      executeCommand.params.recall = storedParams;
    }

    executeCommand.params.apiKey = executeCommand.params.apiKey ?? 'defaultApi';

    return workerManager
      .execute<{
        state: UnwrapRef<T>;
        trace?: ApiTraceInfo;
        recallParams?: any;
        chartDataStatus: WidgetChartDataStatusInfo[];
      }>(executeCommand as Command<any>)
      .then((value) => {
        const { state, trace, recallParams, chartDataStatus: chartDataStatusInfoList } = value;
        data.value = state;
        chartDataStatus.value = chartDataStatusInfoList;
        apiTraceInfo.value = trace;
        storedParams = recallParams;
      });
  };

  const abortApiController = {
    byKey: (apiKey: string) => workerManager.abortApiByKey(apiKey),
    exceptKey: (apiKey: string) => workerManager.abortAllApiExceptKey(apiKey),
    all: () => workerManager.abortAllApi(),
  };

  const { isLoading, error, execute } = useAsync(promise, options);

  return {
    data,
    chartDataStatus,
    apiTraceInfo,
    isLoading,
    error,
    execute,
    abortApiController,
  };
};

const useRepeatSettings = ({ command, options, execute }) => {
  const repeatInfo = assign({ ...DEFAULT_DASHBOARD_OPTION }, options?.repeatInfo);

  if (!repeatInfo.isRepeat) {
    return {};
  }
  let isResetRecall = false;
  const repeatFetch = (params?) => {
    const paramsInfo = isResetRecall || !params ? params : assign(params ?? {}, { isResetRecall });
    execute(paramsInfo);
    isResetRecall = false;
  };

  const repeatOptions: RepeatOptions = {
    isSetup: repeatInfo.isSetup,
    isImmediate: repeatInfo.isImmediate,
  };

  const { resetFetch, clearFetch } = useRepeat(repeatFetch, repeatInfo.timeout, repeatOptions);

  const updateFetch = (params) => {
    const paramsInfo = assign(command.params ?? {}, params);
    isResetRecall = paramsInfo.isResetRecall ?? false;

    resetFetch(
      {
        fn: () => repeatFetch(paramsInfo),
      },
      repeatInfo.timeout,
      true,
    );
  };

  return {
    updateFetch,
    clearFetch,
  };
};

const useDashboardCommand = <T>(
  reqCommand: Omit<DashboardCommand, 'namespace'>,
  options?: CommandOptions,
) => {
  const command: DashboardCommand = {
    ...reqCommand,
    namespace: 'dashboard',
  };

  const { data, chartDataStatus, apiTraceInfo, isLoading, error, execute, abortApiController } =
    useApiExecuteSettings<T>(command, options);

  const { updateFetch, clearFetch } = useRepeatSettings({
    command,
    options,
    execute,
  });

  return {
    data,
    chartDataStatus,
    apiTraceInfo,
    isLoading,
    error,
    fetchData: execute,
    resetFetchData: updateFetch,
    clearFetch,
    abortApiController,
  };
};

export { useDashboardCommand };
