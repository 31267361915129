import { Ref, ref } from 'vue';
import { ORACLE_DEFAULT_FETCH_CYCLE } from '@/common/utils/define';
import { ChartData, FetchInfo } from '@/common/utils/types';
import { Command } from '@/worker/commands';
import { CommandOptions, useCommand } from '@/worker/composables/useCommand';
import { defineStore } from 'pinia';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export const useOracleDbMetricStore = defineStore('oracleDbMetric', () => {
  const chartDataList = ref<Record<number, Ref<ChartData>>>({});
  const errorMsgInfos = ref({});
  const fetchCycleInfos = ref({});
  const clearFetchInfos = ref({});
  const defaultFetchCycle: FetchInfo = ORACLE_DEFAULT_FETCH_CYCLE.dbMetric;

  const previewChartDataList = ref<Record<string, Ref<ChartData>>>({});
  const previewErrorMsgInfos = ref({});

  const fetchMetricChartData = ({
    instanceIds,
    metricName,
    frameId,
    metricIntervalInfo,
  }: {
    instanceIds: string[];
    metricName: string;
    frameId: number;
    metricIntervalInfo?: (string | number)[];
  }) => {
    const command: Command<'oracle'> = {
      namespace: 'oracle',
      method: 'dbMetricChart',
      params: {
        frameName: FRAME_NAMES.DB_METRIC.METRIC,
        metricNames: [metricName],
        instanceIds,
        interval:
          metricIntervalInfo?.[1] || fetchCycleInfos.value[frameId]?.[1] || defaultFetchCycle[1],
      },
    };
    const options: CommandOptions = {
      initialData: {},
      repeatInfo: {
        isRepeat: true,
        isImmediate: true,
        repeatFetchInfo: metricIntervalInfo || fetchCycleInfos.value[frameId] || defaultFetchCycle,
      },
    };

    const { data, fetchCycleInfo, error, clearFetch } = useCommand<ChartData>(command, options);

    chartDataList.value[frameId] = data;
    errorMsgInfos.value[frameId] = error;
    fetchCycleInfos.value[frameId] = fetchCycleInfo;
    clearFetchInfos.value[frameId] = clearFetch;
  };

  const fetchPreviewChartData = ({
    instanceIds,
    metricName,
  }: {
    instanceIds: string[];
    metricName: string;
  }) => {
    const command: Command<'oracle'> = {
      namespace: 'oracle',
      method: 'dbMetricChart',
      params: {
        metricNames: [metricName],
        instanceIds,
        interval: 'I5s',
      },
    };
    const options: CommandOptions = {
      initialData: {},
      immediate: true,
    };
    const { data, error } = useCommand<ChartData>(command, options);

    previewChartDataList.value[metricName] = data;
    previewErrorMsgInfos.value[metricName] = error;
  };

  return {
    chartDataList,
    errorMsgInfos,
    fetchCycleInfos,
    clearFetchInfos,
    fetchMetricChartData,

    fetchPreviewChartData,
    previewChartDataList,
    previewErrorMsgInfos,
  };
});
