<script setup lang="ts">
interface Props {
  htmlType?: 'button' | 'div' | 'span';
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  type?: '' | 'border';
  icon?: string;
  disabled?: boolean;
}

interface Emit {
  (e: 'click', evt: any): void;
  (e: 'icon-click'): void;
}

withDefaults(defineProps<Props>(), {
  htmlType: 'div',
  size: 'medium',
  type: '',
  icon: '',
  disabled: false,
});

const emit = defineEmits<Emit>();
</script>

<template>
  <component
    :is="htmlType"
    class="badge-item"
    :class="[`badge-item--${size}`, type && `badge-item--${type}`, disabled && 'disabled']"
    :disabled="disabled"
    @click.self="!disabled && emit('click', $event)"
  >
    <slot />
    <ev-icon
      v-if="icon"
      :icon="icon"
      :size="size === 'xsmall' || size === 'small' ? 'xsmall' : 'small'"
      @click="!disabled && emit('icon-click')"
    />
  </component>
</template>

<style lang="scss" scoped>
.badge-item {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 29px;
  background-color: var(--badge-bg-color);
  font-size: 13px;
  line-height: 24px;
  color: var(--badge-font-color);

  &:not(div, span, .disabled, :disabled) {
    cursor: pointer;
  }

  &--xsmall {
    height: 16px;
    padding: 0 8px;
    font-size: 11px;
    line-height: 16px;
  }

  &--small {
    height: 20px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 20px;
  }

  &--large {
    height: 28px;
    padding: 0 14px;
    font-size: 14px;
    line-height: 28px;
  }

  &--border {
    border: 1px solid var(--badge-type-border-border-color);
    background-color: var(--badge-type-border-bg-color);
  }

  > i {
    margin: 0 -2px 0 4px;
    background-color: var(--badge-icon-color);
    font-size: inherit;
  }

  &:hover:not(span, .disabled),
  &.selected {
    background-color: var(--badge-active-bg-color);
  }

  &.disabled {
    background-color: var(--badge-disabled-bg-color);
    color: var(--badge-disabled-font-color);

    > i {
      background-color: var(--badge-disabled-font-color) !important;
    }
  }
}
</style>
