import { useInternational } from '@/common/locale';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { storeToRefs } from 'pinia';
import { checkValid, showSuccessMsg } from '@/common/utils/commonUtils';
import { REG_EXP } from '@/common/utils';
import { debounce } from 'lodash-es';
import { DashboardData } from '@/dashboard/utils/types';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { clickoutside } from '@/common/directives/clickoutside';

export interface Props {
  envKey: string;
  isAlert?: boolean;
  isShow: boolean;
}
export interface Emit {
  (e: 'close'): void;
  (e: 'update:isShow', value: boolean): void;
}

const setup = (props: Props, emit: Emit) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const isBottomFilterExpand = ref(true);
  const searchValue = ref('');
  const dashboardListViewStore = useDashboardListViewStore();
  const { dashboardList } = storeToRefs(dashboardListViewStore);
  const checkedList = ref([]);
  const searchFilterList = ref<DashboardData[]>([]);
  const { fetchDashboardList } = dashboardListViewStore;
  const isLoading = ref(false);
  const { fetchDashboardPopupEnvInfoForKey, saveEnvInfoForKey, initSetupDashboardData } =
    useDashboardPopupEnvStore();
  const { setupDashBoardData } = storeToRefs(useDashboardPopupEnvStore());

  const errorMsg = ref({
    name: '',
    url: '',
    urlName: '',
  });

  const isPopupShow = computed({
    get: () => props.isShow,
    set: (isShow) => emit('update:isShow', isShow),
  });
  const vClickOutside = clickoutside;
  const clickOutside = (e) => {
    if (e.target.getAttribute('alt') === 'clickIcon') {
      return;
    }
    isPopupShow.value = false;
    emit('close');
  };

  const onClickSave = async () => {
    setupDashBoardData.value.dashboardList = checkedList.value.map((v) => {
      const dashboard = dashboardList.value.find((d) => d.dashboardId === v);
      return {
        dashboardId: dashboard?.dashboardId,
        dashboardName: dashboard?.dashboardName,
      };
    });
    if (!setupDashBoardData.value.urlYN) {
      setupDashBoardData.value.url = '';
      setupDashBoardData.value.urlName = '';
    }

    const setupDashBoard = JSON.stringify(setupDashBoardData.value);
    await saveEnvInfoForKey(props.envKey, setupDashBoard);
    showSuccessMsg(ctx, t('MESSAGE.SAVED'));
    isPopupShow.value = false;
    emit('close');
  };

  const isValid = ref(false);

  const validateName = (value: FocusEvent) => {
    // TODO: 문구 변경
    if (!checkValid(REG_EXP.BUTTON_NAME, value)) {
      errorMsg.value.name = t('NOTI.VALIDATION.NOT_ENGLISH');
    } else {
      errorMsg.value.name = '';
    }
  };
  const validateUrl = (value) => {
    if (value.trim() === '') {
      errorMsg.value.url = t('MESSAGE.REQUIRE_DATA');
    } else {
      errorMsg.value.url = '';
    }
  };
  const validateUrlName = (value) => {
    if (value.trim() === '') {
      errorMsg.value.urlName = t('MESSAGE.REQUIRE_DATA');
    } else {
      errorMsg.value.urlName = '';
    }
  };

  watch(
    searchValue,
    debounce(() => {
      searchFilterList.value = dashboardList.value.filter(
        (v) => v.dashboardName?.toLowerCase().includes(searchValue.value.toLowerCase()),
      );
    }, 300),
  );

  watch(
    () => [setupDashBoardData.value, errorMsg.value],
    () => {
      const { urlYN, url, urlName } = setupDashBoardData.value || {};

      if (!urlYN) {
        errorMsg.value.url = '';
        errorMsg.value.urlName = '';
      }

      const isError =
        errorMsg.value.name !== '' || errorMsg.value.url !== '' || errorMsg.value.urlName !== '';

      if (isError) {
        isValid.value = false;
      } else isValid.value = !(urlYN && (!url.trim() || !urlName.trim()));
    },
    { deep: true },
  );

  onMounted(async () => {
    isLoading.value = true;
    initSetupDashboardData();
    await fetchDashboardList();
    await fetchDashboardPopupEnvInfoForKey(props.envKey);

    checkedList.value = setupDashBoardData.value.dashboardList
      .filter((i) => i.dashboardId)
      .map((v: any) => v.dashboardId);

    isLoading.value = false;
    searchFilterList.value = dashboardList.value;
  });

  return {
    setupDashBoardData,
    onClickSave,
    isValid,
    searchFilterList,
    clickOutside,
    vClickOutside,

    isBottomFilterExpand,
    searchValue,
    errorMsg,
    validateName,
    validateUrl,
    validateUrlName,
    checkedList,
    isLoading,
    t,
  };
};

export { setup };
