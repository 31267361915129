import { isEqual } from 'lodash-es';

const onUnMounted = (el) => {
  const componentEl = el;
  document.removeEventListener('wheel', componentEl.vueWheelOutside);
  componentEl.vueWheelOutside = null;
};

const onMounted = (el, binding) => {
  const componentEl = el;
  const callback = binding.value;

  const handler = (e) => {
    if (
      callback &&
      componentEl &&
      typeof callback === 'function' &&
      componentEl !== e.target &&
      !componentEl.contains(e.target)
    ) {
      callback(e);
    }
  };
  componentEl.vueWheelOutside = handler;
  document.addEventListener('wheel', handler);
};

const onUpdated = (el, binding) => {
  if (isEqual(binding.value, binding.oldValue)) {
    return;
  }
  onMounted(el, binding);
};

const wheeloutside = {
  mounted: onMounted,
  updated: onUpdated,
  unmounted: onUnMounted,
};

export { wheeloutside };
