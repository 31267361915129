import { CustomColumn, GridColumnType } from '@/common/utils';
import {
  FilterItem,
  FilterSearchOperator,
  FilterSelectedValues,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { isArray, isBoolean, isNumber, isString, isObject } from 'lodash-es';

export const getOperatorsByType = (type: GridColumnType): FilterSearchOperator[] => {
  switch (type) {
    case 'number':
      return ['==', '!=', '>', '>=', '<', '<='];
    case 'boolean':
      return ['==', '!='];
    case 'string':
    default:
      return ['==', '!=', 'like', 'not like'];
  }
};

const stringToNumber = (str: string) => {
  const targetString = str.replace(/,/g, '');
  return parseFloat(targetString);
};

const isEqual = (cellValue: unknown, searchValues: string[], type?: GridColumnType) => {
  if (isNumber(cellValue) || type === 'number') {
    const normalizedCellValue = isString(cellValue) ? stringToNumber(cellValue) : cellValue;
    return searchValues.some((value) => normalizedCellValue === stringToNumber(value));
  }

  if (isBoolean(cellValue)) {
    return searchValues.some((value) => cellValue.toString() === value.toLowerCase());
  }

  if (isString(cellValue)) {
    return searchValues.some((value) => cellValue.toLowerCase() === value.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.some((value) => isEqual(value, searchValues)) : false;
  }

  if (isObject(cellValue)) {
    let result = false;
    Object.entries(cellValue)?.forEach(([key, value]) => {
      if (!result) {
        const isKeyMatched = isEqual(key, searchValues);
        const isValueMatched = isEqual(value, searchValues);
        result = isKeyMatched || isValueMatched;
      }
    });

    return result;
  }

  return false;
};

const isNotEqual = (cellValue: unknown, searchValues: string[], type?: GridColumnType) => {
  if (isNumber(cellValue) || type === 'number') {
    const normalizedCellValue = isString(cellValue) ? stringToNumber(cellValue) : cellValue;
    return searchValues.every((value) => normalizedCellValue !== stringToNumber(value));
  }

  if (isBoolean(cellValue)) {
    return searchValues.every((value) => cellValue.toString() !== value.toLowerCase());
  }

  if (isString(cellValue)) {
    return searchValues.every((value) => cellValue.toLowerCase() !== value.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.every((value) => isNotEqual(value, searchValues)) : true;
  }

  if (isObject(cellValue)) {
    let result = false;
    Object.entries(cellValue)?.forEach(([key, value]) => {
      if (!result) {
        const isKeyMatched = isNotEqual(key, searchValues);
        const isValueMatched = isNotEqual(value, searchValues);
        result = isKeyMatched || isValueMatched;
      }
    });

    return result;
  }

  return false;
};

const isLike = (cellValue: unknown, searchValue: string) => {
  if (isString(cellValue)) {
    return cellValue.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isNumber(cellValue)) {
    return `${cellValue}`.includes(searchValue.toLowerCase());
  }

  if (isBoolean(cellValue)) {
    return `${cellValue}`.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.some((value) => isLike(value, searchValue)) : false;
  }

  if (isObject(cellValue)) {
    let result = false;
    Object.entries(cellValue)?.forEach(([key, value]) => {
      if (!result) {
        const isKeyMatched = isLike(key, searchValue);
        const isValueMatched = isLike(value, searchValue);
        result = isKeyMatched || isValueMatched;
      }
    });

    return result;
  }

  return false;
};

const isNotLike = (cellValue: unknown, searchValue: string): boolean => {
  if (isString(cellValue)) {
    return !cellValue.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isNumber(cellValue)) {
    return !`${cellValue}`.includes(searchValue.toLowerCase());
  }

  if (isBoolean(cellValue)) {
    return !`${cellValue}`.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.every((value) => isNotLike(value, searchValue)) : true;
  }

  if (isObject(cellValue)) {
    let result = false;
    Object.entries(cellValue)?.forEach(([key, value]) => {
      if (!result) {
        const isKeyMatched = isNotLike(key, searchValue);
        const isValueMatched = isNotLike(value, searchValue);
        result = isKeyMatched || isValueMatched;
      }
    });

    return result;
  }

  return false;
};

const isGreaterThan = (cellValue: unknown, searchValue: string) => {
  if (isNumber(cellValue)) {
    return cellValue > stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(cellValue) > stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isGreaterThan(value, searchValue));
  }

  return false;
};

const isGreaterThanOrEqual = (cellValue: unknown, searchValue: string) => {
  if (isNumber(cellValue)) {
    return cellValue >= stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(cellValue) >= stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isGreaterThanOrEqual(value, searchValue));
  }

  return false;
};

const isLessThan = (cellValue: unknown, searchValue: string) => {
  if (isNumber(cellValue)) {
    return cellValue < stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(cellValue) < stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isLessThan(value, searchValue));
  }

  return false;
};

const isLessThanOrEqual = (cellValue: unknown, searchValue: string) => {
  if (isNumber(cellValue)) {
    return cellValue <= stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(cellValue) <= stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isLessThanOrEqual(value, searchValue));
  }

  return false;
};

export const filterFunctionByOperator = ({
  targetValue,
  operator,
  searchValues,
  type,
}: {
  targetValue: string;
  operator: string;
  searchValues: string[];
  type?: GridColumnType;
}): boolean => {
  switch (operator) {
    case '==':
      return isEqual(targetValue, searchValues, type);
    case '!=':
      return isNotEqual(targetValue, searchValues, type);
    case 'like':
      return isLike(targetValue, searchValues[0]);
    case 'not like':
      return isNotLike(targetValue, searchValues[0]);
    case '>':
      return isGreaterThan(targetValue, searchValues[0]);
    case '>=':
      return isGreaterThanOrEqual(targetValue, searchValues[0]);
    case '<':
      return isLessThan(targetValue, searchValues[0]);
    case '<=':
      return isLessThanOrEqual(targetValue, searchValues[0]);
    default:
      return false;
  }
};

export const getFilterItemsByGridColumns = (
  columns: CustomColumn[] | Readonly<CustomColumn[]>,
  additional?: FilterItem[],
): FilterItem[] => {
  const filterItems: FilterItem[] = columns
    .filter(({ searchable }) => searchable)
    .map(({ caption, field, type }) => {
      return {
        key: {
          id: field,
          name: caption,
        },
        operators: getOperatorsByType(type as GridColumnType),
        values: {
          multi: type === 'boolean',
          items:
            type === 'boolean'
              ? [
                  { id: 'true', name: 'True' },
                  { id: 'false', name: 'False' },
                ]
              : [],
        },
      };
    });

  if (additional) {
    additional.forEach((addItem) => {
      const targetIndex = filterItems.findIndex((item) => item.key.id === addItem.key.id);
      if (targetIndex === -1) {
        filterItems.push(addItem);
      } else {
        filterItems[targetIndex] = addItem;
      }
    });
  }

  return filterItems;
};

export const getFormattedFilterSearchStorageKey = (
  componentName: string,
  index?: number,
): string => {
  return `filterSearch_${window.location.pathname}_${componentName}_${index ?? ''}`;
};

export const operatorMap = {
  '==': 'is',
  '!=': 'is not',
  like: 'like',
  notLike: 'not like',
};
export const transformOperator = (data: FilterSelectedValues) => {
  return {
    logicalOperator: data.logicalOperator,
    tokens: data.tokens.map(({ key, operator, value }) => {
      return {
        key,
        operator: operatorMap[operator],
        value,
      };
    }),
  };
};
