import { computed, ref } from 'vue';
import { FileInfo } from '@/common/utils/fileUploadUtils';

const AWS_CREDENTIAL_TYPE = {
  TOKEN: 'general',
  IAM_ROLES_ANYWHERE: 'roles_anywhere',
  VAULT: 'vault',
  SERVER: 'server',
  ASSUME_ROLE: 'assume_role',
};
const IAM_ROLES_ANYWHERE_FILE = {
  CERTIFICATE: 'Certificate',
  PRIVATE_KEY: 'Private Key',
};

const FILE_INFO_INIT_STATE: FileInfo = {
  name: '',
  file: null,
  data: null,
};

const checkFileExtension = (fileName: string, availExtensions: string[]) => {
  const dotIndex = fileName.lastIndexOf('.');
  const extension = fileName.substring(dotIndex);

  return availExtensions.includes(extension);
};

const convertFileContent = (content: ArrayBuffer): string => {
  const decoder = new TextDecoder();
  return decoder.decode(content);
};

const useFile = (extensions: string[]) => {
  const isAvailExtensions = ref(true);

  const validateUploadFile = (file: FileInfo): FileInfo => {
    if (checkFileExtension(file.name, extensions)) {
      isAvailExtensions.value = true;

      const stringContent = convertFileContent(file.data as ArrayBuffer);

      return { ...file, data: stringContent };
    }

    isAvailExtensions.value = false;
    return { ...FILE_INFO_INIT_STATE };
  };

  return {
    isAvailExtensions,
    validateUploadFile,
  };
};

const useCertificateFile = (computedModelValue) => {
  const extensions = ['.crt', '.pem'];

  const { isAvailExtensions, validateUploadFile } = useFile(extensions);

  const deleteFile = () => {
    computedModelValue.value.osConfig!.awsRolesAnyWhereCertificate = null;
    computedModelValue.value.osConfig!.awsRolesAnyWhereCertificateFileName = null;
  };

  const updateFileInfo = (uploadFileInfo: FileInfo) => {
    const { data: stringFileContent, name } = validateUploadFile(uploadFileInfo);

    if (stringFileContent) {
      computedModelValue.value.osConfig!.awsRolesAnyWhereCertificate = stringFileContent as string;
      computedModelValue.value.osConfig!.awsRolesAnyWhereCertificateFileName = name;
      return;
    }

    deleteFile();
  };

  return {
    isAvailExtensions,
    extensions,
    updateFileInfo,
    deleteFile,
  };
};

const usePrivateKeyFile = (computedModelValue) => {
  const extensions = ['.key', '.pem'];

  const { isAvailExtensions, validateUploadFile } = useFile(extensions);

  const deleteFile = () => {
    computedModelValue.value.osConfig!.awsRolesAnyWherePrivateKey = null;
    computedModelValue.value.osConfig!.awsRolesAnyWherePrivateKeyFileName = null;
  };

  const updateFileInfo = (uploadFileInfo: FileInfo) => {
    const { data: stringFileContent, name } = validateUploadFile(uploadFileInfo);

    if (stringFileContent) {
      computedModelValue.value.osConfig!.awsRolesAnyWherePrivateKey = stringFileContent as string;
      computedModelValue.value.osConfig!.awsRolesAnyWherePrivateKeyFileName = name;
      return;
    }

    deleteFile();
  };

  return {
    isAvailExtensions,
    extensions,
    updateFileInfo,
    deleteFile,
  };
};

const useFileUploadWindow = () => {
  const isFileUploadWindowVisible = ref(false);

  type FileType = (typeof IAM_ROLES_ANYWHERE_FILE)[keyof typeof IAM_ROLES_ANYWHERE_FILE];
  const fileType = ref<FileType | ''>('');

  const title = computed(() => `${fileType.value} File Upload`);

  const openFileUploadWindow = (type: FileType) => {
    isFileUploadWindowVisible.value = true;
    fileType.value = type;
  };

  return {
    isFileUploadWindowVisible,
    fileType,
    title,
    openFileUploadWindow,
  };
};

export {
  AWS_CREDENTIAL_TYPE,
  IAM_ROLES_ANYWHERE_FILE,
  useFile,
  useCertificateFile,
  usePrivateKeyFile,
  useFileUploadWindow,
  checkFileExtension,
  convertFileContent,
};
