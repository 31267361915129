import { isNumber } from 'lodash-es';
import dayjs from 'dayjs';
import { MetricResponse } from '@/openapi/data/model';
import { DB_TYPE, DBType, DetailInfoData, ONE_DAY, ONE_HOUR, UserTags } from '@/common/utils';
import { DISPLAY_DB_TYPE } from '@/database/utils/define';
import { returnMatchingFormat } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { ClusterData, ClusterDataWithChildren } from './types';

export class MetricFailError extends Error {
  constructor({ reason }) {
    super(reason);
    this.name = 'MetricFailError';
  }

  getErrorStatusText() {
    if (this.message === 'null') {
      return 'Unknown Error';
    }
    return this.message ?? 'Unknown Error';
  }
}

/*
  v7 이전 metric API 포맷으로 변경
  [v7 포맷]
  metrics[0]: {
    ...
    values: [[time, value], ...],
  }
  [이전 포맷]
  metrics[0]: {
    dataId,
    values: [{time, value}, ...],
  }
 */
export const preprocessMetric = ({ response }: { response: MetricResponse[] }) => {
  return response.map(({ metrics, dataDefinition = {} }) => {
    return {
      metrics: (metrics || [{}]).map(({ values }) => ({
        dataId: dataDefinition.dataId || '',
        values: (values || []).map(([time, value]) => ({ time, value })),
      })),
    };
  });
};

export const checkValidMetric = (data: MetricResponse[]) => {
  data.forEach(({ status, reason }) => {
    if (status === 'fail') {
      throw new MetricFailError({ reason });
    }
  });
};

export const mapMetricNameToDataId = ({
  dbType,
  metricName = '',
}: {
  dbType: DBType;
  metricName: string;
}) => (metricName.startsWith(`db_${dbType}_`) ? metricName : `db_${dbType}_${metricName}`);

export const mapMetricNamesToDataIds = ({
  dbType,
  metricNames = [],
}: {
  dbType: DBType;
  metricNames: string[];
}) => {
  const mappedMetricNames: string[] = [];
  metricNames.forEach((name) => {
    if (!name) return;
    mappedMetricNames.push(name.startsWith(`db_${dbType}_`) ? name : `db_${dbType}_${name}`);
  });
  return mappedMetricNames;
};

export const mapDbTypeToDisplay = (dbType?: string) =>
  dbType ? DISPLAY_DB_TYPE[dbType.toUpperCase()] : '';

interface ClusterTreeGridMapperOption {
  setClusterUncheckable?: boolean;
  setClusterExpanded?: boolean;
  setInitialParent?: boolean;
  initialValues?: any;
}

const sortByIsCluster = <T extends { isCluster?: boolean }>(instances: T[]) =>
  instances.sort((a, b) => {
    const aIsCluster = a.isCluster ? 1 : 0;
    const bIsCluster = b.isCluster ? 1 : 0;
    return bIsCluster - aIsCluster;
  });

export const clusterTreeGridMapper = <T extends ClusterData>(
  data?: T[], // rowData 의 child node들
  parentData?: T,
  nameField: string = 'name',
  option?: ClusterTreeGridMapperOption,
): (ClusterDataWithChildren<T> | T)[] => {
  if (!data) {
    return [];
  }
  const clusterNameMapper: Record<string, number> = {}; // clusterId <-> 배열 index mapper
  const dataToReturn: (ClusterDataWithChildren<T> | T)[] = [];

  sortByIsCluster(data).forEach((item) => {
    // item: 인스턴스 또는 클러스터 또는 클러스터링 (aws)
    const { clusterId, clusterName, isCluster, platform } = item || {};
    if (!clusterId) {
      // 어떠한 클러스터에도 속해있지 않은 일반 인스턴스일 경우
      dataToReturn.push(item);
      return;
    }

    // 클러스터 또는 클러스터링 하위 인스턴스 체크
    const parentIndex = clusterNameMapper[clusterId];
    if (!isNumber(parentIndex)) {
      // clusterNameMapper 에 클러스터 아이디 등록되어 있지 않을 경우
      if (platform === 'aws' && isCluster) {
        // 클러스터 (aws 전용))
        clusterNameMapper[clusterId] = dataToReturn.length; // new index
        dataToReturn.push({
          ...item,
          isCluster: true,
          expand: option?.setClusterExpanded ?? true,
          uncheckable: option?.setClusterUncheckable,
          children: [],
        });
        return;
      }
      if (clusterId) {
        // 클러스터링 (일반 그룹화)
        clusterNameMapper[clusterId] = dataToReturn.length; // new index
        if (option?.setInitialParent) {
          // 부모의 값을 초기화 하는 경우 초기화 변수(initialValues) 설정
          dataToReturn.push({
            ...option?.initialValues,
            clusterId,
            [nameField]: clusterName,
            isClustering: true,
            expand: option?.setClusterExpanded ?? true,
            uncheckable: option?.setClusterUncheckable,
            children: [{ ...item, uncheckable: false }] as ClusterData[],
          });
        } else {
          dataToReturn.push({
            ...(parentData ?? item),
            clusterId,
            [nameField]: clusterName,
            isClustering: true,
            expand: option?.setClusterExpanded ?? true,
            uncheckable: option?.setClusterUncheckable,
            children: [{ ...item, uncheckable: false }] as ClusterData[],
          });
        }

        return;
      }
    }

    if (
      !isNumber(parentIndex) ||
      !(dataToReturn[parentIndex] as ClusterDataWithChildren<T>)?.children
    ) {
      // 클러스터 또는 클러스터링의 하위 인스턴스가 아닐 경우
      dataToReturn.push(item);
      return;
    }

    // 클러스터 또는 클러스터링 하위 인스턴스일 경우 (parentIndex 찾음)
    (dataToReturn[parentIndex] as ClusterDataWithChildren<T>)?.children?.push({
      ...item,
      uncheckable: false,
    });
  });

  return dataToReturn;
};

export const isDBTypeValid = (value: any): value is NonNullable<DBType> => {
  return typeof value === 'string' && (Object.values(DB_TYPE) as string[]).includes(value);
};

export const getSqlDetailTimePeriodByInterval = ({
  fromTime,
  toTime,
}: {
  fromTime?: string;
  toTime?: string;
}): { fromTime: string; toTime: string } => {
  // SQL List > Time Period Indicator value to SQL Detail fromTime, toTime

  const toTimeDayjs = toTime ? dayjs(toTime, returnMatchingFormat(toTime)) : dayjs();
  const fromTimeDayjs = fromTime ? dayjs(fromTime, returnMatchingFormat(fromTime)) : null;
  const intervalValue = fromTimeDayjs ? toTimeDayjs.diff(fromTimeDayjs) : ONE_HOUR;

  if (intervalValue >= ONE_DAY * 30) {
    // 30d, API limit
    return {
      fromTime: toTimeDayjs.subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss'),
      toTime: toTimeDayjs.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  if (intervalValue <= ONE_HOUR) {
    // 1h
    return {
      fromTime: toTimeDayjs.subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
      toTime: toTimeDayjs.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  return {
    fromTime: toTimeDayjs.subtract(intervalValue).format('YYYY-MM-DD HH:mm:ss'),
    toTime: toTimeDayjs.format('YYYY-MM-DD HH:mm:ss'),
  };
};

export const getTargetTagInfoData = (targetTagList?: UserTags[]): DetailInfoData[] => {
  if (!targetTagList?.length) {
    return [];
  }

  return targetTagList.map((tag) => {
    return {
      label: tag.tagName ?? '',
      field: tag.tagName ?? '',
      values: tag.tagValues ?? [],
      renderType: 'label',
    };
  });
};
