<template>
  <dl class="label-item">
    <dt
      v-if="props.label"
      class="label-item__label"
      :class="labelClass"
    >
      {{ props.label }}
      <span
        v-if="props.required"
        class="required"
      >
        *
      </span>
    </dt>
    <dd
      v-if="slots.icon"
      class="label-icon"
    >
      <slot name="icon" />
    </dd>
    <dd
      class="label-item__body"
      :class="[linkedItemClass]"
      @click="onClickItem"
      @mouseover="onMouseoverItem"
      @mousemove="onMousemoveItem"
      @mouseleave="onMouseleaveItem"
    >
      <slot />
    </dd>
    <common-tooltip
      v-if="isValidClickItemFn"
      :is-show="isHoverItem"
      :evt="mouseoverEvt"
      :text="props.tooltipText"
    />
  </dl>
</template>

<script setup lang="ts">
import { useLabelItem, useTooltip } from '@/common/components/molecules/labelItem/uses';
import CommonTooltip from '@/common/components/molecules/commonTooltip/CommonTooltip.vue';
import { LabelType } from '@/common/utils/types';
import { useSlots } from 'vue';

interface Props {
  label?: string;
  type?: LabelType;
  clickItemFn?: null | (() => void);
  tooltipText?: string;
  required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  type: 'default',
  clickItemFn: null,
  tooltipText: '',
  required: false,
});

const slots = useSlots();

const { mouseoverEvt, isHoverItem, onMouseoverItem, onMousemoveItem, onMouseleaveItem } =
  useTooltip(props);

const { labelClass, linkedItemClass, isValidClickItemFn, onClickItem } = useLabelItem(
  props,
  isHoverItem,
);
</script>

<style lang="scss">
.label-item {
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;

  &__label {
    display: inline-block;
    padding-right: 8px;
    font-size: 13px;
    color: var(--label-font-color);

    .required {
      padding-top: 8px;
      color: #ff1f3f;
    }
  }
  &__body {
    display: inline-block;
    padding-right: 10px;
    color: var(--label-font-strong-color);
  }
  .label-icon {
    position: relative;
    padding-left: 23px;
  }

  .label-item__label--primary {
    color: #198fff;
  }
  .label-item__label--ghost {
    color: var(--color-d-gray-04);
  }
  .label-item__label--bold {
    font-weight: bold;
  }
  .label-item--is-linked {
    cursor: pointer;
    text-decoration-line: underline;
    &:hover {
      color: #198fff;
    }
  }
}
</style>
