/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  IntegrateCustomReportRequest,
  IntegrateCustomReportResponse,
  IntegrateDashboardGroupPatchRequest,
  IntegrateDashboardGroupResponse,
  IntegrateDashboardPatchRequest,
  IntegrateDashboardPatchResponse,
  IntegrateDashboardResponse,
  IntegrateDashboardSharePatchRequest,
  IntegrateFavoriteDashboardPatchRequest,
  IntegrateFeaturedReportRequest,
  IntegrateFeaturedReportResponse,
  ResultResponse,
  SuccessResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getCustomReportsIntegratedDashboardControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateCustomReportResponse> => {
  const localVarPath = '/api/v6/dashboards/custom-report';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getBoardsIntegratedDashboardControllerAxios = ({
  column,
  dashboardId,
  includeImage,
  pageNum,
  pageSize,
  sortType,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  column?: string;
  dashboardId?: number;
  includeImage?: boolean;
  pageNum?: number;
  pageSize?: number;
  sortType?: 'ASC' | 'DESC';
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardResponse> => {
  const localVarPath = '/api/v6/dashboards/dashboard';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      dashboardId,
      includeImage,
      pageNum,
      pageSize,
      sortType,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getFeaturedReportsIntegratedDashboardControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateFeaturedReportResponse> => {
  const localVarPath = '/api/v6/dashboards/featured-report';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupsIntegratedDashboardControllerAxios = ({
  dashboardGroupId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  dashboardGroupId?: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardGroupResponse> => {
  const localVarPath = '/api/v6/dashboards/group';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardGroupId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const setCustomReportIntegratedDashboardControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateCustomReportRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/dashboards/custom-report';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchBoardShareIntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateDashboardSharePatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v6/dashboards/dashboard/user-share';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const setFeaturedReportV2IntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateFeaturedReportRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/dashboards/featured-report';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const setFeaturedReportIntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateFeaturedReportRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/dashboards/featured_report';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchBoardIntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateDashboardPatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardPatchResponse> => {
  const localVarPath = '/api/v6/dashboards/dashboard';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const exportDashboardAttributeIntegratedDashboardControllerAxios = ({
  dashboardId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  dashboardId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v6/dashboards/dashboard/export';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const importDashboardAttributeIntegratedDashboardControllerAxios = ({
  jsonList,
  isCustomReport,
  isPreset,
  isReport,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  jsonList: Array<string>;
  isCustomReport?: boolean;
  isPreset?: boolean;
  isReport?: boolean;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v6/dashboards/dashboard/import';
  const httpMethod = 'post';

  const data: any = jsonList;
  const config: any = {
    params: {
      isCustomReport,
      isPreset,
      isReport,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchFavoriteBoardIntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateFavoriteDashboardPatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v6/dashboards/favorite';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchGroupIntegratedDashboardControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: IntegrateDashboardGroupPatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v6/dashboards/group';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getCustomReportsIntegratedDashboardControllerAxios,
  getBoardsIntegratedDashboardControllerAxios,
  getFeaturedReportsIntegratedDashboardControllerAxios,
  getGroupsIntegratedDashboardControllerAxios,
  setCustomReportIntegratedDashboardControllerAxios,
  patchBoardShareIntegratedDashboardControllerAxios,
  setFeaturedReportV2IntegratedDashboardControllerAxios,
  setFeaturedReportIntegratedDashboardControllerAxios,
  patchBoardIntegratedDashboardControllerAxios,
  exportDashboardAttributeIntegratedDashboardControllerAxios,
  importDashboardAttributeIntegratedDashboardControllerAxios,
  patchFavoriteBoardIntegratedDashboardControllerAxios,
  patchGroupIntegratedDashboardControllerAxios,
};
