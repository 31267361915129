import { FilterData } from '@/dashboard/types/filter';

export const getInitFilterValue = <Filter extends FilterData>(
  filterData: Filter,
): Filter['value'] => {
  switch (filterData.filterType) {
    case 'toggle':
    case 'checkbox':
      return false;
    case 'text':
      return '';
    case 'number':
      return undefined;
    case 'selectbox':
    case 'selectbox2':
      return { optionItem: undefined, optionItems: [] };
    case 'calendar':
      return '';
    case 'target':
      return { optionItem: undefined, optionItems: [] };
    default:
      return undefined;
  }
};

export const setInitFilterValue = <Filter extends FilterData>(filterData: Filter): Filter => {
  return { ...filterData, value: getInitFilterValue(filterData) };
};

export const isFilterDataValueSetted = ({ filterType, value }: FilterData): boolean => {
  switch (filterType) {
    case 'toggle':
    case 'checkbox':
      return true;
    case 'text':
      return value !== '';
    case 'number':
      return value != null;
    case 'selectbox':
    case 'selectbox2':
      return value.optionItem != null && value.optionItems.length > 0;
    case 'calendar':
      return value !== '';
    case 'target':
      return value.optionItem != null && value.optionItems.length > 0;
    default:
      throw new Error('Invalid filter type');
  }
};
