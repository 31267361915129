import { TagListItem } from '@/common/components/molecules/tagListInput/tagListInput.types';

export const getStringWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const { width = 0 } = context?.measureText(text) ?? {};
  return Math.ceil(width);
};

export const getTagWidth = (text: string, tagMaxWidth: number) => {
  const stringWidth = getStringWidth(text);
  return Math.min(tagMaxWidth, stringWidth);
};

export interface VisibleTagItemParam<ValueType> {
  wrapperEl: HTMLElement;
  itemList: TagListItem<ValueType>[];
  options?: {
    tagPadding?: number;
    wrapperPadding?: number;
    tagMaxWidthPercent?: number;
  };
}
export const getVisibleTagItems = <ValueType>({
  wrapperEl,
  itemList,
  options,
}: VisibleTagItemParam<ValueType>) => {
  if (!wrapperEl || !itemList?.length) {
    return [];
  }

  const wrapperWidth = wrapperEl.clientWidth;
  const { tagPadding = 20, wrapperPadding = 20, tagMaxWidthPercent = 0.8 } = options ?? {};
  const tagMaxWidth = Math.ceil(wrapperWidth * tagMaxWidthPercent);

  const { totalWidth, visibleTagIndex } = itemList.reduce(
    (acc: { totalWidth: number; visibleTagIndex: number }, item) => {
      if (acc.totalWidth === 0) {
        acc.totalWidth += wrapperPadding * 2;
      }

      const expectedTotalWidth = getTagWidth(item.name, tagMaxWidth) + tagPadding + acc.totalWidth;
      if (expectedTotalWidth <= wrapperWidth) {
        acc.visibleTagIndex += 1;
        acc.totalWidth += getTagWidth(item.name, tagMaxWidth) + tagPadding;
        return acc;
      }
      return acc;
    },
    {
      totalWidth: 0,
      visibleTagIndex: 0,
    },
  );

  let lastIndex = visibleTagIndex < 0 ? itemList.length : visibleTagIndex;
  const moreCountWidth = getTagWidth(`+ ${itemList.length - lastIndex}`, tagMaxWidth) + tagPadding;
  if (lastIndex > 1 && totalWidth + moreCountWidth >= wrapperWidth) {
    lastIndex -= 1;
  } else if (lastIndex === 0) {
    lastIndex = 1;
  }

  return itemList?.slice(0, lastIndex);
};
