import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import {
  CreateAgentRequest,
  UpdateAgentRequest,
  XmAgentAccessTokenResponse,
  XmAgentResponse,
} from '../model';

const deleteV1AgentsByAgentIdAxios = ({
  agentId,
  frameName,
}: {
  agentId: string;
  frameName?: string;
}): PromiseAxiosResponse<string> =>
  axiosInstance.delete(`/api/v1/agents/${agentId}`, { params: { frameName } });

const getV1AgentsAxios = (): PromiseAxiosResponse<Array<XmAgentResponse>> =>
  axiosInstance.get('/api/v1/agents');

const getV1AgentsByAgentIdAxios = ({
  agentId,
}: {
  agentId: string;
}): PromiseAxiosResponse<XmAgentResponse> => axiosInstance.get(`/api/v1/agents/${agentId}`);

const patchV1AgentsAccessTokenByAgentIdAxios = ({
  agentId,
  frameName,
}: {
  agentId: string;
  frameName?: string;
}): PromiseAxiosResponse<XmAgentAccessTokenResponse> =>
  axiosInstance.patch(`/api/v1/agents/${agentId}/accessToken`, { frameName });

const patchV1AgentsByAgentIdAxios = ({
  agentId,
  request,
}: {
  agentId: string;
  request: UpdateAgentRequest;
}): PromiseAxiosResponse<XmAgentResponse> =>
  axiosInstance.patch(`/api/v1/agents/${agentId}`, request);

const postV1AgentsAxios = ({
  request,
}: {
  request: CreateAgentRequest;
}): PromiseAxiosResponse<XmAgentResponse> => axiosInstance.post('/api/v1/agents', request);

export {
  deleteV1AgentsByAgentIdAxios,
  getV1AgentsAxios,
  getV1AgentsByAgentIdAxios,
  patchV1AgentsAccessTokenByAgentIdAxios,
  patchV1AgentsByAgentIdAxios,
  postV1AgentsAxios,
};
