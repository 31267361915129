import { RouteRecordRaw } from 'vue-router';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';

const PREFIX = 'tool_';

const toolMenu: RouteRecordRaw[] = [
  {
    path: '/tool/',
    name: 'Tool',
    component: PaViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    props: () => ({ useNavigationHeader: false }),
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_TOOL,
    },
    children: [
      {
        path: 'query-manager',
        name: `${PREFIX}Query Manager`,
        component: () =>
          import(
            /* webpackChunkName: "tool-query-manager" */ '@/tool/views/queryManager/QueryManager.vue'
          ),
      },
      {
        path: 'query-manager-history',
        name: `${PREFIX}Query Manager History`,
        component: () =>
          import(
            /* webpackChunkName: "tool-query-manager-history" */ '@/tool/views/queryManagerHistory/QueryManagerHistory.vue'
          ),
      },
    ],
  },
];
const getToolMenu = () => {
  const filteredToolMenu = extractAccessibleRoutes(toolMenu);

  return [...filteredToolMenu];
};

export { toolMenu, getToolMenu };
