export const getLocaleKey = (text: string): string =>
  text ? text.replace(/ /g, '_').replace(/_&_/g, '_').toUpperCase() : '';

export const getDetailKey = (text: string): string =>
  text ? text.replace(/ /g, '').replace(/_/g, '').toLowerCase() : '';

export const compressText = ({
  text,
  length = 100,
  suffix = '...',
}: {
  text: string;
  length?: number;
  suffix?: string;
}) => {
  if (text.length <= length) {
    return text;
  }
  return `${text.slice(0, length)}${suffix}`;
};
