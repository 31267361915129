import { OpenSMOptions } from '@/common/utils/types';
import { useBrowserPopupStore } from '@/common/stores/browser-popup';
import router from '@/common/router';
import { WINDOW_SIZE } from '@/common/utils/define';
import { storeToRefs } from 'pinia';

interface OpenPopupParams extends OpenSMOptions {
  query: {
    [key in string]: any;
  };
  sharedStorageInfo?: {
    session?: string[];
    local?: string[];
  };
}

const addMessageEvent = () => {
  const popupStore = useBrowserPopupStore();
  const { isAddedMsgEvent } = storeToRefs(popupStore);
  if (!isAddedMsgEvent.value) {
    isAddedMsgEvent.value = true;
    window.addEventListener('unload', popupStore.closeAllPopup, false);
  }
};

/**
 * browser popup open
 * @param query popup 전달 인자
 * @param popupStyle custom width height or WINDOW_SIZE
 * @param isSingle multi popup 여부
 */
const openBrowserPopup = ({
  query = {},
  popupStyle = 'EXTRA_LARGE_EXPAND',
  isSingle = true,
}: OpenPopupParams) => {
  const popupStore = useBrowserPopupStore();
  const { addPopup } = popupStore;

  const route = router.resolve({
    path: '/popup',
    query,
  });

  let features = '';
  if (typeof popupStyle === 'string') {
    const windowStyle = WINDOW_SIZE[popupStyle];
    features = `width=${windowStyle.width}, height=${windowStyle.height}`;
  } else {
    features = `width=${popupStyle.width}, height=${popupStyle.height}`;
  }
  const popup = window.open(route.href, '_blank', features);

  if (popup) {
    addPopup(isSingle, query.viewName, popup);
  }

  return popup;
};

let resizeTimeout;
const handleResize = (minWidth: number, minHeight: number) => () => {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;

      switch (true) {
        case window.outerWidth < minWidth && window.outerHeight < minHeight:
          window.resizeTo(minWidth, minHeight);
          break;
        case window.outerWidth < minWidth:
          window.resizeTo(minWidth, window.outerHeight);
          break;
        case window.outerHeight < minHeight:
          window.resizeTo(window.outerWidth, minHeight);
          break;
        default:
          break;
      }
    }, 1000);
  }
};

export { addMessageEvent, openBrowserPopup, handleResize };
