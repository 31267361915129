import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  DashboardData,
  WidgetChartData,
  WidgetChartType,
  WidgetTimePeriod,
} from '@/dashboard/utils/types';
import { getBoardsIntegratedDashboardControllerAxios } from '@/openapi/metaV6/api/integrated-dashboard-controller-api';
import { getWidgetMappingInfoDashboardWidgetV7ControllerAxios } from '@/openapi/metaV6/api/dashboard-widget-v7-controller-api';
import { WidgetGroupValueResponseV7 } from '@/openapi/metaV6/model';
import { WidgetTriggerPathType, WidgetUUID } from '@/common/utils/types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface TriggerDetailInfo {
  triggerPath: WidgetTriggerPathType;
  widgetId: WidgetUUID;
  targetId: string;
  targetName?: string;
  addition?: Record<string, any>;
}

export interface SummaryDetailData {
  title: string;
  widgetId: WidgetUUID;
  chartType: WidgetChartType;
  chartData: WidgetChartData[];
  chartOption: any;
  colorTheme: string;
  timePeriod: WidgetTimePeriod;
  params: any;
  targetIdsByStat?: string[][];
}

export const useDashboardViewStore = defineStore('dashboardDashboardView', () => {
  const monitoringDashboard = ref<DashboardData>({
    dashboardId: -1,
    dashboardGroupId: -1,
    dashboardName: '',
    preset: false,
    share: false,
    startScreen: false,
    createUser: false,
    featuredReport: false,
    customReport: false,
    dashboardOption: {
      showTimePeriod: false,
    },
    sharePermission: {
      shareUsers: [],
      edit: false,
      delete: false,
      shared: false,
    },
  });

  const toggleShowTimePeriod = () => {
    monitoringDashboard.value.dashboardOption.showTimePeriod =
      !monitoringDashboard.value.dashboardOption.showTimePeriod;
  };

  const widgetsForMonitoringDashboard = ref<WidgetGroupValueResponseV7[]>([]);

  const fetchCurrentDashboardInfo = async (dashboardId: number) => {
    try {
      const { data } = await getBoardsIntegratedDashboardControllerAxios({
        dashboardId,
        includeImage: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_INFO,
      });

      const dashboardInfo = data?.data;

      if (dashboardInfo?.length) {
        monitoringDashboard.value = {
          dashboardId: dashboardInfo[0].dashboardId!,
          dashboardGroupId: dashboardInfo[0].dashboardGroupId || -1,
          dashboardName: dashboardInfo[0].dashboardName || '',
          startScreen: dashboardInfo[0].startScreen || false,
          preset: dashboardInfo[0].preset || false,
          share: dashboardInfo[0].share || false,
          createUser: dashboardInfo[0].createUser || false,
          featuredReport: dashboardInfo[0].featuredReport || false,
          customReport: dashboardInfo[0].customReport || false,
          menuPaths: dashboardInfo[0].menuPaths,
          analysisMenuPaths: dashboardInfo[0].analysisMenuPaths,
          dashboardOption: dashboardInfo[0].dashboardOption
            ? JSON.parse(dashboardInfo[0].dashboardOption)
            : {
                showTimePeriod: true,
              },
          sharePermission: {
            shareUsers: dashboardInfo[0].sharePermission?.shareUsers || [],
            edit: dashboardInfo[0].sharePermission?.edit || false,
            delete: dashboardInfo[0].sharePermission?.delete || false,
            shared: dashboardInfo[0].sharePermission?.shared || false,
          },
        };
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchWidgetsForMonitoringDashboard = async (dashboardId: number) => {
    try {
      const { data } = await getWidgetMappingInfoDashboardWidgetV7ControllerAxios({
        dashboardId,
        startScreen: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_MAPPING_INFO,
      });
      widgetsForMonitoringDashboard.value = data.data?.[0]?.widgets ?? [];
    } catch (e) {
      console.log(e);
      widgetsForMonitoringDashboard.value = [];
    }
  };

  const fetchMonitoringDashboard = async (dashboardId: number) => {
    await fetchCurrentDashboardInfo(dashboardId);
    await fetchWidgetsForMonitoringDashboard(dashboardId);
  };

  const selectedDetailInfo = ref<TriggerDetailInfo | null>(null);

  const isShowSummaryDetailSlide = ref<boolean>(false);
  const summaryDetailData = ref<SummaryDetailData | null>(null);

  const initSelectedDetailInfo = () => {
    selectedDetailInfo.value = null;
  };

  const setSelectedDetailInfo = (detailInfo: TriggerDetailInfo) => {
    selectedDetailInfo.value = detailInfo;
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
  };

  const initSummaryDetailSlide = () => {
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
    selectedDetailInfo.value = null;
  };

  const setSummaryDetailData = (data: SummaryDetailData) => {
    summaryDetailData.value = data;
    selectedDetailInfo.value = null;
    isShowSummaryDetailSlide.value = true;
  };

  const dashboardIdByRouter = ref<string>('');
  const changeIdByRouter = (id) => {
    dashboardIdByRouter.value = id;
  };

  return {
    monitoringDashboard,
    widgetsForMonitoringDashboard,
    fetchMonitoringDashboard,

    isShowSummaryDetailSlide,
    summaryDetailData,
    setSummaryDetailData,
    initSummaryDetailSlide,

    selectedDetailInfo,
    initSelectedDetailInfo,
    setSelectedDetailInfo,

    dashboardIdByRouter,
    changeIdByRouter,

    toggleShowTimePeriod,
  };
});
