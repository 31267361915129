import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { AgentInfo } from '@/common/api/resTypes/agentRes';
import { VIEW_TYPE } from '@/common/utils/define';
import {
  getV1AgentsAxios,
  getV1AgentsByAgentIdAxios,
  patchV1AgentsByAgentIdAxios,
  postV1AgentsAxios,
} from '@/openapi/meta/api/xm-agent-controller-api';
import { AgentType } from '@/common/utils/types';

interface State {
  initViewType: string;
  isShowWindow: boolean;
  selectedAgentId: string;
  agents: AgentInfo[];
  agentInfo: AgentInfo;
}

interface CreateAgentParams {
  description?: string;
  enabled?: boolean;
  name: string;
  shared?: boolean;
  type?: AgentType;
}

interface UpdateAgentParams {
  description?: string;
  enabled?: boolean;
  name?: string;
}

export const agentManagement: Module<State, RootState> = {
  namespaced: true,
  state: {
    // Window
    initViewType: VIEW_TYPE.REGISTER,
    isShowWindow: false,
    selectedAgentId: '',

    // Agent List
    agents: [],

    // Selected Agent Info
    agentInfo: {
      accessToken: null,
      agentId: '',
      agentState: {
        connectedTime: '',
        packetReceivedTime: '',
        packets: null,
        remoteAddress: '',
        state: '',
        updatedTime: '',
      },
      description: '',
      enabled: true,
      hostname: '',
      instances: [],
      name: '',
      shared: false,
      type: 'ALL',
    },
  },
  mutations: {
    setIsShowWindow: (state: State, isShow: boolean) => {
      state.isShowWindow = isShow;
    },
    setInitViewType: (state: State, viewType: string) => {
      state.initViewType = viewType;
    },
    setAgents: (state: State, agents: AgentInfo[]) => {
      state.agents = [...agents];
    },
    setAgentInfo: (state: State, agentInfo: AgentInfo) => {
      state.agentInfo = { ...agentInfo };
    },

    setSelectedAgentId: (state: State, agentId: string) => {
      state.selectedAgentId = agentId;
    },
    setAgentName: (state: State, agentName: string) => {
      state.agentInfo.name = agentName;
    },
    setAgentType: (state: State, agentType: string) => {
      state.agentInfo.type = agentType;
    },
    setAgentEnabled: (state: State, enabled: boolean) => {
      state.agentInfo.enabled = enabled;
    },
    setAgentShared: (state: State, shared: boolean) => {
      state.agentInfo.shared = shared;
    },
    setAgentDescription: (state: State, desc: string) => {
      state.agentInfo.description = desc;
    },
    setAgentAccessToken: (state: State, accessToken: string) => {
      state.agentInfo.accessToken = accessToken;
    },

    initAgentInfo: (state: State) => {
      state.agentInfo.accessToken = null;
      state.agentInfo.agentId = '';
      state.agentInfo.agentState = {
        connectedTime: '',
        packetReceivedTime: '',
        packets: null,
        remoteAddress: '',
        state: '',
        updatedTime: '',
      };
      state.agentInfo.description = '';
      state.agentInfo.enabled = true;
      state.agentInfo.hostname = '';
      state.agentInfo.instances = [];
      state.agentInfo.name = '';
      state.agentInfo.shared = false;
      state.agentInfo.type = 'ALL';
    },
    initAgentAccessToken: (state: State) => {
      state.agentInfo.accessToken = null;
    },
  },
  actions: {
    dispatchAgents: async ({ commit }) => {
      const { data } = await getV1AgentsAxios();
      commit(
        'setAgents',
        data.slice(1).map((v) => {
          const { agentState } = v;
          return { ...v, ...agentState };
        }),
      );
    },
    dispatchAgentInfo: async ({ commit }, agentId: string) => {
      const { data } = await getV1AgentsByAgentIdAxios({ agentId });
      commit('setAgentInfo', data);
      commit('initAgentAccessToken');
    },
    createAgentInfo: async ({ commit, getters }) => {
      const { name, type, description, enabled } = await getters.getAgentInfo;
      const request: CreateAgentParams = {
        name,
        type,
        description,
        enabled,
        shared: false,
      };
      const { data } = await postV1AgentsAxios({ request });
      commit('setAgentInfo', data);
    },
    editAgentInfo: async ({ commit, getters }) => {
      const { agentId, name, description, enabled } = await getters.getAgentInfo;
      const request: UpdateAgentParams = {
        name,
        description,
        enabled,
      };
      const { data } = await patchV1AgentsByAgentIdAxios({ agentId, request });
      commit('setAgentInfo', data);
    },
  },
  getters: {
    getInitViewType: (state: State) => state.initViewType,
    getIsShowWindow: (state: State) => state.isShowWindow,
    getAgents: (state: State) => state.agents,
    getAgentInfo: (state: State) => state.agentInfo,
    getAgentAccessToken: (state: State) => state.agentInfo.accessToken,
  },
};
