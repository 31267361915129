import { InjectionKey, provide, ref, Ref } from 'vue';
import { WidgetUUID } from '@/common/utils';

export const WIDGET_CLICK_KEY = Symbol('') as InjectionKey<{
  clickedWidgetId: Ref<string | null>;
  clickedWidgetInfo: Ref<Map<WidgetUUID, Map<string, string | undefined>>>;
  updateClickedWidgetInfo: (widgetUUID: WidgetUUID, data: Map<string, string | undefined>) => void;
}>;

export const useWidgetClickProvide = () => {
  const clickedWidgetId = ref<string | null>(null);
  const clickedWidgetInfo = ref<Map<WidgetUUID, Map<string, string | undefined>>>(new Map());

  const updateClickedWidgetInfo = (
    widgetUUID: WidgetUUID,
    data: Map<string, string | undefined>,
  ) => {
    clickedWidgetId.value = widgetUUID;
    clickedWidgetInfo.value.set(widgetUUID, data);
  };

  provide(WIDGET_CLICK_KEY, {
    clickedWidgetId,
    clickedWidgetInfo,
    updateClickedWidgetInfo,
  });
};
