<script setup lang="ts">
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { setup, Props, Emit } from '@/common/components/molecules/timePeriod/timePeriod.setup';

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});
const emit = defineEmits<Emit>();

const { selectedPeriod, periodShortName } = setup(props, emit);
</script>

<template>
  <div class="time-period">
    <div>
      <tag-item
        v-if="props.periods?.[0].shortName !== 'a'"
        size="xsmall"
      >
        {{ periodShortName }}
      </tag-item>
      <ev-select
        v-model="selectedPeriod"
        :disabled="props.disabled"
        :items="periods"
        class="time-period__select"
      />
    </div>
  </div>
</template>

<style lang="scss">
.time-period {
  position: relative;
  width: 180px;
  height: 24px;

  > div {
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
  }

  &__select {
    width: 100% !important;

    .ev-input {
      width: 100% !important;
      height: 24px !important;
      padding-right: 0 !important;
      font-size: 12px !important;
    }

    .ev-input-suffix {
      right: 4px;
    }
  }

  .tag-item {
    position: absolute;
    left: 4px;
    z-index: 1;
    justify-content: center;
    width: 35px;
    padding: 4px 6px;
    font-weight: 400;

    /* stylelint-disable */
    + .time-period__select {
      .ev-input {
        padding-left: 42px !important;
      }
    }
    /* stylelint-enable */
  }
}
</style>
