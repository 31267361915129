import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { makeMetricDisplayNames } from '@/common/utils/commonUtils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getStatsDataStatInformationControllerAxios } from '@/openapi/data/api/data-stat-information-controller-api';
import { StatItem } from '@/openapi/data/model';

interface State {
  metricNames: StatItem[];
  isSetMetrics: boolean;
}

export const dbMetric: Module<State, RootState> = {
  namespaced: true,
  state: {
    metricNames: [],
    isSetMetrics: false,
  },
  mutations: {
    initMetricInfo: (state: State) => {
      state.metricNames = [];
      state.isSetMetrics = false;
    },
    setMetricName: (state: State, metricNames: StatItem[]) => {
      state.metricNames = metricNames;
    },
    setIsSetMetrics: (state: State, isSet: boolean) => {
      state.isSetMetrics = isSet;
    },
  },
  actions: {
    fetchMetricNames: async ({ commit }, categories: string[]) => {
      await commit('initMetricInfo');
      const frameName =
        categories.length > 0
          ? `${FRAME_NAMES.DB_METRIC.NAMES} (${categories.join(',')})`
          : FRAME_NAMES.DB_METRIC.NAMES;
      const { data } = await getStatsDataStatInformationControllerAxios({
        categories,
        frameName,
      });
      const metricInfos = data.data?.[0].dataIds ?? [];
      commit(
        'setMetricName',
        metricInfos.filter((item) => item && item?.dataType?.includes('metrics')),
      );
      commit('setIsSetMetrics', true);
    },
  },
  getters: {
    isSetMetrics: (state: State): boolean => state.isSetMetrics,
    getMetricNames: (state: State): StatItem[] => state.metricNames,
    getMetricDisplayNames: (state: State) => makeMetricDisplayNames(state.metricNames),
    getOnlyMetricNames: (state: State): string[] =>
      state.metricNames.reduce((acc, cur) => {
        acc.push(cur.dataId ?? '');
        return acc;
      }, [] as string[]),
  },
};
