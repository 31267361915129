import '@/common/styles/main.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import router from '@/common/router';
import { store, key } from '@/common/store';
import BaseMenu from '@/common/components/organisms/baseMenu/BaseMenu.vue';
import EVUI, { EvMessageBox, EvMessage, EvNotification } from 'evui';
import Vue3GridLayout from 'vue3-grid-layout-next';
import VueResizeObserver from 'vue-resize-observer';
import { i18n } from '@/common/locale';
import { installComponent } from '@/dashboard/components/widgets/install';
import { registerTheme } from 'echarts/core';
import { getSafeColorListByTheme } from '@/common/utils/chartUtils';

const pinia = createPinia();
const app = createApp(App);

// pass the injection key
app.use(store, key);
app.use(router);
app.use(pinia);

app.use(EVUI);
app.use(i18n);
app.use(Vue3GridLayout);

app.directive('v-resize', VueResizeObserver);

app.config.globalProperties.$messagebox = EvMessageBox;
app.config.globalProperties.$message = EvMessage;
app.config.globalProperties.$notify = EvNotification;

installComponent(app);
app.component('BaseMenu', BaseMenu);

registerTheme('EXEM', { color: getSafeColorListByTheme('EXEM') });
registerTheme('COMPANY', { color: getSafeColorListByTheme('COMPANY') });
registerTheme('FINANCE', { color: getSafeColorListByTheme('FINANCE') });
registerTheme('MAXGAUGE', { color: getSafeColorListByTheme('MAXGAUGE') });
registerTheme('PUBLIC', { color: getSafeColorListByTheme('PUBLIC') });
registerTheme('BLUE', { color: getSafeColorListByTheme('BLUE') });
registerTheme('GREEN', { color: getSafeColorListByTheme('GREEN') });
registerTheme('BROWN', { color: getSafeColorListByTheme('BROWN') });
registerTheme('MONO', { color: getSafeColorListByTheme('MONO') });
registerTheme('PURPLE', { color: getSafeColorListByTheme('PURPLE') });

app.mount('#app');
