import { WidgetChartData, WidgetChartType, WidgetCreateType } from '@/dashboard/utils/types';
import { hasValidTargets, filterChartDataByValidity, isChartDataValid } from './widgets.utils';

export const ChartDataFilter: Record<
  Exclude<
    WidgetChartType,
    'TEXT' | 'IMAGE' | 'MEMO' | 'GROUP' | 'HORIZONTAL_FILTER' | 'VERTICAL_FILTER'
  >,
  (createType: WidgetCreateType) => (chartData: WidgetChartData) => boolean
> = {
  // graphs widget
  TIME_SERIES: filterChartDataByValidity('TIME_SERIES'),
  SCATTER: filterChartDataByValidity('SCATTER'),
  ONE_DAY_COMPARE: filterChartDataByValidity('ONE_DAY_COMPARE'),
  EQUALIZER: filterChartDataByValidity('EQUALIZER'),
  SCOREBOARD: filterChartDataByValidity('SCOREBOARD'),
  PIE: filterChartDataByValidity('PIE'),
  STACKED_PROPORTION_BAR: filterChartDataByValidity('STACKED_PROPORTION_BAR'),
  ACTION_VIEW: isChartDataValid,
  GAUGE: filterChartDataByValidity('GAUGE'),
  // list widget
  TABLE: filterChartDataByValidity('TABLE'),
  TREE_GRID: filterChartDataByValidity('TREE_GRID'),
  TOP_LIST: filterChartDataByValidity('TOP_LIST'),
  // architecture widget
  EXCLUSIVE: isChartDataValid,
  STATUS: hasValidTargets,
  STATUS_SUMMARY: isChartDataValid,
  TOPOLOGY: hasValidTargets,
  SERVER: hasValidTargets,
  // business widget
  SERVICE_CARD: hasValidTargets,
} as const;
