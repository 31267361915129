import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useInternational } from '@/common/locale';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { BASE_MENU_VALUE } from '../baseMenu.define';

export const setup = () => {
  const { t } = useInternational();

  const baseMenuStore = useBaseMenuStore();
  const { panelMenu } = storeToRefs(baseMenuStore);
  const { closeMenu } = baseMenuStore;
  const tooltipMouseEvent = ref<MouseEvent | null>(null);

  const { onMouseEventInTarget } = useSimpleTextTooltip(tooltipMouseEvent);

  const panelMenuTitle = computed<string>(() => {
    if (!panelMenu.value.menuInfo) {
      return '';
    }
    if (panelMenu.value.menuInfo.value === BASE_MENU_VALUE.SETTINGS) {
      return t('WORD.GNB.SETTING');
    }
    return panelMenu.value.menuInfo.text;
  });

  const onClosePanel = () => {
    closeMenu();
  };

  return { t, panelMenuTitle, tooltipMouseEvent, onMouseEventInTarget, panelMenu, onClosePanel };
};
