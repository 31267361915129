<script setup lang="ts">
import { type Props, setup } from './dashboardElement.setup';

const props = defineProps<Props>();

const { isFavorited, isAnalysisFavorited, favoriteRef, onClickElement } = setup(props);
</script>

<template>
  <li
    :class="['dashboard-menu-element sub-menu-element', { selected }]"
    @click="onClickElement"
  >
    <button
      ref="favoriteRef"
      :class="[
        'dashboard-menu-element__fav',
        { 'dashboard-menu-element__fav--active': isFavorited || isAnalysisFavorited },
      ]"
    >
      <ev-icon :icon="isFavorited || isAnalysisFavorited ? 'icon-fill-star' : 'icon-star'" />
    </button>
    <div class="dashboard-menu-element__name">
      <span>{{ dashboardInfo.text }}</span>
    </div>
  </li>
</template>

<style lang="scss">
.dashboard-menu-element {
  justify-content: flex-start;

  &__fav {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    cursor: pointer;
    background-color: transparent;

    i {
      width: 16px;
      height: 16px;
      mask-size: 18px;
    }

    &--active i {
      background-color: var(--icon-star-favorite-color);
    }
  }

  &__name {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    color: inherit;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover:not(.selected) {
    background-color: var(--gnb-icon-hover-bg-color);
  }
}

@include max-screen() {
  .dashboard-menu-element__fav {
    i {
      mask-size: 16px;
    }
  }
}
</style>
