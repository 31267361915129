export const MESSAGE_ACTION = {
  GET_API_INFO: 'GET_API_INFO',
  INIT: 'INIT',
  SET_TARGET_FRAME_INFO: 'SET_TARGET_FRAME_INFO',
} as const;

export const MAIN_FRAMES = {
  ALERT_VIEW: 'Alert View',
  NOTIFICATION_HISTORY_VIEW: 'Notification History View',
  ALERT_DETAIL: 'Alert Detail',
  CONTAINER_VIEW: 'Container View',
  CONTAINER_DETAIL: 'Container Detail',
  DASHBOARD: 'Dashboard',
  DASHBOARD_ALERT_DETAIL: 'Alert Detail - Dashboard',
  DB_METRIC: 'DB Metric',
  HOST_VIEW: 'Host View',
  HOST_DETAIL: 'Host Detail',
  HOST_PROCESS_DETAIL: 'Process Detail - Host',
  HOST_NETWORK_DETAIL: 'Network Detail - Host',
  HOST_DISK_DETAIL: 'Disk Detail - Host',
  INSTANCE_VIEW: 'Instance View',
  INSTANCE_DETAIL: 'Instance Detail',
  INSTANCE_SESSION_DETAIL: 'Instance Session Detail',
  INSTANCE_SQL_DETAIL: 'Instance SQL Detail',
  REDIS_INSTANCE_DETAIL: 'Redis Instance Detail', // NOTE: Redis 만 별도 API 호출
  INSTANCE_CLUSTER_DETAIL: 'Instance Cluster Detail',
  NETWORK_DEVICE_VIEW: 'Network Device View',
  NETWORK_DEVICE_DETAIL: 'Network Device Detail',
  PARAMETER_DETAIL: 'Parameter Detail',
  REPORT_HISTORY: 'Report History',
  REPORT_EXPORT: 'Report Export',
  SCATTER_SUMMARY_DETAIL: 'Scatter Summary Detail',
  ACTIVE_TRANSACTION_LIST: 'Active Transaction List',
  SESSION_TAB: 'Session Tab',
  SQLSERVER_SINGLE_VIEW: 'SQL Server - Single View',
  SQLSERVER_MULTI_VIEW: 'SQL Server - Multi View',
  BUSINESS_VIEW: 'Business View',
  BUSINESS_DETAIL: 'Business Detail',
  BUSINESS_MAP: 'Business Map',
  BUSINESS_ALERT_DETAIL: 'Business Alert Detail',
  TAG: 'Tag',
  TRACES_VIEW: 'Traces View',
  TRANSACTION_VIEW: 'Transaction View',
  TRANSACTION_DETAIL: 'Transaction Detail',
  USER_ENVIRONMENT: 'User Environment',
  WAS_VIEW: 'WAS View',
  WAS_DETAIL: 'WAS Detail',
  // Config
  CONFIG_MY_INFO: 'Config - My Info',
  CONFIG_USER_GROUP: 'Config - User Group',
  CONFIG_USER_GROUP_WINDOW: 'Config - User Group Setting Window',
  CONFIG_USER_WINDOW: 'Config - User Setting Window',
  CONFIG_ROLE_PERMISSION: 'Config - Permission > Role',
  CONFIG_INFRASTRUCTURE_HOST: 'Config - Infrastructure > Host',
  CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW: 'Config - Host Group Setting Window',
  CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW: 'Config - Host Edit Window',
  CONFIG_INFRASTRUCTURE_NETWORK_DEVICE: 'Config - Infrastructure > Network Device',
  CONFIG_INFRASTRUCTURE_DEVICE_WINDOW: 'Config - Network Device Setting Window',
  CONFIG_INFRASTRUCTURE_OID: 'Config - Infrastructure > OID',
  CONFIG_INFRASTRUCTURE_OID_WINDOW: 'Config - OID Setting Window',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE: 'Config - Infrastructure > Message Queue',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW:
    'Config - Infrastructure > Message Queue Group Setting Window',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW:
    'Config - Infrastructure > Message Queue List Setting Window',
  CONFIG_KUBERNETES: 'Config - Kubernetes',
  CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW: 'Config - Kubernetes Agent Install Window',
  CONFIG_APPLICATION: 'Config - Application',
  CONFIG_APPLICATION_WAS_GROUP_WINDOW: 'Config - Application WAS Group Setting Window',
  CONFIG_APPLICATION_WAS_WINDOW: 'Config - Application WAS Setting Window',
  CONFIG_DATABASE_INSTANCE: 'Config - Database > Instance',
  CONFIG_DATABASE_AGENT: 'Config - Database > Agent',
  CONFIG_DATABASE_INSTANCE_GROUP_WINDOW: 'Config - Database Instance Group Setting Window',
  CONFIG_DATABASE_INSTANCE_WINDOW: 'Config - Database Instance Setting Window',
  CONFIG_DATABASE_AGENT_WINDOW: 'Config - Agent Setting Window',
  CONFIG_BUSINESS: 'Config - Business',
  CONFIG_BUSINESS_GROUP_WINDOW: 'Config - Business Group Setting Window',
  CONFIG_BUSINESS_WINDOW: 'Config - Business Setting Window',
  CONFIG_REPORT_DASHBOARD_EXPORT: 'Config - Report > Dashboard Export',
  CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW: 'Config - Dashboard Export Group Setting Window',
  CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW: 'Config - Dashboard Export Setting Window',
  CONFIG_REPORT_SCHEDULE: 'Config - Report > Schedule',
  CONFIG_REPORT_SCHEDULE_GROUP_WINDOW: 'Config - Schedule Group Setting Window',
  CONFIG_REPORT_SCHEDULE_WINDOW: 'Config - Schedule Setting Window',
  CONFIG_USER_ALERT: 'Config - User Alert',
  CONFIG_USER_ALERT_GROUP_WINDOW: 'Config - User Alert Group Setting Window',
  CONFIG_USER_ALERT_RULE_WINDOW: 'Config - User Alert Rule Setting Window',
  CONFIG_NOTIFICATION: 'Config - Notification',
  CONFIG_NOTIFICATION_WINDOW: 'Config - Notification Setting Window',
  CONFIG_SYSTEM_ALERT_INFRASTRUCTURE: 'Config - System Alert > Infrastructure',
  CONFIG_SYSTEM_ALERT_NETWORK_DEVICE: 'Config - System Alert > Network Device',
  CONFIG_SYSTEM_ALERT_KUBERNETES: 'Config - System Alert > Kubernetes',
  CONFIG_SYSTEM_ALERT_APPLICATION: 'Config - System Alert > Application',
  CONFIG_SYSTEM_ALERT_DATABASE: 'Config - System Alert > Database',
  CONFIG_GROUP_NOTIFICATION_SETTING: 'Config - Group Notification Setting',
  CONFIG_INTERFACE_SETTING_WINDOW: 'Config - Interface Setting Window',
  CONFIG_TARGET_PERMISSION: 'Config - Target Permission',
  SETTING_USER_TAG: 'Setting - Tag',
  SETTING_USER_TAG_KEY_WINDOW: 'Setting - Tag Key Window',
  SETTING_USER_TAG_VALUE_WINDOW: 'Setting - Tag Value Window',
  SETTING_USER_TAG_INFORMATION: 'Setting - Tag Information',
  // K8S
  K8S_CLUSTER_TOPOLOGY_VIEW: 'Cluster Topology View',
  K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL: 'Alert Detail - Cluster Topology',
  K8S_OBJECT_TOPOLOGY_VIEW: 'Object Topology View',
  K8S_OBJECT_TOPOLOGY_ALERT_DETAIL: 'Alert Detail - Object Topology',
  K8S_LIST_DETAIL: 'Kubernetes List Detail',
  K8S_CLUSTER_VIEW: 'Cluster View',
  K8S_POD_VIEW: 'Pod View',
  K8S_POD_DETAIL: 'Pod Detail',
  K8S_NAMESPACE_VIEW: 'Namespace View',
  K8S_NAMESPACE_DETAIL: 'Namespace Detail',
  K8S_NODE_VIEW: 'Node View',
  K8S_NODE_DETAIL: 'Node Detail',
  K8S_DEPLOYMENT_VIEW: 'Deployment View',
  K8S_DEPLOYMENT_DETAIL: 'Deployment Detail',
  K8S_STATEFUL_SET_VIEW: 'StatefulSet View',
  K8S_STATEFUL_SET_DETAIL: 'StatefulSet Detail',
  K8S_DAEMON_SET_VIEW: 'DaemonSet View',
  K8S_DAEMON_SET_DETAIL: 'DaemonSet Detail',
  K8S_REPLICA_SET_VIEW: 'ReplicaSet View',
  K8S_REPLICA_SET_DETAIL: 'ReplicaSet Detail',
  K8S_JOB_VIEW: 'Job View',
  K8S_JOB_DETAIL: 'Job Detail',
  K8S_CRONJOB_VIEW: 'CronJob View',
  K8S_CRONJOB_DETAIL: 'CronJob Detail',
  K8S_SERVICE_VIEW: 'Service View (Kubernetes)',
  K8S_SERVICE_DETAIL: 'Service Detail (Kubernetes)',
  K8S_INGRESS_VIEW: 'Ingress View',
  K8S_INGRESS_DETAIL: 'Ingress Detail',
  K8S_CONFIG_STORAGE_VIEW: 'Config & Storage View',
  K8S_CONFIG_STORAGE_DETAIL: 'Config & Storage Detail',
  K8S_CONFIGMAP_VIEW: 'ConfigMap View',
  K8S_CONFIGMAP_DETAIL: 'ConfigMap Detail',
  K8S_PERSISTENT_VOLUME_VIEW: 'Persistent Volume View',
  K8S_PERSISTENT_VOLUME_DETAIL: 'Persistent Volume Detail',
  K8S_PERSISTENT_VOLUME_CLAIM_VIEW: 'Persistent Volume Claim View',
  K8S_PERSISTENT_VOLUME_CLAIM_DETAIL: 'Persistent Volume Claim Detail',
  K8S_STORAGE_CLASSES_VIEW: 'Storage Classes View',
  K8S_STORAGE_CLASSES_DETAIL: 'Storage Classes Detail',
  K8S_ROLE_VIEW: 'Role View',
  K8S_ROLE_DETAIL: 'Role Detail',
  K8S_ROLE_BINDING_VIEW: 'RoleBinding View',
  K8S_ROLE_BINDING_DETAIL: 'RoleBinding Detail',
  K8S_CLUSTER_ROLE_VIEW: 'ClusterRole View',
  K8S_CLUSTER_ROLE_DETAIL: 'ClusterRole Detail',
  K8S_CLUSTER_ROLE_BINDING_VIEW: 'ClusterRoleBinding View',
  K8S_CLUSTER_ROLE_BINDING_DETAIL: 'ClusterRoleBinding Detail',
  K8S_SERVICE_ACCOUNT_VIEW: 'Service Account View',
  K8S_SERVICE_ACCOUNT_DETAIL: 'Service Account Detail',
  // MFC - single, multi view
  ORACLE_SINGLE_VIEW: 'Oracle - Single View',
  ORACLE_MULTI_VIEW: 'Oracle - Multi View',
  POSTGRESQL_SINGLE_VIEW: 'PostgreSQL - Single View',
  POSTGRESQL_MULTI_VIEW: 'PostgreSQL - Multi View',
  MYSQL_SINGLE_VIEW: 'MySQL - Single View',
  MYSQL_MULTI_VIEW: 'MySQL - Multi View',
  // RTM, PA Window
  DATABASE_ALERT_DETAIL: 'Alert Detail - Database',
  FUNCTION_DETAIL_WINDOW: 'Function Detail Window',
  SESSION_DETAIL_WINDOW: 'Session Detail Window',
  SESSION_MANAGER_WINDOW: 'Session Manager Window',
  SQL_DETAIL_WINDOW: 'SQL Detail Window',
  SQL_ELAPSED_LIST_WINDOW: 'SQL Elapsed List Window',
  STAT_CHANGE_WINDOW: 'Stat Change Window',
  PLAN_DIFF_WINDOW: 'Plan Diff Window',
  // PA
  PA_ALERT_HISTORY: 'PA - Alert History',
  PA_DB_INFO: 'PA - DB Info',
  PA_EXCEPTION_SUMMARY: 'PA - Exception Summary',
  PA_PARAMETER_HISTORY: 'PA - Parameter History',
  PA_PLAN_ANALYSIS: 'PA - Plan Analysis',
  PA_SEARCH_SESSION: 'PA - Search Session',
  PA_SEARCH_SQL: 'PA - Search SQL',
  PA_SQL_SUMMARY: 'PA - SQL Summary',
  PA_TOP_N_ANALYSIS: 'PA - Top N Analysis',
  PA_TABLESPACE: 'PA - Tablespace',
  PA_TRANSACTION_TREND: 'PA - Transaction Trend',
  PA_TREND_ANALYSIS: 'PA - Trend Analysis',
  PA_UNDO_TEMP: 'PA - Undo & Temp',
  PA_WAIT_SUMMARY: 'PA - Wait Summary',
  // Config

  // Log
  LOG_LOGGING: 'Log - Logging',
  LOG_LIVE_TAIL: 'Log - Live Tail',
  LOG_SETTING: 'Setting - Log',

  // Query Manager
  QUERY_MANAGER: 'Query Manager',
  QUERY_MANAGER_HISTORY: 'Query Manager History',

  // Cloud
  CLOUD_AWS: 'Cloud - AWS',

  // Data Retention
  DATA_RETENTION: 'Data Retention',
} as const;

const BASE_FRAME_NAMES = {
  ALERT_VIEW: {
    FILTERS: `${MAIN_FRAMES.ALERT_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.ALERT_VIEW}/Alert List`,
    TAGS: `${MAIN_FRAMES.ALERT_VIEW}/Tags`,
  },
  ALERT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.ALERT_DETAIL}/Information`,
    CURRENT: `${MAIN_FRAMES.ALERT_DETAIL}/Current`,
    CURRENT_CLEAR: `${MAIN_FRAMES.ALERT_DETAIL}/[Clear] Current`,
    HISTORY_TREND: `${MAIN_FRAMES.ALERT_DETAIL}/History`,
    HISTORY_SORT_BY_TIME: `${MAIN_FRAMES.ALERT_DETAIL}/History - Sort By Time`,
    PATH: `${MAIN_FRAMES.ALERT_DETAIL}/Information - Path`,
  },
  NOTIFICATION_HISTORY_VIEW: {
    FILTERS: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Notification History View`,
    DETAIL: `${MAIN_FRAMES.NOTIFICATION_HISTORY_VIEW}/Notification Status Detail`,
  },
  CONTAINER_VIEW: {
    FILTERS: `${MAIN_FRAMES.CONTAINER_VIEW}/Filters`,
    CONTAINER_LIST: `${MAIN_FRAMES.CONTAINER_VIEW}/Container List`,
  },
  CONTAINER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.CONTAINER_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.CONTAINER_DETAIL}/Metric`,
    RELATIONAL_INFORMATION: `${MAIN_FRAMES.CONTAINER_DETAIL}/Relational Information`,
    LOG: `${MAIN_FRAMES.CONTAINER_DETAIL}/Log`,
    APPLICATION_GET: `${MAIN_FRAMES.CONTAINER_DETAIL}/[GET] Application`,
    ACTIVES: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives Detail - Kill Thread`,
    DATA_SOURCE: `${MAIN_FRAMES.CONTAINER_DETAIL}/Data Source`,
    TRANSACTIONS: `${MAIN_FRAMES.CONTAINER_DETAIL}/Transactions`,
  },
  DASHBOARD: {
    ALERT_COUNT: `${MAIN_FRAMES.DASHBOARD}/Alert Count`,
    DASHBOARD_MAPPING_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Mapping Info`,
    DASHBOARD_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Info`,
    DASHBOARD_WIDGET_INFO: `${MAIN_FRAMES.DASHBOARD}/Dashboard Widgets Info`,
    DASHBOARD_USER_GROUP: `${MAIN_FRAMES.DASHBOARD}/Dashboard User Group List`,
    DASHBOARD_USER_GROUP_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] Dashboard User Group List`,
    DASHBOARD_USER_GROUP_MOVE: `${MAIN_FRAMES.DASHBOARD}/[Move] Dashboard User Group List`,
    DASHBOARD_USER_GROUP_SAVE: `${MAIN_FRAMES.DASHBOARD}/[Save] Dashboard User Group List`,
    DASHBOARD_LIST: `${MAIN_FRAMES.DASHBOARD}/Dashboard List`,
    DASHBOARD_LIST_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] Dashboard List`,
    DASHBOARD_LIST_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] Dashboard List`,
    DASHBOARD_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] Dashboard`,
    DASHBOARD_CLIENT_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] Dashboard - Client`,
    DASHBOARD_IMPORT: `${MAIN_FRAMES.DASHBOARD}/[Import] Dashboard`,
    FAVORITE_DASHBOARD_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] Favorite Dashboard`,
    REPORT_DASHBOARD_SET: `${MAIN_FRAMES.DASHBOARD}/[Set] Report Dashboard`,
    GLOBAL_VARIABLES: `${MAIN_FRAMES.DASHBOARD}/Global Variables`,
    GLOBAL_VARIABLES_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update]Global Variables`,
    VIEW_PACK_LIST: `${MAIN_FRAMES.DASHBOARD}/View Pack List`,
    VIEW_PACK_UPDATE: `${MAIN_FRAMES.DASHBOARD}/[Update] View Pack`,
    VIEW_PACK_DELETE: `${MAIN_FRAMES.DASHBOARD}/[Delete] View Pack`,
    VIEW_PACK_IMPORT: `${MAIN_FRAMES.DASHBOARD}/[Import] View Pack`,
    VIEW_PACK_EXPORT: `${MAIN_FRAMES.DASHBOARD}/[Export] View Pack`,
    PRESET_LIST: `${MAIN_FRAMES.DASHBOARD}/Preset List`,
    START_SCREEN_SET: `${MAIN_FRAMES.DASHBOARD}/[Set] Start Screen`,
    SHARED_DASHBOARD_SAVE: `${MAIN_FRAMES.DASHBOARD}/[Save] Shared Dashboard`,
    WIDGETS: `${MAIN_FRAMES.DASHBOARD} Widgets`,
  },
  DASHBOARD_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear System Alert`,
  },
  DATABASE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Clear System Alert`,
  },
  BUSINESS_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.BUSINESS_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.BUSINESS_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.BUSINESS_ALERT_DETAIL}/Clear System Alert`,
  },
  DB_METRIC: {
    NAMES: `${MAIN_FRAMES.DB_METRIC}/DB Metric Name`,
    METRIC: `${MAIN_FRAMES.DB_METRIC}/DB Metric`,
    METRIC_I1M: `${MAIN_FRAMES.DB_METRIC}/DB Metric 1 minute interval`,
    METRIC_I5S: `${MAIN_FRAMES.DB_METRIC}/DB Metric 5 second interval`,
  },
  HOST_VIEW: {
    FILTERS: `${MAIN_FRAMES.HOST_VIEW}/Filters`,
    HOST_LIST: `${MAIN_FRAMES.HOST_VIEW}/Host List`,
  },
  HOST_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.HOST_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.HOST_DETAIL}/Metric`,
    PROCESS: `${MAIN_FRAMES.HOST_DETAIL}/Process`,
    NETWORK: `${MAIN_FRAMES.HOST_DETAIL}/Network`,
    DISK: `${MAIN_FRAMES.HOST_DETAIL}/Disk`,
    CONTAINER: `${MAIN_FRAMES.HOST_DETAIL}/Container`,
    INSTALLED_PACKAGE: `${MAIN_FRAMES.HOST_DETAIL}/Installed Package`,
    RELATIONAL_INFORMATION: `${MAIN_FRAMES.HOST_DETAIL}/Relational Information`,
    AGENT: `${MAIN_FRAMES.HOST_DETAIL}/Agent`,
  },
  HOST_PROCESS_DETAIL: {
    LIMITS: `${MAIN_FRAMES.HOST_PROCESS_DETAIL}/Limits`,
    PROCESS_STAT: `${MAIN_FRAMES.HOST_PROCESS_DETAIL}/Process Stat`,
  },
  HOST_NETWORK_DETAIL: {
    NETWORK_STAT: `${MAIN_FRAMES.HOST_NETWORK_DETAIL}/Network Stat`,
  },
  HOST_DISK_DETAIL: {
    DISK_STAT: `${MAIN_FRAMES.HOST_DISK_DETAIL}/Disk Stat`,
  },
  NETWORK_DEVICE_VIEW: {
    DEVICE_LIST: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Device List`,
    FILTERS: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Filters`,
    TOP_5_LIST: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Top 5 List`,
  },
  NETWORK_DEVICE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Information`,
    INTERFACE: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Interface`,
    METRIC: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Metric`,
    SYSLOG: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/SysLog`,
    SNMP_TRAP: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/SNMP Trap`,
    ALERT: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Alert`,
  },
  INSTANCE_VIEW: {
    FILTERS: `${MAIN_FRAMES.INSTANCE_VIEW}/Filters`,
    INSTANCE_LIST: `${MAIN_FRAMES.INSTANCE_VIEW}/Database List`,
    FAVORITE_INSTANCE_UPDATE: `${MAIN_FRAMES.INSTANCE_VIEW}/[Update] Favorite Database`,
  },
  INSTANCE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.INSTANCE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.INSTANCE_DETAIL}/Metric`,
    ACTIVE_SESSION: `${MAIN_FRAMES.INSTANCE_DETAIL}/Active Session`,
    EVENT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Event`,
    SQL_LIST: `${MAIN_FRAMES.INSTANCE_DETAIL}/SQL List`,
    LOCK_INFO: `${MAIN_FRAMES.INSTANCE_DETAIL}/Lock Info`,
    META_LOCK_INFO: `${MAIN_FRAMES.INSTANCE_DETAIL}/Meta Lock Info`,
    ALERT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Alert`,
    PARAMETER: `${MAIN_FRAMES.INSTANCE_DETAIL}/Parameter`,
    LOGS: `${MAIN_FRAMES.INSTANCE_DETAIL}/Logs`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_DETAIL}/SQL Full Text`,
    CLOUD_EVENT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Cloud Event`,
  },
  INSTANCE_SESSION_DETAIL: {
    EVENT_INFO: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Event Info`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Active Session History`,
    INFORMATION: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Information`,
    KILL_SESSION: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Kill Session`,
    PLAN: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Plan`,
    PARAMETER_LIST: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Parameter List`,
    PERFORMANCE_DETAILS: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Session Performance Details`,
    SESSION_METRIC: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Session Metric`,
    SQL_METRIC: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/SQL Metric`,
  },
  INSTANCE_SQL_DETAIL: {
    EVENT_INFO: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Event Info`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/History`,
    INFORMATION: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Information`,
    PLAN: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Plan`,
    PLAN_HASH_LIST: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Plan Hash List`,
    PARAMETER_LIST: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Parameter List`,
    TREND_SQL: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend By SQL Stat`,
    TREND_SESSION: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend By Session Stat`,
    TREND_BUFFER_GETS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Buffer Gets`,
    TREND_CPU_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - CPU Time`,
    TREND_DISK_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Disk Reads`,
    TREND_ELAPSED_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Elapsed Time`,
    TREND_EXECUTIONS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Executions`,
    TREND_USER_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - User Calls`,
    TREND_RECURSIVE_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Recursive Calls`,
    TREND_WORKER_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Worker Time`,
    TREND_LOGICAL_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Logical Reads`,
    TREND_PHYSICAL_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Physical Reads`,
    TREND_LATENCY: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Latency`,
    TREND_BUFFER_PULL_READ_REQUESTS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - InnoDB Buffer Pull Read Requests`,
    TREND_BUFFER_POOL_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - InnoDB Buffer Pool Reads`,
    TREND_TOTAL_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Total Time`,
    TREND_SHARED_BLKS_HIT: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Shared Blocks Hit`,
    TREND_SHARED_BLKS_READ: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Shared Blocks Read`,
    TREND_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Calls`,
  },
  REDIS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Metric`,
    CLIENT_LIST: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Client List`,
    COMMAND_STAT: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Command Stat`,
  },
  INSTANCE_CLUSTER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.INSTANCE_CLUSTER_DETAIL}/Information`,
    MAP: `${MAIN_FRAMES.INSTANCE_CLUSTER_DETAIL}/Map`,
  },
  MYSQL_SINGLE_VIEW: {
    ADMIN_REFERENCE_ALERT_LOGS: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Alert Logs`,
    ADMIN_REFERENCE_TEMP_USAGE_CHART: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Temp Usage Chart`,
    ADMIN_REFERENCE_OPEN_TABLES: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Open Tables`,
    ADMIN_REFERENCE_PARAMETER: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Parameter`,
    ADMIN_REFERENCE_TOP_TABLESPACE_SIZE: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Top Tablespace Size`,
    OVERVIEW: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview`,
    OVERVIEW_ALERT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Alert`,
    OVERVIEW_REPLICATION: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Replication`,
    OVERVIEW_SORTING: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Sorting`,
    OVERVIEW_REDO_LOG: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Redo Log File Size`,
    SCAN_TYPE: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Scan Type`,
    SLOW_QUERY: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Slow Query`,
    STATICS_EVENTS_TOP_DIFF_STATISTICS: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Statics & Events > Top Diff Statistics`,
    STATICS_EVENTS_TOP_EVENT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Statics & Events > Top Event`,
    SQL_QUERY_ERROR_TOP_SQL: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/SQL Query Error > Top SQL Order By`,
    TREND_SUMMARY: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Trend Summary`,
    ALERT_COUNT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Alert Count`,
  },
  MYSQL_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Overview`,
    SLOW_QUERY: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Slow Query`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/DB Session Type`,
    RANK_GLOBAL_TEMP_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Global Temp Usage`,
    RANK_REDO_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Redo Usage`,
    RANK_SORT_MERGE_PASSES: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Sort Merge Passes`,
    RANK_SESSION_TEMP_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Session Temp Usage`,
    RANK_QUERIES: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Queries[Delta]`,
    RANK_ALERT: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Alert`,
    RANK_METRIC: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Metric`,
    ALERT_COUNT: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Alert Count`,
  },
  ORACLE_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Overview`,
    REDO: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Redo`,
    UNDO_TEMP: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Undo & Temp`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Session Type`,
    DB_WAIT_CLASS: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Wait Class`,
    DB_WAIT_CLASS_TREND: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Wait Class Trend`,
    DB_TIME_MODEL: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Time Model`,
    DB_TIME_MODEL_TREND: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Time Model Trend`,
    ALERT_COUNT: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Alert Count`,
  },
  PLAN_DIFF_WINDOW: {
    PLAN_DIFF: `${MAIN_FRAMES.PLAN_DIFF_WINDOW}/Plan Diff`,
    PLAN_HASH_VALUE: `${MAIN_FRAMES.PLAN_DIFF_WINDOW}/Plan Hash Value`,
  },
  POSTGRESQL_SINGLE_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview`,
    OVERVIEW_LOCK_TREE: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Lock Tree`,
    OVERVIEW_ALERT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Alert`,
    OVERVIEW_VACUUM: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Vacuum`,
    OVERVIEW_REPLICATION: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Replication`,
    OVERVIEW_CHECKPOINT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Checkpoint`,
    SLOW_QUERY: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Slow Query`,
    ADMIN_REFERENCE_ALERT_LOGS: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Alert Logs`,
    ADMIN_REFERENCE_VACUUM: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Vacuum`,
    ADMIN_REFERENCE_ADDITIONAL_INFO: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Additional Information`,
    OBJECT_TOP_OBJECT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Object > Top Object`,
    OBJECT_OBJECT_SCAN: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Object > Object Scan`,
    STATICS_EVENTS_TOP_DIFF_STATISTICS: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Statics & Events > Top Diff Statistics`,
    STATICS_EVENTS_TOP_EVENT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Statics & Events > Top Event`,
    SQL_FUNCTION_TOP_SQL: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/SQL & Function > Top SQL`,
    SQL_FUNCTION_TOP_FUNCTION: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/SQL & Function > Top Function`,
    TREND_SUMMARY: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Trend Summary`,
    ALERT_COUNT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Alert Count`,
  },
  POSTGRESQL_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Overview`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/DB Session Type`,
    RANK: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank`,
    RANK_QUERY_IO: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Query IO`,
    RANK_TRANSACTION_TIME: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Transaction Time`,
    RANK_VACUUM_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Vacuum Usage`,
    RANK_TEMP_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Temp Usage`,
    RANK_FILESYSTEM_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Filesystem Usage`,
    RANK_ALERT: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Alert`,
    RANK_TOP_METRIC_1MIN: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Top Metric 1 minute`,
    RANK_TOP_METRIC_10MIN: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Top Metric 1 minute`,
    SLOW_QUERY: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Slow Query`,
    ALERT_COUNT: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Alert Count`,
  },
  REPORT_HISTORY: {
    REPORT_HISTORY_LIST: `${MAIN_FRAMES.REPORT_HISTORY}/Report History List`,
    DOWNLOAD: `${MAIN_FRAMES.REPORT_HISTORY}/Download`,
  },
  REPORT_EXPORT: {
    REPORT_LIST: `${MAIN_FRAMES.REPORT_EXPORT}/Report List`,
    EXPORT: `${MAIN_FRAMES.REPORT_EXPORT}/Export`,
  },
  SQLSERVER_SINGLE_VIEW: {
    ALERT_LOGS: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Alert Logs`,
    DB_METRIC: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/DB Metric`,
    TREND_24: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/24 Trend`,
    TEMPDB_USAGE_GAUGE: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/TempDB Usage > Gauge`,
    TEMPDB_USAGE_TREND: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/TempDB Usage > Trend`,
    OVERVIEW_CPU_SQL_ACTIVE_SESSION_LOCK: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > CPU & SQL, Active Session Lock`,
    OVERVIEW_ACTIVE_SESSION_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > Active Session Elapsed Time Scatter`,
    OVERVIEW_SQL_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > SQL Elapsed Time Scatter`,
    SESSION_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Session Elapsed Time`,
    ALERT_COUNT: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Alert Count`,
  },
  BUSINESS_VIEW: {
    FILTERS: `${MAIN_FRAMES.BUSINESS_VIEW}/Filters`,
    BUSINESS_LIST: `${MAIN_FRAMES.BUSINESS_VIEW}/Business List`,
    BUSINESS_DATABASE: `${MAIN_FRAMES.BUSINESS_VIEW}/DB Stat`,
    BUSINESS_WAS: `${MAIN_FRAMES.BUSINESS_VIEW}/WAS Stat`,
  },
  BUSINESS_DETAIL: {
    TARGET_KINDS: `${MAIN_FRAMES.BUSINESS_DETAIL}/Target Kinds`,
    INFORMATION: `${MAIN_FRAMES.BUSINESS_DETAIL}/Information`,
    APP_METRIC: `${MAIN_FRAMES.BUSINESS_DETAIL}/App Metric`,
    DB_METRIC: `${MAIN_FRAMES.BUSINESS_DETAIL}/DB Metric`,
    ALERT: `${MAIN_FRAMES.BUSINESS_DETAIL}/Alert`,
  },
  BUSINESS_MAP: {
    FILTERS: `${MAIN_FRAMES.BUSINESS_MAP}/Filters`,
    CIRCUIT_BREAKER: `${MAIN_FRAMES.BUSINESS_MAP}/Circuit Breaker`,
    NODE_CONNECTION: `${MAIN_FRAMES.BUSINESS_MAP}/Circuit Breaker > Node Connection`,
  },
  SESSION_TAB: {
    ACTIVE_BACKENDS: `${MAIN_FRAMES.SESSION_TAB}/Active Backends`,
    BLOCKED: `${MAIN_FRAMES.SESSION_TAB}/Blocked`,
    BLOCKED_DETAIL: `${MAIN_FRAMES.SESSION_TAB}/Blocked Detail`,
    WAIT_CHAINS: `${MAIN_FRAMES.SESSION_TAB}/Wait Chains`,
    WAIT_CLASS: `${MAIN_FRAMES.SESSION_TAB}/Wait Class`,
    WAIT_EVENT: `${MAIN_FRAMES.SESSION_TAB}/Wait Event`,
    LOCK_TREE: `${MAIN_FRAMES.SESSION_TAB}/Lock Tree`,
    META_LOCK_TREE: `${MAIN_FRAMES.SESSION_TAB}/Meta Lock Tree`,
    SQL_SUMMARY: `${MAIN_FRAMES.SESSION_TAB}/SQL Summary`,
    SESSION: `${MAIN_FRAMES.SESSION_TAB}/Session`,
    SESSIONS: `${MAIN_FRAMES.SESSION_TAB}/Sessions`,
    THREAD: `${MAIN_FRAMES.SESSION_TAB}/Thread`,
  },
  SESSION_DETAIL_WINDOW: {
    FULL_TEXT: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Full Text`,
    KILL_SESSION: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Kill Session`,
    INFORMATION: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Information`,
    PLAN: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Plan`,
    EVENT_INFO: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Event Info`,
    PARAMETER_LIST: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Parameter List`,
  },
  SESSION_MANAGER_WINDOW: {
    SESSION: `${MAIN_FRAMES.SESSION_MANAGER_WINDOW}/Session`,
    BLOCKED: `${MAIN_FRAMES.SESSION_MANAGER_WINDOW}/Blocked`,
  },
  SQL_ELAPSED_LIST_WINDOW: {
    SLOW_QUERY: `${MAIN_FRAMES.SQL_ELAPSED_LIST_WINDOW}/Slow Query`,
  },
  SQL_DETAIL_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.SQL_DETAIL_WINDOW}/Information`,
    PLAN: `${MAIN_FRAMES.SQL_DETAIL_WINDOW}/Plan`,
  },
  STAT_CHANGE_WINDOW: {},
  FUNCTION_DETAIL_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.FUNCTION_DETAIL_WINDOW}/Information`,
    SRC_VIEW: `${MAIN_FRAMES.FUNCTION_DETAIL_WINDOW}/Source View`,
  },
  USER_ENVIRONMENT: {
    STAT_INFO: `${MAIN_FRAMES.USER_ENVIRONMENT}/Stat Info`,
    SELECTED_INSTANCE_ID: `${MAIN_FRAMES.USER_ENVIRONMENT}/Selected Instance ID`,
    SLOW_QUERY_OPTION: `${MAIN_FRAMES.USER_ENVIRONMENT}/Slow Query Option`,
    SAVE: `${MAIN_FRAMES.USER_ENVIRONMENT}/[Save]`,
  },
  SIMPLE_WINDOW: {
    ACTIVE_BACKEND: 'Active Backend Window/Active Backend',
    FILE_SYSTEM_USAGE: 'File System Usage Window/File System Usage',
    METRIC: 'Metric Window/Metric',
    GLOBAL_TEMP_LIST: 'Global Temp List Window/Global Temp List',
    LOCK_TREE: 'Lock Tree Window/Lock Tree',
    META_LOCK_TREE: 'Lock Tree Window/Meta Lock Tree',
    LOADED_CLASS: 'Loaded Class Window/Loaded Class',
    OPEN_TABLES: 'Open Tables Window/Open Tables',
    PARAMETER: 'Parameter Window/Parameter',
    THREAD: 'Thread Window/Thread',
    TRANSACTION_DETAIL: 'Transaction Detail Window/Transaction Detail',
    TOP_10_SESSION_TEMP_LIST: "Top 10 Sessions Temp List Window/Top 10 Session's Temp List",
    TOP_10_TABLESPACE_LIST: 'Top 10 Tablespace List Window/Top 10 Tablespace List',
    TOP_10_SORTING_LIST: 'Top 10 Sorting List Window/Top 10 Sorting List',
    TOP_10_OLD_TRANSACTION: 'Top 10 Old Transaction Window/Top 10 Old Transaction',
    TOP_10_OBJECT_LIST_ORDER_BY: 'Top 10 Object List Order By Window/Top 10 Object List Order By',
    TOP_10_EVENT_LIST: 'Top 10 Event List Window/Top 10 Event List',
    TOP_10_SQL_LIST_ORDER_BY: 'Top 10 SQL List Order By Window/Top 10 List Order By',
    TOP_10_SQL_FULL_SCAN_LIST: 'Top 10 SQL Full Scan List Window/Top 10 SQL Full Scan List',
    TOP_SQL: 'Top SQL Window/Top SQL',
    STAT_CHART_OPTION_METRIC: 'Stat Chart Option Window/Metric',
    SQL_STAT_LIST: 'SQL Stat List Window/SQL Stat List',
    STAT_METRIC: 'Stat Metric Window/Stat Metric',
    SESSION_LIST: 'Session List Window/Session List',
    SYSLOG_MESSAGE: 'Syslog Window/System Message Log',
    SNMP_TRAP_MESSAGE_WINDOW: 'SNMP Message Window/SNMP Message',
    SQL_TEXT_WINDOW: 'SQL Text Window/SQL Text',
    VACUUMING_BACKENDS_WINDOW: 'Vacuuming Backends Window/Vacuuming Backends',
  },
  SCATTER_SUMMARY_DETAIL: {
    SCATTER_CHART: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/Scatter Chart`,
    LIST: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/List`,
    WIDGET_MAPPING_INFO: `${MAIN_FRAMES.SCATTER_SUMMARY_DETAIL}/Widget Mapping Info`,
  },
  ACTIVE_TRANSACTION_LIST: {
    DETAIL: `${MAIN_FRAMES.ACTIVE_TRANSACTION_LIST}/Detail`,
  },
  WAS_VIEW: {
    FILTERS: `${MAIN_FRAMES.WAS_VIEW}/Filters`,
    WAS_LIST: `${MAIN_FRAMES.WAS_VIEW}/WAS List`,
  },
  WAS_DETAIL: {
    ACTIVES: `${MAIN_FRAMES.WAS_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail - Kill Thread`,
    CONFIG: `${MAIN_FRAMES.WAS_DETAIL}/Config`,
    DATA_SOURCE: `${MAIN_FRAMES.WAS_DETAIL}/Data Source`,
    HEAP_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Heap Dump`,
    HEAD_DUMP_EXEC: `${MAIN_FRAMES.WAS_DETAIL}/[Exec] Heap Dump`,
    GARBAGE_COLLECTION: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection`,
    GARBAGE_COLLECTION_JVM_GC_STAT: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection - JVM/GC Stat`,
    METRIC: `${MAIN_FRAMES.WAS_DETAIL}/Metric`,
    INFORMATION: `${MAIN_FRAMES.WAS_DETAIL}/Information`,
    INFORMATION_WAS_GROUP: `${MAIN_FRAMES.WAS_DETAIL}/Information - WAS Group`,
    TRANSACTIONS: `${MAIN_FRAMES.WAS_DETAIL}/Transactions`,
    THREAD_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump`,
    THREAD_DUMP_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump Detail`,
    LIBRARIES: `${MAIN_FRAMES.WAS_DETAIL}/Libraries`,
    LOADED_CLASS: `${MAIN_FRAMES.WAS_DETAIL}/Loaded Class`,
  },
  TRACES_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRACES_VIEW}/Filters`,
    TRACES_LIST: `${MAIN_FRAMES.TRACES_VIEW}/Traces List`,
  },
  TRANSACTION_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRANSACTION_VIEW}/Filters`,
    CURRENT_STAT_CARD: `${MAIN_FRAMES.TRANSACTION_VIEW}/Current Card`,
    TOP_5_CARD: `${MAIN_FRAMES.TRANSACTION_VIEW}/Top 5 Card`,
    TRANSACTION_TREND: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Trend`,
    TRANSACTION_COUNT: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Count`,
  },
  TRANSACTION_DETAIL: {
    ETOE_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Path,List`,
    ETOE_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Information`,
    ETOE_METRIC: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Metric`,
    ETOE_CALL_TREE_DETAIL: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Detail`,
    ETOE_CALL_TREE_SUMMARY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Summary`,
    ETOE_SQL_TEXT: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/SQL Text`,
    ETOE_EXCEPTION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Exception`,
    ETOE_ACTIVE_HISTORY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Active History`,
    SPAN_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Path`,
    SPAN_LIST: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/List`,
    SPAN_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Information`,
    SPAN_TAGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Tags`,
    SPAN_LOGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Logs`,
    SPAN_ERROR: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Error`,
  },
  TAG: {
    TARGET_TAGS: `${MAIN_FRAMES.TAG}/Target Tags`,
    TAG_ID_LIST: `${MAIN_FRAMES.TAG}/Tag ID List`,
    TARGET_TAGS_CATEGORY_BY_VALUE: (value: string) =>
      `${MAIN_FRAMES.TAG}/Target Tags Category ${value}`,
  },
  PARAMETER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.PARAMETER_DETAIL}/Information`,
  },
} as const;

const CONFIG_FRAME_NAMES = {
  CONFIG_MY_INFO: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_MY_INFO}/Information`,
    PATCH: `${MAIN_FRAMES.CONFIG_MY_INFO}/Patch`,
    SAVE: `${MAIN_FRAMES.CONFIG_MY_INFO}/[Save] information`,
    ACCESS_TOKEN_LIST: `${MAIN_FRAMES.CONFIG_MY_INFO}/Access Token List`,
  },
  CONFIG_USER_GROUP: {
    USER_GROUP_LIST: `${MAIN_FRAMES.CONFIG_USER_GROUP}/User Group List`,
    USER_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Delete] User Group`,
    USER_LIST: `${MAIN_FRAMES.CONFIG_USER_GROUP}/User List`,
    USER_DELETE: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Delete] User`,
    USER_LOCK: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Lock] User`,
    USER_ALERT_ENABLED: `${MAIN_FRAMES.CONFIG_USER_GROUP}/[Enabled] User Alert`,
  },
  CONFIG_USER_GROUP_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/Information`,
    USER_LIST_ALL: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/User List - All`,
    GROUP_EDIT: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/[Edit] GROUP`,
    GROUP_SAVE: `${MAIN_FRAMES.CONFIG_USER_GROUP_WINDOW}/[Save] GROUP`,
  },
  CONFIG_USER_WINDOW: {
    PERMISSION: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/Permission`,
    PERMISSION_SET: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Set] Permission`,
    INFORMATION_SAVE: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Save] Information`,
    USER_SAVE: `${MAIN_FRAMES.CONFIG_USER_WINDOW}/[Save] User`,
  },
  CONFIG_ROLE_PERMISSION: {
    USER_ROLE_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/User Role List`,
    USER_ROLE_LIST_DELETE: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/[Delete] User Role List`,
    USER_ROLE_LIST_SAVE: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/[Save] User Role List`,
    PERMISSION_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/Permission List`,
    PERMISSION_DEFAULT_LIST: `${MAIN_FRAMES.CONFIG_ROLE_PERMISSION}/Permission Default List`,
  },
  CONFIG_TARGET_PERMISSION: {
    GROUP_TARGET: `${MAIN_FRAMES.CONFIG_TARGET_PERMISSION}/GROUP_TARGET`,
    PATCH_PERMISSION: `${MAIN_FRAMES.CONFIG_TARGET_PERMISSION}/PATCH_PERMISSION`,
  },
  CONFIG_INFRASTRUCTURE_HOST: {
    HOST_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host Group List`,
    HOST_GROUP_INFORMATION: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host Group Information`,
    HOST_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Delete] Host Group`,
    HOST_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host List`,
    HOST_LIST_ALL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host List - All`,
    HOST_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Delete] Host`,
    GROUP_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Move] Group`,
  },
  CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW: {
    HOST_CUSTOM_TAG: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW}/Instance Custom Tags`,
    HOST_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW}/[Update] Host Information`,
  },
  CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW: {
    CONFIG_DETAIL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/Config Detail`,
    CONFIG_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Add] Config`,
    CONFIG_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Edit] Config`,
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/Config List`,
    CONFIG_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Delete] CONFIG`,
    HOST_GROUP_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Update] Host Group`,
    HOST_GROUP_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Add] Host Group`,
    HOST_LIST_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Update] Host List`,
  },
  CONFIG_INFRASTRUCTURE_NETWORK_DEVICE: {
    NETWORK_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/Network Group List`,
    DEVICE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/Device List`,
    DEVICE_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/[Move] Device`,
    DEVICE_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/[Delete] Device`,
  },
  CONFIG_INFRASTRUCTURE_NETWORK_WINDOW: {
    NETWORK_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Network Group List`,
    NETWORK_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Save] Network Group`,
  },
  CONFIG_INFRASTRUCTURE_DEVICE_WINDOW: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Agent List`,
    DEVICE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Device List`,
    DEVICE_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Save] Device`,
    CONNECTION_TEST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Test] Connection`,
    SNMP_SECURITY_INFO: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/SNMP Security Info`,
    EVENT_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Delete] Device Event`,
  },
  CONFIG_INFRASTRUCTURE_OID: {
    OID_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/Oid Group List`,
    OID_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/OID List`,
    OID_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/[Delete] OID`,
    OID_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/[Delete] OID Group`,
    OID_APPLY_AGENT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/OID Apply Agent`,
  },
  CONFIG_INFRASTRUCTURE_OID_WINDOW: {
    OID_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/[Save] OID Group`,
    OID_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/[Save] OID`,
    OID_EVENT_TYPE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Event Type List`,
    OID_PACK_DETAIL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Oid Pack Detail`,
    OID_RESOURCE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Oid Resource List`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE: {
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/Group List`,
    GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Delete] Group`,
    MESSAGE_QUEUE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/Message Queue List`,
    MESSAGE_QUEUE_LIST_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Delete] Message Queue List`,
    MESSAGE_QUEUE_LIST_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Move] Message Queue List`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW: {
    GROUP: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/Group`,
    GROUP_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/[Add] Group`,
    GROUP_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/[Edit] Group`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW: {
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Group List`,
    MESSAGE_QUEUE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Message Queue`,
    MESSAGE_QUEUE_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Add] Message Queue`,
    MESSAGE_QUEUE_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Edit] Message Queue`,
    MESSAGE_QUEUE_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Move] Message Queue`,
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Agent List`,
    TEST_CONNECTION: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Test] Connection`,
  },
  CONFIG_KUBERNETES: {
    CLUSTER_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES}/Cluster List`,
    CLUSTER_SAVE: `${MAIN_FRAMES.CONFIG_KUBERNETES}/[Save] Cluster`,
    CLUSTER_DELETE: `${MAIN_FRAMES.CONFIG_KUBERNETES}/[Delete] Cluster`,
    NAMESPACE_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES}/Namespace List`,
  },
  CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW: {
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW}/Config List`,
    YAML_DOWNLOAD: `${MAIN_FRAMES.CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW}/[Download] YAML`,
  },
  CONFIG_APPLICATION: {
    WAS_GROUP_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/WAS Group List`,
    WAS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Save] WAS Group`,
    WAS_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/WAS List`,
    WAS_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] WAS List`,
  },
  CONFIG_APPLICATION_WAS_GROUP_WINDOW: {
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/Config List`,
    CONFIG_ADD: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Add] Config`,
    CONFIG_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Delete] Config`,
    CONFIG_EDIT: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Edit] Config`,
    WAS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Save] WAS Group`,
  },
  CONFIG_APPLICATION_WAS_WINDOW: {
    WAS_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_WINDOW}/WAS List`,
    WAS_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Save] WAS`,
  },
  CONFIG_DATABASE_INSTANCE: {
    INSTANCE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Instance Group List`,
    INSTANCE_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Delete] Instance Group`,
    INSTANCE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Instance List`,
    INSTANCE_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Delete] Instance`,
    INSTANCE_MOVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Move] Instance`,
    DATABASE_TYPE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Database Type List`,
  },
  CONFIG_DATABASE_INSTANCE_GROUP_WINDOW: {
    INSTANCE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_GROUP_WINDOW}/Instance Group List`,
    INSTANCE_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_GROUP_WINDOW}/[Save] Instance Group`,
  },
  CONFIG_DATABASE_INSTANCE_WINDOW: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Agent List`,
    INSTANCE_INFORMATION: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Instance Information`,
    INSTANCE_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/[Save] Instance`,
    INSTANCE_CUSTOM_TAG: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Instance Custom Tags`,
  },
  CONFIG_DATABASE_AGENT: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/Agent List`,
    AGENT_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/[Delete] Agent List`,
    INSTANCE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/Instance List`,
  },
  CONFIG_DATABASE_AGENT_WINDOW: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}/Agent List`,
    ACCESS_TOKEN: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}Access Token`,
    AGENT_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}/[Save] Agent`,
  },
  CONFIG_BUSINESS: {
    BUSINESS_GROUP_LIST: `${MAIN_FRAMES.CONFIG_BUSINESS}/Business Group List`,
    BUSINESS_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Delete] Business Group`,
    BUSINESS_LIST: `${MAIN_FRAMES.CONFIG_BUSINESS}/Business List`,
    BUSINESS_MOVE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Move] Business`,
    BUSINESS_DELETE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Delete] Business`,
  },
  CONFIG_BUSINESS_GROUP_WINDOW: {
    BUSINESS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_BUSINESS_GROUP_WINDOW}/[Save] Business Group`,
  },
  CONFIG_BUSINESS_WINDOW: {
    CUSTOM_ICON_DATA: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/Custom Icon Data`,
    BUSINESS_INFORMATION: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/Business Information`,
    BUSINESS_SAVE: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/[Save] Business`,
  },
  CONFIG_REPORT_DASHBOARD_EXPORT: {
    DASHBOARD_EXPORT_GROUP_LIST: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/Dashboard Export Group List`,
    DASHBOARD_EXPORT_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Delete] Dashboard Export Group List`,
    DASHBOARD_EXPORT_LIST: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/Dashboard EXPORT LIST`,
    DASHBOARD_EXPORT_MOVE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Move] Dashboard Export List`,
    DASHBOARD_EXPORT_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Delete] Dashboard Export List`,
  },
  CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW: {
    DASHBOARD_EXPORT_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW}/[Save] Report Dashboard Export Group`,
  },
  CONFIG_REPORT_DASHBOARD_EXPORT_WINDOW: {
    REPORT_TEMPLATE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/Report Template`,
    REPORT_DASHBOARD_EXPORT_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/[Save] Report Dashboard Export`,
    GLOBAL_VARIABLE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/Global Variable`,
  },
  CONFIG_REPORT_SCHEDULE: {
    SCHEDULE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/Schedule Group List`,
    SCHEDULE_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Delete] Schedule Group List`,
    SCHEDULE_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/Schedule List`,
    SCHEDULE_MOVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Move] Schedule List`,
    SCHEDULE_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Delete] Schedule List`,
    SCHEDULE_ENABLED: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Enabled] Schedule`,
  },
  CONFIG_REPORT_SCHEDULE_GROUP_WINDOW: {
    REPORT_SCHEDULE_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_GROUP_WINDOW}/[Save] Report Schedule Group`,
  },
  CONFIG_REPORT_SCHEDULE_WINDOW: {
    SCHEDULE_INFORMATION: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/Schedule Information`,
    SCHEDULE_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/[Save] Schedule`,
    REPORT_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/Report List`,
    USER_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/User List (Email)`,
  },
  CONFIG_USER_ALERT: {
    ALERT_GROUP_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Alert Group List`,
    ALERT_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Delete] Alert Group`,
    RULE_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Rule List`,
    RULE_DELETE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Delete] Rule`,
    RULE_ACTIVATE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Activate] Rule`,
    RULE_MUTE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[MUTE] Rule`,
    TAGS: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Tags`,
  },
  CONFIG_USER_ALERT_GROUP_WINDOW: {
    ALERT_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_USER_ALERT_GROUP_WINDOW}/[Save] Alert Group`,
  },
  CONFIG_USER_ALERT_RULE_WINDOW: {
    RULE_DATA: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Rule Data`,
    RULE_SAVE: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/[Save] Rule`,
    TREND: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Trend`,
    NOTIFICATION: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Notification`,
    USER_GROUP: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/User Group (SMS, Email)`,
    TAGS: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Tags`,
    PATH_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Path List`,
  },
  CONFIG_NOTIFICATION: {
    MESSENGER_TYPE_LIST: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/Messenger Type LIST`,
    NOTIFICATION_LIST: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/Notification List`,
    NOTIFICATION_DELETE: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/[Delete] Notification`,
    NOTIFICATION_UPDATE: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/[Update] Notification`,
  },
  CONFIG_NOTIFICATION_WINDOW: {
    NOTIFICATION_ADD: `${MAIN_FRAMES.CONFIG_NOTIFICATION_WINDOW}/[Add] Notification`,
    NOTIFICATION_EDIT: `${MAIN_FRAMES.CONFIG_NOTIFICATION_WINDOW}/[Edit] Notification`,
  },
  CONFIG_SYSTEM_ALERT_INFRASTRUCTURE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Host Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Host List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_NETWORK_DEVICE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Network Device Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Device List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_KUBERNETES: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Cluster List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Node List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_APPLICATION: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/WAS Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/WAS List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_DATABASE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Instance Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Instance List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Alert Setting`,
  },

  CONFIG_GROUP_NOTIFICATION_SETTING: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_GROUP_NOTIFICATION_SETTING}/Information`,
    GROUP_NOTIFICATION_SAVE: `${MAIN_FRAMES.CONFIG_GROUP_NOTIFICATION_SETTING}/[Save] Group Notification`,
  },
  CONFIG_INTERFACE_SETTING_WINDOW: {
    INTERFACE_LIST: `${MAIN_FRAMES.CONFIG_INTERFACE_SETTING_WINDOW}/Interface List`,
    INTERFACE_SAVE: `${MAIN_FRAMES.CONFIG_INTERFACE_SETTING_WINDOW}/[Save] Interface`,
  },
} as const;

const K8S_FRAME_NAMES = {
  K8S_CLUSTER_TOPOLOGY_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Filters`,
    TOPOLOGY_DATA: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Topology Data`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Alert Count`,
  },
  K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_OBJECT_TOPOLOGY_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Filters`,
    TOPOLOGY_DATA: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Topology Data`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Alert Count`,
  },
  K8S_OBJECT_TOPOLOGY_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_LIST_DETAIL: {
    NODE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Node List`,
    NAMESPACE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Namespace List`,
    POD: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Pod List`,
    SERVICE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Service List`,
    INGRESS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Ingress List`,
    PERSISTENT_VOLUME: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Persistent Volume List`,
    PERSISTENT_VOLUME_CLAIMS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Persistent Volume Claims List`,
    STORAGE_CLASS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Storage Class List`,
  },
  K8S_CLUSTER_VIEW: {
    CLUSTER_LIST: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Cluster List`,
    STAT_LIST: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Stat List`,
    OVERVIEW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Overview`,
    USER_PRESET: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/User Preset`,
    MAP_CHART: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Map Chart`,
    STAT_SETTING_WINDOW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/[Save] Stat Setting Window`,
    CHART_SETTING_WINDOW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/[Save] Chart Setting Window`,
  },
  K8S_POD_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_POD_VIEW}/Filters`,
    POD_LIST: `${MAIN_FRAMES.K8S_POD_VIEW}/Pod List`,
  },
  K8S_POD_DETAIL: {
    DELETE: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Delete] Pod`,
    INFORMATION: `${MAIN_FRAMES.K8S_POD_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_POD_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.K8S_POD_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_POD_DETAIL}/YAML`,
    LOGS: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Get] Log`,
    CONTAINERS: `${MAIN_FRAMES.K8S_POD_DETAIL}/Containers`,
    APPLICATION_GET: `${MAIN_FRAMES.K8S_POD_DETAIL}/[GET] Application`,
    ACTIVES: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives Detail - Kill Thread`,
    DATA_SOURCE: `${MAIN_FRAMES.K8S_POD_DETAIL}/Data Source`,
    TRANSACTIONS: `${MAIN_FRAMES.K8S_POD_DETAIL}/Transactions`,
    RELATIONAL_RESOURCE: `${MAIN_FRAMES.K8S_POD_DETAIL}/Relational Resource`,
  },
  K8S_NAMESPACE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_NAMESPACE_VIEW}/Filters`,
    NAMESPACE_LIST: `${MAIN_FRAMES.K8S_NAMESPACE_VIEW}/Namespace List`,
  },
  K8S_NAMESPACE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/Information`,
    YAML: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/YAML`,
  },
  K8S_NODE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_NODE_VIEW}/Filters`,
    NODE_LIST: `${MAIN_FRAMES.K8S_NODE_VIEW}/Node List`,
  },
  K8S_NODE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_NODE_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Pod`,
  },
  K8S_DEPLOYMENT_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Status Card`,
    DEPLOYMENT_LIST: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Deployment List`,
  },
  K8S_DEPLOYMENT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Pod`,
  },
  K8S_STATEFUL_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Status Card`,
    STATEFUL_SETS_LIST: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Stateful Sets List`,
  },
  K8S_STATEFUL_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Pod`,
  },
  K8S_DAEMON_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Status Card`,
    DAEMON_SETS_LIST: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Daemon Sets List`,
  },
  K8S_DAEMON_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Pod`,
  },
  K8S_REPLICA_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Status Card`,
    REPLICA_SETS_LIST: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Replica Sets List`,
  },
  K8S_REPLICA_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Pod`,
  },
  K8S_JOB_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_JOB_VIEW}/Filters`,
    JOB_LIST: `${MAIN_FRAMES.K8S_JOB_VIEW}/Jobs List`,
  },
  K8S_JOB_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_JOB_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Pod`,
  },
  K8S_CRONJOB_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CRONJOB_VIEW}/Filters`,
    CRONJOB_LIST: `${MAIN_FRAMES.K8S_CRONJOB_VIEW}/CronJobs List`,
  },
  K8S_CRONJOB_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/YAML`,
    JOB: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Job`,
    POD: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Pod`,
  },
  K8S_SERVICE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_SERVICE_VIEW}/Filters`,
    SERVICE_LIST: `${MAIN_FRAMES.K8S_SERVICE_VIEW}/service List`,
  },
  K8S_SERVICE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Pod`,
  },
  K8S_INGRESS_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_INGRESS_VIEW}/Filters`,
    INGRESS_LIST: `${MAIN_FRAMES.K8S_INGRESS_VIEW}/Ingress List`,
  },
  K8S_INGRESS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/YAML`,
    ROLES: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Roles`,
    LOAD_BALANCER: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Load Balancer`,
  },
  K8S_CONFIG_STORAGE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_VIEW}/Filters`,
    CONFIG_STORAGE_LIST: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_VIEW}/Config & Storage List`,
  },
  K8S_CONFIG_STORAGE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/YAML`,
    PODS: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Pods`,
  },
  K8S_CONFIGMAP_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CONFIGMAP_VIEW}/Filters`,
    CONFIGMAP_LIST: `${MAIN_FRAMES.K8S_CONFIGMAP_VIEW}/ConfigMap List`,
  },
  K8S_CONFIGMAP_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/YAML`,
    DATA: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Data`,
  },
  K8S_PERSISTENT_VOLUME_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_VIEW}/Filters`,
    PERSISTENT_VOLUME_LIST: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_VIEW}/Persistent List`,
  },
  K8S_PERSISTENT_VOLUME_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/YAML`,
    CLAIM: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Claim`,
  },
  K8S_PERSISTENT_VOLUME_CLAIM_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_VIEW}/Filters`,
    PERSISTENT_VOLUME_CLAIM_LIST: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_VIEW}/Persistent Volume Claim List`,
  },
  K8S_PERSISTENT_VOLUME_CLAIM_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/YAML`,
    SELECTOR: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Selector`,
  },
  K8S_STORAGE_CLASSES_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_VIEW}/Filters`,
    STORAGE_CLASSES_LIST: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_VIEW}/Storage Classes List`,
  },
  K8S_STORAGE_CLASSES_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/YAML`,
    PARAMETER: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Parameter`,
    PERSISTENT_VOLUMES: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Persistent Volumes`,
  },
  K8S_ROLE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_ROLE_VIEW}/Filters`,
    ROLE_LIST: `${MAIN_FRAMES.K8S_ROLE_VIEW}/Role List`,
  },
  K8S_ROLE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/YAML`,
    ROLES: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Roles`,
    RULES: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Rules`,
  },
  K8S_ROLE_BINDING_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_ROLE_BINDING_VIEW}/Filters`,
    ROLES_BINDINGS_LIST: `${MAIN_FRAMES.K8S_ROLE_BINDING_VIEW}/Roles Bindings List`,
  },
  K8S_ROLE_BINDING_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Event`,
    REFERENCE: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Reference`,
    BINDING: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Binding`,
    YAML: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/YAML`,
  },
  K8S_CLUSTER_ROLE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_VIEW}/Filters`,
    CLUSTER_ROLE_LIST: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_VIEW}/Cluster Role List`,
  },
  K8S_CLUSTER_ROLE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/YAML`,
    RULE: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Rule`,
  },
  K8S_CLUSTER_ROLE_BINDING_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_VIEW}/Filters`,
    CLUSTER_ROLES_BINDING_LIST: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_VIEW}/Cluster Roles Binding List`,
  },
  K8S_CLUSTER_ROLE_BINDING_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/YAML`,
    REFERENCE: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Reference`,
    BINDINGS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Bindings`,
  },
  K8S_SERVICE_ACCOUNT_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_VIEW}/Filters`,
    SERVICE_ACCOUNTS_LIST: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_VIEW}/Service Accounts List`,
  },
  K8S_SERVICE_ACCOUNT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Event`,
    SECRETS: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Secrets`,
    YAML: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/YAML`,
  },
} as const;

const PA_FRAME_NAMES = {
  PA_DB_INFO: {
    DATABASE_SIZE_INFO: `${MAIN_FRAMES.PA_DB_INFO}/Database Size Info`,
    DATABASE_FILE_INFO: `${MAIN_FRAMES.PA_DB_INFO}/Database File Info`,
    DATABASE_INO: `${MAIN_FRAMES.PA_DB_INFO}/DATABASE Info`,
    FILE_TREND_DAY: `${MAIN_FRAMES.PA_DB_INFO}/File Trend - Day`,
    FILE_TREND_TIME: `${MAIN_FRAMES.PA_DB_INFO}/File Trend - Time`,
    TOP_TABLE: `${MAIN_FRAMES.PA_DB_INFO}/Top Table`,
  },
  PA_EXCEPTION_SUMMARY: {
    TOTAL_COUNT_CHART: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Total Exception/Transaction Count - Chart`,
    TOTAL_COUNT_LIST: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Total Exception/Transaction Count - List`,
    RATIO: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/ Exception/Transaction Ratio`,
    EXCEPTION_LIST: `${MAIN_FRAMES.PA_EXCEPTION_SUMMARY}/Exception List`,
  },
  PA_SQL_SUMMARY: {
    STAT_RATIO: `${MAIN_FRAMES.PA_SQL_SUMMARY}/Stat Ratio - Summary`,
    TOP_SQL: `${MAIN_FRAMES.PA_SQL_SUMMARY}/Top SQL`,
  },
  PA_TOP_N_ANALYSIS: {
    VALUE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Value - Trend`,
    VALUE_TOP: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Value - Top`,
    SCHEME: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Scheme`,
    MODULE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Module`,
    TOP_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top SQL`,
    TOP_SCHEMA: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top Schema`,
    TOP_USER: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top User`,
    TOP_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Top Procedure`,
    TREND_TOTAL_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Total SQL`,
    TREND_SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - SQL`,
    TREND_TOTAL_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Total Procedure`,
    TREND_PROCEDURE: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Trend - Procedure`,
    SQL: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/SQL`,
    FULL_TEXT: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Full Text`,
  },
  PA_PLAN_ANALYSIS: {
    PLAN_ANALYSIS_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan Analysis List`,
    TREND: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Trend`,
    SQL_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/SQL List`,
    PLAN: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan`,
    PLAN_HASH_LIST: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Plan Hash List`,
    REASON: `${MAIN_FRAMES.PA_PLAN_ANALYSIS}/Reason`,
  },
  PA_SEARCH_SQL: {
    SEARCH_SQL_LIST: `${MAIN_FRAMES.PA_SEARCH_SQL}/Search SQL List`,
    TREND: `${MAIN_FRAMES.PA_SEARCH_SQL}/Trend`,
    FULL_TEXT: `${MAIN_FRAMES.PA_SEARCH_SQL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.PA_SEARCH_SQL}/History`,
    PLAN: `${MAIN_FRAMES.PA_SEARCH_SQL}/Plan`,
  },
  PA_SEARCH_SESSION: {
    SEARCH_SESSION_LIST: `${MAIN_FRAMES.PA_SEARCH_SESSION}/Search Session List`,
  },
  PA_TABLESPACE: {
    ASM_DAY: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Day`,
    ASM_DAY_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Day,Partial`,
    ASM_TIME: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Time`,
    ASM_TIME_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Time,Partial`,
    ASM_GROUP: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Group`,
    ASM_SIZE_FORECAST: `${MAIN_FRAMES.PA_TABLESPACE}/ASM - Size Forecast`,
    TABLESPACE_DAY: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Day`,
    TABLESPACE_DAY_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Day,Partial`,
    TABLESPACE_TIME: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - Time`,
    TABLESPACE_TIME_PARTIAL: `${MAIN_FRAMES.PA_TABLESPACE}/Tablespace - TIme,Partial`,
    TOP_100_TABLESPACE: `${MAIN_FRAMES.PA_TABLESPACE}/Top 100 Tablespace`,
    TOP_100_SEGMENT: `${MAIN_FRAMES.PA_TABLESPACE}/Top 100 Segment`,
  },
  PA_TRANSACTION_TREND: {
    RESPONSE_TIME_CHART: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Response Time Chart`,
    TRANSACTION_SUMMARY: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction Summary`,
    TRANSACTION_LIST: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction List`,
    TRANSACTION_LIST_COUNT: `${MAIN_FRAMES.PA_TRANSACTION_TREND}/Transaction List - Count`,
  },
  PA_WAIT_SUMMARY: {
    WAIT_CLASS: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait Class`,
    WAIT_CLASS_EVENT: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait Class & Event`,
    WAIT_SQL_LIST: `${MAIN_FRAMES.PA_WAIT_SUMMARY}/Wait SQL List`,
  },
  PA_UNDO_TEMP: {
    UNDO_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Used Size`,
    UNDO_AVG_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Avg Used Size`,
    UNDO_SESSION_LIST: `${MAIN_FRAMES.PA_UNDO_TEMP}/Undo > Session List`,
    TEMP_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Used Size`,
    TEMP_AVG_USED_SIZE: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Avg Used Size`,
    TEMP_SESSION_LIST: `${MAIN_FRAMES.PA_UNDO_TEMP}/Temp > Session List`,
  },
  PA_TREND_ANALYSIS: {
    ACTIVE_TRANSACTION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Transaction`,
    ACTIVE_SESSION_SECONDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session Sec Times`,
    ACTIVE_SESSION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session`,
    ACTIVE_SESSION_SUM: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Active Session (SUM)`,
    APPLICATION_RATIO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Application Ratio`,
    ALERT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Alert`,
    BACKENDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Backends`,
    BLOCKED: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Blocked`,
    DB_METRIC: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/DB Metric`,
    CHECK_POOL_ACTIVE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Check Pool Active`,
    LOCK_TREE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Lock Tree`,
    META_LOCK_TREE: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Meta Lock Tree`,
    LONG_ACTIVE_BACKENDS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Active Backends`,
    LONG_ACTIVE_THREAD: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Active Thread`,
    SESSION: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Session`,
    SQL_SUMMARY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/SQL Summary`,
    LONG_SESSION_QUERY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Long Session Query`,
    SQL_STAT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/SQL Stat`,
    TREND: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Trend `,
    TREND_POOL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Trend Pool`,
    METRIC_LIST: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Metric List `,
    THREADS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Threads`,
    TOP_EVENT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top Event`,
    TOP_OBJECT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top Object`,
    TOP_SQL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Top SQL`,
    TIME_MODEL: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Time Model`,
    TIME_RANGE_BAR: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Time Range Bar`,
    WAIT_LIST: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait > List`,
    WAIT_CHART: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait > Chart`,
    WAIT_CHAINS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Chains`,
    WAIT_CLASS: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Class`,
    WAIT_EVENT: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Wait Event`,
    USER_RATIO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/User Ratio`,
    USER_CONNECTION_INFO: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/User Connection Info`,
    FULL_TEXT: `${MAIN_FRAMES.PA_TOP_N_ANALYSIS}/Full Text`,
    SLOW_QUERY: `${MAIN_FRAMES.PA_TREND_ANALYSIS}/Slow Query`,
  },
  PA_ALERT_HISTORY: {
    ALERT_CHART: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Alert - Chart`,
    ALERT_LIST: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Alert - List`,
    TOP_ALERT: `${MAIN_FRAMES.PA_ALERT_HISTORY}/Top Alert`,
  },
  PA_PARAMETER_HISTORY: {
    PARAMETER_LIST: `${MAIN_FRAMES.PA_PARAMETER_HISTORY}/Parameter List`,
  },
} as const;

const LOG_FRAME_NAMES = {
  LOG_LOGGING: {
    FILTERS: `${MAIN_FRAMES.LOG_LOGGING}/Filters`,
    LOGGING_LIST: `${MAIN_FRAMES.LOG_LOGGING}/Logging List`,
    LOGGING_CHART: `${MAIN_FRAMES.LOG_LOGGING}/Logging Chart`,
    LOGGING_PREVIOUS: `${MAIN_FRAMES.LOG_LOGGING}/Logging Previous`,
    LOGGING_KEYWORD: `${MAIN_FRAMES.LOG_LOGGING}/Logging Keyword`,
    LOGGING_KEYWORD_UPDATE: `${MAIN_FRAMES.LOG_LOGGING}/[Update] Logging Keyword`,
    LOGGING_CUSTOM_KEYWORD: `${MAIN_FRAMES.LOG_LOGGING}/Logging Custom Keyword`,
    LOGGING_CUSTOM_KEYWORD_UPDATE: `${MAIN_FRAMES.LOG_LOGGING}/[Update] Logging Custom Keyword`,
  },
  LOG_LIVE_TAIL: {
    LIVE_TAIL_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail List`,
    LIVE_TAIL_LOG_TYPE: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Log Type`,
    LIVE_TAIL_TARGET_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Target List`,
    LIVE_TAIL_POD_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Pod List`,
    LIVE_TAIL_WORKLOAD_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Workload List`,
  },
  LOG_SETTING: {
    LOGGING_GROUP_LIST: `${MAIN_FRAMES.LOG_SETTING}/Logging Group List`,
    LOGGING_GROUP_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Logging Group`,
    LOGGING_GROUP_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Logging Group`,
    LOGGING_GROUP_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Logging Group`,
    LOGGING_LIST: `${MAIN_FRAMES.LOG_SETTING}/Logging List`,
    LOGGING_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Logging`,
    LOGGING_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Logging`,
    LOGGING_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Logging`,
    LOGGING_ACTIVE: `${MAIN_FRAMES.LOG_SETTING}/[Active] Logging`,
    LOGGING_INFO: `${MAIN_FRAMES.LOG_SETTING}/Logging Info`,
    PATH_LIST_TARGET_TYPE: `${MAIN_FRAMES.LOG_SETTING}/Path List Target Type`,
    PATH_LIST_USED: `${MAIN_FRAMES.LOG_SETTING}/Path List Used`,
    PATH_LIST: `${MAIN_FRAMES.LOG_SETTING}/Path List`,
    PATH_LIST_BY_LOG_TYPE: `${MAIN_FRAMES.LOG_SETTING}/Path List By Log Type`,
    PATH_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Path`,
    PATH_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Path`,
    PATH_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Path`,
    PATH_INFO: `${MAIN_FRAMES.LOG_SETTING}/Path Info`,
    LIVE_TAIL_LIST: `${MAIN_FRAMES.LOG_SETTING}/Live Tail List`,
    LIVE_TAIL_INFO: `${MAIN_FRAMES.LOG_SETTING}/Live Tail Info`,
    LIVE_TAIL_ACTIVATE: `${MAIN_FRAMES.LOG_SETTING}/[Activate] Live Tail`,
    LIVE_TAIL_DEACTIVATE: `${MAIN_FRAMES.LOG_SETTING}/[Deactivate] Live Tail`,
    LIVE_TAIL_GROUP_LIST: `${MAIN_FRAMES.LOG_SETTING}/Live Tail Group List`,
    LIVE_TAIL_ISOLATE: `${MAIN_FRAMES.LOG_SETTING}/[Isolate] Live Tail`,
    LIVE_TAIL_GROUP_PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Live Tail Group Parser`,
    LIVE_TAIL_CUSTOM_PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Live Tail Custom Parser`,
    PARSER_LIST: `${MAIN_FRAMES.LOG_SETTING}/Parser List`,
    PARSER_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Parser`,
    PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Parser`,
    PARSER_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Parser`,
    PARSER_INFO: `${MAIN_FRAMES.LOG_SETTING}/Parser Info`,
    PARSER_SIMULATION: `${MAIN_FRAMES.LOG_SETTING}/Parser Simulation`,
    ACCESS_LOG_LIST: `${MAIN_FRAMES.LOG_SETTING}/Access Log List`,
  },
} as const;

const QUERY_MANAGER_FRAME_NAMES = {
  QUERY_MANAGER: {
    TABLE_REFERENCE: `${MAIN_FRAMES.QUERY_MANAGER}/Table Reference`,
    CREATOR_INFO: `${MAIN_FRAMES.QUERY_MANAGER}/Creator Info`,
    MODIFIER_INFO: `${MAIN_FRAMES.QUERY_MANAGER}/Modifier Info`,
    RUN_QUERY: `${MAIN_FRAMES.QUERY_MANAGER}/Run Query`,
    VIEW_PLAN: `${MAIN_FRAMES.QUERY_MANAGER}/View Plan`,
  },
  QUERY_MANAGER_HISTORY: {
    HISTORY_LIST: `${MAIN_FRAMES.QUERY_MANAGER_HISTORY}/History List`,
    HISTORY_DETAIL: `${MAIN_FRAMES.QUERY_MANAGER_HISTORY}/History Detail`,
  },
} as const;

const CLOUD_AWS_FRAME_NAMES = {
  CLOUD_AWS_ACCOUNT: {
    GET_LIST: `${MAIN_FRAMES.CLOUD_AWS}/[GET] Account List`,
    GET_INFO: `${MAIN_FRAMES.CLOUD_AWS}/[GET] Account Info`,
    PUT_INFO: `${MAIN_FRAMES.CLOUD_AWS}/[PUT] Account Info`,
    DELETE_INFO: `${MAIN_FRAMES.CLOUD_AWS}/[DELETE] Account Info`,
    POST_ACTIVE: `${MAIN_FRAMES.CLOUD_AWS}/[POST] Active Account`,
    POST_INACTIVE: `${MAIN_FRAMES.CLOUD_AWS}/[POST] Inactive Account`,
    REFRESH: `${MAIN_FRAMES.CLOUD_AWS}/Refresh`,
  },
  CLOUD_AWS_INSTANCE: {
    POST_ACTIVE: `${MAIN_FRAMES.CLOUD_AWS}/[POST] Active Instance`,
    POST_INACTIVE: `${MAIN_FRAMES.CLOUD_AWS}/[POST] Inactive Instance`,
  },
} as const;

const SETTING_USER_TAG = {
  SETTING_USER_TAG: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG}/Tag Keys`,
    GET_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG}/Tag Values`,
    DELETE_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG}/[DELETE] Tag Key`,
    DELETE_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG}/[DELETE] Tag Values`,
  },
  SETTING_TAG_KEY_WINDOW: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/Tag Keys`,
    ADD_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/[ADD] Tag Key`,
    EDIT_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/[EDIT] Tag Key`,
  },
  SETTING_TAB_VALUE_WINDOW: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/Tag Keys`,
    GET_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[GET] Tag Values`,
    ADD_USER_TAG_VALUE: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[ADD] Tag Value`,
    EDIT_USER_TAG_VALUE: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[EDIT] Tag Value`,
  },
  SETTING_TAG_INFORMATION: {
    GET_CUSTOM_TAG_BY_TARGET: `${MAIN_FRAMES.SETTING_USER_TAG_INFORMATION}/Tags By Target`,
  },
} as const;

const MANAGEMENT_FRAME_NAMES = {
  DATA_RETENTION: {
    GET_CATEGORY: `${MAIN_FRAMES.DATA_RETENTION}/Category`,
    GET_TABLE: `${MAIN_FRAMES.DATA_RETENTION}/Table`,
    EDIT_TABLE_RETENTION: `${MAIN_FRAMES.DATA_RETENTION}/[EDIT] Table Retention`,
    GET_METRICS_DELTA: `${MAIN_FRAMES.DATA_RETENTION}/Metrics delta`,
    GET_METRICS_CURRENT: `${MAIN_FRAMES.DATA_RETENTION}/Metrics current`,
  },
} as const;

export const FRAME_NAMES = {
  ...BASE_FRAME_NAMES,
  ...CONFIG_FRAME_NAMES,
  ...K8S_FRAME_NAMES,
  ...PA_FRAME_NAMES,
  ...LOG_FRAME_NAMES,
  ...QUERY_MANAGER_FRAME_NAMES,
  ...CLOUD_AWS_FRAME_NAMES,
  ...SETTING_USER_TAG,
  ...MANAGEMENT_FRAME_NAMES,
} as const;
