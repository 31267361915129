/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  RoleGroupPermissionResponse,
  RoleGroupRolePermissionPatchRequest,
  RoleGroupRolePermissionResponse,
  SuccessResponse,
  TargetGroupPermissionResponse,
  TargetPermissionCheckResponse,
  TargetPermissionPatchRequest,
  TargetPermissionResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const deletePermissionGroupRolesPermissionControllerAxios = ({
  rolePermissionId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  rolePermissionId: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/permissions/group-roles';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      rolePermissionId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionGroupRolesPermissionControllerAxios = ({
  rolePermissionId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  rolePermissionId?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RoleGroupPermissionResponse> => {
  const localVarPath = '/api/v7/permissions/group-roles';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      rolePermissionId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionGroupRolesAuthListPermissionControllerAxios = ({
  rolePermissionId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  rolePermissionId: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RoleGroupRolePermissionResponse> => {
  const localVarPath = '/api/v7/permissions/group-roles/auth';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      rolePermissionId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionGroupRolesAuthDefaultListPermissionControllerAxios = ({
  roleId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  roleId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RoleGroupRolePermissionResponse> => {
  const localVarPath = '/api/v7/permissions/group-roles/auth-default';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      roleId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionsPermissionControllerAxios = ({
  business,
  userGroupId,
  category,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  business: boolean;
  userGroupId: number;
  category?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetPermissionResponse> => {
  const localVarPath = '/api/v7/permissions/group-targets';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      business,
      category,
      userGroupId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPermissionGroupUsersPermissionControllerAxios = ({
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetGroupPermissionResponse> => {
  const localVarPath = '/api/v7/permissions/group-users';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchPermissionGroupRolesAuthListPermissionControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: RoleGroupRolePermissionPatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/permissions/group-roles/auth';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchPermissionsPermissionControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: TargetPermissionPatchRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/permissions/group-targets';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const checkPermissionTargetListPermissionControllerAxios = ({
  targets,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  targets: Array<string>;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetPermissionCheckResponse> => {
  const localVarPath = '/api/v7/permissions/check/auth';
  const httpMethod = 'post';

  const data: any = targets;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deletePermissionGroupRolesPermissionControllerAxios,
  getPermissionGroupRolesPermissionControllerAxios,
  getPermissionGroupRolesAuthListPermissionControllerAxios,
  getPermissionGroupRolesAuthDefaultListPermissionControllerAxios,
  getPermissionsPermissionControllerAxios,
  getPermissionGroupUsersPermissionControllerAxios,
  patchPermissionGroupRolesAuthListPermissionControllerAxios,
  patchPermissionsPermissionControllerAxios,
  checkPermissionTargetListPermissionControllerAxios,
};
