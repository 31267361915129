<script lang="ts" setup>
import { TOKEN_STATUS } from '@/common/components/molecules/filterSearch/filterSearch.define';
import {
  Props,
  Emit,
  setup,
} from '@/common/components/molecules/filterSearch/filterSearchToken.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  token,
  isEditingTokenParts,
  isInvalidToken,
  isWritingToken,
  onInput,
  onKeyEnter,
  onKeyUpDown,
  onKeyEsc,
  onClickTokenParts,
  onKeyCtrlEnter,
  onClickAddMultiValue,
  onClickRemoveToken,
} = setup(props, emit);
</script>

<template>
  <div
    :class="[
      'filter-search-token',
      {
        'filter-search-token--invalid': isInvalidToken(),
        'filter-search-token--editing': isWritingToken(),
      },
    ]"
    @click.stop
  >
    <span
      v-if="token.key"
      class="token-parts token-parts--key"
    >
      {{ token.key.name }}
    </span>

    <template v-if="token.operator">
      <input
        v-if="isEditingTokenParts('operator')"
        :placeholder="token.operator?.name"
        @input="onInput"
        @keydown.enter.exact="onKeyEnter"
        @keydown.up.exact="(event) => onKeyUpDown(event, 'up')"
        @keydown.down.exact="(event) => onKeyUpDown(event, 'down')"
        @keydown.esc.exact="onKeyEsc"
      />
      <span
        v-else
        class="token-parts token-parts--operator"
        @click.stop="() => onClickTokenParts('operator')"
      >
        {{ token.operator?.name }}
      </span>
    </template>

    <template v-if="token.values?.length || isEditingTokenParts('value')">
      <input
        v-if="isEditingTokenParts('value')"
        :placeholder="token.values?.map(({ name }) => name).join(',')"
        @input="onInput"
        @keydown.enter.exact="onKeyEnter"
        @keydown.up.exact="onKeyUpDown($event, 'up')"
        @keydown.down.exact="onKeyUpDown($event, 'down')"
        @keydown.esc.exact="onKeyEsc"
        @keydown.ctrl.enter.exact="onKeyCtrlEnter"
      />
      <span
        v-else
        class="token-parts token-parts--value"
        @click.stop="() => onClickTokenParts('value')"
      >
        {{ token.values?.map(({ name }) => name).join(',') }}
      </span>
    </template>

    <ev-button
      v-if="props.isActiveAddMultiValueBtn && tokenData.status !== TOKEN_STATUS.KEY_DONE"
      type="icon"
      size="xsmall"
      class="filter-search-token__button"
      @click.stop="onClickAddMultiValue"
    >
      <ev-icon class="icon-plus-square" />
    </ev-button>

    <ev-button
      type="icon"
      size="xsmall"
      class="filter-search-token__button"
      @click.stop="onClickRemoveToken"
    >
      <ev-icon class="icon-close" />
    </ev-button>
  </div>
</template>
<style lang="scss">
.filter-search-token {
  display: flex;
  position: relative;
  align-items: center;
  padding: 1px;
  border: 1px solid var(--filter-search-token-valid-border-color);
  border-radius: 2px;
  background-color: var(--filter-search-token-bg-color);
  font-size: 12px;
  line-height: 16px;
  color: var(--color-w-gray-11);
  white-space: nowrap;

  &--invalid {
    border-color: var(--filter-search-token-invalid-border-color);
  }

  &--editing {
    border-color: var(--filter-search-token-editing-border-color);
  }

  .token-parts {
    display: inline-block;
    height: 16px;
    margin: 1px 3px 0;
    overflow: hidden;
    border-radius: 2px;
    color: var(--filter-search-token-default-font-color);
    text-overflow: ellipsis;

    &--value,
    &--key {
      flex: auto;
    }

    &--operator {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &--operator,
    &--value {
      padding: 0 3px;
      cursor: pointer;
      color: var(--filter-search-token-editable-font-color);
      white-space: nowrap;

      &:hover {
        background-color: var(--filter-search-token-editable-parts-hover-bg-color);
      }

      &:active {
        background-color: var(--filter-search-token-editable-parts-active-bg-color);
      }
    }
  }

  &__button {
    flex-grow: 0;
    flex-shrink: 0;
    width: 16px !important;
    height: 16px !important;

    .icon-plus-square {
      background-color: var(--filter-search-token-add-button-icon-color) !important;
    }

    .icon-close {
      background-color: var(--filter-search-token-remove-button-icon-color) !important;
    }

    &.type-icon:hover:not(.selected, :active, :disabled, .disabled) {
      background-color: var(--filter-search-token-button-hover-bg-color) !important;
    }
  }

  input {
    height: 100%;
    padding: 0 5px;
    outline: none;
    background-color: transparent !important;
    color: var(--input-text-font-color) !important;

    &::placeholder {
      color: var(--input-text-placeholder-color);
    }
  }
}
</style>
