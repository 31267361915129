/* eslint-disable no-redeclare */
import { I18nTranslation } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetScatterRawData } from '@/worker/commands/dashboard/scatter';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { checkMetricChartTypeDataStatus } from '@/dashboard/components/widgets/checkChartDataStatus/metric/checkMetricRawDataStatus';
import {
  MetricChartType,
  CurrentChartType,
  TableChartType,
  ScatterChartType,
  ChartDataType,
} from '../widgets.types';
import { checkCurrentChartTypeDataStatus } from './current/checkCurrentRawDataStatus';
import { checkTableChartTypeDataStatus } from './table/checkTableRawDataStatus';

export const defaultCheckChartDataStatus = <
  RawData extends MetricRawData | CurrentRawData | TableRawData | WidgetScatterRawData,
>(
  rawData: RawData,
  chartDataStatus: WidgetChartDataStatusInfo[],
): WidgetChartDataStatusInfo[] => {
  return chartDataStatus;
};

export function checkChartDataStatus(
  chartDataType: 'metrics',
  t: I18nTranslation,
): (
  chartType: MetricChartType,
) => (
  rawData: MetricRawData,
  chartDataStatus: WidgetChartDataStatusInfo[],
) => WidgetChartDataStatusInfo[];
export function checkChartDataStatus(
  chartDataType: 'current',
  t: I18nTranslation,
): (
  chartType: CurrentChartType,
) => (
  rawData: CurrentRawData,
  chartDataStatus: WidgetChartDataStatusInfo[],
) => WidgetChartDataStatusInfo[];
export function checkChartDataStatus(
  chartDataType: 'table',
  t: I18nTranslation,
): (
  chartType: TableChartType,
) => (
  rawData: TableRawData,
  chartDataStatus: WidgetChartDataStatusInfo[],
) => WidgetChartDataStatusInfo[];
export function checkChartDataStatus(
  chartDataType: 'scatter',
  t: I18nTranslation,
): (
  chartType: ScatterChartType,
) => (
  rawData: WidgetScatterRawData,
  chartDataStatus: WidgetChartDataStatusInfo[],
) => WidgetChartDataStatusInfo[];

export function checkChartDataStatus(chartDataType: ChartDataType, t: I18nTranslation) {
  switch (chartDataType) {
    case 'table':
      return checkTableChartTypeDataStatus(t);
    case 'metrics':
      return checkMetricChartTypeDataStatus();
    case 'current':
      return checkCurrentChartTypeDataStatus(t);
    case 'scatter':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (chartType: ScatterChartType) =>
        (rawData: WidgetScatterRawData, chartDataStatus: WidgetChartDataStatusInfo[]) =>
          defaultCheckChartDataStatus(rawData, chartDataStatus);
    default:
      throw new Error(`Unsupported chartDataType: ${chartDataType}`);
  }
}
