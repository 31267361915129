<template>
  <div class="loading-screen full-screen">
    <div
      ref="container"
      class="lottie"
    />
    <span class="loading-screen__description">{{
      t('MESSAGE.LOADING_SCREEN', '요청 화면으로 이동 중입니다!')
    }}</span>
  </div>
</template>

<script setup lang="ts">
import animationData from '@/common/assets/loading/loading.json';
import { useInternational } from '@/common/locale';
import { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';
import { ref, onMounted, onUnmounted } from 'vue';

const { t } = useInternational();

const container = ref<HTMLDivElement | null>(null);
let loadingScreenAnimation: AnimationItem | null = null;

onMounted(() => {
  if (container.value) {
    loadingScreenAnimation = lottie.loadAnimation({
      container: container.value,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }
});

onUnmounted(() => {
  if (loadingScreenAnimation) {
    loadingScreenAnimation.destroy();
  }
});
</script>

<style scoped lang="scss">
.lottie {
  width: 690px;
  height: 480px;
}
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d9eaf9;
  &__description {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-blue-10);
  }
}
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
