import { Seconds } from '@/common/utils';
import {
  sessionUpdateV7ControllerAxios,
  sessionValidateV7ControllerAxios,
} from '@/openapi/gateway/api/gateway-controller-api';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const useSessionTimerStore = defineStore('sessionTimer', () => {
  const isTimerRunning = ref<boolean>(false);
  const remainingSessionTime = ref<Seconds>(-1);
  const displaySessionTime = computed<string>(() => {
    if (remainingSessionTime.value < 0) {
      return '00:00';
    }
    return dayjs.duration(remainingSessionTime.value, 'seconds').format('mm:ss');
  });
  const isValidSessionTime = computed<boolean>(() => remainingSessionTime.value > 0);

  const fetchSessionTime = async (sessionToken: string) => {
    try {
      if (sessionToken) {
        const { data } = await sessionValidateV7ControllerAxios({ request: { sessionToken } });
        const { remain } = data;
        remainingSessionTime.value = remain;
      }
    } catch (e: any) {
      remainingSessionTime.value = -1;
      console.log(e);
    }
  };

  const updateSessionTime = async (params) => {
    try {
      if (Object.values(params).every((val) => !!val)) {
        await sessionUpdateV7ControllerAxios({ request: params });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return {
    isTimerRunning,
    isValidSessionTime,
    remainingSessionTime,
    displaySessionTime,
    fetchSessionTime,
    updateSessionTime,
  };
});

export { useSessionTimerStore };
