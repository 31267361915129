import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { entryAccessTokenInstance } from '@/common/api';
import {
  CreateTenantRequest,
  TenantAlertsResponse,
  UpdateTenantRequest,
  XmTenantResponse,
} from '../model';

// 테넌트 삭제
const deleteV1TenantsByTenantIdAxios = ({
  tenantId,
}: {
  tenantId: string;
}): PromiseAxiosResponse<string> => entryAccessTokenInstance.delete(`/api/v1/tenants/${tenantId}`);

// 테넌트 내 모든 인스턴스의 알람 조회
const getV1TenantAlertsAxios = (): PromiseAxiosResponse<TenantAlertsResponse> =>
  axiosInstance.get('/api/v1/tenant-alerts');

// 모든 테넌트 가져오는 API
const getV1TenantsAxios = (): PromiseAxiosResponse<Array<XmTenantResponse>> =>
  entryAccessTokenInstance.get('/api/v1/tenants');

// 테넌트 이름 수정하는 API
const patchV1TenantsByTenantIdAxios = ({
  tenantId,
  request,
}: {
  tenantId: string;
  request: UpdateTenantRequest;
}): PromiseAxiosResponse<string> =>
  entryAccessTokenInstance.patch(`/api/v1/tenants/${tenantId}`, request);

// 새로운 테넌트 생성하는 API
const postV1TenantsAxios = ({
  request,
}: {
  request: CreateTenantRequest;
}): PromiseAxiosResponse<string> => entryAccessTokenInstance.post('/api/v1/tenants', request);

export {
  deleteV1TenantsByTenantIdAxios,
  getV1TenantAlertsAxios,
  getV1TenantsAxios,
  patchV1TenantsByTenantIdAxios,
  postV1TenantsAxios,
};
