import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { baseInstance, entryAccessTokenInstance } from '@/common/api';
import { EntryToken, Token } from '@/common/store/auth';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  ChangePasswordRequest,
  CreateUserRequest,
  EmailCheckRequest,
  ResetPasswordRequest,
  UpdateUserRequest,
  UserLoginRequest,
  UserLoginResponse,
  UserResponse,
  UserWithGroupsAndRoleResponse,
} from '../model';

// 유저 탈퇴 API
const deleteV1MeAxios = ({ userId }: { userId: number }): PromiseAxiosResponse<string> =>
  axiosInstance.delete('/api/v1/me', {
    params: {
      userId,
    },
  });

// 유저 정보 조회 API - 1차 로그인 후
const getV1MeEntryAccessAxios = (): PromiseAxiosResponse<UserWithGroupsAndRoleResponse> =>
  entryAccessTokenInstance.get('/api/v1/me');

// 유저 정보 조회 API - 2차 로그인 후
const getV1MeAxios = (params?: {
  frameName: string;
}): PromiseAxiosResponse<UserWithGroupsAndRoleResponse> =>
  axiosInstance.get('/api/v1/me', { params });

// 유저 정보 수정 API
const patchV1MeAxios = (request: UpdateUserRequest): PromiseAxiosResponse<UserResponse> =>
  axiosInstance.patch('/api/v1/me', request);

const postV1ChangePasswordAxios = (request: ChangePasswordRequest): PromiseAxiosResponse<string> =>
  axiosInstance.post('/api/v1/change-password', request);

// 중복 이메일 체크 API
const postV1EmailCheckAxios = (request: EmailCheckRequest): PromiseAxiosResponse<boolean> =>
  axiosInstance.post('/api/v1/email-check', request);

const postV1JoinAxios = (request: CreateUserRequest): PromiseAxiosResponse<UserResponse> =>
  axiosInstance.post('/api/v1/join', request);

const postV1LoginAxios = (request: UserLoginRequest): PromiseAxiosResponse<UserLoginResponse> =>
  axiosInstance.post('/api/v1/login', request);

// 임시 비밀번호 발급 메일 전송 API
const postV1ResetPasswordAxios = (request: ResetPasswordRequest): PromiseAxiosResponse<string> =>
  baseInstance.post('/api/v1/reset-password', request);

// 유저 비번 리셋
const postV1ResetPasswordAdminAxios = (
  request: ResetPasswordRequest,
): PromiseAxiosResponse<string> => axiosInstance.post('/api/v1/reset-password-admin', request);

// RSA 암호화된 유저 패스워드 수정 API
// 개별 header 설정이 필요해서 axios config 추가함
const postV3ChangePasswordAxios = (
  request: ChangePasswordRequest,
  config: AxiosRequestConfig = {},
): PromiseAxiosResponse<string> => axiosInstance.post('/api/v3/change-password', request, config);

// 회원가입
const postV3JoinAxios = (request: CreateUserRequest): PromiseAxiosResponse<UserResponse> =>
  axiosInstance.post('/api/v3/join', request);

// 로그인
const postV3LoginAxios = (request: UserLoginRequest): PromiseAxiosResponse<EntryToken> =>
  axiosInstance.post('/api/v3/login', request);

const postV1RefreshTokenAxios = (tokenInfo): Promise<AxiosResponse<Token>> =>
  axiosInstance.post('/api/v1/refresh-token', tokenInfo);

export {
  deleteV1MeAxios,
  getV1MeEntryAccessAxios,
  getV1MeAxios,
  patchV1MeAxios,
  postV1ChangePasswordAxios,
  postV1EmailCheckAxios,
  postV1JoinAxios,
  postV1LoginAxios,
  postV1ResetPasswordAxios,
  postV1ResetPasswordAdminAxios,
  postV3ChangePasswordAxios,
  postV3JoinAxios,
  postV3LoginAxios,
  postV1RefreshTokenAxios,
};
