import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import { NotificationIdMap } from '@/config/views/alertGroup/alertRuleWindow.types';
import { getNotificationNotificationControllerAxios } from '@/openapi/metaV6/api/notification-controller-api';
import { useUserGroupStore } from '@/config/stores/userGroup';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

const useUser = () => {
  const userGroupStore = useUserGroupStore();
  const { userGroups } = storeToRefs(userGroupStore);

  const apiResponse = ref(false);
  const fetchUserGroup = async (isInit) => {
    if (apiResponse.value && !isInit) {
      return;
    }
    apiResponse.value = true;
    try {
      await userGroupStore.dispatchUserGroups(FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.USER_GROUP);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getUserNamesByIds = (ids: number[]) => {
    const userList = userGroups.value.map((item) => item.users).flat();
    return ids
      .map((id) => {
        const userInfo = userList.find((item) => item?.userId === id);
        return userInfo?.email ? `${userInfo.name!}(${userInfo.email!})` : userInfo?.name ?? '';
      })
      .filter((v) => !!v);
  };
  return {
    userGroups,
    fetchUserGroup,
    getUserNamesByIds,
  };
};

const useNotification = () => {
  const notificationByType = ref<{ [key in string]: any[] }>({});

  const apiResponse = ref<{ [key in string]: boolean }>({});

  const fetchNotificationByType = async (type, isInit) => {
    if (apiResponse.value[type] && !isInit) {
      return;
    }
    apiResponse.value[type] = true;
    try {
      const { data } = await getNotificationNotificationControllerAxios({
        type: type.toUpperCase(),
        frameName: `${FRAME_NAMES.CONFIG_USER_ALERT_RULE_WINDOW.NOTIFICATION} - ${type}`,
      });
      notificationByType.value[type] = data?.data ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNotificationAllType = async (isInit) => {
    await Promise.all(
      ['slack', 'telegram', 'webhook'].map((type) => fetchNotificationByType(type, isInit)),
    );
  };

  const getNotificationRowsByType = (type) => {
    return notificationByType.value[type];
  };
  const idMap = {
    slack: 'slackId',
    telegram: 'telegramId',
    webhook: 'webhookId',
  };
  const getNotificationNamesByIds = (type, ids) => {
    const notificationList = notificationByType.value[type];
    const idField = idMap[type];

    return (
      notificationList?.filter((item) => ids.includes(item[idField])).map((item) => item.name) ?? []
    );
  };

  return {
    notificationByType,

    fetchNotificationByType,
    fetchNotificationAllType,

    getNotificationRowsByType,
    getNotificationNamesByIds,
  };
};

export const useAlertNotificationStore = defineStore('alertNotification', () => {
  const storedData = ref<NotificationIdMap>({});

  const { userGroups, fetchUserGroup, getUserNamesByIds } = useUser();
  const { notificationByType, fetchNotificationAllType, getNotificationNamesByIds } =
    useNotification();

  const fetchAllNotificationInfo = (isInit = false) => {
    fetchUserGroup(isInit);
    fetchNotificationAllType(isInit);
  };

  const setStoredData = (data: NotificationIdMap) => {
    storedData.value = data;
  };
  const initData = () => {
    storedData.value = {};
  };
  return {
    storedData,

    userGroups,
    getUserNamesByIds,

    notificationByType,
    getNotificationNamesByIds,

    fetchAllNotificationInfo,
    setStoredData,
    initData,
  };
});
