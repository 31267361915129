import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { ChartData, FetchInfo } from '@/common/utils/types';
import { ref, Ref, toRaw, unref } from 'vue';
import { Command } from '@/worker/commands';
import { CommandOptions, useCommand } from '@/worker/composables/useCommand';
import { checkFrameFetchCycle, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { ORACLE_DEFAULT_FETCH_CYCLE } from '@/common/utils/define';

interface DbSessionTypeState {
  chartData: Ref<ChartData>;
  errorStatusText: string;
  fetchCycle: FetchInfo;
}

const frameName = 'DB Session Type';
const defaultFetchCycle: FetchInfo = ORACLE_DEFAULT_FETCH_CYCLE.dbSessionType;

export const dbSessionType: Module<DbSessionTypeState, RootState> = {
  namespaced: true,
  state: {
    chartData: ref<ChartData>({} as ChartData),
    errorStatusText: '',
    fetchCycle: structuredClone(defaultFetchCycle),
  },
  mutations: {
    setChartData: (state: DbSessionTypeState, data: Ref<ChartData>) => {
      state.chartData = data;
    },
    setFetchCycle: (state: DbSessionTypeState, fetchCycle: FetchInfo) => {
      state.fetchCycle = fetchCycle;
    },
    setErrorStatusText: (state: DbSessionTypeState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchChartData({ state, commit, rootGetters }) {
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'dbSessionTypeChart',
        params: {
          instanceIds: toRaw(unref(rootGetters['oracleMultiViewEnv/getInstanceIds'])),
        },
      };
      const options: CommandOptions = {
        initialData: state.chartData,
        immediate: false,
      };
      const { data, apiTraceInfo, error, intervalInfo, execute } = useCommand<ChartData>(
        command,
        options,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle,
        prevArr: state.fetchCycle,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });
      commit('setChartData', data);
      commit('setFetchCycle', fetchCycle);
      commit('setErrorStatusText', getAPIErrorStatusText(error.value));
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
  },
  getters: {
    getChartData: (state: DbSessionTypeState) => state.chartData,
    getFetchCycle: (state: DbSessionTypeState) => state.fetchCycle,
    getErrorStatusText: (state: DbSessionTypeState) => state.errorStatusText,
  },
};
