import { computed } from 'vue';
import { type CurrentInstance } from '@/common/stores/instance';
import type { BreadCrumbMenuInfo, BreadCrumbMode } from '../breadCrumbs.types';
import { getLinkWrapperTop } from './breadCrumbElementLink.setup';

export interface Props {
  menuInfo?: BreadCrumbMenuInfo;
  mode?: BreadCrumbMode;
  currentInstance?: CurrentInstance;
  currentMultiViewExpandedMenu?: string;
}

export interface Emit {
  (
    e: 'instance-multi-view-expand',
    menu?: BreadCrumbMenuInfo,
    pos?: {
      top: number;
    },
  ): void;
}

const useInstanceElement = (props: Props, emit: Emit) => {
  const multiViewExpand = (menu: BreadCrumbMenuInfo, evt: MouseEvent) => {
    if (props.currentMultiViewExpandedMenu === menu.value) {
      props.currentMultiViewExpandedMenu = undefined;
      emit('instance-multi-view-expand', undefined);
      return;
    }
    props.currentMultiViewExpandedMenu = menu.value;

    const top = getLinkWrapperTop(evt);
    const position = {
      top,
    };

    emit('instance-multi-view-expand', menu, position);
  };

  return {
    multiViewExpand,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const singleMenu = computed(() =>
    props.mode === 'instance'
      ? props.menuInfo?.children?.filter((m) => m.dbType !== 'redis')
      : props.menuInfo?.children?.filter((m) => !m.children?.length),
  );

  const nestedMenu = computed(() => {
    const middleMenu =
      props.mode === 'instance' ? [] : props.menuInfo?.children?.filter((m) => m.children?.length);

    return middleMenu || props.menuInfo?.subMenuList;
  });

  return {
    singleMenu,
    nestedMenu,
    ...useInstanceElement(props, emit),
  };
};
