<template>
  <div class="component-loading">
    <div class="component-loading__dim" />
    <i class="component-loading__icon" />
  </div>
</template>

<style scoped lang="scss">
.component-loading {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__dim {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--component-loading-dim-bg-color);
  }

  &__icon {
    width: 24px;
    height: 24px;

    // TODO: Figma에서 Angular Gradient를 svg로 내보낼 때 버그가 있어서 png로 대체
    background: url('@/common/assets/loading-ver2-light.png') no-repeat center center/24px;
    animation: component-loading 2s linear infinite;

    [data-theme='dark'] & {
      background-image: url('@/common/assets/loading-ver2-dark.png');
    }
  }
}

@keyframes component-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
