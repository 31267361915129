<script setup lang="ts">
import { setup } from '@/common/components/molecules/baseCustomTooltip/baseCustomTooltip.setup';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';

interface Props {
  customClass?: string;
  useDeselect?: boolean;
  useHoverEvent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  customClass: '',
  useDeselect: false,
  useHoverEvent: false,
});

const { customTooltipEl, isShowTooltip, tooltipStyle, directionClass, show, hide, onMouseleave } =
  setup(props);

defineExpose({
  show,
  hide,
});
</script>

<template>
  <teleport to="#base-custom-tooltip">
    <transition name="custom-fade">
      <div
        v-if="isShowTooltip"
        ref="customTooltipEl"
        v-click-outside="hide"
        :class="['custom-tooltip-context', props.customClass, directionClass.trim()]"
        :style="tooltipStyle"
        @mouseleave="onMouseleave"
      >
        <slot />
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss">
#base-custom-tooltip {
  position: absolute;

  @include set-scrollbar();

  .custom-tooltip-context {
    position: absolute;
    z-index: 850;
    box-shadow: var(--window-shadow);
  }

  .custom-fade-enter-active,
  .custom-fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .custom-fade-enter,
  .custom-fade-leave-to {
    opacity: 0;
  }
}
</style>
