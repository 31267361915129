import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getMetricData } from '@/common/api/data';
import { MetricResponse } from '@/openapi/data/model';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { MetricFailError, checkValidMetric } from '@/database/utils/utils';
import { MetricRequest } from '@/common/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface ScanTypeInfo {
  sumNoGoodIndexUsed: object[];
  sumNoIndexUsed: object[];
  time: number[];
}

export interface ScanTypeState {
  scanType: ScanTypeInfo;
  errorStatusText: string;
}

export const scanType: Module<ScanTypeState, RootState> = {
  namespaced: true,
  state: {
    scanType: {
      sumNoGoodIndexUsed: [],
      sumNoIndexUsed: [],
      time: [],
    },
    errorStatusText: '',
  },
  mutations: {
    setScanType(state: ScanTypeState, metricData: MetricResponse[] = []) {
      metricData.forEach((data, idx) => {
        const values = transformMetricChartValues(data.metrics?.[0].values);
        if (idx === 0) {
          state.scanType.time = values.map(([time]) => new Date(time).valueOf());
        }
        if (data.dataDefinition?.dataId === 'db_mysql_handler_read_first') {
          state.scanType.sumNoGoodIndexUsed = values.map(([, value]) => value) ?? [];
        } else if (data.dataDefinition?.dataId === 'db_mysql_handler_read_rnd')
          state.scanType.sumNoIndexUsed = values.map(([, value]) => value) ?? [];
      });
    },
    setErrorStatusText: (state: ScanTypeState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchScanType({ commit }, instanceId) {
      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.SCAN_TYPE;
      try {
        const metricRequests = [
          'db_mysql_handler_read_first',
          'db_mysql_handler_read_rnd',
        ].map<MetricRequest>((name) => ({
          aggregationType: 'byTarget',
          period: 'p1h',
          interval: 'I10m',
          category: 'mysql',
          dataId: name,
          summaryType: 'sum',
          targetIds: [instanceId],
        }));

        const { data } = await getMetricData({
          metricV7Requests: metricRequests,
          frameName,
          isTimeout: true,
        });

        const { data: metricData } = data;
        checkValidMetric(metricData ?? []);
        commit('setScanType', metricData);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getScanType: (state: ScanTypeState) => state.scanType,
    getScanTypeTime: (state: ScanTypeState) => state.scanType.time,
    getScanTypeSumNoGoodIndexUsed: (state: ScanTypeState) => state.scanType.sumNoGoodIndexUsed,
    getScanTypeSumNoIndexUsed: (state: ScanTypeState) => state.scanType.sumNoIndexUsed,
    getErrorStatusText: (state: ScanTypeState) => state.errorStatusText,
  },
};
