import { RouteRecordRaw } from 'vue-router';
import { ssoCheck } from '@/common/router/navigationGuard';

const ssoMenu: RouteRecordRaw[] = [
  {
    path: '/sso',
    component: () => import(/* webpackChunkName: "sso" */ '../views/SsoView.vue'),
    props: (route) => ({ id: route.query.id, error: route.query.error }),
    beforeEnter: ssoCheck,
  },
];

export { ssoMenu };
