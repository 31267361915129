import { Tree } from '@/dashboard/utils/tree';
import {
  ReferenceInfo,
  ReferenceVariable,
} from '@/dashboard/components/widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { WidgetUUID } from '@/common/utils';

export const createWidgetReferenceRelation = (
  widgetInfoList: {
    widgetId: WidgetUUID;
    referenceInfo?: ReferenceInfo;
  }[],
) => {
  const relation = new Tree(null, 'root');

  widgetInfoList.forEach(({ widgetId: childLabel, referenceInfo }) => {
    if (referenceInfo) {
      Object.values(referenceInfo).forEach((variables: ReferenceVariable[]) => {
        variables.forEach((variable) => {
          if (variable.value?.type === 'customVariable') {
            const parentLabel = variable.value.widgetId;
            let parentNode = relation.findChild(parentLabel);
            if (!parentNode) {
              parentNode = relation.addChild(parentLabel);
            }
            const childNode = relation.findChild(childLabel);
            if (!childNode || !Tree.isDescendant(parentNode, childNode)) {
              parentNode.addChild(childLabel);
            }
          }
        });
      });
    }
    if (!relation.findChild(childLabel)) {
      relation.addChild(childLabel);
    }
  });

  return relation;
};
