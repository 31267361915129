import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import router from '@/common/router';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useI18n } from 'vue-i18n';
import { DB_TYPE } from '@/common/utils/define';

const useModel = () => {
  const store: Store<RootState> = useStore();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useI18n();

  const multiViewEnvByDbType = ref('');
  const instanceIds = computed(
    () => store.getters[`${multiViewEnvByDbType.value}MultiViewEnv/getInstanceIds`],
  );

  const backToDatabaseList = async () => {
    await router.push('/database/instance');
  };
  const initSelectedInfo = async (routerPath) => {
    if (!routerPath) {
      await backToDatabaseList();
      return;
    }

    const pathArr = routerPath.split('/');
    multiViewEnvByDbType.value = pathArr.length && pathArr[pathArr.length - 2];

    // TODO vuex error
    if (!multiViewEnvByDbType.value || multiViewEnvByDbType.value === DB_TYPE.SQLSERVER) {
      return;
    }

    await store.dispatch(`${multiViewEnvByDbType.value}MultiViewEnv/fetchLastSelectedId`);

    if (!instanceIds.value?.length) {
      showErrorMsg(ctx, t('NOTI.PRODUCT.INVALID_ACCESS'));
      await store.commit(`${multiViewEnvByDbType.value}MultiViewEnv/initSelectedInfo`);
      await backToDatabaseList();
    }
  };

  watch(
    () => router.currentRoute.value,
    async (newVal) => {
      const currentPath = newVal?.path?.split('/');
      if (currentPath[currentPath.length - 1] === 'multiView') {
        await initSelectedInfo(newVal.path);
      }
    },
  );

  onMounted(async () => {
    await initSelectedInfo(router.currentRoute.value.path);
  });

  return {
    multiViewEnvByDbType,
  };
};

export { useModel };
