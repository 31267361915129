export const APPLICATION = {
  GRIDCOLUMNS_application_was_wasList: 'GRIDCOLUMNS_application_was_wasList',
  GRIDCOLUMNS_application_wasDetail_active_activeTxnList:
    'GRIDCOLUMNS_application_wasDetail_active_activeTxnList',
  GRIDCOLUMNS_application_wasDetail_config_configList:
    'GRIDCOLUMNS_application_wasDetail_config_configList',
  GRIDCOLUMNS_application_wasDetail_dataSource_connectionList:
    'GRIDCOLUMNS_application_wasDetail_dataSource_connectionList',
  GRIDCOLUMNS_application_wasDetail_heapDump_heapDumpList:
    'GRIDCOLUMNS_application_wasDetail_heapDump_heapDumpList',
  GRIDCOLUMNS_application_wasDetail_libraries_libraryList:
    'GRIDCOLUMNS_application_wasDetail_libraries_libraryList',
  GRIDCOLUMNS_application_wasDetail_loadedClassList_loadedClassList:
    'GRIDCOLUMNS_application_wasDetail_loadedClassList_loadedClassList',
  GRIDCOLUMNS_application_wasDetail_threadDump_threadDumpList:
    'GRIDCOLUMNS_application_wasDetail_threadDump_threadDumpList',
  GRIDCOLUMNS_application_wasDetail_transaction_transactionList:
    'GRIDCOLUMNS_application_wasDetail_transaction_transactionList',

  GRIDCOLUMNS_application_transactionDetail_otel_otelList:
    'GRIDCOLUMNS_application_transactionDetail_otel_otelList',
  GRIDCOLUMNS_application_transactionDetail_otelDetail_logList_logList:
    'GRIDCOLUMNS_application_transactionDetail_otelDetail_logList_logList',

  GRIDCOLUMNS_application_transactionDetail_etoe_etoeList:
    'GRIDCOLUMNS_application_transactionDetail_etoe_etoeList',
  GRIDCOLUMNS_application_transactionDetail_etoeDetail_activeHistory_activeList:
    'GRIDCOLUMNS_application_transactionDetail_etoeDetail_activeHistory_activeList',
  GRIDCOLUMNS_application_transactionDetail_etoelDetail_callTree_CallTreeListSummary:
    'GRIDCOLUMNS_application_transactionDetail_etoelDetail_callTree_CallTreeListSummary',
  GRIDCOLUMNS_application_transactionDetail_etoelDetail_callTree_CallTreeListDetail:
    'GRIDCOLUMNS_application_transactionDetail_etoelDetail_callTree_CallTreeListDetail',
  GRIDCOLUMNS_application_transactionDetail_etoeDetail_sqlText_sqlList:
    'GRIDCOLUMNS_application_transactionDetail_etoeDetail_sqlText_sqlList',

  GRIDCOLUMNS_application_traces_tracesList: 'GRIDCOLUMNS_application_traces_tracesList',

  GRIDCOLUMNS_application_activeTransactionDetail_activeTxnList:
    'GRIDCOLUMNS_application_activeTransactionDetail_activeTxnList',

  FILTER_application_was: 'FILTER_application_was',
  FILTER_application_transaction: 'FILTER_application_transaction',
};
