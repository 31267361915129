import { computed, ref, watch, StyleValue } from 'vue';
import { type CurrentInstance } from '@/common/stores/instance';
import type { BreadCrumbMenuInfo, MenuPosition, BreadCrumbMode } from '../breadCrumbs.types';

export interface Props {
  menuPosition: MenuPosition | null;
  isMenuToggle: boolean;
  loading: boolean;
  menuList?: BreadCrumbMenuInfo;
  currentBreadCrumbId?: string;
  mode?: BreadCrumbMode;
  currentInstance?: CurrentInstance;
}

export interface Emit {
  (e: 'update:searchDashboardWord', word: string): void;
  (e: 'leave', event: MouseEvent): void;
  (e: 'close'): void;
}

const useInstanceModal = (props: Props) => {
  const multiViewChildren = ref<BreadCrumbMenuInfo>();
  const multiViewChildrenStyle = ref<StyleValue>();

  const instanceMultiViewExpand = (menu?: BreadCrumbMenuInfo, position?: { top: number }) => {
    multiViewChildren.value = menu;

    const menuTop = parseInt(props.menuPosition?.top ?? '', 10);
    const childTop = position?.top ?? 0;
    multiViewChildrenStyle.value = {
      top: `${childTop - menuTop}px`,
    };
  };

  return {
    multiViewChildren,
    multiViewChildrenStyle,
    instanceMultiViewExpand,
  };
};

const useDashboardModal = () => {
  const searchDashboardWord = ref('');

  return {
    searchDashboardWord,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const { multiViewChildren, multiViewChildrenStyle, instanceMultiViewExpand } =
    useInstanceModal(props);
  const { searchDashboardWord } = useDashboardModal();

  const filteredMenuList = computed<BreadCrumbMenuInfo>(() => {
    return {
      ...props.menuList,
      children: props.menuList?.children?.filter((menu) => {
        if (!searchDashboardWord.value) return true;

        return menu.text
          .toLocaleLowerCase()
          .includes(searchDashboardWord.value.toLocaleLowerCase().trim());
      }),
    };
  });

  const onMenuMouseLeave = (e: MouseEvent) => {
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (relatedTarget.dataset.breadCrumbId === props.currentBreadCrumbId) return;

    emit('leave', e);
  };

  const onMenuCloseClick = () => {
    emit('close');
    multiViewChildren.value = undefined;
    multiViewChildrenStyle.value = undefined;
  };

  watch(filteredMenuList, () => {
    multiViewChildren.value = undefined;
    multiViewChildrenStyle.value = undefined;
  });

  return {
    multiViewChildren,
    multiViewChildrenStyle,

    searchDashboardWord,
    filteredMenuList,

    onMenuMouseLeave,
    onMenuCloseClick,
    instanceMultiViewExpand,
  };
};
