import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText, roundToDigitNumber } from '@/common/utils/commonUtils';
import { slowQueriesSqlListV7ControllerAxios } from '@/openapi/postgresqlV7/api/sql-list-v7-controller-api';
import { Query, SlowQueryItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface State {
  slowQueries: SlowQueryItem[];
  queryTime: Query;
  errorStatusText: string;
}

export const slowQuery: Module<State, RootState> = {
  namespaced: true,
  state: {
    slowQueries: [],
    queryTime: {
      fromTime: '',
      toTime: '',
    },
    errorStatusText: '',
  },
  mutations: {
    setSlowQueries: (state: State, data: SlowQueryItem[]) => {
      state.slowQueries = data;
    },
    setSlowQueryQueryTime: (
      state: State,
      { fromTime, toTime }: { fromTime: string; toTime: string },
    ) => {
      state.queryTime.fromTime = fromTime;
      state.queryTime.toTime = toTime;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchSlowQueries({ commit }, instanceIds: string[]) {
      const frameName = 'SlowQuery';
      try {
        const {
          data: { data, query },
        } = await slowQueriesSqlListV7ControllerAxios({
          instanceIds,
          frameName: FRAME_NAMES.POSTGRESQL_MULTI_VIEW.SLOW_QUERY,
          period: 'p3m',
        });

        commit('setSlowQueries', data);
        commit('setSlowQueryQueryTime', query);
        commit('setErrorStatusText', '');
        commit('postgresqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getSlowQueries: (state: State) => state.slowQueries,
    getSlowQueriesSecTime: (state: State) =>
      state.slowQueries.map((value) => ({
        ...value,
        slows: value.slows!.map((slow) => ({
          ...slow,
          elapsedTime: roundToDigitNumber(slow.elapsedTime! / 1000),
        })),
      })),
    getSlowQueryQueryTime: (state: State) => state.queryTime,
    getErrorStatusText: (state: State) => state.errorStatusText,
  },
};
