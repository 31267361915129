<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  modelValue: string | undefined;
  options: {
    label: string;
    disabled?: boolean;
  }[];
  type?: 'default' | 'round';
}
interface Emits {
  (event: 'update:modelValue', newValue: string | undefined): void;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'default',
});
const emit = defineEmits<Emits>();

const selectedValue = computed({
  get() {
    return props.modelValue ?? props.options.at(0)?.label;
  },
  set(newSelectedValue) {
    emit('update:modelValue', newSelectedValue);
  },
});
</script>

<template>
  <ev-radio-group
    v-model="selectedValue"
    :class="['toggle-button-group', { round: type === 'round' }]"
    type="button"
  >
    <ev-radio
      v-for="({ label, disabled }, index) in options"
      :key="`${label}__${index}`"
      :label="label"
      :disabled="disabled"
    />
  </ev-radio-group>
</template>

<style lang="scss">
.toggle-button-group.type-button[role='group'] .ev-radio span {
  padding: 2px 10px !important;
}
</style>

<style scoped lang="scss">
.ev-radio-group {
  gap: 2px;
  width: fit-content;
  padding: 1px;
  border: 1px solid transparent;
}
.ev-radio {
  flex: none;
  &.checked {
    background-color: var(--radio-round-active-bg-color) !important;
  }
}
:deep(.ev-radio-label) {
  width: fit-content !important;
  border-radius: inherit !important;
  &:hover {
    background-color: inherit !important;
    color: inherit !important;
  }
}
.round {
  &.ev-radio-group {
    border-radius: 50px !important;
    &:hover {
      border: 1px solid var(--radio-group-round-on-hover-border-color);
      background-color: var(--radio-group-round-on-hover-bg-color);
    }
  }
  .ev-radio {
    border-radius: inherit !important;
  }
}
</style>
