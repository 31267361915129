import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { capitalize } from 'lodash-es';
import {
  listViewGridOption,
  listViewSearchBarOptionWithUseClientFilterFalse,
} from '@/common/define/grid.define';
import {
  CustomColumn,
  CustomColumnsReadonly,
  GridOption,
  GridSearchBarOption,
  MenuItem,
} from '@/common/utils/types';
import { ExportExcelOption } from '@/common/utils/exportExcelUtils';
import { useInternational } from '@/common/locale';
import { convertMsToSec, roundToDigit } from '@/common/utils/commonUtils';
import { useTargetDetailProvide } from '@/alert/components/alertTargetList/alertTargetList.uses';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { findGridColumnIndex } from '@/common/utils/gridUtils';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { ALERT_RULE_NAME_ICON } from '@/alert/utils/tagsAndTargets.uses';
import { AlertThresholdInfo } from '@/alert/components/alertGrid/alertGrid.uses';
import { type NotificationMethod } from '@/openapi/alert/model';

export interface Props {
  gridColumns: CustomColumn[] | CustomColumnsReadonly;
  gridData: any[];
  type?: 'main' | 'detail';
  componentIs?: 'grid' | 'treeGrid';
  checkedRows?: any[];
  searchWord?: string;
  options?: {
    useGridSetting?: boolean;
    useCheckbox?: boolean;
    useExcelFromServer?: boolean;
    useSearchBar?: boolean;
    usePage?: boolean;
    gridOption?: GridOption;
  };
  envKey?: string;
  isTargetClickable?: boolean;
}
export interface Emit {
  (e: 'update:checkedRows', value: any[]): void;
  (e: 'update:searchWord', value: string): void;
  (e: 'on-click-cell', value: { field: string; value: any; row: any; cellIndex?: number }): void; // 트리그리드는 cellIndex 미제공
  (e: 'openDetail'): void;
  (e: 'export-to-excel-from-server', option: ExportExcelOption): void;
}

export const PROPS_DEFAULTS = {
  type: 'main',
  componentIs: 'grid',
  checkedRows: () => [],
  searchWord: '',
  options: () => ({
    useGridSetting: true,
    useCheckbox: false,
    useExcelFromServer: false,
    usePage: false,
  }),
  isTargetClickable: true,
} as const;

export const useCriteriaTooltip = () => {
  const criteriaTooltipMouseEvent = ref<MouseEvent | null>(null);
  const { onMouseEventInTarget } = useSimpleTextTooltip(criteriaTooltipMouseEvent);

  const criteriaTooltipText = ref('');

  const onCriteriaMouse = ({ text, e }: { text: string; e: MouseEvent }) => {
    criteriaTooltipText.value = text;
    onMouseEventInTarget(e);
  };

  return {
    criteriaTooltipMouseEvent,
    criteriaTooltipText,
    onCriteriaMouse,
  };
};

const useDashboardJump = () => {
  const { t } = useInternational();

  const isVisibleSetupDashBoard = ref(false);
  const openModal = () => {
    isVisibleSetupDashBoard.value = true;
  };

  const customContextMenu: MenuItem = {
    text: t('MESSAGE.GO_TO_DASHBOARD'),
    click: () => {
      openModal();
    },
  };

  const dashboardEnvKey = USER_ENV_KEYS.ALERT_LIST_DETAIL_HISTORY_LIST_DASHBOARD_SETTING_LIST;
  const { fetchDashboardPopupEnvInfoForKey } = useDashboardPopupEnvStore();
  const setupDashboardPopupClose = async () => {
    await fetchDashboardPopupEnvInfoForKey(dashboardEnvKey);
  };

  const timeInfo = ref('');
  const alertId = ref('');
  const { dashboardPopupListCnt, dashboardPopupList, dashboardName } = storeToRefs(
    useDashboardPopupEnvStore(),
  );
  const router = useRouter();
  const moveDashboard = (id: number) => {
    router.push({
      name: 'dashboard_Dashboard View',
      params: {
        id,
      },
      query: {
        alertId: alertId.value,
        timeInfo: timeInfo.value,
      },
    });
  };
  const menuRef = ref();

  const menuItems = computed(() => {
    if (dashboardPopupList.value.length === 0) {
      return [];
    }
    return dashboardPopupList.value.map((item) => {
      return {
        text: item.dashboardName,
        click: () => {
          if (item.dashboardId) {
            moveDashboard(item.dashboardId);
          } else {
            location.replace(item.url || '');
          }
        },
      };
    });
  });

  const viewDashboardList = (e, rowObj) => {
    timeInfo.value = rowObj.triggeredTime;
    alertId.value = rowObj.resultId;
    if (dashboardPopupListCnt.value > 1) {
      menuRef.value!.show(e);
    } else if (dashboardPopupListCnt.value === 1) {
      if (dashboardPopupList.value[0].dashboardId) {
        moveDashboard(dashboardPopupList.value[0].dashboardId);
      } else if (dashboardPopupList.value[0].url) {
        location.replace(dashboardPopupList.value[0].url);
      }
    }
  };

  const dashboardTooltipRef = ref();
  const onShowDashboardTooltip = (e) => {
    if (dashboardPopupListCnt.value === 0) {
      dashboardTooltipRef.value.show(e);
    }
  };
  const onHideDashboardTooltip = (e) => {
    const { relatedTarget } = e;
    if (!relatedTarget?.classList.contains('dashboard-msg-tooltip')) {
      dashboardTooltipRef.value.hide();
    }
  };

  return {
    customContextMenu,
    isVisibleSetupDashBoard,
    dashboardEnvKey,
    dashboardName,
    setupDashboardPopupClose,
    viewDashboardList,
    dashboardPopupListCnt,
    menuRef,
    menuItems,
    dashboardTooltipRef,
    onShowDashboardTooltip,
    onHideDashboardTooltip,
  };
};

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const {
    customContextMenu,
    isVisibleSetupDashBoard,
    dashboardEnvKey,
    setupDashboardPopupClose,
    dashboardName,
    viewDashboardList,
    dashboardPopupListCnt,
    menuRef,
    menuItems,
    dashboardTooltipRef,
    onShowDashboardTooltip,
    onHideDashboardTooltip,
  } = useDashboardJump();

  const checkedRows = computed({
    get: () => props.checkedRows,
    set: (val) => emit('update:checkedRows', val || []),
  });
  const searchWord = computed({
    get: () => props.searchWord,
    set: (val) => emit('update:searchWord', val ?? ''),
  });

  const exportExcelOption = computed<ExportExcelOption>(() => ({
    title: 'Alert List',
    rowDataFormatter: {
      ruleName: (originalValue: string) =>
        originalValue === ALERT_RULE_NAME_ICON ? '' : originalValue,
      targets: (originalValue: { name: string; id: string }[]) => {
        // TODO: targets 배열 길이 지나치게 길 경우 Excel 비정상 저장
        return originalValue.map((value) => value.name).join(', ');
      },
      tags: (originalValue: { name: string; id: string }[]) => {
        return originalValue.map((value) => value.name).join(', ');
      },
      threshold: (thresholdInfo: AlertThresholdInfo) => {
        if (!thresholdInfo) {
          return '';
        }

        const { evaluateValue, duringTimeInterval, comparison, value } = thresholdInfo;
        if (duringTimeInterval) {
          return `${evaluateValue} ${t(
            'WORD.DURING_THE',
          )} ${duringTimeInterval} ${comparison} ${value}`;
        }
        return `${evaluateValue} ${comparison} ${value}`;
      },
      duration: (originalValue: number) => convertMsToSec(originalValue),
      notiStatus: (v: NotificationMethod[]) =>
        (v ?? []).reduce(
          (acc, { methodType, success }) =>
            `${acc ? `${acc}, ` : ''}${capitalize(methodType)}: ${
              success ? 'Succeeded' : 'Failed'
            }`,
          '',
        ),
    },
    exportByServer: props.options?.useExcelFromServer ?? false,
  }));

  const gridOption = computed<GridOption>(() => ({
    ...listViewGridOption,
    useCheckbox: {
      use: props.options?.useCheckbox ?? false,
      mode: 'multi',
      headerCheck: true,
    },
    useGridSetting: {
      use: props.options?.useGridSetting ?? true,
      customContextMenu: props.type === 'detail' ? [customContextMenu] : undefined,
    },
    page: {
      use: props.options?.usePage ?? false,
      useClient: false,
    },
    maintainScrollOnUpdateRows: true,
    ...(props.options?.gridOption || {}),
  }));
  const searchBarOption = computed<GridSearchBarOption>(() => {
    if (props.options?.useSearchBar === false) {
      return { use: false };
    }

    if (props.type === 'main') {
      return {
        ...listViewSearchBarOptionWithUseClientFilterFalse,
        placeholder: t('WORD.SEARCH'),
      };
    }

    return {
      ...listViewSearchBarOptionWithUseClientFilterFalse,
      mode: 'left',
      placeholder: t('WORD.SEARCH'),
    };
  });

  const columnIdx = computed(() =>
    findGridColumnIndex({
      fields: ['ruleName', 'ruleNameIcon'],
      columns: props.gridColumns,
    }),
  );
  const getAlertValue = (val: string) =>
    `${!val || Number.isNaN(+val) ? val : roundToDigit(+val, 2)}`;

  useTargetDetailProvide(emit);

  const { criteriaTooltipMouseEvent, criteriaTooltipText, onCriteriaMouse } = useCriteriaTooltip();

  return {
    gridOption,
    searchBarOption,
    checkedRows,
    searchWord,
    exportExcelOption,
    columnIdx,
    getAlertValue,
    t,

    criteriaTooltipMouseEvent,
    criteriaTooltipText,
    onCriteriaMouse,

    isVisibleSetupDashBoard,
    dashboardEnvKey,
    setupDashboardPopupClose,
    viewDashboardList,
    dashboardName,
    dashboardPopupListCnt,
    menuRef,
    menuItems,
    dashboardTooltipRef,
    onShowDashboardTooltip,
    onHideDashboardTooltip,
  };
};
