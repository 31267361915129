import { makeRows } from '@/common/utils/gridUtils';
import { columnFormatter } from '@/alert/utils/utils';
import { AlertTargetItem } from '@/alert/components/alertTargetList/alertTargetList.types';
import { CustomColumn, CustomColumnsReadonly } from '@/common/utils/types';
import { AlertType } from '@/alert/components/alertDetail/alertDetail.types';
import { isDateTime } from '@/common/utils/time.utils';
import { utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import { useStatInfoStore } from '@/common/stores/stat-info';

export const TARGET_TYPE = {
  ALL: 'all',
  TARGETS: 'targets',
  TAGS: 'tags',
  NONE: 'none',
} as const;

export const ALERT_RULE_NAME_ICON = 'icon-child-right';

interface MakeAlertRowsParam {
  data: any[];
  columns: CustomColumn[] | CustomColumnsReadonly;
  targetFieldName?: string;
  targetType?: (typeof TARGET_TYPE)[keyof typeof TARGET_TYPE];
  formatter?: { [key in string]?: (v, item) => any };
  alertType?: AlertType;
  hasNullRow?: boolean;
}

const getItems = {
  tags: (items): { tags: AlertTargetItem[] } => {
    if (!items?.length || !Array.isArray(items)) {
      return { tags: [] };
    }
    return {
      tags: Array.from(
        new Map(
          items.map((item) => [
            item.tagId,
            {
              id: item.tagId,
              name: `${item.tagKey}:${item.tagName || item.tagId || ''}`,
              category: item.tagKey,
            },
          ]),
        ).values(),
      ),
    };
  },
  targets: (items): { targets: AlertTargetItem[] } => {
    if (!items?.length || !Array.isArray(items)) {
      return { targets: [] };
    }
    return {
      targets: Array.from(
        new Map(
          items.map((item) => {
            const showCategory = item.category !== 'process';
            return [
              item.targetId,
              {
                id: item.targetId,
                name: showCategory
                  ? `${item.category}:${item.targetName || item.targetId}`
                  : `${item.targetName || item.targetId}`,
                category: item.category,
              },
            ];
          }),
        ).values(),
      ),
    };
  },
};

export const tagsAndTargetsController = {
  [TARGET_TYPE.TARGETS]: (data) => getItems.targets(data),
  [TARGET_TYPE.TAGS]: (data) => getItems.tags(data),
  [TARGET_TYPE.ALL]: (data) => ({
    ...getItems.targets(
      data
        ?.map((item) => item.targetItems)
        .flat()
        .filter((item) => !!item),
    ),
    ...getItems.tags(
      data
        ?.map((item) => item.tagItems)
        .flat()
        .filter((item) => !!item),
    ),
  }),
};

export const useAlertTagsAndTargets = () => {
  const statInfoStore = useStatInfoStore();
  const alertNameMapper = (v: string) => {
    return statInfoStore.getStatInfo({
      statId: v,
    })?.name;
  };

  const makeAlertRows = ({
    data,
    columns,
    targetFieldName = 'targets',
    targetType = TARGET_TYPE.ALL,
    formatter = {},
    hasNullRow = false,
  }: MakeAlertRowsParam) => {
    if (!data || !columns) {
      return [];
    }
    let alertData: any[] = data;
    if (hasNullRow) {
      alertData = alertData.filter((item) => !!item);
    }
    alertData = alertData.map((item) => {
      const tagsAndTargets =
        targetType === TARGET_TYPE.NONE
          ? {}
          : tagsAndTargetsController[targetType](item[targetFieldName]);
      return {
        ...item,
        ...tagsAndTargets,
      };
    });

    const mergedFormatter = {
      ...columnFormatter,
      alertName: alertNameMapper,
      ...formatter,
    };
    return makeRows(alertData, columns, mergedFormatter);
  };

  const makeAlertTreeRows = ({
    data,
    columns,
    targetFieldName = 'targets',
    targetType = TARGET_TYPE.ALL,
    formatter = {},
    hasNullRow = false,
  }: MakeAlertRowsParam) => {
    if (!data?.length) {
      return [];
    }
    let alertData: any[] = data;
    if (hasNullRow) {
      alertData = alertData.filter((item) => !!item);
    }

    const mergedFormatter = {
      ...columnFormatter,
      alertName: alertNameMapper,
      ...formatter,
    };

    return alertData.map((item) => {
      const formattedItem = columns.reduce(
        (acc, cur: CustomColumn) => {
          const { field } = cur;
          acc[field] = item[field];

          if (isDateTime(acc[field])) {
            acc[field] = utcZeroTimeToStandardTime(acc[field]);
          }

          if (mergedFormatter[field]) {
            acc[field] = mergedFormatter[field]!(acc[field], item);
          }

          return acc;
        },
        {} as Record<string, any>,
      );

      const tagsAndTargets =
        targetType === TARGET_TYPE.NONE
          ? {}
          : tagsAndTargetsController[targetType](item[targetFieldName]);

      return {
        ...formattedItem,
        ...tagsAndTargets,
        children: item.child?.length
          ? makeAlertTreeRows({
              data: item.child,
              columns,
              targetFieldName,
              targetType,
              formatter,
              hasNullRow,
            }).map((childItem) => ({
              ...childItem,
              ruleName: ALERT_RULE_NAME_ICON,
              uncheckable: true,
              isChild: true,
              children: undefined,
            }))
          : undefined,
      };
    });
  };

  return {
    tagsAndTargetsController,
    makeAlertRows,
    makeAlertTreeRows,
  };
};
