import { computed, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { useInternational } from '@/common/locale';
import { GLOBAL_TIME_RANGE_FORMAT } from '../timePeriodIndicator/timePeriodIndicator.define';

export interface Props {
  dateTimePeriod: string[];
  timeRangeSecLimit?: number;
}

export interface Emit {
  (e: 'update:isShow', value: boolean): void;
  (e: 'clickCancel'): void;
  (e: 'clickOk', value: string[]): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  let from = dayjs(props.dateTimePeriod?.[0], GLOBAL_TIME_RANGE_FORMAT);
  if (!from.isValid()) from = dayjs().startOf('day');

  let to = dayjs(props.dateTimePeriod?.[1], GLOBAL_TIME_RANGE_FORMAT);
  if (!to.isValid()) to = dayjs().endOf('day');

  const fromDate = from.format('YYYY-MM-DD');
  const toDate = to.format('YYYY-MM-DD');
  const datePickerValues = ref([fromDate, toDate]);

  const fromTime = from.format('HH:mm:ss');
  const toTime = to.format('HH:mm:ss');
  const timePickerValues = ref([fromTime, toTime]);

  const fromDateTime = computed(() =>
    dayjs(`${datePickerValues.value[0]} ${timePickerValues.value[0]}`),
  );
  const toDateTime = computed(() =>
    dayjs(`${datePickerValues.value[1]} ${timePickerValues.value[1]}`),
  );

  const diffFromTo = computed(() => toDateTime.value.diff(fromDateTime.value, 'second'));
  const isOverLimit = computed(() => {
    if (!props.timeRangeSecLimit || props.timeRangeSecLimit < 0) return false;
    return diffFromTo.value > props.timeRangeSecLimit;
  });

  const validTimeFormatExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  const timeValidationInfo = reactive({
    fromTime: computed(() => {
      if (fromDateTime.value > toDateTime.value) {
        return false;
      }

      return validTimeFormatExp.test(timePickerValues.value[0]);
    }),
    toTime: computed(() => {
      if (fromDateTime.value > toDateTime.value) {
        return false;
      }

      return validTimeFormatExp.test(timePickerValues.value[1]);
    }),
  });

  const onClickResetDateTimePicker = () => {
    const today = dayjs.tz().format('YYYY-MM-DD');
    datePickerValues.value = [today, today];
    timePickerValues.value = ['00:00:00', '23:59:59'];
  };

  const getDisabledDate = (localeMidnightTime: Date) => {
    return dayjs(localeMidnightTime).format('YYYY-MM-DD') > dayjs.tz().format('YYYY-MM-DD');
  };

  const onClickCancel = () => {
    emit('clickCancel');
  };

  const onClickOk = () => {
    emit('clickOk', [
      fromDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
      toDateTime.value.format('YYYY.MM.DD HH:mm:ss'),
    ]);
  };

  return {
    t,
    datePickerValues,
    timePickerValues,
    timeValidationInfo,
    isOverLimit,
    onClickResetDateTimePicker,
    getDisabledDate,
    onClickCancel,
    onClickOk,
  };
};
