import { uid } from 'uid';
import { Command, Namespace } from '@/worker/commands';
import { JsonRpc } from '@/worker/types';
import { CommandOptions } from '@/worker/composables/useCommand';
import router from '@/common/router';
import { onMessageHandler } from '@/common/utils/windowMessage.util';

export default class WorkerManager {
  static #instance;

  #worker?: Worker;

  #commandMap?: Map<string, any>;

  #isValidCommand?: boolean;

  constructor() {
    if (WorkerManager.#instance) {
      return WorkerManager.#instance;
    }

    this.#commandMap = new Map();
    this.#worker = new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' });
    this.#worker.onmessage = this.#onMessageHandler;
    this.#isValidCommand = true;
    WorkerManager.#instance = this;
  }

  static exist() {
    return !!WorkerManager.#instance;
  }

  clear() {
    this.#worker?.terminate();
  }

  #onMessageHandler = (msg) => {
    const { action } = msg.data;

    if (action && onMessageHandler[action]) {
      onMessageHandler[action](msg.data);
    } else {
      const { result, error, id }: JsonRpc['response'] = msg.data;
      const { resolve, reject } = this.#commandMap?.get(id);

      this.#commandMap?.delete(id);

      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    }
  };

  async execute<T>(
    command: Command<Namespace>,
    beforeCallback?: CommandOptions['beforeCallback'],
  ): Promise<T> {
    const id = uid();
    const { namespace, method, params, token, utcOffset } = command;

    if (!token) {
      if (this.#isValidCommand) {
        this.#isValidCommand = false;
      }
      router.push('/logout');
      return Promise.reject();
    }

    this.#isValidCommand = true;
    let resolve;
    let reject;
    const promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    this.#commandMap?.set(id, { resolve, reject });

    const request: JsonRpc['request'] = {
      namespace,
      method,
      params,
      id,
      token,
      utcOffset,
    };

    if (typeof beforeCallback === 'function' && params) {
      beforeCallback(params);
    }

    this.#worker?.postMessage(request);

    return promise;
  }

  abortApiByKey(key: string) {
    const apiKey = key || 'defaultApi';
    this.#worker?.postMessage({ method: 'abortApiByKey', params: { apiKey } });
  }

  abortAllApiExceptKey(key: string) {
    if (!key) {
      return;
    }
    this.#worker?.postMessage({ method: 'abortAllApi', params: { apiKey: key } });
  }

  abortAllApi() {
    this.#worker?.postMessage({ method: 'abortAllApi' });
  }
}
