import { Ref } from 'vue';

export const useSimpleTextTooltip = (mouseEvt: Ref<MouseEvent | null>) => {
  const onMouseEventInTarget = (e: MouseEvent) => {
    const visibleEventNames = ['mouseenter', 'mouseover', 'click'];
    mouseEvt.value = visibleEventNames.includes(e.type) ? e : null;
  };
  return {
    onMouseEventInTarget,
  };
};
