/* eslint-disable no-redeclare */

import { BinaryBytesUnit, TimeUnit } from '../utils/convertUnits.types';

const ApiTimeUnit = ['ps', 'µs', 'cs', 'ms', 'sec', 's', 'm', 'h'] as const;
type ApiTimeUnit = (typeof ApiTimeUnit)[number];

const DataTransmissionUnit = ['bps', 'kbps', 'mbps', 'gbps'] as const;
type DataTransmissionUnit = (typeof DataTransmissionUnit)[number];

const ApiBinaryBiteUnit = ['byte', 'bytes', 'bytes/sec', 'KiB', 'MiB', 'GiB'] as const;
type ApiBinaryBiteUnit = (typeof ApiBinaryBiteUnit)[number];

const ApiDecimalBiteUnit = [
  'byte',
  'bytes',
  'bytes/sec',
  'KB',
  'kb/sec',
  'MB',
  'mb/sec',
  'GB',
] as const;
type ApiDecimalBiteUnit = (typeof ApiDecimalBiteUnit)[number];

export const ApiDataUnit = [
  ...ApiBinaryBiteUnit,
  ...ApiDecimalBiteUnit,
  ...DataTransmissionUnit,
] as const;
export type ApiDataUnit = (typeof ApiDataUnit)[number];

export const ApiPercentUnit = ['%'] as const;
export type ApiPercentUnit = (typeof ApiPercentUnit)[number];

export const ApiCountUnit = [
  'Count',
  'count',
  'count/sec',
  'number',
  'block',
  'blocks',
  '°C',
  'lsn',
  'pages',
  'string',
  'table',
  'time',
  'age',
] as const;
export type ApiCountUnit = (typeof ApiCountUnit)[number];

export const ApiCoreUnit = [
  'nanocore',
  'microcore',
  'millicore',
  'core',
  'kilocore',
  'megacore',
  'gigacore',
] as const;
export type ApiCoreUnit = (typeof ApiCoreUnit)[number];

export const ApiUnit = [
  ...ApiTimeUnit,
  ...ApiPercentUnit,
  ...ApiDataUnit,
  ...ApiCountUnit,
] as const;

export const convertApiDataUnitToBinaryByte = (apiUnit: ApiDataUnit): BinaryBytesUnit => {
  switch (apiUnit) {
    case 'byte':
    case 'bytes':
    case 'bytes/sec':
    case 'bps':
      return 'byte';
    case 'KiB':
    case 'KB':
    case 'kb/sec':
    case 'kbps':
      return 'KiB';
    case 'MiB':
    case 'MB':
    case 'mb/sec':
    case 'mbps':
      return 'MiB';
    case 'GiB':
    case 'GB':
    case 'gbps':
      return 'GiB';
    default:
      throw new Error(`${apiUnit}을 ApiDataUnit에 추가해주세요.`);
  }
};

/**
 * Converts an ApiTimeUnit to a TimeUnit | 'cs'.
 *
 * @param {ApiTimeUnit} apiUnit - The unit of time from the API.
 * @returns {TimeUnit | 'cs'} The corresponding TimeUnit or 'cs' if the unit is 'cs'.
 *
 * @throws {Error} Throws an error if the provided apiUnit is not recognized.
 *
 * TimeUnit은 화면에서 보여지는 unit 리스트들입니다.
 * 'cs'는 'ms'로 바뀌어 화면에서 사용되기때문에 ReturnType을 TimeUnit | 'cs'로 설정하였습니다.
 */
export const convertTimeApiUnit = (apiUnit: ApiTimeUnit): TimeUnit | 'cs' => {
  switch (apiUnit) {
    case 'ps':
      return 'ps';
    case 'µs':
      return 'µs';
    case 'cs':
      return 'cs';
    case 'ms':
      return 'ms';
    case 'sec':
    case 's':
      return 's';
    case 'm':
      return 'm';
    case 'h':
      return 'h';
    default:
      throw new Error(`${apiUnit}을 TimeUnit에 추가해주세요.`);
  }
};

export const isApiTimeUnit = (unit: any): unit is ApiTimeUnit => {
  return ApiTimeUnit.includes(unit);
};

export const isDataTransmissionUnit = (unit: any): unit is DataTransmissionUnit => {
  return DataTransmissionUnit.includes(unit);
};

export const isApiDataUnit = (unit: any): unit is ApiDataUnit => {
  return ApiDataUnit.includes(unit);
};

export const isApiCountUnit = (unit: any): unit is ApiCountUnit => {
  return ApiCountUnit.includes(unit);
};

export const isApiCoreUnit = (unit: unknown): unit is ApiCoreUnit => {
  return ApiCoreUnit.some((coreUnit) => coreUnit === unit);
};
