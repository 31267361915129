<script setup lang="ts">
import {
  Props,
  Emit,
  setup,
} from '@/common/components/molecules/baseGridChipsCell/baseGridChipsCell.setup';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';

const props = withDefaults(defineProps<Props>(), {
  rowIndex: -1,
  chips: () => [],
  shownChipCount: undefined,
  isMultipleValueChip: false,
  isDashboard: false,
  isClickable: false,
});

const emit = defineEmits<Emit>();

const {
  baseGridChipsEl,
  filteredChips,
  moreCount,
  chipsClass,
  onClickChip,
  onClickCount,
  onClickCountClickable,
  getDashboardNameByLocalized,
} = setup(props, emit);

const onCountClick = (event: Event) => {
  if (props.isClickable) {
    onClickCountClickable(event);
  } else {
    onClickCount(event);
  }
};
</script>

<template>
  <div
    ref="baseGridChipsEl"
    :class="['base-grid__chips', chipsClass, { 'base-grid__chips--clickable': props.isClickable }]"
  >
    <template v-if="isMultipleValueChip">
      <tag-item
        v-for="(chip, index) in filteredChips"
        :key="`${chip}${index}`"
        :title="chip"
        size="xsmall"
        class="chip"
        html-type="button"
        @click="(e) => onClickChip(e, chip)"
        >{{ chip }}
      </tag-item>
    </template>
    <template v-else>
      <tag-item
        v-for="(chip, index) in filteredChips"
        :key="`${chip}${index}`"
        :title="chip"
        size="xsmall"
        class="chip"
      >
        {{ isDashboard ? getDashboardNameByLocalized(chip) : chip }}
      </tag-item>
    </template>
    <button
      v-if="moreCount"
      class="chip count"
      type="button"
      @click.stop="onCountClick"
    >
      {{ `+${moreCount}` }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.base-grid__chips {
  overflow: hidden;

  &--clickable {
    cursor: pointer;
  }

  .chip {
    &:not(.count) {
      @include shortening(inline-flex, auto);
    }

    &.hidden {
      display: none;
    }
  }
  &.stretch .chip {
    max-width: 100%;
  }

  .count {
    height: 16px;
    padding: 0 8px;
    cursor: pointer;
    user-select: none !important;
    border-radius: 29px;
    background-color: var(--badge-bg-color);
    font-size: 11px;
    line-height: 16px;
    color: var(--badge-font-color);
  }
}
</style>
