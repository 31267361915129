import { RouteRecordRaw } from 'vue-router';
import { clearModule, logoutApp } from '@/common/router/navigationGuard';

const loginMenu: RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: [
      clearModule,
      () => {
        window.location.href = '/';
      },
    ],
  },
  {
    path: '/logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: logoutApp,
  },
];

export { loginMenu };
