interface Props {
  productName: string;
  isExpand: boolean;
}

interface Emits {
  (e: 'click-title');
}

const setup = (props: Props, emits: Emits) => {
  const onClickTitle = () => emits('click-title');
  return {
    onClickTitle,
  };
};

export { setup };
export type { Props, Emits };
