import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { MetricResponse } from '@/openapi/data/model';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { MetricRequest } from '@/common/utils';
import { MetricFailError, checkValidMetric } from '@/database/utils/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export type StatValue = [number | string, number];
export interface ApiData {
  metricName: string;
  values: StatValue[];
}
export interface StatData {
  [key: string]: StatValue[];
}
export interface FetchParam {
  instanceId: string;
  statNames: string[];
  frameId?: string;
}
interface State {
  stats: StatData;
  errorStatusText: string;
}

export const oneMinuteIntervalList = ['db_postgresql_tempUsage', 'db_postgresql_trxTime'];

export const dbStats: Module<State, RootState> = {
  namespaced: true,
  state: {
    stats: {},
    errorStatusText: '',
  },
  mutations: {
    setAllStat: (state: State, data: MetricResponse[]) => {
      const tempObj = {};
      data.forEach((item) => {
        tempObj[item.dataDefinition?.dataId ?? ''] = transformMetricChartValues(
          item.metrics?.[0].values,
        );
      });

      state.stats = tempObj;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchDBStats({ commit }, { instanceId, statNames }: FetchParam) {
      try {
        const deduplicationNames: string[] = [...new Set(statNames)];
        // TODO: 추후 interval(수집 주기) 확인 필요
        const oneMinuteInterval = deduplicationNames.filter((name) =>
          oneMinuteIntervalList.includes(name),
        );
        if (oneMinuteInterval.length) {
          const allStat: MetricResponse[] = [];

          const metricRequests = oneMinuteInterval.map<MetricRequest>((name) => ({
            aggregationType: 'byTarget',
            period: 'p1h',
            interval: 'I1m',
            category: 'postgresql',
            summaryType: 'current',
            dataId: name,
            targetIds: [instanceId],
          }));

          const promises = [
            getMetricData({
              metricV7Requests: metricRequests,
              frameName: FRAME_NAMES.DB_METRIC.METRIC_I1M,
              isTimeout: true,
            }),
          ];
          const others = deduplicationNames.filter((name) => !oneMinuteIntervalList.includes(name));
          if (others.length) {
            const othersMetricRequests = others.map<MetricRequest>((name) => ({
              aggregationType: 'byTarget',
              period: 'p3m',
              interval: 'I5s',
              category: 'postgresql',
              summaryType: 'current',
              dataId: name,
              targetIds: [instanceId],
            }));

            promises.push(
              getMetricData({
                metricV7Requests: othersMetricRequests,
                frameName: FRAME_NAMES.DB_METRIC.METRIC_I5S,
                isTimeout: true,
              }),
            );
          }

          const result = await Promise.allSettled(promises);
          result.forEach((value) => {
            if (value.status === 'fulfilled') {
              const { data } = value.value;
              checkValidMetric(data?.data ?? []);
              allStat.push(...(data?.data ?? []));
            }
          });
          commit('setAllStat', allStat);
          commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', FRAME_NAMES.DB_METRIC.METRIC, {
            root: true,
          });
        } else {
          const metricRequests = deduplicationNames.map<MetricRequest>((name) => ({
            aggregationType: 'byTarget',
            period: 'p3m',
            interval: 'I5s',
            category: 'postgresql',
            summaryType: 'current',
            dataId: name,
            targetIds: [instanceId],
          }));

          const { data } = await getMetricData({
            metricV7Requests: metricRequests,
            frameName: FRAME_NAMES.DB_METRIC.METRIC,
            isTimeout: true,
          });

          checkValidMetric(data?.data ?? []);

          commit('setAllStat', data?.data ?? []);
          commit(
            'postgresqlSingleViewEnv/deleteFramesByFailedApi',
            FRAME_NAMES.DB_METRIC.METRIC_I1M,
            { root: true },
          );
          commit(
            'postgresqlSingleViewEnv/deleteFramesByFailedApi',
            FRAME_NAMES.DB_METRIC.METRIC_I5S,
            { root: true },
          );
        }
        commit('setErrorStatusText', '');
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAMES.DB_METRIC.METRIC, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getAllStatData: (state: State) => state.stats,
    getErrorStatusText: (state: State) => state.errorStatusText,
  },
};
