import { Module } from 'vuex';
import { RootState } from '@/common/store';
import {
  getV1WebhookAxios,
  patchV1WebhookByWebhookIdAxios,
  postV1WebhookAxios,
} from '@/openapi/meta/api/webhook-controller-api';
import { WebhookItem as WebhookModelItem } from '@/openapi/meta/model/webhook-item';
import { CreateWebhookRequest, UpdateWebhookRequest } from '@/openapi/meta/model';

export interface WebhookItem {
  id: number | null;
  request: {
    method: string;
    url: string;
    header: string;
    body: string;
    enabled: boolean;
  };
  additional: {
    name: string;
    description: string;
  };
}

export interface State {
  webhooks: [];
  selectedWebhook: WebhookModelItem;
}

export const DEFAULT_INFO = {
  id: null,
  request: {
    enabled: false,
    method: 'post',
    url: '',
    header: '',
    body: '',
  },
  additional: {
    name: '',
    description: '',
  },
};

export const webhook: Module<State, RootState> = {
  namespaced: true,
  state: {
    webhooks: [],
    selectedWebhook: structuredClone(DEFAULT_INFO),
  },
  mutations: {
    setWebhooks: (state, data) => {
      state.webhooks = data;
    },
    setSelectedWebhook: (state, data: WebhookModelItem) => {
      state.selectedWebhook = data;
    },
  },
  actions: {
    fetchWebhooks: async ({ commit }) => {
      const { data } = await getV1WebhookAxios();
      // await commit('setWebhooks', data.data || []);
      if (data.data?.length) {
        await commit('setSelectedWebhook', data.data[0]);
      }
    },
    saveWebhook: async ({ commit }, request: CreateWebhookRequest) => {
      const { data } = await postV1WebhookAxios({ request });
      if (data.data?.length) {
        await commit('setSelectedWebhook', data.data[0]);
      }
    },
    editWebhook: async (
      { commit },
      { webhookId, request }: { webhookId: number; request: UpdateWebhookRequest },
    ) => {
      const { data } = await patchV1WebhookByWebhookIdAxios({ webhookId, request });
      if (data.data?.length) {
        await commit('setSelectedWebhook', data.data[0]);
      }
    },
  },
  getters: {
    getSelectedWebhook: (state: State) => state.selectedWebhook,
  },
};
