import { useInternational } from '@/common/locale';
import { STORAGE_KEY } from '@/common/utils';
import { webStorageController } from '@/common/utils/webStorage.util';
import type {
  ForbiddenError,
  LoginError,
  LoginFailAction,
} from '@/login/components/loginForm/loginForm.types';
import { AxiosError } from 'axios';

const useLoginErrorHandler = () => {
  const isForbiddenError = (e?: LoginError): e is ForbiddenError => {
    return e?.status === 403;
  };

  const handleForbiddenError = (
    e: ForbiddenError,
    t: ReturnType<typeof useInternational>['t'],
  ): LoginFailAction => {
    switch (e.code) {
      case 'USER_ID_IS_LOCKED':
        return {
          isShowMsgBox: true,
          msg: `${t('MESSAGE.LOCK_USER')} ${t('MESSAGE.CONTACT_MANAGER')}`,
        };
      case 'LOGIN_FAIL_COUNT_OVER':
        return {
          isShowMsgBox: true,
          msg: `${t('MESSAGE.LOCK_USER_PASSWORD')} ${t('MESSAGE.CONTACT_MANAGER')}`,
        };
      case 'LOGIN_ACCESS_RESTRICT_OVER':
        return {
          isShowMsgBox: true,
          msg: `${t('MESSAGE.LOCK_USER_LONG', {
            value: e?.customMessage?.loginAccessRestrictDay,
          })} ${t('MESSAGE.CONTACT_MANAGER')}`,
        };
      default:
        return {
          isShowMsgBox: true,
          msg: '',
        };
    }
  };

  const handleLoginError = (
    e: AxiosError<LoginError>,
    t: ReturnType<typeof useInternational>['t'],
  ) => {
    const defaultHandlingInfo: LoginFailAction = {
      msg: t('MESSAGE.TEMP_SERVER_ERROR'),
      isShowMsgBox: true,
    };

    switch (e?.response?.status) {
      case 401:
        return Object.assign(defaultHandlingInfo, {
          msg: t('MESSAGE.INCORRECT_ID_PASSWORD'),
          isShowMsgBox: false,
        });
      case 403:
        if (e?.response?.data && isForbiddenError(e?.response?.data)) {
          return Object.assign(defaultHandlingInfo, handleForbiddenError(e.response.data, t));
        }
        return defaultHandlingInfo;
      default:
        return defaultHandlingInfo;
    }
  };

  return {
    handleLoginError,
  };
};
const useLoginAction = () => {
  const checkRememberId = (isChecked: boolean, id: string) => {
    if (isChecked) {
      webStorageController.setItem({
        type: 'local',
        key: STORAGE_KEY.LAST_LOGIN_ID,
        value: JSON.stringify({ id }),
      });
    } else {
      webStorageController.removeItem({
        type: 'local',
        key: STORAGE_KEY.LAST_LOGIN_ID,
      });
    }
  };

  return {
    checkRememberId,
  };
};

export { useLoginErrorHandler, useLoginAction };
