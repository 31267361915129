<script setup lang="ts">
import { setup } from '@/common/components/molecules/statusTag/statusTag.setup';

interface Props {
  type: string;
}

const props = defineProps<Props>();

const { typeToClass } = setup(props);
</script>

<template>
  <span
    class="status-tag"
    :class="typeToClass"
  >
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.status-tag {
  display: inline-block;
  min-width: 72px;
  padding: 2px 10px 1px;
  border: 1px solid var(--status-tag-active-border-color);
  border-radius: 2px;
  background-color: var(--status-tag-active-bg-color);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: var(--status-tag-active-font-color);
  text-align: center;

  & + & {
    margin-left: 4px;
  }

  &.normal,
  &.succeeded,
  &.complete,
  &.completion,
  &.available,
  &.ready,
  &.connection,
  &.connect,
  &.up,
  &.bound,
  &.ok,
  &.admin {
    border-color: var(--status-tag-succeeded-border-color);
    background-color: var(--status-tag-succeeded-bg-color);
    color: var(--status-tag-succeeded-font-color);
  }

  &.waiting,
  &.pending,
  &.warning,
  &.memory-pressure,
  &.disk-pressure,
  &.pid-pressure,
  &.network-unavailable,
  &.suspended,
  &.dormant,
  &.not_present,
  &.lower_layer_down,
  &.notready {
    border-color: var(--status-tag-warning-border-color);
    background-color: var(--status-tag-warning-bg-color);
    color: var(--status-tag-warning-font-color);
  }

  &.timed-waiting,
  &.error,
  &.exception,
  &.critical,
  &.terminated,
  &.down,
  &.false,
  &.fail,
  &.failed,
  &.failure-target,
  &.abnormal {
    border-color: var(--status-tag-error-border-color);
    background-color: var(--status-tag-error-bg-color);
    color: var(--status-tag-error-font-color);
  }

  &.none,
  &.disabled,
  &.no-signal,
  &.inactive,
  &.unknown,
  &.no-data,
  &.combination,
  &.and,
  &.or,
  &.tag,
  &.target,
  &.off,
  &.testing,
  &.exited,
  &.snoozing {
    border-color: var(--status-tag-inactive-border-color);
    background-color: var(--status-tag-inactive-bg-color);
    color: var(--status-tag-inactive-font-color);
  }

  &.blocked {
    border-color: var(--status-tag-disabled-border-color);
    background-color: var(--status-tag-disabled-bg-color);
    color: var(--status-tag-disabled-font-color);
  }
}
</style>
