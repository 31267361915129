import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { repeat } from '@/worker/utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/common/stores/auth';

interface RepeatOptions {
  isSetup?: boolean;
  isImmediate?: boolean;
}

const useRepeat = (
  executeApi: () => void,
  timeout: number,
  options?: RepeatOptions,
  abortApi?: () => void,
) => {
  const { isCallable, tokenInfo } = storeToRefs(useAuthStore());

  const isRunningImmediate = ref<boolean>(false);
  const { isSetup = true, isImmediate = false } = options ?? {};
  let isRunning = false;

  const fetch = {
    timer: 0,
    timeout,
  };

  const clearFetch = async () => {
    await nextTick(() => {
      clearTimeout(fetch.timer);
      if (abortApi && typeof abortApi === 'function') {
        abortApi();
      }
      isRunning = false;
    });
  };

  const resetFetch = (
    resetOptions?: { fn?: () => void; timeout?: number },
    newTimeout?: number,
    isExecute = false,
  ) => {
    if (!isRunningImmediate.value || isExecute) {
      fetch.timeout = newTimeout || resetOptions?.timeout || fetch.timeout;
      clearTimeout(fetch.timer);
      repeat(resetOptions?.fn || executeApi, fetch);
      isRunning = true;
    }
    isRunningImmediate.value = false;
  };

  if (isSetup) {
    if (isImmediate) {
      isRunningImmediate.value = true;
      onMounted(() => {
        repeat(executeApi, fetch);
        isRunning = true;
      });
    }

    onUnmounted(() => {
      isRunningImmediate.value = false;
      isRunning = false;
      clearFetch();
    });
  } else if (isImmediate) {
    isRunningImmediate.value = true;
    isRunning = true;
    clearTimeout(fetch.timer);
    repeat(executeApi, fetch);
  }

  watch([isCallable, tokenInfo], ([isVisible, tokenObj]) => {
    if (isVisible && tokenObj.accessToken && isRunning) {
      clearTimeout(fetch.timer);
      repeat(executeApi, fetch);
    } else if (!isVisible) {
      setTimeout(
        () => {
          if (!isCallable.value) {
            clearTimeout(fetch.timer);
          }
        },
        1_000 * 60 * 3,
      );
    }
  });

  return { fetch, resetFetch, clearFetch };
};

export { useRepeat, type RepeatOptions };
