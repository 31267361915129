import { StatCategory } from './statCategoryList';

export const sortParentCategory = (category1: string, category2: string) => {
  if (category1 === 'custom') {
    return -1;
  }
  if (category2 === 'custom') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortCategory = (category1: string, category2: string) => {
  if (category1 === 'common') {
    return -1;
  }
  if (category2 === 'common') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortTag = (tag1: string, tag2: string) => {
  return tag1.localeCompare(tag2);
};

export const mapChildCategoryToExact = ({ parentCategory, childCategory }: StatCategory) => {
  if (parentCategory === childCategory) {
    return 'common';
  }
  return childCategory;
};

export const mapChildCategoryWithParent = ({ parentCategory, childCategory }: StatCategory) => {
  return childCategory === 'common' ? parentCategory : childCategory;
};
