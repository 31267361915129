/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getTopScanObjectV2PostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { TopScanObjectItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface TopObjectState {
  topObject: TopScanObjectItem[];
  errorStatusText: string;
}

export const topObject: Module<TopObjectState, RootState> = {
  namespaced: true,
  state: {
    topObject: [],
    errorStatusText: '',
  },
  mutations: {
    setTopObject(state: TopObjectState, data: TopScanObjectItem[]) {
      state.topObject = data;
    },
    setErrorStatusText: (state: TopObjectState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchTopObject({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OBJECT_TOP_OBJECT;
      try {
        const { data } = await getTopScanObjectV2PostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setTopObject', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopObject: (state: TopObjectState) => state.topObject,
    getObjectName: (state: TopObjectState) => state.topObject.map((v) => v.objectName).reverse(),
    getScan: (state: TopObjectState) => setEvBarChartData(state.topObject.map((v) => v.diffScan)),
    getErrorStatusText: (state: TopObjectState) => state.errorStatusText,
  },
};
