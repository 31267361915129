import {
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  ApiTraceData,
  ApiTraceInfo,
  TargetFrameInfo,
  useApiTraceStore,
} from '@/common/stores/api-trace';
import { formatSqlText, generateUUID, showErrorMsg } from '@/common/utils/commonUtils';
import { MESSAGE_ACTION } from '@/common/define/apiTrace.define';
import { scrollTab, setTranslateXByIndex } from '@/common/utils/tab.utils';
import { throttle } from 'lodash-es';
import {
  NameValueKey,
  ApiHistoryData,
  NameValueField,
  TargetHistory,
  TabPanelData,
} from './apiTrace.types';

interface TargetInfo {
  snapTimes: NameValueKey[];
  frames: NameValueKey[];
  subFrames: TabPanelData[];
  targetSnapId: string;
  targetFrame: string | null;
  targetSubFrame: string | null;
}

export interface Props {
  isShow: boolean;
  isPopup?: boolean;
  targetFrameInfo?: TargetFrameInfo;
}

export interface Emit {
  (e: 'changePath', v: string): void;
  (e: 'changeApiHistory', v: ApiHistoryData[]);
  (e: 'changeTargetHistory', v: TargetHistory): void;
}

export const TOGGLE_DATA = {
  SQL: 'sql',
  DATA: 'data',
} as const;

const DEFAULT_RESPONSE_INFO: NameValueField[] = [
  {
    name: 'URL',
    field: 'url',
    value: '',
  },
  {
    name: 'Status',
    field: 'status',
    value: '',
  },
  {
    name: 'Request Time',
    field: 'requestTime',
    value: '',
  },
  {
    name: 'Elapsed Time (sec)',
    field: 'elapsedTime',
    value: '',
  },
  {
    name: 'Params',
    field: 'params',
    value: '',
  },
];

const sortFrames = (frames: NameValueKey[]) => frames.sort((a, b) => a.name.localeCompare(b.name));
const sortSubFrames = (subFrames: TabPanelData[]) =>
  subFrames.sort((a, b) => a.text.localeCompare(b.text));

const getResponseInfo = (data: any): NameValueField[] =>
  DEFAULT_RESPONSE_INFO.map(({ name, field }) => ({
    name,
    field,
    value: data?.[field] ?? '',
  }));

export const setup = (props: Required<Props>, emit: Emit) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const apiTraceStore = useApiTraceStore();

  const subFrameTabsRef = ref();
  const currentPath = ref<string>('');
  const isErrorApi = ref<boolean>(false);
  const dataSqlToggle = ref<'sql' | 'data'>(TOGGLE_DATA.SQL);
  const targetInfo = reactive<TargetInfo>({
    snapTimes: [],
    frames: [],
    subFrames: [],
    targetSnapId: '',
    targetFrame: null,
    targetSubFrame: null,
  });
  const responseInfos = ref<NameValueField[]>(getResponseInfo({}));
  const responseData = ref<{ sql: string; data: string }>({
    sql: '',
    data: '',
  });
  const tabRenderTrigger = ref<string>('');

  const getFormattedSql = (text) => {
    const sql = formatSqlText(text, 'postgresql');
    return sql.length === 0 && text.length > 0 ? text : sql;
  };

  const textViewerData = computed<{
    type: 'json' | 'sql';
    text: string;
  }>(() => {
    const type = dataSqlToggle.value === TOGGLE_DATA.DATA ? 'json' : 'sql';
    const text =
      type === 'json' ? responseData.value.data : getFormattedSql(responseData.value.sql);

    return {
      type,
      text,
    };
  });

  let apiHistories: ApiHistoryData[] = [];
  let targetHistory: ApiHistoryData | undefined;

  const getTargetInfo = (): TargetHistory => ({
    snapId: targetInfo.targetSnapId,
    frameId: targetInfo.targetFrame ?? '',
    subFrameId: targetInfo.targetSubFrame ?? '',
    dataSqlToggle: dataSqlToggle.value,
  });

  const init = () => {
    isErrorApi.value = false;
    currentPath.value = '';
    targetInfo.snapTimes = [];
    targetInfo.frames = [];
    targetInfo.subFrames = [];
    targetInfo.targetSnapId = '';
    targetInfo.targetFrame = null;
    targetInfo.targetSubFrame = null;
    responseInfos.value = getResponseInfo({});
    responseData.value = {
      sql: '',
      data: '',
    };
    dataSqlToggle.value = TOGGLE_DATA.SQL;
  };

  const getFrameAndSubFrame = (apiTraceInfo: Record<string, ApiTraceInfo>) =>
    Object.entries(apiTraceInfo).reduce(
      (acc, [key]) => {
        const parts = key.split('/');
        const [frameName] = parts;
        parts.splice(0, 1);
        const subFrameName = parts.join('/');

        if (!acc.frames.some((frame) => frame.key === frameName)) {
          acc.frames.push({
            name: frameName,
            key: frameName,
            value: generateUUID(),
          });
        }

        const iconClass = apiTraceInfo[key].isError ? 'icon-fill-warning' : '';
        if (!acc.subFrames.some((subFrame) => subFrame.key === subFrameName)) {
          acc.subFrames.push({
            text: subFrameName,
            value: generateUUID(),
            key,
            iconClass,
          });
        }

        return acc;
      },
      {
        frames: [] as NameValueKey[],
        subFrames: [] as TabPanelData[],
      },
    );

  const updateFrames = (snapId: string) => {
    const beforeTargetFrame = targetInfo.frames?.find(
      (item) => item.value === targetInfo.targetFrame,
    );
    const beforeTargetFrameKey = beforeTargetFrame?.key;

    targetHistory = apiHistories.find((history) => history.snapId === snapId);
    targetInfo.frames = sortFrames(targetHistory?.frames ?? []);

    const frameItem = targetInfo.frames?.find((item) => item.key === beforeTargetFrameKey);
    if (frameItem) {
      targetInfo.targetFrame = frameItem.value;
    } else {
      targetInfo.targetFrame = targetInfo.frames?.[0]?.value ?? null;
    }
  };

  const updateSubFrames = (targetFrame: string | null) => {
    const beforeTargetSubFrame = targetInfo.subFrames?.find(
      (item) => item.value === targetInfo.targetSubFrame,
    );
    const beforeTargetSubFrameKey = beforeTargetSubFrame?.key;

    const targetFrameItem = targetInfo.frames?.find((item) => item.value === targetFrame);
    const subFrames =
      targetHistory?.subFrames?.filter((subFrame) => {
        const [frameKey] = subFrame.key.split('/');
        return targetFrame && targetFrameItem?.key === frameKey;
      }) ?? [];
    targetInfo.subFrames = sortSubFrames(subFrames);

    const subFrameItem = targetInfo.subFrames?.find((item) => item.key === beforeTargetSubFrameKey);

    if (subFrameItem) {
      targetInfo.targetSubFrame = subFrameItem.value;
    } else {
      targetInfo.targetSubFrame = targetInfo.subFrames?.[0]?.value ?? null;
    }
  };

  const updateApiTrace = (newData: ApiTraceData) => {
    if (currentPath.value !== newData.path) {
      init();
    }

    if (apiHistories.length >= 10) {
      apiHistories.unshift();
    }

    const snapId = generateUUID();
    const { frames, subFrames } = getFrameAndSubFrame(newData.apiTraceInfo);

    apiHistories.push({
      frames,
      subFrames,
      snapId,
      snapTime: newData.snapTime,
      infoByFrameName: newData.apiTraceInfo,
    });
    targetInfo.snapTimes.push({
      name: newData.snapTime,
      key: newData.snapTime,
      value: snapId,
    });
    targetInfo.targetSnapId = snapId;
    currentPath.value = newData.path;

    updateFrames(snapId);
    updateSubFrames(targetInfo.targetFrame);

    emit('changePath', newData.path);
  };

  const updateApiTraceFromParent = async ({
    path,
    apiHistory,
    parentTargetHistory,
  }: {
    path: string;
    apiHistory: ApiHistoryData[];
    parentTargetHistory: TargetHistory;
  }) => {
    currentPath.value = path;
    apiHistories = apiHistory;
    dataSqlToggle.value = parentTargetHistory.dataSqlToggle;
    targetInfo.snapTimes = apiHistories.map((history) => ({
      name: history.snapTime,
      key: history.snapTime,
      value: history.snapId,
    }));
    targetInfo.targetSnapId = parentTargetHistory.snapId;
    await nextTick();

    targetInfo.targetFrame = parentTargetHistory.frameId;
    updateFrames(targetInfo.targetSnapId);
    await nextTick();

    targetInfo.targetSubFrame = parentTargetHistory.subFrameId;
    updateSubFrames(targetInfo.targetFrame);
  };

  const getApiKey = ({ type, id }: TargetFrameInfo) => {
    if (!type) {
      return id;
    }

    const apiKeys = Object.keys(targetHistory?.infoByFrameName ?? {});

    return apiKeys.find((key) => key.includes(`(${id})`)) ?? '';
  };

  const changeTargetFrameInfo = async (info: TargetFrameInfo) => {
    if (!props.isShow || !info.id) {
      return;
    }

    const apiKey = getApiKey(info);
    const [targetFrameKey = ''] = apiKey.split('/');
    const targetFrame = targetInfo.frames.find((frame) => frame.key === targetFrameKey);
    const subFrames =
      targetHistory?.subFrames?.filter((subFrame) => {
        const [frameKey] = subFrame.key.split('/');
        return targetFrame && targetFrame?.key === frameKey;
      }) ?? [];
    const targetSubFrameIdx = subFrames.findIndex((subFrame) => subFrame.key === apiKey);

    if (!(apiKey && targetFrame && targetSubFrameIdx > -1 && subFrames?.length)) {
      showErrorMsg(ctx, `Not found target frame: ${info.id}`);
      return;
    }

    targetInfo.targetFrame = targetFrame?.value;
    await nextTick();

    const targetSubFrame = subFrames[targetSubFrameIdx];

    targetInfo.subFrames = sortSubFrames(subFrames);
    await nextTick();

    targetInfo.targetSubFrame = targetSubFrame.value;
  };

  const sendMessage = (action: (typeof MESSAGE_ACTION)[keyof typeof MESSAGE_ACTION]) => {
    window.opener.postMessage({ action }, window.opener.origin);
  };

  const messageListener = async (event: MessageEvent) => {
    if (event.origin !== window.opener.origin) {
      return;
    }

    const { action, data } = event.data;

    switch (action) {
      case MESSAGE_ACTION.INIT:
        updateApiTraceFromParent(JSON.parse(data));
        break;

      case MESSAGE_ACTION.GET_API_INFO:
        updateApiTrace(JSON.parse(event.data?.data));
        break;

      case MESSAGE_ACTION.SET_TARGET_FRAME_INFO: {
        if (props.isShow) {
          changeTargetFrameInfo(JSON.parse(data));
        }
        break;
      }

      default:
        break;
    }
  };

  const onClickSnap = () => {
    if (props.isPopup) {
      sendMessage(MESSAGE_ACTION.GET_API_INFO);
    } else {
      updateApiTrace(apiTraceStore.getApiTraceInfo());
    }
  };

  const setTabTranslateXBySubTargetFrame = () => {
    const subFrame = targetInfo.targetSubFrame;
    const subTargetFrameIdx =
      targetInfo.subFrames.findIndex((item) => item.value === subFrame) ?? -1;
    setTranslateXByIndex(subFrameTabsRef, subTargetFrameIdx);
  };

  const setScroll = throttle((e) => {
    const isNext = e?.deltaY > 0;
    scrollTab(subFrameTabsRef, isNext);
  }, 100);

  const onScrollTab = (e) => {
    setScroll(e);
  };

  watch(
    () => targetInfo.targetSnapId,
    (snapId) => {
      updateFrames(snapId);
    },
  );

  watch(
    () => targetInfo.targetFrame,
    (frame) => {
      updateSubFrames(frame);
      tabRenderTrigger.value = generateUUID();
    },
  );

  watch(
    () => targetInfo.targetSubFrame,
    async (subFrame) => {
      if (!props.isShow) {
        return;
      }

      const targetSubFrame = targetInfo.subFrames?.find((item) => item.value === subFrame);
      const apiInfo = subFrame
        ? targetHistory?.infoByFrameName?.[targetSubFrame?.key ?? '']
        : {
            sql: '',
            data: '',
            isError: false,
          };

      responseInfos.value = getResponseInfo(apiInfo);
      responseData.value = {
        sql: apiInfo?.sql ?? '',
        data: apiInfo?.data ?? '',
      };
      isErrorApi.value = apiInfo?.isError ?? false;

      if (isErrorApi.value) {
        dataSqlToggle.value = 'data';
      }

      emit('changeApiHistory', apiHistories);
      emit('changeTargetHistory', getTargetInfo());

      setTabTranslateXBySubTargetFrame();
    },
  );

  watch(
    () => props.isShow,
    async (show) => {
      if (!show || props.isPopup) {
        return;
      }

      init();
      updateApiTrace(apiTraceStore.getApiTraceInfo());
      setTimeout(() => {
        changeTargetFrameInfo(props.targetFrameInfo);
      }, 200);
    },
    {
      immediate: true,
    },
  );

  watch(dataSqlToggle, () => {
    emit('changeTargetHistory', getTargetInfo());
  });

  watch(
    () => props.targetFrameInfo,
    (info) => {
      if (!props.isShow) {
        return;
      }

      changeTargetFrameInfo(info);
    },
    {
      deep: true,
    },
  );

  onMounted(() => {
    if (!props.isPopup) {
      return;
    }

    window.addEventListener('message', messageListener);
    sendMessage(MESSAGE_ACTION.INIT);
  });

  onBeforeUnmount(() => {
    if (!props.isPopup) {
      return;
    }
    window.removeEventListener('message', messageListener);
  });

  return {
    subFrameTabsRef,
    tabRenderTrigger,
    currentPath,
    isErrorApi,
    dataSqlToggle,
    targetInfo,
    responseInfos,
    textViewerData,
    onClickSnap,
    onScrollTab,
  };
};
