const NAVI_BUTTON_WIDTH = 20;

export const scrollTab = (tabRef: any, isNext = true) => {
  if (!tabRef.value || !tabRef.value?.hasScroll) {
    return;
  }

  if (isNext) {
    tabRef.value?.scrollTab('next');
  } else {
    tabRef.value?.scrollTab('prev');
  }
};

// ## Comment: baseModal window 에서만 테스트해봄 body 에 붙여있는 경우는 사용전 한번 테스트 바람.
export const setTranslateXByIndex = (tabsRef: any, targetIndex: number) => {
  if (!tabsRef.value || targetIndex === -1) {
    return;
  }

  try {
    const [tabListWrapperEl] = tabsRef.value.$el.getElementsByClassName('ev-tabs-nav-wrapper');
    const [tabListEl] = tabsRef.value.$el.getElementsByClassName('ev-tabs-list');
    const targetTabEl = tabListEl.getElementsByClassName('ev-tabs-title')?.[targetIndex];

    if (!(tabListEl && tabListEl && targetTabEl)) {
      return;
    }

    const { width: wrapperWidth, left: wrapperLeft } = tabListWrapperEl.getBoundingClientRect();
    const { left: targetLeft, right: targetRight } = targetTabEl.getBoundingClientRect();

    const originOffset = wrapperLeft + NAVI_BUTTON_WIDTH;
    const originTargetLeft = targetLeft - originOffset;
    const originTargetRight = targetRight - originOffset;

    if (originTargetLeft < 0) {
      // 보이는 tab wrapper 보다 앞에 있을때
      tabsRef.value?.scrollTab('prev', -Math.round(originTargetLeft));
    } else if (originTargetRight > wrapperWidth - NAVI_BUTTON_WIDTH) {
      // 보이는 tab wrapper 보다 뒤에 있을때
      tabsRef.value?.scrollTab('next', Math.round(originTargetLeft));
    }
  } catch (e) {
    console.log(e);
  }
};
