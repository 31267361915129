<template>
  <div
    ref="slideOutRef"
    :class="[
      'slide-out',
      { 'slide-out--show': isVisible },
      { 'slide-out--expand': isExpand },
      { [`slide-out--gnb-${gnbCondition}`]: gnbCondition },
    ]"
  >
    <section
      :class="[
        'slide-out-window',
        `slide-out-window--${options!.direction}`,
        options!.layout ? `slide-out-window--${options!.layout}` : '',
        { 'slide-out-window--popup': props.isPopup },
      ]"
      @mouseenter="emitMouseHover('enter')"
      @mouseover="emitMouseHover('over')"
      @mouseleave="emitMouseHover('leave')"
    >
      <ev-button
        v-show="isVisible && options?.hasExpand"
        type="primary"
        class="slide-out-window__tip slide-out-window__tip--expand"
        :disabled="isExpand"
        @click="onClickExpand"
      >
        <i class="icon-arrow-up" />
        <span class="text">Expand</span>
      </ev-button>
      <ev-button
        v-show="isVisible && options?.hasExpand"
        type="primary"
        class="slide-out-window__tip slide-out-window__tip--collapse"
        @click="onClickCollapse"
      >
        <i class="icon-arrow-down" />
        <span class="text">Collapse</span>
      </ev-button>

      <slot name="headerTop" />
      <div
        v-if="props.title"
        class="slide-out-window__header"
      >
        <div class="header-content-wrapper left-wrapper">
          <slot name="titleLeft" />
          <p
            :title="props.title"
            class="slide-out-window__title"
          >
            {{ props.title }}
          </p>
          <slot name="headerLeft" />
        </div>
        <div class="header-content-wrapper right-wrapper">
          <slot name="headerRight" />
          <div class="slide-out-window__header-actions">
            <span
              v-for="item in headerActions"
              :key="item.type"
              :class="['header-action', `header-action--${item.type}`]"
              @click="clickAction(item.type)"
            >
              <ev-icon :icon="item.icon" />
            </span>
          </div>
        </div>
      </div>
      <div class="slide-out-window__body">
        <ev-loading :model-value="props.isLoading" />
        <slot />
      </div>
    </section>
    <div
      v-if="options?.hasDimLayer"
      class="slide-out-dim-layer"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref } from 'vue';
import { SlideOutSizeType } from '@/common/utils/types';
import { storeToRefs } from 'pinia';
import { useBaseMenuStore } from '@/common/stores/base-menu';

export interface SlideOutWindowOption {
  direction?: 'left' | 'right'; // 슬라이드 방향
  sizeType?: SlideOutSizeType; // width 선택: 3종류 고정
  headerActions?: ('open' | 'close')[]; // header 우측 동작 버튼 목록
  hasDimLayer?: boolean; // dim layer 여부
  width?: string; // custom width
  minWidth?: string; // custom width
  minHeight?: string; // custom width
  bodyYScroll?: 'auto' | 'initial';
  top?: string; // position top
  translateX?: string; // translate X axis
  layout?: string;
  hasExpand?: boolean; // detail slide 이외의 좌측 확장 여부
}

interface Props {
  isVisible: boolean;
  isLoading?: boolean;
  isPopup?: boolean; // 팝업 여부
  isHeightFixed?: boolean; // 높이 고정 여부
  title?: string;
  options?: SlideOutWindowOption;
}

const SLIDE_OUT_WIDTH = {
  SMALL: '380px',
  MEDIUM: '550px',
  LARGE: '900px',
};
const DEFAULT_OPTION: SlideOutWindowOption = {
  direction: 'right',
  sizeType: 'MEDIUM',
  headerActions: ['open', 'close'],
  hasDimLayer: false,
  minWidth: '0',
  minHeight: '0',
  bodyYScroll: 'auto',
  top: '0px',
  translateX: '0px',
  hasExpand: true,
};
const props = withDefaults(defineProps<Props>(), {
  isVisible: false,
  isPopup: false,
  isLoading: false,
  isHeightFixed: true,
  title: '',
  options: () => ({
    direction: 'right',
    sizeType: 'MEDIUM',
    headerActions: ['open', 'close'],
    hasDimLayer: false,
    minWidth: '0',
    minHeight: '0',
    bodyYScroll: 'auto',
    top: '0px',
    translateX: '0px',
    hasExpand: true,
  }),
});
const emit = defineEmits<{
  (e: 'update:isVisible', value: boolean): void;
  (e: 'update:isHeightFixed', value: boolean): void;
  (e: 'open-popup');
  (e: 'close-slide');
  (e: 'mouseover-slide');
  (e: 'mouseenter-slide');
  (e: 'mouseleave-slide');
}>();

const isVisible = computed<boolean>({
  get: () => props.isVisible as boolean,
  set: (val) => emit('update:isVisible', val),
});

const options = computed<Props['options']>(() => ({ ...DEFAULT_OPTION, ...props.options }));
const slideStyleObj = computed(() => {
  const { top, width, sizeType, translateX, minWidth, minHeight, bodyYScroll } = options.value!;
  const headerHeight = 'var(--gnb-header-height)';
  const slideWidth = width || (sizeType ? SLIDE_OUT_WIDTH[sizeType] : SLIDE_OUT_WIDTH.MEDIUM);
  let height;
  let bodyHeight;
  let bodyMaxHeight;

  if (props.isHeightFixed) {
    height = top ? `calc(100% - ${top})` : '100%';
    bodyHeight = `calc(100% - ${headerHeight})`;
    bodyMaxHeight = 'initial';
  } else {
    height = 'auto';
    bodyHeight = 'auto';
    bodyMaxHeight = `calc(${Math.floor(window.innerHeight * 0.95)}px - ${top} - ${headerHeight})`;
  }
  return {
    top,
    width: slideWidth,
    height,
    minWidth,
    minHeight,
    bodyMaxHeight,
    bodyHeight,
    bodyYScroll,
    headerHeight,
    translateX,
  };
});

const headerActions = computed(
  () =>
    options.value?.headerActions?.map((item) => {
      const icon = item === 'open' ? 'ev-icon-external-link3' : 'ev-icon-close3';
      return {
        type: item,
        icon,
      };
    }) ?? [],
);

const isExpand = ref<boolean>(false);
const { expandedMenu, panelMenu } = storeToRefs(useBaseMenuStore());

const gnbCondition = computed<string>(() => {
  if (panelMenu.value.menuInfo && expandedMenu.value.expanded) return 'expanded-and-opened';
  if (panelMenu.value.menuInfo) return 'opened';
  if (expandedMenu.value.expanded) return 'expanded';
  return '';
});

const slideOutRef = ref<HTMLElement | null>(null);

const closeSlide = () => {
  isVisible.value = false;
  isExpand.value = false;
  emit('close-slide');
};

const onClickExpand = () => {
  isExpand.value = true;
};

const onClickCollapse = () => {
  if (isExpand.value) {
    isExpand.value = false;
  } else {
    closeSlide();
  }
};

const useSlideDetailOutsideClick = (
  isDetailVisible: Ref<boolean>,
  closeDetail: () => void,
  excludeSelectors: string[] = [],
) => {
  const handleOutsideClick = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;

    if (isDetailVisible.value && slideOutRef.value && !slideOutRef.value.contains(clickedElement)) {
      const isExcluded = excludeSelectors.some(
        (selector) => clickedElement.closest(selector) !== null,
      );

      if (!isExcluded) {
        closeDetail();
      }
    }
  };

  onMounted(() => {
    document.addEventListener('mousedown', handleOutsideClick);
  });

  onUnmounted(() => {
    document.removeEventListener('mousedown', handleOutsideClick);
  });
};

useSlideDetailOutsideClick(isVisible, closeSlide, [
  '.slide-out',
  '.vue-grid-item',
  '.hexagon-chart',
  '.service-card',
  '.bullet-chart',
  '.equalizer-chart',
  '.cluster-map__main',
  '.table-body table',
  '#ev-window-modal',
  '#base-custom-tooltip',
  '#ev-grid-filtering-items-modal',
  '#ev-context-menu-modal',
  '#ev-grid-filter-setting-modal',
  '#ev-grid-column-setting-modal',
  '.tooltop-container',
  '.common-tooltip',
  '#ev-message-box-modal',
  '.on-site-alarm',
]);

const clickAction = (type) => {
  switch (type) {
    case 'close':
      closeSlide();
      break;
    case 'open':
      emit('open-popup');
      break;
    default:
      break;
  }
};
const emitMouseHover = (type: 'enter' | 'over' | 'leave') => {
  switch (type) {
    case 'enter':
      emit('mouseenter-slide');
      break;
    case 'over':
      emit('mouseover-slide');
      break;
    case 'leave':
      emit('mouseleave-slide');
      break;
    default:
      break;
  }
};

const escapeCloseSlide = (e: KeyboardEvent) => {
  if (
    e.key === 'Escape' &&
    isVisible.value &&
    !document.getElementById('ev-window-modal')?.children.length
  ) {
    closeSlide();
  }
};

onMounted(() => {
  document.addEventListener('keydown', escapeCloseSlide);
});

onUnmounted(() => {
  document.removeEventListener('keydown', escapeCloseSlide);
});
</script>

<style lang="scss">
.slide-out-dim-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $on-slide-out-window - 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.slide-out-window {
  $slide-top: v-bind('slideStyleObj.top');
  $slide-width: v-bind('slideStyleObj.width');
  $slide-height: v-bind('slideStyleObj.height');
  $slide-min-width: v-bind('slideStyleObj.minWidth');
  $slide-min-height: v-bind('slideStyleObj.minHeight');
  $header-height: v-bind('slideStyleObj.headerHeight');
  $body-height: v-bind('slideStyleObj.bodyHeight');
  $body-max-height: v-bind('slideStyleObj.bodyMaxHeight');
  $horizontal-padding: 9px 12px;

  position: fixed;
  top: $slide-top;
  left: 100%;
  z-index: $on-slide-out-window;
  width: $slide-width;
  max-width: 100%;
  height: $slide-height;
  background-color: var(--slide-detail-content-bg-color);
  transition:
    transform $animate-fast,
    width $animate-fast,
    height $animate-fast,
    left $animate-fast;

  &__tip {
    display: inline-flex;
    position: absolute;
    left: -64px;
    z-index: $on-app-header + 5;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 24px;
    padding: 4px 12px 4px 10px;
    cursor: pointer;
    background-color: #515151;
    color: #e9e9e9;
    transform: rotate(-90deg);

    &--expand {
      top: calc(50% - 50.5px);
      border-radius: 0 4px 0 0 !important;
    }

    &--collapse {
      top: calc(50% + 50.5px);
      border-radius: 4px 0 0 !important;

      ::before {
        position: absolute;
        top: 50%;
        right: -2px;
        width: 1px;
        height: calc(100% + 2px);
        background-color: var(--slide-out-window-button-middle-line-color);
        transform: translateY(-50%);
        content: '';
      }
    }

    i {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .text {
      font-size: 12px;
    }
  }

  &--popup {
    width: 100%;
    overflow: auto;
  }

  &--left {
    left: 0;
    transform: translateX(-100%);
  }

  &__header {
    display: flex;
    z-index: 2;
    gap: 6px;
    justify-content: space-between;
    height: $header-height;
    padding: $horizontal-padding;
    border-bottom: 1px solid var(--slide-header-border-color);
    background-color: var(--slide-header-bg-color);
    color: var(--slide-out-window-font-color);

    .header-content-wrapper {
      display: flex;
      gap: 6px;
      align-items: center;
      height: 100%;
      overflow: hidden;

      &.left-wrapper {
        flex: 1;
      }
    }
  }

  &__title {
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;

    @include shortening();
  }

  &__header-actions {
    display: flex;
    gap: 10px;
    margin-left: 4px;
    font-size: 18px;
    color: var(--color-d-gray-02);

    .header-action {
      cursor: pointer;
      transition: opacity $animate-fast;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__body {
    position: relative;
    min-width: $slide-min-width;
    height: $body-height;
    min-height: $slide-min-height;
    max-height: $body-max-height;
    padding: $horizontal-padding;
    overflow-y: v-bind('slideStyleObj.bodyYScroll');

    > .ev-tabs:first-child {
      .ev-tabs-header {
        margin-top: -1px;
      }
      .ev-tabs-title {
        &::before {
          top: 1px;
        }
      }
    }
  }
}
.slide-out {
  $slide-translate-x: v-bind('slideStyleObj.translateX');

  overflow: hidden;

  &--show {
    .slide-out-window {
      $slide-translate-x: v-bind('slideStyleObj.translateX');

      box-shadow: var(--slide-out-shadow);
      transform: translateX(-100%);

      &--left {
        transform: translateX(calc(var(--gnb-menu-width) + $slide-translate-x));
      }
    }
  }

  &--expand {
    .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width) + 28px));

      &__tip--expand i {
        background-color: var(--slide-out-window-arrow-icon-color) !important;
      }
    }
  }

  /* stylelint-disable */
  &--gnb-expanded {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-expanded) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-expanded) + 28px));
    }
  }

  &--gnb-opened {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-opened) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-opened) + 28px));
    }
  }

  &--gnb-expanded-and-opened {
    &.slide-out--show .slide-out-window {
      &--left {
        transform: translateX(calc(var(--gnb-menu-width-expanded-and-opened) + $slide-translate-x));
      }
    }

    &.slide-out--expand .slide-out-window {
      width: calc(100% - (var(--gnb-menu-width-expanded-and-opened) + 28px));
    }
  }
  /* stylelint-enable */
}
.slide-out--detail {
  .ev-tabs-body {
    padding: 0;
    background-color: var(--slide-detail-content-bg-color);
  }
}
</style>
