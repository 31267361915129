import { UserAccessMenuRequest } from '@/openapi/metaV6/model';
import { accessMenuUserV6ControllerAxios } from '@/openapi/metaV6/api/user-v6-controller-api';
import { DISPLAY_DB_TYPE } from '@/database/utils/define';

const getPrefix = (prefix: string): string | null => {
  const prefixMap = {
    ...DISPLAY_DB_TYPE,
    FAULT: 'Application',
  };
  return prefixMap[prefix?.toUpperCase()] ?? null;
};
const getMenuName = (name: string) => {
  const menuPath = name?.split('_') ?? [];
  const prefix = getPrefix(menuPath[0]);
  const menuName = menuPath.at(-1);
  return prefix ? `${prefix} > ${menuName}` : menuName;
};

export const checkConnectionStateAtBeforeEnter = async (to) => {
  if (!to?.path || !to?.name) {
    return;
  }
  const { path, fullPath, name } = to;

  const splitFullPath = fullPath?.split('?');
  const queryString = fullPath && splitFullPath?.length > 1 ? splitFullPath.at(-1) : '';

  const apiParams: UserAccessMenuRequest = {
    url: path,
    queryString,
    menu: getMenuName(name),
  };
  try {
    await accessMenuUserV6ControllerAxios({
      eventRequest: apiParams,
    });
  } catch (e) {
    console.log(e);
  }
};
