import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { FetchParam, StatData } from '@/postgresql/store/singleView/dbStats';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { MetricResponse } from '@/openapi/data/model';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { MetricFailError, checkValidMetric } from '@/database/utils/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface State {
  stats: StatData;
  errorStatusText: string;
}

export const dbStats: Module<State, RootState> = {
  namespaced: true,
  state: {
    stats: {},
    errorStatusText: '',
  },
  mutations: {
    setAllStat: (state: State, data: MetricResponse[]) => {
      const tempObj = {};

      data.forEach((item) => {
        tempObj[item.dataDefinition?.dataId ?? ''] = transformMetricChartValues(
          item.metrics?.[0].values,
        );
      });

      state.stats = tempObj;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchDBStats({ commit }, { instanceId, statNames }: FetchParam) {
      const frameName = FRAME_NAMES.DB_METRIC.METRIC;
      try {
        const deduplicationNames: string[] = [...new Set(statNames)];

        const metricRequestCalls = deduplicationNames.map((name) =>
          getMetricData({
            metricV7Requests: [
              {
                aggregationType: 'byTarget',
                period: 'p3m',
                interval: 'I5s',
                category: 'mysql',
                dataId: name,
                summaryType: 'current',
                targetIds: [instanceId],
              },
            ],
            frameName,
            isTimeout: true,
          }),
        );

        const result = await Promise.allSettled(metricRequestCalls);
        const data: MetricResponse[] = [];
        result.forEach((res) => {
          if (res.status === 'fulfilled') {
            const { data: resData } = res.value;
            checkValidMetric(resData?.data ?? []);
            data.push(resData?.data?.[0] ?? {});
          }
        });

        commit('setAllStat', data);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getAllStatData: (state: State) => state.stats,
    getErrorStatusText: (state: State) => state.errorStatusText,
  },
};
