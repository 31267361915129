import { getMetricsDataV7ControllerAxios } from '@/openapi/data/api/data-v7-controller-api';
import { getHashedString } from '@/common/api/utils/utils';

export const getMetricData = async (
  request: Parameters<typeof getMetricsDataV7ControllerAxios>[0],
) => {
  const { ...exceptHashedRequest } = request;
  const hashed =
    getHashedString<Omit<Parameters<typeof getMetricsDataV7ControllerAxios>[0], 'hashedRequest'>>(
      exceptHashedRequest,
    );
  return getMetricsDataV7ControllerAxios({ ...request, hashedRequest: hashed });
};
