import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { CreateFolderRequest, FolderInstanceV2Response, UpdateFolderRequest } from '../model';

// 테넌트 내 폴더 삭제
const deleteV2FoldersByFolderIdAxios = ({
  folderId,
}: {
  folderId: string;
}): PromiseAxiosResponse<string> => axiosInstance.delete(`/api/v2/folders/${folderId}`);

// 테넌트 내 모든 폴더 조회
const getV2FoldersAxios = (): PromiseAxiosResponse<FolderInstanceV2Response> =>
  axiosInstance.get('/api/v2/folders');

// 테넌트 내 폴더 조회
const getV2FoldersByFolderIdAxios = ({
  folderId,
}: {
  folderId: string;
}): PromiseAxiosResponse<FolderInstanceV2Response> =>
  axiosInstance.get(`/api/v2/folders/${folderId}`);

// 테넌트 내 폴더 수정
const patchV2FoldersByFolderIdAxios = ({
  folderId,
  request,
}: {
  folderId: string;
  request: UpdateFolderRequest;
}): PromiseAxiosResponse<FolderInstanceV2Response> =>
  axiosInstance.patch(`/api/v2/folders/${folderId}`, request);

// 테넌트 내 폴더 생성
const postV2FoldersAxios = ({
  request,
}: {
  request: CreateFolderRequest;
}): PromiseAxiosResponse<FolderInstanceV2Response> =>
  axiosInstance.post('/api/v2/folders', request);

export {
  deleteV2FoldersByFolderIdAxios,
  getV2FoldersAxios,
  getV2FoldersByFolderIdAxios,
  patchV2FoldersByFolderIdAxios,
  postV2FoldersAxios,
};
