import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getSlowQueryMysqlV7ControllerAxios } from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { SlowItemV7 } from '@/openapi/mysqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface SlowQueryState {
  slowQuery: SlowItemV7[];
  queryTime: {
    fromTime: string;
    toTime: string;
  };
  errorStatusText: string;
}

export const slowQuery: Module<SlowQueryState, RootState> = {
  namespaced: true,
  state: {
    slowQuery: [],
    queryTime: {
      fromTime: '',
      toTime: '',
    },
    errorStatusText: '',
  },
  mutations: {
    setSlowQuery: (state: SlowQueryState, data: SlowItemV7[]) => {
      state.slowQuery = data;
    },
    setSlowQueryQueryTime: (
      state: SlowQueryState,
      { fromTime, toTime }: { fromTime: string; toTime: string },
    ) => {
      state.queryTime.fromTime = fromTime;
      state.queryTime.toTime = toTime;
    },
    setErrorStatusText: (state: SlowQueryState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchSlowQuery({ commit }, instanceId) {
      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.SLOW_QUERY;
      try {
        const { data } = await getSlowQueryMysqlV7ControllerAxios({
          instanceId,
          frameName,
        });

        commit('setSlowQuery', data.data);
        commit('setSlowQueryQueryTime', data.query);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getSlowQuery: (state: SlowQueryState) => state.slowQuery,
    getSlowQueryQueryTime: (state: SlowQueryState) => state.queryTime,
    getErrorStatusText: (state: SlowQueryState) => state.errorStatusText,
  },
};
