<script lang="ts" setup generic="T extends number[]">
import {
  BasePaginationProps,
  setup,
} from '@/common/components/molecules/basePagination/basePagination.setup';

const props = withDefaults(defineProps<BasePaginationProps<T>>(), {
  current: 1,
  total: 0,
  visiblePage: 5,
  align: 'right',
  showSetting: false,
  showPageInfo: false,
});

const emit = defineEmits<{
  (e: 'update:current', value: number): void;
  (e: 'update:per-data', value: T[number]): void;
}>();

const { selectedPerData, selectorItems, currentPage, currentRowRange } = setup(props, emit);
</script>

<template>
  <div :class="['base-pagination', props.align]">
    <div
      v-if="props.showSetting"
      class="base-pagination__setting"
    >
      Results per page
      <ev-select
        v-model="selectedPerData"
        :items="selectorItems"
        class="size-small"
      />
    </div>
    <div
      v-if="props.showPageInfo"
      class="base-pagination__page-info"
    >
      {{ `${currentRowRange} of ${props.total}` }}
    </div>
    <ev-pagination
      v-model="currentPage"
      :total="props.total"
      :per-page="selectedPerData"
      :visible-page="props.visiblePage"
    />
  </div>
</template>

<style lang="scss">
.base-pagination {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 24px;
  color: var(--pagination-base-color);

  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }

  &__setting {
    display: flex;
    gap: 8px;
    align-items: center;

    .ev-select {
      width: 56px !important;
    }
  }

  &__page-info {
    min-width: 95px;
    margin-left: 4px;
    font-size: 11px;
    color: var(--pagination-base-color);
    text-align: right;
  }

  .pagination {
    margin-left: 12px;
  }

  :first-child {
    margin-left: 0;
  }
}
</style>
