import { RouteRecordRaw } from 'vue-router';
import { requireAuth, requireInstances, setDayjsTimezone } from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';

const PREFIX_DATABASE = 'database_';

const databaseMenu: RouteRecordRaw[] = [
  {
    path: '/database/',
    name: `${PREFIX_DATABASE}Database`,
    component: RtmViewport,
    beforeEnter: [requireAuth, requireInstances, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE,
    },
    children: [
      {
        path: 'instance',
        name: `${PREFIX_DATABASE}Instance`,
        component: () =>
          import(/* webpackChunkName: "database-list-view" */ '../views/InstanceView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
        },
      },
    ],
  },
];

const getDatabaseNaviMenu = () => {
  const filteredDatabaseMenu = extractAccessibleRoutes(databaseMenu);

  return [...filteredDatabaseMenu];
};

export { databaseMenu, getDatabaseNaviMenu };
