import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';

export const checkScoreboardStyle2ChartData =
  ({ diffUnitError }: { diffUnitError: string }) =>
  (
    rawData: CurrentRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    let firstStatUnit = '';

    const { currentData } = rawData;

    return chartDataStatus.reduce<WidgetChartDataStatusInfo[]>(
      (dataStatusList, apiChartDataStatus, index) => {
        if (index === 0) {
          firstStatUnit = currentData.at(index)?.at(0)?.unit ?? '';
          if (apiChartDataStatus.status === 'fail') {
            dataStatusList.push(apiChartDataStatus);
          }
        } else if (index === 1) {
          const secondStatUnit = currentData[index][0]?.unit ?? '';
          if (firstStatUnit != null && secondStatUnit != null && firstStatUnit !== secondStatUnit) {
            dataStatusList.push({
              status: 'fail',
              type: 'custom',
              chartDataId: apiChartDataStatus.chartDataId,
              reason: diffUnitError,
            });
          }
        }
        return dataStatusList;
      },
      [],
    );
  };
