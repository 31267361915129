import { computed } from 'vue';
import { ItemInfo } from '@/common/components/organisms/alertListDetail/alertListDetail.types';
import { useInternational } from '@/common/locale';

export interface Props {
  selectedId: string;
  list: ItemInfo[];
  title?: string;
}
export interface Emit {
  (e: 'update:selected-id', value: number): void;
  (e: 'click-item', value: ItemInfo): void;
}

export const setup = (props, emit) => {
  const { t } = useInternational();

  const selectedId = computed<string>({
    get: () => props.selectedId,
    set: (val: string) => emit('update:selected-id', val),
  });

  const onClickItem = (clickedItem: ItemInfo) => {
    selectedId.value = clickedItem.id;
    emit('click-item', clickedItem);
  };

  return {
    selectedId,
    onClickItem,
    t,
  };
};
