/* eslint-disable no-redeclare */
import { useInternational } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { WidgetModeType } from '@/dashboard/utils/types';
import { ComputedRef, watch } from 'vue';
import { ChartDataType, ChartRawData, ChartType } from '../widgets.types';
import {
  isMetricRawData,
  isCurrentRawData,
  isTableRawData,
  isScatterRawData,
  isCurrentChartType,
  isMetricChartType,
  isScatterChartType,
  isTableChartType,
} from '../widgets.utils';
import { checkChartDataStatus } from './checkChartDataStatus';

export function useChartDataStatus(
  chartDataType: ChartDataType,
  chartType: ChartType,
  rawData: ComputedRef<ChartRawData>,
  chartDataStatus: ComputedRef<WidgetChartDataStatusInfo[]>,
  mode: ComputedRef<WidgetModeType | undefined>,
  emit: { (event: 'changeChartDataStatus', statusList: WidgetChartDataStatusInfo[]): void },
) {
  const { t } = useInternational();

  watch([rawData, chartDataStatus], ([newRawData, newChartDataStatus]) => {
    if (mode.value === 'preview') {
      let chartDataStatusList: WidgetChartDataStatusInfo[] = [];

      if (
        chartDataType === 'metrics' &&
        isMetricChartType(chartType) &&
        isMetricRawData(newRawData)
      ) {
        chartDataStatusList = checkChartDataStatus('metrics', t)(chartType)(
          newRawData,
          newChartDataStatus,
        );
      } else if (
        chartDataType === 'current' &&
        isCurrentChartType(chartType) &&
        isCurrentRawData(newRawData)
      ) {
        chartDataStatusList = checkChartDataStatus('current', t)(chartType)(
          newRawData,
          newChartDataStatus,
        );
      } else if (
        chartDataType === 'table' &&
        isTableChartType(chartType) &&
        isTableRawData(newRawData)
      ) {
        chartDataStatusList = checkChartDataStatus('table', t)(chartType)(
          newRawData,
          newChartDataStatus,
        );
      } else if (
        chartDataType === 'scatter' &&
        isScatterChartType(chartType) &&
        isScatterRawData(newRawData)
      ) {
        chartDataStatusList = checkChartDataStatus('scatter', t)(chartType)(
          newRawData,
          newChartDataStatus,
        );
      }
      emit('changeChartDataStatus', chartDataStatusList);
    }
  });
}
