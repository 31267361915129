import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export type InstanceId = string;
export interface CurrentRouteInfo {
  dbType: string;
  view: string;
}

export const useInstanceIds = () => {
  const store: Store<RootState> = useStore();
  const route = useRoute();
  const currentRouteInfo = computed<CurrentRouteInfo>(() => {
    if (!route?.name) {
      return { dbType: '', view: '' };
    }
    const dividedName = typeof route.name === 'string' ? route.name.split('_') : [];
    return {
      dbType: dividedName[0] ?? '',
      view: dividedName[1] ? dividedName[1].replaceAll(' ', '') : '',
    };
  });
  const getInstanceIds = (): InstanceId[] => {
    const { dbType, view } = currentRouteInfo.value;
    if (!dbType || !view) {
      return [];
    }
    if (view === 'MultiView') {
      return store.getters[`${dbType}${view}Env/getInstanceIds`];
    }

    const instanceId = store.getters[`${dbType}${view}Env/getSelectedInstanceId`];
    return instanceId ? [instanceId] : [];
  };

  return {
    currentRouteInfo,
    getInstanceIds,
  };
};
