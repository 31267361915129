/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getTopWaitEventPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { TopSessionEventItem } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface TopEventState {
  topEvent: TopSessionEventItem[];
  errorStatusText: string;
}

export const topEvent: Module<TopEventState, RootState> = {
  namespaced: true,
  state: {
    topEvent: [],
    errorStatusText: '',
  },
  mutations: {
    setTopEvent(state: TopEventState, data: TopSessionEventItem[]) {
      state.topEvent = data;
    },
    setErrorStatusText: (state: TopEventState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchTopEvent({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.STATICS_EVENTS_TOP_EVENT;
      try {
        const { data } = await getTopWaitEventPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setTopEvent', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopEvent: (state: TopEventState) => state.topEvent,
    getEventName: (state: TopEventState) => state.topEvent.map((v) => v.eventName).reverse(),
    getCount: (state: TopEventState) => setEvBarChartData(state.topEvent.map((v) => v.count)),
    getErrorStatusText: (state: TopEventState) => state.errorStatusText,
  },
};
