import {
  FilterDropdownInfoCategories,
  FilterToken,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { computed } from 'vue';
import { TOKEN_STATUS } from '@/common/components/molecules/filterSearch/filterSearch.define';

type EditableTokenPartsCategory = 'operator' | 'value';

export interface Props {
  tokenData: FilterToken;
  isEditingToken: boolean;
  isLastToken: boolean;
  isActiveAddMultiValueBtn: boolean;
  dropdownListCategory: FilterDropdownInfoCategories;
  isShowDropdownList: boolean;
}

export interface Emit {
  (e: 'input', event: InputEvent): void;
  (e: 'key-enter', event: KeyboardEvent): void;
  (e: 'key-ctrl-enter'): void;
  (e: 'key-arrow', value: { event: KeyboardEvent; direction: 'up' | 'down' }): void;
  (e: 'key-esc'): void;
  (e: 'remove'): void;
  (
    e: 'click-parts',
    value: { targetToken: FilterToken; category: EditableTokenPartsCategory },
  ): void;
  (e: 'click-add-multi-value', value: FilterToken): void;
}

export const setup = (props: Props, emit: Emit) => {
  const token = computed<FilterToken>(() => props.tokenData);

  const isEditingTokenParts = (category: FilterDropdownInfoCategories): boolean => {
    return (
      props.isEditingToken && props.dropdownListCategory === category && props.isShowDropdownList
    );
  };

  const onInput = (e: InputEvent) => {
    emit('input', e);
  };

  const onKeyEnter = (e: KeyboardEvent) => {
    emit('key-enter', e);
  };

  const onKeyCtrlEnter = () => {
    emit('key-ctrl-enter');
  };

  const onKeyUpDown = (e: KeyboardEvent, direction: 'up' | 'down') => {
    emit('key-arrow', {
      event: e,
      direction,
    });
  };

  const onKeyEsc = () => {
    emit('key-esc');
  };

  const onClickTokenParts = (category: EditableTokenPartsCategory) => {
    emit('click-parts', {
      targetToken: token.value,
      category,
    });
  };

  const onClickAddMultiValue = () => {
    emit('click-add-multi-value', token.value);
  };

  const isInvalidToken = (): boolean => token.value.status !== TOKEN_STATUS.VALUE_DONE;

  const isWritingToken = (): boolean =>
    token.value.status !== TOKEN_STATUS.VALUE_DONE && props.isLastToken && props.isShowDropdownList;

  const onClickRemoveToken = () => {
    emit('remove');
  };

  return {
    token,
    isEditingTokenParts,
    isInvalidToken,
    isWritingToken,
    onInput,
    onKeyEnter,
    onKeyUpDown,
    onKeyEsc,
    onClickTokenParts,
    onKeyCtrlEnter,
    onClickAddMultiValue,
    onClickRemoveToken,
  };
};
