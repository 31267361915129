import { ref } from 'vue';

type UseAsyncPromise = Promise<any> | ((args: Record<any, any>) => Promise<any>);
interface UseAsyncOptions {
  immediate?: boolean;
  initialData?: any;
  successCallback?: (data: any) => void;
  errorCallback?: (error: any) => void;
}

const useAsync = (promise: UseAsyncPromise, options?: UseAsyncOptions) => {
  const { immediate = false, initialData = null, successCallback, errorCallback } = options ?? {};
  const isLoading = ref(false);
  const state = ref(initialData);
  const error = ref();

  const execute = async (args?) => {
    const rawPromise = typeof promise === 'function' ? promise({ ...args }) : promise;

    try {
      isLoading.value = true;
      state.value = await rawPromise;
      error.value = {};

      if (typeof successCallback === 'function') {
        successCallback(state.value);
      }
    } catch (e) {
      error.value = e;

      if (typeof errorCallback === 'function') {
        errorCallback(e);
      }
    } finally {
      isLoading.value = false;
    }

    return state.value;
  };

  if (immediate) {
    execute();
  }

  return {
    state,
    isLoading,
    error,
    execute,
  };
};

export { UseAsyncPromise, UseAsyncOptions, useAsync };
