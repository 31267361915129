import { isEqual } from 'lodash-es';
import { DirectiveBinding, ObjectDirective } from 'vue';

const getEventType = ({ click, mouseup }: { click?: boolean; mouseup?: boolean }) => {
  if (click) return 'click';
  if (mouseup) return 'mouseup';
  return 'mousedown';
};

const onUnMounted = (el: any, binding: DirectiveBinding) => {
  const componentEl = el;
  const eventType = getEventType(binding.modifiers);
  document.removeEventListener(eventType, componentEl.vueClickOutside);
  componentEl.vueClickOutside = null;
};
const onMounted = (el: any, binding: DirectiveBinding) => {
  const componentEl = el;
  const callback = binding.value;
  const eventType = getEventType(binding.modifiers);

  const handler = (e) => {
    if (
      callback &&
      componentEl &&
      typeof callback === 'function' &&
      componentEl !== e.target &&
      !componentEl.contains(e.target)
    ) {
      callback(e);
    }
  };
  componentEl.vueClickOutside = handler;
  document.addEventListener(eventType, handler);
};
const onUpdated = (el: any, binding: DirectiveBinding) => {
  if (isEqual(binding.value, binding.oldValue)) {
    return;
  }
  onMounted(el, binding);
};

const clickoutside: ObjectDirective = {
  mounted: onMounted,
  updated: onUpdated,
  unmounted: onUnMounted,
};

export { clickoutside };
