import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { RoleId, Supervisors } from '@/common/auth/auth.defines';

const PREFIX_ALERT = 'alert_';

const alertMenu: RouteRecordRaw[] = [
  {
    path: '/alert/',
    name: `${PREFIX_ALERT}Alert`,
    component: RtmViewport,
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT,
    },
    children: [
      {
        path: 'list',
        name: `${PREFIX_ALERT}Alert List`,
        component: () =>
          import(/* webpackChunkName: "alert-view" */ '@/alert/views/alertView/AlertView.vue'),
        beforeEnter: [requireAuth, setDayjsTimezone],
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST,
        },
      },
      {
        path: 'notification-history',
        name: `${PREFIX_ALERT}Notification History`,
        component: () =>
          import(
            /* webpackChunkName: "alert-view" */ '@/alert/views/notificationHistoryView/NotificationHistoryView.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone],
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_NOTIFICATION_HISTORY,
          roles: Supervisors,
        },
      },
    ],
  },
];

const getAlertNaviMenu = (roleId: RoleId) => {
  const filteredAlertMenu = extractAccessibleRoutes(alertMenu, roleId);
  return [...filteredAlertMenu];
};

export { alertMenu, getAlertNaviMenu };
