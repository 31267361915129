<template>
  <base-menu v-if="props.useBaseMenu" />
  <div class="main-viewport">
    <navigation-header
      v-if="props.useNavigationHeader"
      use-path
    />
    <router-view v-slot="{ Component }">
      <keep-alive exclude="QueryManagerHistory">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <ev-loading v-model="isLoading" />
  <slide-detail v-model:is-visible="isSlideDetailOpened" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Store } from 'vuex';
import { storeToRefs } from 'pinia';
import { RootState, useStore } from '@/common/store';
import NavigationHeader from '@/common/components/molecules/navigationHeader/NavigationHeader.vue';
import SlideDetail from '@/common/components/templates/slideDetail/SlideDetail.vue';
import { usePaSlideDetailStore } from '@/database/stores/pa-slide-detail';

interface Props {
  useNavigationHeader?: boolean;
  useBaseMenu?: boolean;
}

const store: Store<RootState> = useStore();
const props = withDefaults(defineProps<Props>(), {
  useNavigationHeader: true,
  useBaseMenu: true,
});
const isLoading = computed(() => store.getters.getIsLoading);
const { isSlideDetailOpened } = storeToRefs(usePaSlideDetailStore());
</script>
