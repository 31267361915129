import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getOverviewMysqlV7ControllerAxios } from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { ConnectionCountItemV7 } from '@/openapi/mysqlV7/model/connection-count-item-v7';
import { ConnectionsItemV7 } from '@/openapi/mysqlV7/model/connections-item-v7';
import { HardwareItemV7 } from '@/openapi/mysqlV7/model/hardware-item-v7';
import { Query } from '@/openapi/mysqlV7/model/query';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface Connections extends Required<ConnectionsItemV7> {
  collectTime: string;
}

interface ConnectionCount extends Required<ConnectionCountItemV7> {
  collectTime: string;
}

export interface OverviewState {
  hardware: HardwareItemV7[];
  connections: Connections[];
  connectionCount: ConnectionCount[];
  queryTime: Query[] | null;
  ratio: number;
  errorStatusText: string;
}
export const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state: {
    connections: [
      {
        maxConnections: 0,
        currentConnections: 0,
        collectTime: '',
      },
    ],
    connectionCount: [
      {
        collectTime: '',
        activeCount: 0,
        idleCount: 0,
        longCount: 0,
        lockCount: 0,
        activePercent: 0,
        idlePercent: 0,
        longPercent: 0,
        lockPercent: 0,
        nonePercent: 0,
      },
    ],
    queryTime: [
      {
        fromTime: '',
        toTime: '',
      },
    ],
    hardware: [
      {
        cpu: 0,
        memory: 0,
        collectTime: '',
      },
    ],
    ratio: 0,
    errorStatusText: '',
  },
  mutations: {
    setConnections(state: OverviewState, data: Connections[]) {
      state.connections = data;
    },
    setConnectionsRatio(state: OverviewState, data: Connections) {
      state.ratio =
        Math.floor(
          data.maxConnections ? ((data.currentConnections ?? 0) / data.maxConnections) * 100 : 0,
        ) || 0;
    },
    setHardware(state: OverviewState, data: HardwareItemV7[]) {
      state.hardware = data;
    },
    setConnectionCount(state: OverviewState, data: ConnectionCount[]) {
      state.connectionCount = data;
    },
    setQueryTime(state: OverviewState, data: Query[]) {
      state.queryTime = data;
    },
    setErrorStatusText: (state: OverviewState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchOverview({ commit }, instanceId) {
      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.OVERVIEW;
      try {
        const {
          data: { hardware, connectionCount, connections },
        } = await getOverviewMysqlV7ControllerAxios({
          instanceId,
          frameName,
        });

        commit(
          'setHardware',
          hardware?.data?.length ? hardware.data : [{ cpu: 0, memory: 0, collectTime: '' }],
        );
        commit(
          'setConnectionCount',
          connectionCount?.data?.length
            ? connectionCount.data
            : [
                {
                  collectTime: '',
                  activeCount: 0,
                  idleCount: 0,
                  longCount: 0,
                  lockCount: 0,
                  activePercent: 0,
                  idlePercent: 0,
                  longPercent: 0,
                  lockPercent: 0,
                  nonePercent: 0,
                },
              ],
        );
        commit('setQueryTime', connectionCount?.query);
        commit(
          'setConnections',
          connections?.data?.length
            ? connections.data
            : [{ maxConnections: 0, currentConnections: 0, collectTime: '' }],
        );
        commit(
          'setConnectionsRatio',
          connections?.data?.length
            ? connections.data[0]
            : { maxConnections: 0, currentConnections: 0, collectTime: '' },
        );

        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getConnections: (state: OverviewState) => state.connections,
    getConnectionsRatio: (state: OverviewState) => state.ratio,
    getHardware: (state: OverviewState) => state.hardware,
    getConnectionCount: (state: OverviewState) => state.connectionCount,
    getQueryTime: (state: OverviewState) => state.queryTime,
    getErrorStatusText: (state: OverviewState) => state.errorStatusText,
  },
};
