/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  CurrentV7Request,
  DataCurrentResponse,
  DataMetricResponse,
  DataObjectResponse,
  DataScatterDetailResponse,
  DataScatterResponse,
  DataScatterTableResponse,
  DataTableResponse,
  DataTopologyResponse,
  DatabaseServiceMetricV7Request,
  MetricV7Request,
  ObjectV7Request,
  ScatterDetailRequest,
  ScatterV7Request,
  ServiceDataMetricResponse,
  TableV7Request,
  TopologyV7Request,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getCurrentDataV7ControllerAxios = ({
  currentV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  currentV7Requests: Array<CurrentV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DataCurrentResponse> => {
  const localVarPath = '/api/v7/data/current';
  const httpMethod = 'post';

  const data: any = currentV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMetricsDataV7ControllerAxios = ({
  metricV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  metricV7Requests: Array<MetricV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataMetricResponse> => {
  const localVarPath = `/api/v7/data/metrics${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = metricV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectDataV7ControllerAxios = ({
  objectV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  objectV7Requests: Array<ObjectV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DataObjectResponse> => {
  const localVarPath = '/api/v7/data/object';
  const httpMethod = 'post';

  const data: any = objectV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getScatterDataV7ControllerAxios = ({
  scatterV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  scatterV7Requests: Array<ScatterV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataScatterResponse> => {
  const localVarPath = `/api/v7/data/scatter${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = scatterV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getScatterDetailDataV7ControllerAxios = ({
  requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  requests: Array<ScatterDetailRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataScatterDetailResponse> => {
  const localVarPath = `/api/v7/data/scatter-detail${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getScatterListDataV7ControllerAxios = ({
  requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  requests: Array<ScatterDetailRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataScatterTableResponse> => {
  const localVarPath = `/api/v7/data/scatter-list${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getServiceMetricsDataV7ControllerAxios = ({
  metricV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  metricV7Requests: Array<DatabaseServiceMetricV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ServiceDataMetricResponse> => {
  const localVarPath = '/api/v7/data/service-metrics';
  const httpMethod = 'post';

  const data: any = metricV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTableDataV7ControllerAxios = ({
  tableV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  tableV7Requests: Array<TableV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataTableResponse> => {
  const localVarPath = `/api/v7/data/table${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = tableV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopologyDataV7ControllerAxios = ({
  topologyV7Requests,
  signal,
  frameName,
  isTimeout,
  responseType,
  hashedRequest,
}: {
  topologyV7Requests: Array<TopologyV7Request>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
  hashedRequest?: string;
}): PromiseAxiosResponse<DataTopologyResponse> => {
  const localVarPath = `/api/v7/data/topology${hashedRequest ? `?${hashedRequest}` : ''}`;
  const httpMethod = 'post';

  const data: any = topologyV7Requests;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getCurrentDataV7ControllerAxios,
  getMetricsDataV7ControllerAxios,
  getObjectDataV7ControllerAxios,
  getScatterDataV7ControllerAxios,
  getScatterDetailDataV7ControllerAxios,
  getScatterListDataV7ControllerAxios,
  getServiceMetricsDataV7ControllerAxios,
  getTableDataV7ControllerAxios,
  getTopologyDataV7ControllerAxios,
};
