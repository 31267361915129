<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  isDarkMode: boolean;
}
interface Emits {
  (event: 'update:isDarkMode', newIsDarkMode: boolean): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const isDarkMode = computed({
  get() {
    return props.isDarkMode;
  },
  set(newIsDarkMode) {
    emit('update:isDarkMode', newIsDarkMode);
  },
});
</script>

<template>
  <ev-toggle v-model="isDarkMode" />
</template>

<style scoped lang="scss">
.ev-toggle {
  border-color: var(--lnb-toggle-bg-color) !important;
  background-color: var(--lnb-toggle-bg-color) !important;
  &:hover {
    border-color: var(--lnb-toggle-hover-bg-color) !important;
    background-color: var(--lnb-toggle-hover-bg-color) !important;
  }
  &::before {
    background-color: var(--lnb-toggle-circle-bg-color);
  }
  &::after {
    mask-image: url('@/common/assets/sparrow/icon-light-theme.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: var(--lnb-toggle-circle-icon-color) !important;
  }

  &.checked::after {
    mask-image: url('@/common/assets/sparrow/icon-dark-theme.svg');
  }
  &.checked:not(.readonly, .disabled) {
    border-color: var(--lnb-toggle-bg-color) !important;
    background-color: var(--lnb-toggle-bg-color) !important;

    &:hover {
      border-color: var(--lnb-toggle-hover-bg-color) !important;
      background-color: var(--lnb-toggle-hover-bg-color) !important;
    }
  }
}
</style>
