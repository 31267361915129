import { useInternational } from '@/common/locale';
import { MenuInfo } from '../types';

export interface Props {
  info: MenuInfo;
  selected?: boolean;
}

export interface Emit {
  (e: 'hover', evt: MouseEvent): void;
  (e: 'click'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const onHoverButton = (e: MouseEvent) => {
    emit('hover', e);
  };

  const onClickButton = () => {
    emit('click');
  };

  return { t, onHoverButton, onClickButton };
};
