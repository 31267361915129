import { ChartThemeType } from '@/dashboard/utils/types';

export const SAFE_DEFAULT_CHART_THEME = 'BLUE'; // 팔레트 개선 이전에 설정해둔 theme(BLOSSOM, ...)일 경우 COMPANY로 변경

export const COLOR_LIST_BY_THEME: Record<'dark' | 'light', Record<ChartThemeType, string[]>> = {
  dark: {
    BLUE: [
      '#2563EB',
      '#60A5FA',
      '#0EA5E9',
      '#3B82F6',
      '#0284C7',
      '#22D3EE',
      '#1D4ED8',
      '#38BDF8',
      '#3F65D0',
      '#06B6D4',
      '#93C5FD',
      '#0369A1',
      '#88BDFF',
      '#1E40AF',
      '#7DD3FC',
      '#0C4A6E',
      '#0891B2',
      '#4AB5DD',
      '#0E7490',
      '#10AFEE',
      '#155E75',
      '#AAD0DD',
      '#5571B7',
      '#3A639B',
    ],
    GREEN: [
      '#10B981',
      '#059669',
      '#22C55E',
      '#047857',
      '#16A34A',
      '#84CC16',
      '#34D399',
      '#166534',
      '#4ADE80',
      '#6EE7B7',
      '#065F46',
      '#A3E635',
      '#1C7872',
      '#4D7C0F',
      '#064E3B',
      '#3F6212',
      '#65A30D',
      '#08B256',
      '#908B0E',
      '#365314',
      '#157524',
      '#66900E',
      '#0E9079',
      '#4F900E',
    ],
    MONO: [
      '#64748B',
      '#94A3B8',
      '#A8A29E',
      '#78716C',
      '#5F7EAA',
      '#B5BFCC',
      '#6B82A2',
      '#646496',
      '#7FABC3',
      '#A1A1AA',
      '#D6D3D1',
      '#8B8B9A',
      '#A8A29E',
      '#BDC5D0',
      '#968E80',
      '#9EA9B6',
      '#7A89A5',
      '#83584E',
      '#6A6A75',
      '#827A65',
      '#57534E',
      '#78716C',
      '#71717A',
      '#815C49',
    ],
    PURPLE: [
      '#8B5CF6',
      '#7C3AED',
      '#A78BFA',
      '#A168FF',
      '#6D28D9',
      '#A855F7',
      '#C4B5FD',
      '#7152D7',
      '#C5ACFD',
      '#A25AE1',
      '#DAC8FE',
      '#7A507D',
      '#BC8BE7',
      '#7147B4',
      '#CEC7F2',
      '#6B21A8',
      '#B277E1',
      '#A21CAF',
      '#C084FC',
      '#86198F',
      '#7E22CE',
      '#8F3D94',
      '#9333EA',
      '#843889',
    ],
    BROWN: [
      '#CA8A04',
      '#FDBA74',
      '#A16207',
      '#D97706',
      '#B45309',
      '#FD9D74',
      '#92400E',
      '#D98506',
      '#944416',
      '#CA6304',
      '#856D5F',
      '#FD9D74',
      '#936919',
      '#FEB9AA',
      '#A84F19',
      '#854D0E',
      '#F97316',
      '#98521D',
      '#AE4E39',
      '#EA580C',
      '#9A3412',
      '#BF6D51',
      '#C2410C',
      '#9A3412',
    ],
    MAXGAUGE: [
      '#A5CAD6',
      '#3A639B',
      '#25556B',
      '#82A3B2',
      '#4C7867',
      '#97AB9F',
      '#AAD0DD',
      '#A8A29E',
      '#A6CFFF',
      '#968E80',
      '#908B0E',
      '#66900E',
      '#0284C7',
      '#0369A1',
      '#0891B2',
      '#9C76FF',
      '#6C34FF',
      '#4E99D1',
      '#30A6FC',
      '#4346D3',
      '#9BD9FF',
      '#A78BFA',
      '#CEC7F2',
      '#8B8B9A',
    ],
    PUBLIC: [
      '#0044CC',
      '#FF6600',
      '#01AAFF',
      '#53B200',
      '#6701FF',
      '#FF66B8',
      '#B2CCFF',
      '#B24700',
      '#78FF01',
      '#A366FF',
      '#B2005F',
      '#FFD1B2',
      '#FFB2DB',
      '#01FFBB',
      '#8FB200',
      '#C766FF',
      '#00B283',
      '#FFEE01',
      '#FFAA00',
      '#B2F5FF',
      '#CCFF01',
      '#EE01FF',
      '#B2A700',
      '#FF6666',
    ],
    FINANCE: [
      '#0156FF',
      '#FFEE01',
      '#78FF01',
      '#66CCFF',
      '#FFAA00',
      '#FF0188',
      '#B2CCFF',
      '#FFFAB2',
      '#53B200',
      '#01FFBB',
      '#B27700',
      '#FFB2DB',
      '#5100CC',
      '#FF6666',
      '#B2FFEB',
      '#A700B2',
      '#F0FFB2',
      '#00B283',
      '#6699FF',
      '#EE01FF',
      '#0044CC',
      '#B2005F',
      '#D1B2FF',
      '#FFD1B2',
    ],
    COMPANY: [
      '#B20000',
      '#0044CC',
      '#FFAA00',
      '#53B200',
      '#FFA366',
      '#01AAFF',
      '#FFB2B2',
      '#6699FF',
      '#FF6600',
      '#B24700',
      '#FFF566',
      '#FF0188',
      '#B2A700',
      '#01FFBB',
      '#5100CC',
      '#B2E5FF',
      '#FAB2FF',
      '#00B283',
      '#0156FF',
      '#EE01FF',
      '#FFEE01',
      '#ADFF66',
      '#66EBFF',
      '#FFAA00',
    ],
    EXEM: [
      '#0156FF',
      '#FFEE01',
      '#FF0000',
      '#FFA366',
      '#A201FF',
      '#66EBFF',
      '#009BB2',
      '#B2CCFF',
      '#B2A700',
      '#B20000',
      '#FFD1B2',
      '#E3B2FF',
      '#CCFF01',
      '#78FF01',
      '#FFFAB2',
      '#EE01FF',
      '#D1B2FF',
      '#5100CC',
      '#FAB2FF',
      '#53B200',
      '#0044CC',
      '#00B283',
      '#D6FFB2',
      '#01AAFF',
    ],
  },
  light: {
    BLUE: [
      '#2563EB',
      '#60A5FA',
      '#0EA5E9',
      '#1E3A8A',
      '#0284C7',
      '#1E40AF',
      '#93C5FD',
      '#38BDF8',
      '#0369A1',
      '#7DD3FC',
      '#3B82F6',
      '#BFDBFE',
      '#0C4A6E',
      '#06B6D4',
      '#1D4ED8',
      '#22D3EE',
      '#0891B2',
      '#164E63',
      '#0E7490',
      '#083344',
      '#155E75',
      '#AAD0DD',
      '#25556B',
      '#3A639B',
    ],
    GREEN: [
      '#10B981',
      '#059669',
      '#22C55E',
      '#166534',
      '#047857',
      '#34D399',
      '#16A34A',
      '#4ADE80',
      '#84CC16',
      '#6EE7B7',
      '#065F46',
      '#A3E635',
      '#134E4A',
      '#4D7C0F',
      '#064E3B',
      '#3F6212',
      '#65A30D',
      '#08B256',
      '#908B0E',
      '#365314',
      '#157524',
      '#66900E',
      '#0E9079',
      '#4F900E',
    ],
    MONO: [
      '#64748B',
      '#94A3B8',
      '#475569',
      '#A1A1AA',
      '#71717A',
      '#52525B',
      '#A8A29E',
      '#78716C',
      '#334155',
      '#B5BFCC',
      '#57534E',
      '#D6D3D1',
      '#A8A29E',
      '#292524',
      '#78716C',
      '#44403C',
      '#0C0A09',
      '#6E655A',
      '#968E80',
      '#BDC5D0',
      '#475D69',
      '#9EA9B6',
      '#8B8B9A',
      '#6A6A75',
    ],
    PURPLE: [
      '#5B21B6',
      '#8B5CF6',
      '#A78BFA',
      '#7C3AED',
      '#A855F7',
      '#6D28D9',
      '#6B21A8',
      '#C4B5FD',
      '#4C1D95',
      '#9333EA',
      '#CEC7F2',
      '#C084FC',
      '#581C87',
      '#A21CAF',
      '#7152D7',
      '#86198F',
      '#7E22CE',
      '#701A75',
      '#C5ACFD',
      '#3B0764',
      '#DAC8FE',
      '#A25AE1',
      '#8F3D94',
      '#BC8BE7',
    ],
    BROWN: [
      '#854D0E',
      '#A16207',
      '#D97706',
      '#92400E',
      '#B45309',
      '#78350F',
      '#FDBA74',
      '#CA8A04',
      '#715112',
      '#CA6304',
      '#D98506',
      '#A84F19',
      '#856D5F',
      '#98521D',
      '#FD9D74',
      '#712312',
      '#FB923C',
      '#9A3412',
      '#FEB9AA',
      '#F97316',
      '#9A3412',
      '#EA580C',
      '#7C2D12',
      '#C2410C',
    ],
    MAXGAUGE: [
      '#A5CAD6',
      '#3A639B',
      '#25556B',
      '#82A3B2',
      '#4C7867',
      '#97AB9F',
      '#AAD0DD',
      '#A8A29E',
      '#A6CFFF',
      '#968E80',
      '#908B0E',
      '#66900E',
      '#0284C7',
      '#0369A1',
      '#0891B2',
      '#9C76FF',
      '#6C34FF',
      '#4E99D1',
      '#30A6FC',
      '#4346D3',
      '#9BD9FF',
      '#A78BFA',
      '#CEC7F2',
      '#8B8B9A',
    ],
    PUBLIC: [
      '#144BB8',
      '#E56B19',
      '#1AA2E6',
      '#61AD1F',
      '#6C1AE6',
      '#ED5EAA',
      '#BACEF7',
      '#B85614',
      '#7AD927',
      '#975EED',
      '#B8146B',
      '#F7D3BA',
      '#F7BADB',
      '#27D9A9',
      '#91AD1F',
      '#B95EED',
      '#1FAD87',
      '#FFD400',
      '#E5A119',
      '#A8E6F0',
      '#B5D927',
      '#D81AE6',
      '#DAB70B',
      '#ED5E5E',
    ],
    FINANCE: [
      '#1A5EE6',
      '#FFD400',
      '#7AD927',
      '#47B4EB',
      '#E5A119',
      '#E61A87',
      '#BACEF7',
      '#F7E9A1',
      '#61AD1F',
      '#27D9A9',
      '#B88114',
      '#F7BADB',
      '#5614B8',
      '#ED5E5E',
      '#A8F0DD',
      '#AD14B8',
      '#E2F0A8',
      '#1FAD87',
      '#5E8EED',
      '#D81AE6',
      '#144BB8',
      '#B8146B',
      '#D3BAF7',
      '#F7D3BA',
    ],
    COMPANY: [
      '#B81414',
      '#144BB8',
      '#E5A119',
      '#61AD1F',
      '#ED975E',
      '#1AA2E6',
      '#F7BABA',
      '#5E8EED',
      '#E56B19',
      '#B85614',
      '#FBE36A',
      '#E61A87',
      '#DAB70B',
      '#27D9A9',
      '#5614B8',
      '#BAE3F7',
      '#F3BAF7',
      '#1FAD87',
      '#1A5EE6',
      '#D81AE6',
      '#FFD400',
      '#A1E467',
      '#67D4E4',
      '#E5A119',
    ],
    EXEM: [
      '#1A5EE6',
      '#FFD400',
      '#E51919',
      '#ED975E',
      '#9B1AE6',
      '#67D4E4',
      '#1F9AAD',
      '#BACEF7',
      '#DAB70B',
      '#B81414',
      '#F7D3BA',
      '#E1BAF7',
      '#B5D927',
      '#7AD927',
      '#F7E9A1',
      '#D81AE6',
      '#D3BAF7',
      '#5614B8',
      '#F3BAF7',
      '#61AD1F',
      '#144BB8',
      '#1FAD87',
      '#CAF0A8',
      '#1AA2E6',
    ],
  },
};

export const EXTRA_COLOR_LIST: Record<'dark' | 'light', string[]> = {
  dark: [
    '#66CCFF',
    '#78FF01',
    '#6701FF',
    '#CCFF01',
    '#FFA366',
    '#66FFD6',
    '#FFCC66',
    '#0156FF',
    '#8FB200',
    '#01DDFF',
    '#FFFAB2',
    '#FFB2B2',
    '#B2A700',
    '#FAB2FF',
    '#6699FF',
    '#ADFF66',
    '#A366FF',
    '#E0FF66',
    '#FF6600',
    '#01FFBB',
    '#FFAA00',
    '#01AAFF',
    '#A201FF',
    '#66EBFF',
    '#FFF566',
    '#EE01FF',
    '#B2F5FF',
    '#E3B2FF',
    '#FFD1B2',
    '#D6FFB2',
    '#D1B2FF',
    '#F0FFB2',
    '#B24700',
    '#00B283',
    '#B27700',
    '#0077B2',
    '#C766FF',
    '#B2E5FF',
    '#F566FF',
    '#FFEE01',
    '#009BB2',
    '#FFE5B2',
    '#B2CCFF',
    '#5100CC',
    '#53B200',
    '#7100B2',
    '#B2FFEB',
    '#0044CC',
    '#FF0000',
    '#FFB2DB',
    '#B20000',
    '#FF66B8',
    '#A700B2',
    '#FF0188',
    '#FF6666',
    '#B2005F',
  ],
  light: [
    '#47B4EB',
    '#7AD927',
    '#6C1AE6',
    '#B5D927',
    '#ED975E',
    '#67E4C3',
    '#EDBE5E',
    '#1A5EE6',
    '#91AD1F',
    '#27C1D9',
    '#FFD400',
    '#61AD1F',
    '#DAB70B',
    '#F3BAF7',
    '#144BB8',
    '#A1E467',
    '#7C14B8',
    '#CBE467',
    '#E56B19',
    '#27D9A9',
    '#E5A119',
    '#1AA2E6',
    '#9B1AE6',
    '#67D4E4',
    '#FBE36A',
    '#D81AE6',
    '#A8E6F0',
    '#5614B8',
    '#F7D3BA',
    '#CAF0A8',
    '#D3BAF7',
    '#E2F0A8',
    '#B85614',
    '#1FAD87',
    '#B88114',
    '#1481B8',
    '#B95EED',
    '#BAE3F7',
    '#E45EED',
    '#F7E9A1',
    '#1F9AAD',
    '#F5DAA3',
    '#BACEF7',
    '#E1BAF7',
    '#F7BABA',
    '#975EED',
    '#A8F0DD',
    '#5E8EED',
    '#E51919',
    '#F7BADB',
    '#B81414',
    '#ED5EAA',
    '#AD14B8',
    '#E61A87',
    '#ED5E5E',
    '#B8146B',
  ],
};

export const COLOR_PALETTE_LIST: Record<
  string,
  {
    background: string;
    border: string;
  }[]
>[] = [
  {
    BLUE: [
      {
        background: '#A9E4F6',
        border: '#A9E4F6',
      },
      {
        background: '#6DB9E3',
        border: '#6DB9E3',
      },
      {
        background: '#3589B8',
        border: '#3589B8',
      },
      {
        background: '#166997',
        border: '#166997',
      },
      {
        background: '#005889',
        border: '#005889',
      },
      {
        background: '#97D2FF',
        border: '#97D2FF',
      },
      {
        background: '#6AA9F2',
        border: '#6AA9F2',
      },
      {
        background: '#3F77CA',
        border: '#3F77CA',
      },
      {
        background: '#224EA5',
        border: '#224EA5',
      },
      {
        background: '#0D3D9B',
        border: '#0D3D9B',
      },
    ],
    PINK: [
      {
        background: '#FFCEED',
        border: '#FFCEED',
      },
      {
        background: '#FCA3E4',
        border: '#FCA3E4',
      },
      {
        background: '#E961DC',
        border: '#E961DC',
      },
      {
        background: '#C036C0',
        border: '#C036C0',
      },
      {
        background: '#AC0CAC',
        border: '#AC0CAC',
      },
      {
        background: '#FFCEE0',
        border: '#FFCEE0',
      },
      {
        background: '#FBA0C1',
        border: '#FBA0C1',
      },
      {
        background: '#EA6394',
        border: '#EA6394',
      },
      {
        background: '#CF336B',
        border: '#CF336B',
      },
      {
        background: '#BE0C4C',
        border: '#BE0C4C',
      },
    ],
    YELLOW: [
      {
        background: '#FDF1B3',
        border: '#FDF1B3',
      },
      {
        background: '#F4D867',
        border: '#F4D867',
      },
      {
        background: '#B39741',
        border: '#B39741',
      },
      {
        background: '#907529',
        border: '#907529',
      },
      {
        background: '#806006',
        border: '#806006',
      },
      {
        background: '#ECFA9B',
        border: '#ECFA9B',
      },
      {
        background: '#CDDE6A',
        border: '#CDDE6A',
      },
      {
        background: '#AABF26',
        border: '#AABF26',
      },
      {
        background: '#8A9600',
        border: '#8A9600',
      },
      {
        background: '#747E00',
        border: '#747E00',
      },
    ],
    WARM_GRAY: [
      {
        background: '#E4DAD0',
        border: '#E4DAD0',
      },
      {
        background: '#CBBFB2',
        border: '#CBBFB2',
      },
      {
        background: '#AB9D8D',
        border: '#AB9D8D',
      },
      {
        background: '#807266',
        border: '#807266',
      },
      {
        background: '#6E5E53',
        border: '#6E5E53',
      },
    ],
  },
  {
    PURPLE: [
      {
        background: '#A4B3FF',
        border: '#A4B3FF',
      },
      {
        background: '#7C90F6',
        border: '#7C90F6',
      },
      {
        background: '#6065E2',
        border: '#6065E2',
      },
      {
        background: '#4340C8',
        border: '#4340C8',
      },
      {
        background: '#3428B7',
        border: '#3428B7',
      },
      {
        background: '#C5A9FF',
        border: '#C5A9FF',
      },
      {
        background: '#AD88FA',
        border: '#AD88FA',
      },
      {
        background: '#8A5DEB',
        border: '#8A5DEB',
      },
      {
        background: '#7248CA',
        border: '#7248CA',
      },
      {
        background: '#6130CA',
        border: '#6130CA',
      },
    ],
    ORANGE: [
      {
        background: '#FFD7C9',
        border: '#FFD7C9',
      },
      {
        background: '#FFA693',
        border: '#FFA693',
      },
      {
        background: '#FF7171',
        border: '#FF7171',
      },
      {
        background: '#E1404D',
        border: '#E1404D',
      },
      {
        background: '#CD2020',
        border: '#CD2020',
      },
      {
        background: '#FDD7A5',
        border: '#FDD7A5',
      },
      {
        background: '#FAAC6A',
        border: '#FAAC6A',
      },
      {
        background: '#D66F35',
        border: '#D66F35',
      },
      {
        background: '#B24213',
        border: '#B24213',
      },
      {
        background: '#963107',
        border: '#963107',
      },
    ],
    GREEN: [
      {
        background: '#B4F2BB',
        border: '#B4F2BB',
      },
      {
        background: '#7EDE8B',
        border: '#7EDE8B',
      },
      {
        background: '#50BC5E',
        border: '#50BC5E',
      },
      {
        background: '#2C903B',
        border: '#2C903B',
      },
      {
        background: '#107C20',
        border: '#107C20',
      },
      {
        background: '#96F2CC',
        border: '#96F2CC',
      },
      {
        background: '#4FD4B6',
        border: '#4FD4B6',
      },
      {
        background: '#279894',
        border: '#279894',
      },
      {
        background: '#19747A',
        border: '#19747A',
      },
      {
        background: '#00656B',
        border: '#00656B',
      },
    ],
    COOL_GRAY: [
      {
        background: '#DAE0EA',
        border: '#DAE0EA',
      },
      {
        background: '#BCC5D3',
        border: '#BCC5D3',
      },
      {
        background: '#8F9CB3',
        border: '#8F9CB3',
      },
      {
        background: '#606D86',
        border: '#606D86',
      },
      {
        background: '#4C5668',
        border: '#4C5668',
      },
    ],
  },
];

export const DEFAULT_ORDERED_COLOR_KEY_LIST = [
  'BLUE',
  'PURPLE',
  'PINK',
  'ORANGE',
  'YELLOW',
  'GREEN',
  'WARM_GRAY',
  'COOL_GRAY',
];
