import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  getByKeysXmUserEnvControllerAxios,
  postV1UserEnvsAxios,
} from '@/openapi/meta/api/xm-user-env-controller-api';
import { useRtmApi } from '@/common/utils/apiUtils';
import { store } from '@/common/store';

export interface DashboardList {
  dashboardId: number;
  dashboardName: string;
  url?: string;
}

export const useDashboardPopupEnvStore = defineStore('dashboardPopupEnvStore', () => {
  const { callApi } = useRtmApi();
  const { userId, tenantId } = store.getters['myInfo/getAccountInfo'];

  const dashboardName = ref('');
  const dashboardPopupListCnt = ref(0);
  const dashboardPopupList = ref<DashboardList[]>([]);
  const setupDashBoardData = ref({
    name: '',
    dashboardList: [] as any,
    urlYN: false,
    urlName: '',
    url: '',
  });

  const initDashboardPopupListData = () => {
    dashboardPopupListCnt.value = 0;
    dashboardPopupList.value = [];
  };

  const initSetupDashboardData = () => {
    setupDashBoardData.value = {
      name: '',
      dashboardList: [],
      urlYN: false,
      urlName: '',
      url: '',
    };
  };

  const fetchDashboardPopupEnvInfoForKey = async (envKey?: string) => {
    initDashboardPopupListData();
    if (!envKey) return;
    try {
      const { data } = await callApi({
        fn: getByKeysXmUserEnvControllerAxios,
        params: {
          userId,
          tenantId,
          keys: [envKey],
        },
      });
      if (data && data.length > 0) {
        setupDashBoardData.value = JSON.parse(data[0]?.value as string);

        if (setupDashBoardData.value.urlYN) {
          setupDashBoardData.value.dashboardList.push({
            dashboardId: null,
            dashboardName: setupDashBoardData.value.urlName,
            url: setupDashBoardData.value.url,
          });
        }

        dashboardPopupListCnt.value = setupDashBoardData.value.dashboardList.length;
        dashboardPopupList.value = setupDashBoardData.value.dashboardList;
        dashboardName.value = setupDashBoardData.value.name;
      } else {
        dashboardName.value = '';
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveEnvInfoForKey = async (envKey: string, value: string) => {
    try {
      await callApi({
        fn: postV1UserEnvsAxios,
        params: {
          request: {
            key: envKey,
            value,
          },
        },
      });

      dashboardName.value = setupDashBoardData.value.name;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    dashboardPopupListCnt,
    dashboardPopupList,
    setupDashBoardData,
    fetchDashboardPopupEnvInfoForKey,
    saveEnvInfoForKey,
    initSetupDashboardData,
    dashboardName,
  };
});
