import { useInternational } from '@/common/locale';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { WidgetChartType } from '@/dashboard/utils/types';
import { computed } from 'vue';
import { ArgumentInfo, WidgetVariable } from './variableListWindow/variable.types';

type ChartDataIndex = number;

export type ReferenceChartData = Omit<ArgumentInfo, 'argumentName'> & {
  chartDataIndex: ChartDataIndex;
  category: string;
};

export interface ReferenceVariable {
  key: ArgumentInfo;
  value: WidgetVariable | null;
}

export type ReferenceInfo = Record<ChartDataIndex, ReferenceVariable[]>;

export interface ReferenceSettingOptionProps {
  chartType: WidgetChartType;
  referenceChartDataList: ReferenceChartData[];
  referenceInfo?: ReferenceInfo;
}

export interface ReferenceSettingOptionEmits {
  (event: 'update:referenceInfo', value: ReferenceInfo): void;
}

export const CommonChartDataIndex = -1;
export const CommonStatId = '';
export const CommonCategory = '';

const setup = (props: ReferenceSettingOptionProps, emit: ReferenceSettingOptionEmits) => {
  const { t } = useInternational();

  const { getStatInfo } = useStatInfoStore();

  const referenceInfo = computed({
    get() {
      return props.referenceInfo ?? {};
    },
    set(value: ReferenceInfo) {
      emit('update:referenceInfo', value);
    },
  });

  const updateVaraibles = (chartDataIndex: number, variables: ReferenceVariable[]) => {
    referenceInfo.value = {
      ...referenceInfo.value,
      [`${chartDataIndex}`]: variables,
    };
  };

  return {
    t,
    referenceInfo,
    getStatInfo,
    updateVaraibles,
  };
};

export default setup;
