/**
 * 워커 스레드 환경에서 src/common/utils의 코드를 import하면
 * DOM or Store(vuex)에 접근하게 되어 에러가 발생합니다.
 * 이로인해 같은 코드지만 워커 스레드 환경에서 사용하기 위해 파일을 분리했습니다.
 * 추후 utils 함수가 기능 또는 성격에 맞게 분리 작업이 끝나면 워커 스레드 환경에서도 import 할 수 있습니다.
 */

import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { AxiosRequestConfig } from 'axios';
import { CustomError, IntervalType } from '@/common/utils/types';
import { isEmpty } from 'lodash-es';

interface ApiTraceInfo {
  text: string;
  elapsedTime: number;
  id: string | null;
}

interface CollectInfo {
  collectInterval: IntervalType;
  statName: string;
}

interface IntervalInfo {
  collectInfo: CollectInfo[];
  interval: string;
}

interface TransformResDataOpt {
  type: 'chart' | 'default';
  fromTime: string;
  toTime: string;
  interval: string;
  fields: string[];
  valProperty?: {
    name: string;
    metricProperty?: {
      name: string;
      list: string[];
    };
  };
  wrapChartValKey?: string; // type chart 일 때
  needDateTime?: boolean; // type chart 일 때
  dataFormat?: (val: number) => void; // type chart 일 때
  apiConfig?: AxiosRequestConfig; // type chart 일 때
}

interface SaveData {
  onlyNull: null[] | Record<string, null[]> | Record<string, null | string>[] | null;
}

interface AddNullToResDataFormParams {
  curResData: any[];
  fromTime: string;
  toTime: string;
  fields: string[];
  intervalMs: number;
  saveData?: SaveData;
}

type SetIncludeNullDataParam = Record<string, any> | null;

interface AddNullToChartDataFormParams extends AddNullToResDataFormParams {
  isArrEmptyDateTime?: boolean;
  apiConfig?: any;
  needDateTime?: boolean;
  dataFormat?: (val: number) => void;
}

interface DataDateTimeObj {
  data: (null | number)[] & Record<string, any>;
  dateTime: Dayjs[];
}

interface SetObjByMetricNameParams extends DataDateTimeObj {
  metricName: string;
  cusWrapChartValKey: string;
}

interface SetObjByCusWrapChartValKeyParams extends DataDateTimeObj {
  cusWrapChartValKey: string;
}

interface CreateDataDateTimeObj {
  dataDateTimeObj: DataDateTimeObj;
  setObjByMetricName: (params: SetObjByMetricNameParams) => void;
  setObjByCusWrapChartValKey: (params: SetObjByCusWrapChartValKeyParams) => void;
  setObjByOnlyFields: (params: DataDateTimeObj) => void;
}

class MetricFailError extends Error {
  constructor({ reason }) {
    super(reason);
    this.name = 'MetricFailError';
  }

  getErrorStatusText() {
    if (this.message === 'null') {
      return 'Unknown Error';
    }
    return this.message ?? 'Unknown Error';
  }
}

const getApiTraceInfo = (trace): ApiTraceInfo => {
  const { sqlText: text = 'SQL Text 가 없습니다.', elapsedTime = 0, id = null } = trace?.[0] ?? {};

  return {
    text,
    elapsedTime,
    id,
  };
};

const getIntervalInfo = (query): IntervalInfo => {
  const { collectInfo = [], interval } = query ?? {};

  return {
    collectInfo,
    interval,
  };
};

const getStandardTime = (data, utcOffset) =>
  data?.map((utcZeroTime) => +dayjs(utcZeroTime).add(utcOffset, 'm')) ?? [];

// 소수점 digit 자리까지 표기 및 반올림하고 숫자를 리턴한다. default: 3
const roundToDigitNumber = (num: number, digit = 3) => {
  if (num || num === 0) {
    return +`${Math.round(+`${num}e+${digit}`)}e-${digit}`;
  }
  return num;
};

const repeat = (func: () => any, fetch: { timer: any; timeout: number }) => {
  func();
  fetch.timer = setTimeout(() => repeat(func, fetch), fetch.timeout);
};

// UTC 0 -> 표준 시로 변환
const utcZeroTimeToStandardTime = (
  utcZeroTime: undefined | null | string,
  options?: { returnFormat?: string; utcOffset?: number },
): string => {
  const { returnFormat = 'YYYY-MM-DD HH:mm:ss', utcOffset = 0 } = options ?? {};
  if (utcZeroTime) {
    return dayjs(utcZeroTime).add(utcOffset, 'm').format(returnFormat);
  }
  return '';
};

const getCurrentUtcPeriod = ({
  utcOffset,
  utcOffsetUnit = 'm',
  timeOffset = 5,
  timeOffsetUnit = 's',
}: {
  utcOffset: number;
  utcOffsetUnit?: ManipulateType;
  timeOffset?: number;
  timeOffsetUnit?: ManipulateType;
}) => {
  const currentUtc = dayjs().subtract(utcOffset, utcOffsetUnit);
  return {
    fromTime: currentUtc.subtract(timeOffset, timeOffsetUnit).format('YYYY-MM-DD HH:mm:ss'),
    toTime: currentUtc.format('YYYY-MM-DD HH:mm:ss'),
  };
};

const getUtcOffset = (config: AxiosRequestConfig) => {
  if (config.headers?.get && typeof config.headers.get === 'function') {
    return +(config.headers?.get('UtcOffset') ?? 0);
  }
  return 0;
};

const extractNumbers = (str: string) => {
  const regex = /\d+/g;
  const matches = str.match(regex);
  return matches ? matches.map(Number) : [];
};

const timeRegex = /(\d+d)?(\d+h)?(\d+m)?(\d+s)?/;
const getIntervalMs = (
  originInterval: string,
): {
  msTime: number;
  interval: string;
} => {
  let removeILetterInterval;
  if (originInterval[0] === 'I') {
    removeILetterInterval = originInterval.substring(1);
  }

  const [, daysString, hoursString, minutesString, secondsString]: any = (
    removeILetterInterval || originInterval
  ).match(timeRegex);

  const days = parseInt(daysString, 10) || 0;
  const hours = parseInt(hoursString, 10) || 0;
  const minutes = parseInt(minutesString, 10) || 0;
  const seconds = parseInt(secondsString, 10) || 0;

  const msTime = (days * (24 * 3600) + hours * 3600 + minutes * 60 + seconds) * 1000;

  let interval;
  if (days) {
    interval = `I${daysString}`;
  } else if (hours) {
    interval = `I${hoursString}`;
  } else if (minutes) {
    interval = `I${minutesString}`;
  } else if (seconds) {
    interval = `I${secondsString}`;
  }

  return {
    msTime,
    interval,
  };
};

const addValByFieldsToResDataForm = ({
  curResData,
  fromTime,
  toTime,
  fields,
  intervalMs,
  saveData = { onlyNull: null },
}: AddNullToResDataFormParams) => {
  let fromTimeUnix: number = +dayjs(fromTime);
  const toTimeUnix: number = +dayjs(toTime);
  const START_VAL_FIELD_IDX = 1;
  let includedNullData: any[] = [];

  if (!saveData.onlyNull || curResData.length) {
    const nullValObj: Record<string, null> = {};

    for (let i = START_VAL_FIELD_IDX; i < fields.length; i++) {
      nullValObj[fields[i]] = null;
    }

    const DATE_PROPERTY_NAME: string = fields[0];

    let i = 0;
    while (fromTimeUnix < toTimeUnix) {
      if (i < curResData.length && +dayjs(curResData[i][DATE_PROPERTY_NAME]) === fromTimeUnix) {
        includedNullData.push(curResData[i]);
        i++;
      } else {
        includedNullData.push({
          [DATE_PROPERTY_NAME]: dayjs(fromTimeUnix).format('YYYY-MM-DDTHH:mm:ss'),
          ...nullValObj,
        });
      }

      fromTimeUnix += intervalMs;
    }

    if (!curResData.length) {
      saveData.onlyNull = includedNullData;
    }
  } else {
    includedNullData = saveData.onlyNull as [];
  }

  return includedNullData;
};

// 원본 데이터의 빈 부분에 Null 만 넣은 형태
const transformResDataForm = (
  resData: any[],
  options: Omit<TransformResDataOpt, 'needDateTime' | 'dataFormat' | 'apiConfig'>,
) => {
  let reassignData: any;
  if (Array.isArray(resData)) {
    const { valProperty, interval, fields } = options;

    let intervalMs: number | undefined;
    if (interval && typeof intervalMs !== 'number') {
      intervalMs = getIntervalMs(interval).msTime;
    }

    if (intervalMs && fields) {
      const saveData = {
        onlyNull: null,
      } as SaveData;

      if (valProperty) {
        const { name: valPropertyName, metricProperty } = valProperty;

        if (!resData.length) {
          resData[0] = {
            [valPropertyName]: [],
          };
        }

        for (let i = 0; i < resData.length; i++) {
          const curResData = resData[i];

          if (valPropertyName in curResData) {
            if (metricProperty) {
              const checkExistMetricData = (compareVal: string) =>
                curResData[valPropertyName].find(
                  (info) => info?.[metricProperty.name] === compareVal,
                );

              const resultData: any = [];
              for (let j = 0; j < metricProperty?.list?.length; j++) {
                const metricName = metricProperty.list[j];
                const curMetricData = checkExistMetricData(metricName)?.values ?? [];

                resultData.push({
                  [metricProperty.name]: metricName,
                  values: addValByFieldsToResDataForm({
                    curResData: curMetricData,
                    intervalMs,
                    saveData,
                    ...options,
                  }),
                });
              }

              curResData[valPropertyName] = resultData;
            } else {
              curResData[valPropertyName] = addValByFieldsToResDataForm({
                curResData: curResData[valPropertyName],
                intervalMs,
                saveData,
                ...options,
              });
            }
          }
        }
      } else {
        reassignData = addValByFieldsToResDataForm({
          curResData: resData,
          intervalMs,
          ...options,
        });
      }
    }
  }

  return reassignData ?? resData;
};

const addValByFieldsToChartDataForm = ({
  curResData,
  fromTime,
  toTime,
  fields,
  intervalMs,
  apiConfig,
  needDateTime = true,
  dataFormat = (val) => val,
  isArrEmptyDateTime = true,
  saveData = { onlyNull: null },
}: AddNullToChartDataFormParams) => {
  let fromTimeUnix: number = +dayjs(fromTime);
  const toTimeUnix: number = +dayjs(toTime);
  const START_VAL_FIELD_IDX = 1;
  const MULTIPLE_FIELDS_LEN = 3;
  // fields에 [날짜 필드, 값 필드 1] 처럼 값 필드 하나만 있으면 바로 배열로
  // fields에 [날짜 필드, 값 필드 1, 값 필드 2] 처럼 값 핃드가 MULTIPLE 이면 객체 형식으로
  let includedNullData: any = fields.length >= MULTIPLE_FIELDS_LEN ? {} : [];
  const chartDateTime: Dayjs[] = [];

  if (!saveData.onlyNull || curResData.length) {
    const DATE_PROPERTY_NAME: string = fields[0];

    const addStandardDateTime = (dateTime: string): number =>
      chartDateTime.push(
        dayjs(
          utcZeroTimeToStandardTime(
            dateTime,
            apiConfig ? { utcOffset: getUtcOffset(apiConfig) } : {},
          ),
        ),
      );

    const addValToArr = (val: SetIncludeNullDataParam): void =>
      includedNullData.push(val && dataFormat(val[fields[START_VAL_FIELD_IDX]]));

    const addValToObj = (val: SetIncludeNullDataParam): void => {
      for (let i = START_VAL_FIELD_IDX; i < fields.length; i++) {
        if (!includedNullData?.[fields[i]]) {
          includedNullData[fields[i]] = [];
        }

        includedNullData[fields[i]].push(val && dataFormat(val[fields[i]]));
      }
    };

    const setIncludedNullData = (val: SetIncludeNullDataParam): void => {
      if (fields.length >= MULTIPLE_FIELDS_LEN) {
        addValToObj(val);
      } else {
        addValToArr(val);
      }
    };

    let i = 0;
    while (fromTimeUnix < toTimeUnix) {
      if (i < curResData.length && +dayjs(curResData[i][DATE_PROPERTY_NAME]) === fromTimeUnix) {
        if (needDateTime && isArrEmptyDateTime) {
          addStandardDateTime(curResData[i][DATE_PROPERTY_NAME]);
        }

        if (fields.length > START_VAL_FIELD_IDX) {
          setIncludedNullData(curResData[i]);
        }
        i++;
      } else {
        if (needDateTime && isArrEmptyDateTime) {
          addStandardDateTime(dayjs(fromTimeUnix).format('YYYY-MM-DD HH:mm:ss'));
        }

        if (fields.length > START_VAL_FIELD_IDX) {
          setIncludedNullData(null);
        }
      }

      fromTimeUnix += intervalMs;
    }

    if (!curResData.length) {
      saveData.onlyNull = includedNullData;
    }
  } else {
    // null 만 채운 값이 있다면 다시 반복 하지않고 해당 값 사용
    includedNullData = saveData.onlyNull;
  }

  return {
    chartData: includedNullData,
    chartDateTime,
  };
};

const createDataDateTimeObj = (
  isHasMultipleInstanceId: boolean,
  isUseWrapChartValKey: boolean,
): CreateDataDateTimeObj => {
  const dataDateTimeObj = {
    data: {} as any,
    dateTime: [] as Dayjs[],
  };

  return {
    dataDateTimeObj,
    setObjByMetricName: ({
      data,
      dateTime,
      cusWrapChartValKey,
      metricName,
    }: SetObjByMetricNameParams) => {
      if (!dataDateTimeObj.dateTime.length && dateTime.length) {
        dataDateTimeObj.dateTime = dateTime;
      }

      if (!dataDateTimeObj.data[metricName]) {
        dataDateTimeObj.data[metricName] = {};
      }

      if (isHasMultipleInstanceId || isUseWrapChartValKey) {
        if (!dataDateTimeObj.data[metricName]?.[cusWrapChartValKey]) {
          dataDateTimeObj.data[metricName][cusWrapChartValKey] = data;
        }
      } else {
        dataDateTimeObj.data[metricName] = data;
      }
    },
    setObjByCusWrapChartValKey: ({
      data,
      dateTime,
      cusWrapChartValKey,
    }: SetObjByCusWrapChartValKeyParams) => {
      if (!dataDateTimeObj.dateTime.length && dateTime.length) {
        dataDateTimeObj.dateTime = dateTime;
      }

      if (isHasMultipleInstanceId || isUseWrapChartValKey) {
        if (!dataDateTimeObj.data[cusWrapChartValKey]) {
          dataDateTimeObj.data[cusWrapChartValKey] = data;
        }
      } else {
        dataDateTimeObj.data = data;
      }
    },
    setObjByOnlyFields: ({ data, dateTime }: DataDateTimeObj) => {
      dataDateTimeObj.data = data;
      dataDateTimeObj.dateTime = dateTime;
    },
  };
};

// line chart(evui)에 바로 쓸 수 있는 형태로 가공
const transformChartDataForm = (resData: any[], options: TransformResDataOpt) => {
  if (Array.isArray(resData)) {
    const { valProperty, interval, wrapChartValKey, fields } = options;

    let intervalMs: number | undefined;
    if (interval && typeof intervalMs !== 'number') {
      intervalMs = getIntervalMs(interval).msTime;
    }

    if (intervalMs && fields) {
      const saveData = {
        onlyNull: null,
      } as SaveData;

      const isHasMultipleInstanceId = !!valProperty && resData.length > 1;
      const isUseWrapChartValKey = !!wrapChartValKey;

      const {
        setObjByMetricName,
        setObjByCusWrapChartValKey,
        setObjByOnlyFields,
        dataDateTimeObj,
      } = createDataDateTimeObj(isHasMultipleInstanceId, isUseWrapChartValKey);

      const setDataDateTimeObj = ({ curResData, metricName = '', cusWrapChartValKey = '' }) => {
        if (intervalMs) {
          const { chartData, chartDateTime } = addValByFieldsToChartDataForm({
            curResData,
            intervalMs,
            isArrEmptyDateTime: !dataDateTimeObj.dateTime.length,
            saveData,
            ...options,
          });

          if (metricName) {
            setObjByMetricName({
              metricName,
              cusWrapChartValKey,
              data: chartData,
              dateTime: chartDateTime,
            });
          } else if (cusWrapChartValKey) {
            setObjByCusWrapChartValKey({
              cusWrapChartValKey,
              data: chartData,
              dateTime: chartDateTime,
            });
          } else {
            setObjByOnlyFields({
              data: chartData,
              dateTime: chartDateTime,
            });
          }
        }
      };

      if (valProperty) {
        const { name: valPropertyName, metricProperty } = valProperty;

        if (!resData.length) {
          resData[0] = {
            [valPropertyName]: [],
          };
        }

        for (let i = 0; i < resData.length; i++) {
          const curResData = resData[i];

          if (valPropertyName in curResData) {
            if (metricProperty) {
              const checkExistMetricData = (compareVal: string) =>
                curResData[valPropertyName].find(
                  (info) => info?.[metricProperty.name] === compareVal,
                );

              for (let j = 0; j < metricProperty?.list?.length; j++) {
                const metricName = metricProperty.list[j];
                const curMetricData = checkExistMetricData(metricName)?.values ?? [];

                setDataDateTimeObj({
                  curResData: curMetricData,
                  cusWrapChartValKey: curResData?.[wrapChartValKey ?? 'instanceId'], // default InstanceId
                  metricName,
                });
              }
            } else {
              setDataDateTimeObj({
                curResData: curResData[valPropertyName],
                cusWrapChartValKey: curResData?.[wrapChartValKey ?? 'instanceId'], // default InstanceId
              });
            }
          }
        }
      } else {
        setDataDateTimeObj({
          curResData: resData,
        });
      }

      return dataDateTimeObj;
    }
  }

  return null;
};

const transformResData = (data: any[], options: TransformResDataOpt) => {
  let resultData;
  switch (options.type) {
    case 'default':
      resultData = transformResDataForm(data, options);
      break;
    case 'chart':
      resultData = transformChartDataForm(data, options);
      break;
    default:
      resultData = data;
      break;
  }

  return Array.isArray(resultData) ? { data: resultData } : resultData;
};

const replaceInstanceObjectType = (type: string): string => (type === 'i' ? 'Index' : 'Table');

const makeRows = (data: any[] = [], columns) =>
  data.map((obj, idx) =>
    columns.map((col) => {
      if (
        typeof obj[col.field] === 'string' &&
        (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?/.test(obj[col.field]) ||
          /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}(.\d+)?/.test(obj[col.field]))
      ) {
        return utcZeroTimeToStandardTime(obj[col.field]);
      }
      if (
        typeof obj[col.field] === 'string' &&
        (obj[col.field] === 'i' || obj[col.field] === 'r')
      ) {
        return replaceInstanceObjectType(obj[col.field]);
      }
      if (col.field === 'rank') {
        return idx + 1;
      }
      return obj[col.field] ?? null;
    }),
  );

const makeOracleWaitClassRows = (data, columns) => {
  const tempData: any[] = [];
  data.forEach((item) => {
    if (item?.values.length) {
      item.values.forEach((val) => {
        tempData.push({
          waitClassName: item.waitClassName,
          ...val,
        });
      });
    }
  });
  return makeRows(tempData, columns);
};

const getCustomErrorObj = (error: any): CustomError => {
  if (isEmpty(error)) {
    return {} as CustomError;
  }

  if (error instanceof MetricFailError) {
    return {
      code: 'fail',
      request: {},
      response: {
        data: null,
        status: 'fail',
        statusText: error.getErrorStatusText(),
      },
    };
  }

  const { response, request, code, message } = error;
  const { status: reqStatus, statusText: reqStatusText } = request;
  const customErrorObj: CustomError = {
    code,
    request: {
      status: reqStatus,
      statusText: reqStatusText || message,
    },
    response: undefined,
  };

  if (response) {
    const { data: resData, status: resStatus, statusText: resStatusText } = response;
    customErrorObj.response = {
      data: resData,
      status: resStatus,
      statusText: resStatusText || message,
    };
  }

  return customErrorObj;
};

export {
  type ApiTraceInfo,
  type CollectInfo,
  type IntervalInfo,
  type TransformResDataOpt,
  type DataDateTimeObj,
  MetricFailError,
  extractNumbers,
  repeat,
  getApiTraceInfo,
  getIntervalInfo,
  getStandardTime,
  roundToDigitNumber,
  utcZeroTimeToStandardTime,
  getCurrentUtcPeriod,
  getUtcOffset,
  getIntervalMs,
  transformResData,
  replaceInstanceObjectType,
  makeRows,
  makeOracleWaitClassRows,
  getCustomErrorObj,
};
