<script setup lang="ts">
interface Emits {
  (e: 'click', value: Event): void;
}

const emit = defineEmits<Emits>();

const onClickCopy = (e: Event) => {
  emit('click', e);
};
</script>

<template>
  <ev-button
    class="chart-data-copy-button"
    type="icon"
    @click="onClickCopy"
  >
    <ev-icon icon="icon-outline-copy" />
  </ev-button>
</template>
