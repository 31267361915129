<template>
  <div class="information-title">
    <slot />
  </div>
</template>

<style lang="scss">
.information-title {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: var(--config-information-title-color);
}
</style>
