import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import {
  CreateInstanceV2Request,
  InstanceTestConnectionResponse,
  InstanceTestDbConnectionRequest,
  InstanceTestOsConnectionRequest,
  InstanceV2Response,
  UpdateInstanceV2Request,
} from '../model';

const getV2InstancesAxios = (): PromiseAxiosResponse<InstanceV2Response> =>
  axiosInstance.get('/api/v2/instances');

const getV2InstancesByInstanceIdAxios = ({
  instanceId,
}: {
  instanceId: string;
}): PromiseAxiosResponse<InstanceV2Response> =>
  axiosInstance.get(`/api/v2/instances/${instanceId}`);

const patchV2InstancesByInstanceIdAxios = ({
  instanceId,
  tenantId,
  request,
}: {
  instanceId: string;
  tenantId: string;
  request: UpdateInstanceV2Request;
}): PromiseAxiosResponse<InstanceV2Response> =>
  axiosInstance.patch(`/api/v2/instances/${instanceId}`, {
    params: {
      instanceId,
      tenantId,
      request,
    },
  });

const patchV3InstancesByInstanceIdAxios = ({
  instanceId,
  request,
}: {
  instanceId: string;
  request: UpdateInstanceV2Request;
}): PromiseAxiosResponse<InstanceV2Response> =>
  axiosInstance.patch(`/api/v3/instances/${instanceId}`, request);

const postV2InstancesAxios = ({
  // 미사용
  request,
}: {
  request: CreateInstanceV2Request;
}): PromiseAxiosResponse<InstanceV2Response> =>
  axiosInstance.post('/api/v2/instances', {
    params: {
      request,
    },
  });

const postV2InstancesTestDbConnectionAxios = ({
  request,
}: {
  request: InstanceTestDbConnectionRequest;
}): PromiseAxiosResponse<InstanceTestConnectionResponse> =>
  axiosInstance.post('/api/v2/instances/test-db-connection', {
    params: {
      request,
    },
  });

const postV2InstancesTestOsConnectionAxios = ({
  request,
}: {
  request: InstanceTestOsConnectionRequest;
}): PromiseAxiosResponse<InstanceTestConnectionResponse> =>
  axiosInstance.post('/api/v2/instances/test-os-connection', {
    params: {
      request,
    },
  });

const postV3InstancesAxios = ({
  request,
}: {
  request: CreateInstanceV2Request;
}): PromiseAxiosResponse<InstanceV2Response> => axiosInstance.post('/api/v3/instances', request);

const postV3InstancesTestDbConnectionAxios = ({
  request,
}: {
  request: InstanceTestDbConnectionRequest;
}): PromiseAxiosResponse<InstanceTestConnectionResponse> =>
  axiosInstance.post('/api/v3/instances/test-db-connection', request);

const postV3InstancesTestOsConnectionAxios = ({
  request,
}: {
  request: InstanceTestOsConnectionRequest;
}): PromiseAxiosResponse<InstanceTestConnectionResponse> =>
  axiosInstance.post('/api/v3/instances/test-os-connection', request);

export {
  getV2InstancesAxios,
  getV2InstancesByInstanceIdAxios,
  patchV2InstancesByInstanceIdAxios,
  patchV3InstancesByInstanceIdAxios,
  postV2InstancesAxios,
  postV2InstancesTestDbConnectionAxios,
  postV2InstancesTestOsConnectionAxios,
  postV3InstancesAxios,
  postV3InstancesTestDbConnectionAxios,
  postV3InstancesTestOsConnectionAxios,
};
