import { DashboardList } from '@/common/stores/dashboard-popup';
import { DetailInfoData } from '@/common/utils';
import { store } from '@/common/store';
import dayjs from 'dayjs';
import { DashboardKey } from '@/common/uses/useDashboardJump';

export interface Props {
  dashboardList: DashboardList[];
  moveDashboard: (id: number, keyInfo?: DashboardKey) => void;
  keyInfo?: DashboardKey;
  infoDataList?: DetailInfoData[];
}

const setup = (props: Props) => {
  const onClickDashboardList = (list) => {
    if (list.dashboardId) {
      props.moveDashboard(list.dashboardId, props.keyInfo);
    } else {
      const accountInfo = store.getters['myInfo/getAccountInfo'];
      const containerKey = props.infoDataList?.filter((data) => data.field === 'containerKey')[0]
        ?.values[0];

      const url = list.url
        .replace('$intermaxFromTime', +dayjs().tz().subtract(10, 'minutes'))
        .replace('$intermaxToTime', +dayjs().tz())
        .replace('$timeZone', accountInfo.timezone ?? '')
        .replace('$containerId', containerKey ?? '');

      location.replace(url);
    }
  };

  return {
    onClickDashboardList,
  };
};

export { setup };
