<template>
  <base-menu />
  <div
    :class="[
      'main-viewport',
      { 'single-view-viewport': currentRouteName === 'singleView' },
      { 'multi-view-viewport': currentRouteName === 'multiView' },
    ]"
  >
    <template v-if="isRTMView(currentRouteName)">
      <single-view-header v-if="currentRouteName === 'singleView'" />
      <multi-view-header v-else />
    </template>
    <div :class="getCurrentViewWrapper(currentRouteName)">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import router from '@/common/router';
import SingleViewHeader from '@/postgresql/views/singleView/singleViewHeader/SingleViewHeader.vue';
import MultiViewHeader from '@/oracle/views/multiView/multiViewHeader/MultiViewHeader.vue';
import { convertCamelToKebab } from '@/common/utils/commonUtils';

const currentRouteName = computed(() => {
  const currentPath = router.currentRoute.value?.path?.split('/');
  return currentPath[currentPath.length - 1];
});

const isRTMView = (view: string): boolean => ['singleView', 'multiView'].includes(view);

const getCurrentViewWrapper = (view: string): string => {
  if (isRTMView(view)) {
    return `${convertCamelToKebab(view)}-wrapper`;
  }
  return 'main-view-wrapper';
};
</script>
<style lang="scss" scoped>
.main-view-wrapper {
  height: 100%;
  overflow: auto;
}
</style>
