import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { TableRawData } from '@/worker/commands/dashboard/table';

export const checkEqualizerChartData =
  ({ notSupportStat, notNumberError }: { notSupportStat: string; notNumberError: string }) =>
  (
    rawData: TableRawData,
    chartDataStatus: WidgetChartDataStatusInfo[],
  ): WidgetChartDataStatusInfo[] => {
    const { tableData } = rawData ?? {
      tableData: [],
      targets: [],
    };

    return chartDataStatus.reduce<WidgetChartDataStatusInfo[]>(
      (dataStatusList, apiChartDataStatus, index) => {
        if (apiChartDataStatus.status === 'fail') {
          dataStatusList.push(apiChartDataStatus);
        } else {
          const isExistTargetId = tableData[index].columns.some(
            (columnInfo) => columnInfo.dataAttribute === 'TargetId',
          );
          const isExistNumberType = tableData[index].columns.some(
            (columnInfo) => columnInfo.dataType === 'Number',
          );
          if (!isExistTargetId) {
            dataStatusList.push({
              status: 'fail',
              type: 'custom',
              chartDataId: apiChartDataStatus.chartDataId,
              reason: notSupportStat,
            });
          } else if (!isExistNumberType) {
            dataStatusList.push({
              status: 'fail',
              type: 'custom',
              chartDataId: apiChartDataStatus.chartDataId,
              reason: notNumberError,
            });
          } else {
            dataStatusList.push(apiChartDataStatus);
          }
        }
        return dataStatusList;
      },
      [],
    );
  };
