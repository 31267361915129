import { useApiTraceStore } from '@/common/stores/api-trace';
import { useAuthStore } from '@/common/stores/auth';
import { EvMessage } from 'evui';
import { onBeforeUnmount } from 'vue';
import router from '@/common/router';

export const onMessageHandler = {
  setApiTraceInfo: (data) => {
    if (data.action) {
      delete data.action;
    }

    useApiTraceStore().setTraceInfo({
      frameName: data.frameName,
      apiResponse: JSON.parse(data?.apiTraceInfo ?? '{}'),
      isError: data.isError,
    });
  },
  logout: () => {
    if (router.currentRoute.value.fullPath === '/manager') {
      router.push('/manager');
    } else {
      router.push('/logout');
    }
  },
  updateToken: () => {
    useAuthStore().updateToken();
  },
  showMessage: ({ type, message }) => {
    EvMessage({ type, message });
  },
};

export const useMessageEventInMainThread = () => {
  const messageFn = (event) => {
    if (window.origin !== event.origin) {
      return;
    }

    const { data } = event;
    if (data.action && onMessageHandler[data.action]) {
      onMessageHandler[data.action](data);
    }
  };

  window.addEventListener('message', messageFn);

  onBeforeUnmount(() => {
    window.removeEventListener('message', messageFn);
  });
};
