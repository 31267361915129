import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { ViewType, DBType } from '@/common/utils/types';
import { AlertGroupsItem } from '@/openapi/meta/model';
import { AlertMetricsItem } from '@/openapi/common/model';
import { getV1TenantAlertsAxios } from '@/openapi/meta/api/xm-tenant-controller-api';

export interface Alert {
  type: string;
  alertName: string;
  alertDisplayName?: string;
  category: string;
  warning: number;
  critical: number;
  comparison: '>=' | '<=';
  detection: string;
  repeat: number;
  clear: number;
  alertLimit: number;
  enabled: boolean;
  tenantId?: string;
  alertId?: number;
  alertGroupId?: number;
  instanceId?: string;
  instanceName?: string;
  instanceType?: DBType;
  createdDateTime?: string;
  modifiedDateTime?: string;
}

// alertGroup: 알람그룹
// alertGroupInstance: 알람그룹에 등록된 인스턴스
// ungroupedInstance: 알람그룹에 등록되지 않은 인스턴스
export type Status = 'alertGroup' | 'alertGroupInstance' | 'ungroupedInstance' | '';
export interface SelectedRowInfo {
  alertGroupId: number | null;
  groupName: string;
  status: Status;
  instanceIds: string[] | null;
  instanceName: string;
  instanceType: DBType;
}
export interface AlertListInfo {
  [alertGroupIdOrInstanceId: string]: {
    alerts: {
      [alertId: number]: Alert;
    };
    instanceType?: DBType;
  };
}

interface AlertMetric extends Required<AlertMetricsItem> {
  instanceId: string;
  alertGroupId: number;
}

export interface State {
  selectedRowInfo: SelectedRowInfo;
  alertGroups: AlertGroupsItem[];
  groupAlerts: AlertListInfo;
  instanceAlerts: AlertListInfo;
  initViewType: ViewType;
  alertMetrics: AlertMetric[];
  isShowAlertGroupWindow: boolean;
}

export const alert: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedRowInfo: {
      alertGroupId: null,
      groupName: '',
      status: '',
      instanceIds: [],
      instanceName: '',
      instanceType: null,
    },
    alertGroups: [],
    groupAlerts: {},
    instanceAlerts: {},
    initViewType: 'view',
    alertMetrics: [],
    isShowAlertGroupWindow: false,
  },
  mutations: {
    setSelectedRowInfo: (state: State, selectedRowInfo: SelectedRowInfo) => {
      state.selectedRowInfo = selectedRowInfo;
    },
    setAlertGroups: (state: State, alertGroups: AlertGroupsItem[]) => {
      state.alertGroups = alertGroups;
    },
    setGroupAlert: (
      state: State,
      { alertGroupId, alertData }: { alertGroupId: number; alertData: Alert[] },
    ) => {
      state.groupAlerts[alertGroupId] = {
        alerts: {},
      };
      alertData?.forEach((value) => {
        state.groupAlerts[alertGroupId].alerts[value.alertId!] = value;
      });
    },
    setInstanceAlert: (
      state: State,
      {
        instanceId,
        instanceType = null,
        alertData,
      }: { instanceId: string; instanceType: DBType; alertData: Alert[] },
    ) => {
      state.instanceAlerts[instanceId] = {
        instanceType,
        alerts: {},
      };
      alertData?.forEach((value) => {
        if (value.alertId) {
          state.instanceAlerts[instanceId].alerts[value.alertId] = value;
        }
      });
    },
    setInitViewType: (state: State, viewType: ViewType) => {
      state.initViewType = viewType;
    },
    setAlertMetrics: (state: State, alertMetrics: AlertMetric[]) => {
      state.alertMetrics = alertMetrics;
    },
    setIsShowAlertGroupWindow: (state: State, isShow: boolean) => {
      state.isShowAlertGroupWindow = isShow;
    },
    initAlert: (state: State) => {
      state.selectedRowInfo.alertGroupId = null;
      state.selectedRowInfo.groupName = '';
      state.selectedRowInfo.status = '';
      state.selectedRowInfo.instanceIds = [];
      state.selectedRowInfo.instanceName = '';
      state.selectedRowInfo.instanceType = null;
      state.alertGroups = [];
      state.groupAlerts = {};
      state.instanceAlerts = {};
      state.initViewType = 'view';
      state.alertMetrics = [];
      state.isShowAlertGroupWindow = false;
    },
  },
  actions: {
    dispatchAllInstanceAlert: async ({ commit }) => {
      const { data } = await getV1TenantAlertsAxios();
      data?.data?.forEach(({ instanceId, instanceType, alertList: alertData }) => {
        commit('setInstanceAlert', { instanceId, instanceType, alertData });
      });
    },
  },
  getters: {
    getSelectedRowInfo: (state: State) => state.selectedRowInfo,
    getAlertGroups: (state: State) => state.alertGroups,
    getGroupAlerts: (state: State) => state.groupAlerts,
    getInstanceAlerts: (state: State) => state.instanceAlerts,
    getInitViewType: (state: State) => state.initViewType,
    getAlertMetrics: (state: State) => state.alertMetrics,
    getIsShowAlertGroupWindow: (state: State) => state.isShowAlertGroupWindow,
  },
};
