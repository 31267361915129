import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { tenantLoginUserAPI } from '@/common/api/auth';
import { AxiosResponse } from 'axios';
import { STORAGE_KEY } from '@/common/utils/define';
import { postV3LoginAxios } from '@/openapi/meta/api/user-controller-api';

export interface Token {
  accessToken: string;
  refreshToken: string;
}
export interface EntryToken extends Token {
  passwordChange: boolean;
}
interface State {
  entryAccessToken: string;
  accessToken: string;
  refreshToken: string;
  passwordChange: boolean;
}

const makeEncode = (value) => encodeURIComponent(btoa(value));
export const getTokenFromSessionStorage = (type) => {
  if (type === 'entryAccessToken') {
    const entryToken = sessionStorage.getItem(STORAGE_KEY.ENTRY_TOKEN) || '';
    try {
      return atob(decodeURIComponent(entryToken));
    } catch {
      return '';
    }
  }
  const tokenInfo = sessionStorage.getItem(STORAGE_KEY.TOKEN);
  if (tokenInfo) {
    try {
      return JSON.parse(atob(decodeURIComponent(tokenInfo)))[type] || '';
    } catch (e: any) {
      return '';
    }
  }
  return '';
};

export const auth: Module<State, RootState> = {
  namespaced: true,
  state: {
    entryAccessToken: getTokenFromSessionStorage('entryAccessToken'),
    accessToken: getTokenFromSessionStorage('accessToken'),
    refreshToken: getTokenFromSessionStorage('refreshToken'),
    passwordChange: false,
  },
  mutations: {
    setEntryToken: (state: State, tokenInfo: EntryToken) => {
      state.entryAccessToken = tokenInfo.accessToken;
      state.passwordChange = tokenInfo.passwordChange!;
      sessionStorage.setItem(STORAGE_KEY.ENTRY_TOKEN, makeEncode(tokenInfo.accessToken));
    },
    setToken: (state: State, tokenInfo: Token) => {
      state.accessToken = tokenInfo.accessToken;
      state.refreshToken = tokenInfo.refreshToken || '';
      sessionStorage.setItem(STORAGE_KEY.TOKEN, makeEncode(JSON.stringify(tokenInfo)));
    },
    tenantLogout: (state: State) => {
      state.accessToken = '';
      state.refreshToken = '';
      sessionStorage.removeItem(STORAGE_KEY.TOKEN);
      sessionStorage.removeItem(STORAGE_KEY.FOLDERS);
      sessionStorage.removeItem(STORAGE_KEY.SINGLE_VIEW);
      sessionStorage.removeItem(STORAGE_KEY.MULTI_VIEW);
    },
    logout: (state: State) => {
      state.entryAccessToken = '';
      state.accessToken = '';
      state.refreshToken = '';
      state.passwordChange = false;
      sessionStorage.clear();
    },
  },
  actions: {
    // 1차 로그인 - tenant entry 진입
    login: async ({ commit }, loginData) => {
      const { data } = await postV3LoginAxios(loginData);
      commit('setEntryToken', data);
    },
    // 2차 로그인 - tenant(dashboard) 진입
    tenantLogin: async ({ commit }, tenantId) => {
      const { data }: AxiosResponse<Token> = await tenantLoginUserAPI(tenantId);
      commit('setToken', data);
    },
  },
  getters: {
    getEntryAccessToken: (state: State): string => state.entryAccessToken,
    getPasswordChange: (state: State): boolean => state.passwordChange,
    getAccessToken: (state: State): string => state.accessToken,
    getRefreshToken: (state: State): string => state.refreshToken,
  },
};
