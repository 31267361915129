<template>
  <div class="error-container">
    <div class="error-container__content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.error-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('@/common/assets/error-background.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__content {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 1;
    transform: translate(0, -50%);
  }
}
</style>
