import { useInternational } from '@/common/locale';
import { computed } from 'vue';

interface Props {
  isShow: boolean;
  title?: string;
  useTitleCloseBtn?: boolean;
  useConfirm?: boolean;
  useCancel?: boolean;
  useSaveAs?: boolean;
  customSize?: { width: string; height: string };
}

interface Emits {
  (e: 'update:isShow', value: boolean): void;

  (e: 'save-as'): void;

  (e: 'confirm'): void;
}

const setup = (props: Props, emits: Emits) => {
  const { t } = useInternational();
  const isShowWindow = computed<boolean>({
    get: () => props.isShow,
    set: (value) => emits('update:isShow', value),
  });
  const onClickCancel = () => {
    isShowWindow.value = false;
  };

  return {
    t,
    isShowWindow,
    onClickCancel,
  };
};

export { setup };
export type { Props, Emits };
