import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { convertCamelToCapitalStart } from '@/dashboard/utils/dashboardUtils';
import { ASTER_KEY } from '@/dashboard/utils/define';
import {
  getTargetIdsListByTagTagControllerAxios,
  getTargetKeyValueTagControllerAxios,
} from '@/openapi/metaV6/api/tag-controller-api';
import { TagsRequest } from '@/openapi/metaV6/model';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTargetTagStore = defineStore('targetTagStore', () => {
  const TAG_MAP_KEY_CONNECTIVE = '//';
  const tagMap = ref<Map<string, string>>(new Map());

  const getTagMapKey = ({ id = '', category = '' }: { id?: string; category?: string }) =>
    `${id}${TAG_MAP_KEY_CONNECTIVE}${category}`;

  const getTagMapValue = ({ id = '', category = '' }: { id?: string; category?: string }) =>
    id === ASTER_KEY
      ? `${convertCamelToCapitalStart(category)}:${ASTER_KEY}`
      : tagMap.value.get(getTagMapKey({ id, category })) ?? '';

  const setTagMapValue = ({
    id = '',
    category = '',
    name = '',
  }: {
    id?: string;
    category?: string;
    name?: string;
  }) => {
    tagMap.value.set(
      getTagMapKey({ id, category }),
      `${convertCamelToCapitalStart(category)}:${name}` ?? '',
    );
  };

  const fetchData = async (requests: TagsRequest[]) => {
    try {
      const { data } = await getTargetIdsListByTagTagControllerAxios({
        requests,
        frameName: FRAME_NAMES.TAG.TAG_ID_LIST,
      });
      if (data?.data?.length) {
        tagMap.value.clear();

        data.data.forEach((item) => {
          setTagMapValue({ id: item.id, category: item.category, name: item.name });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDataByCategories = async (categories: string[]) => {
    try {
      const { data } = await getTargetKeyValueTagControllerAxios({
        tagKind: 'tag',
        frameName: FRAME_NAMES.TAG.TARGET_TAGS_CATEGORY_BY_VALUE(categories.join(',')),
        categories,
      });
      if (data?.data?.length) {
        tagMap.value.clear();

        data.data.forEach((item) => {
          const category = item.tagKey;

          item.tagValue?.forEach((v) => {
            setTagMapValue({ id: v.id, category, name: v.name });
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllData = async (frameName?: string) => {
    try {
      const { data } = await getTargetKeyValueTagControllerAxios({
        tagKind: 'tag',
        frameName: frameName ?? FRAME_NAMES.TAG.TARGET_TAGS,
      });
      if (data?.data?.length) {
        tagMap.value.clear();

        data.data.forEach((item) => {
          const category = item.tagKey;

          item.tagValue?.forEach((v) => {
            setTagMapValue({ id: v.id, category, name: v.name });
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    TAG_MAP_KEY_CONNECTIVE,
    tagMap,
    fetchData,
    fetchAllData,
    fetchDataByCategories,
    getTagMapValue,
  };
});
