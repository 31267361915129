import dayjs, { Dayjs } from 'dayjs';
import {
  GLOBAL_TIME_LIVE_FORMAT,
  UTC_TIME_FORMAT,
} from '../components/molecules/timePeriodIndicator/timePeriodIndicator.define';
import { TimeFormat } from '../components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { ONE_DAY_IN_SECOND } from './define';

import { TimePeriodType } from './types';

export const getSecondTimeRangeByTimePeriod = (timePeriod: TimePeriodType): number => {
  const time = +timePeriod.slice(1, -1);
  const unit = timePeriod.slice(-1);
  let range: number;
  switch (unit) {
    case 'm':
      range = time * 60;
      break;
    case 'h':
      range = time * 3600;
      break;
    case 'd':
      range = time * ONE_DAY_IN_SECOND;
      break;
    default:
      range = time;
  }
  return range;
};

export const getSecondByDays = (days: number): number => {
  return days * ONE_DAY_IN_SECOND;
};

export const getDayBySecond = (second: number): number => {
  return Math.floor(second / ONE_DAY_IN_SECOND);
};

export const convertNanoSecondToMilliSecond = (nanoSecond: number): number => {
  return Math.floor(nanoSecond / 1_000_000);
};

export const convertNanoToFomattedTime = (nanoSecond: number): string => {
  return dayjs(convertNanoSecondToMilliSecond(nanoSecond)).format('YYYY-MM-DD HH:mm:ss');
};

export const isDateValid = (date: string): boolean => {
  return dayjs(date).isValid();
};

export const isDateTime = (value: any) =>
  typeof value === 'string' &&
  /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}:\d{2}(.\d+)?/.test(value) &&
  isDateValid(value);

/**
 * utc 타임을 dayjs 타입으로 변환
 * @param utc string | number | Date | dayjs.Dayjs
 * @returns Dayjs
 */
export const getTzTimeFromUtc = (utc: string | number | Date | Dayjs): Dayjs => {
  return dayjs.utc(utc).tz();
};

/**
 * @description utc 타임을 timezone에 맞는 format time으로 변환
 */
export const getFormattedTzTimeFromUtc = (
  utc: string | number | Date | Dayjs,
  format: TimeFormat = GLOBAL_TIME_LIVE_FORMAT,
) => {
  return getTzTimeFromUtc(utc).format(format);
};

/**
 * @description timezone time을 utc time으로 변환
 */
export const getUtcTimeFromTzTime = (tzTime: string | number | Date | Dayjs): Dayjs => {
  return dayjs.tz(tzTime).utc();
};

/**
 * @description timezone time을 utc format으로 변환
 */
export const getFormattedUtcTimeFromTzTime = (
  tzTime: string | number | Date | Dayjs = dayjs.tz(),
  format: TimeFormat = UTC_TIME_FORMAT,
) => {
  return getUtcTimeFromTzTime(tzTime).format(format);
};

/**
 * @description 현재 utc time을 반환
 */
export const getUtcTimeNow = (): Dayjs => {
  return dayjs.utc(); /// ===  dayjs().utc();
};

/**
 * @description 현재 utc format time을 반환
 */
export const getFormattedUtcTimeNow = (format: TimeFormat = UTC_TIME_FORMAT): string => {
  return getUtcTimeNow().format(format);
};

/**
 * @description 현재 timezone에 맞는 time을 반환
 */
export const getTzTimeNow = (): Dayjs => {
  return dayjs.tz();
};

/**
 * @description 현재 timezone에 맞는 format time을 반환
 */
export const getFormattedTzTimeNow = (format: TimeFormat = GLOBAL_TIME_LIVE_FORMAT): string => {
  return getTzTimeNow().format(format);
};
