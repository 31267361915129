/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { TopDiffStatisticsResponseItem } from '@/openapi/postgresqlV7/model';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getTopDiffStatisticsPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface TopDiffStatisticsState {
  topDiffStatistics: TopDiffStatisticsResponseItem[];
  errorStatusText: string;
}

export const topDiffStatistics: Module<TopDiffStatisticsState, RootState> = {
  namespaced: true,
  state: {
    topDiffStatistics: [],
    errorStatusText: '',
  },
  mutations: {
    setTopDiffStatistics(state: TopDiffStatisticsState, data: TopDiffStatisticsResponseItem[]) {
      state.topDiffStatistics = data;
    },
    setErrorStatusText: (state: TopDiffStatisticsState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchTopDiffStatistics: async ({ commit }, instanceId: string) => {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.STATICS_EVENTS_TOP_DIFF_STATISTICS;
      try {
        const { data } = await getTopDiffStatisticsPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setTopDiffStatistics', data.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopDiffStatistics: (state: TopDiffStatisticsState) => state.topDiffStatistics,
    getStatName: (state: TopDiffStatisticsState) =>
      state.topDiffStatistics.map((v) => v.statName).reverse(),
    getDiffRatio: (state: TopDiffStatisticsState) =>
      setEvBarChartData(state.topDiffStatistics.map((v) => v.diffRatio)),
    getErrorStatusText: (state: TopDiffStatisticsState) => state.errorStatusText,
  },
};
