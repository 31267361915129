import { computed, watch, inject, ref, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useInstanceStore } from '@/common/stores/instance';
import { DatabaseMultiViewTypes } from '@/database/utils/define';
import { type CurrentInstance } from '@/common/stores/instance';
import type { BreadCrumbMenuInfo, BreadCrumbMode } from '../breadCrumbs.types';
import { BREAD_CRUMBS_CHANGE_FAVORITE_KEY } from '../breadCrumbs.define';

export interface Props {
  menu: BreadCrumbMenuInfo;
  mode?: BreadCrumbMode;
  currentInstance?: CurrentInstance;
  currentMultiViewExpandedMenu?: string;
}

export interface Emit {
  (e: 'instance-multi-view-expand', evt: MouseEvent): void;
}

// Instance
const useInstanceElementLink = (isCurrent: Ref<boolean>, props: Props, emit: Emit) => {
  const { changeInstance } = useInstanceStore();

  const isMultiViewExpanded = ref(false);

  const isInstanceClickBlocked = computed(() => {
    return props.menu.instanceType === 'multiView' && !DatabaseMultiViewTypes[props.menu.dbType!];
  });

  const onInstanceClick = () => {
    if (
      (isCurrent.value &&
        props.menu.instanceType === props.currentInstance?.currentDbInstanceType) ||
      isInstanceClickBlocked.value
    ) {
      return;
    }

    changeInstance(props.menu);
  };

  const onInstanceMultiViewExpand = (evt: MouseEvent) => {
    emit('instance-multi-view-expand', evt);
  };

  watch(
    () => props.currentMultiViewExpandedMenu,
    (newValue) => {
      isMultiViewExpanded.value = newValue === props.menu.value;
    },
    { immediate: true },
  );

  return {
    isMultiViewExpanded,
    isInstanceClickBlocked,
    onInstanceClick,
    onInstanceMultiViewExpand,
  };
};

// Dashboard
const useDashboardElementLink = (props: Props) => {
  const changeFavorite = inject(BREAD_CRUMBS_CHANGE_FAVORITE_KEY, async () => {}); // breadCrumbs.setup.ts에서 제공하는 함수

  const onFavoriteClick = async () => {
    await changeFavorite(props.menu);
  };

  return {
    onFavoriteClick,
  };
};

export const getLinkWrapperTop = (evt: MouseEvent) => {
  if (evt.target instanceof HTMLElement) {
    const linkWrapper = evt.target.closest('.link-wrapper')?.getBoundingClientRect();
    return linkWrapper?.top ?? 0;
  }
  return 0;
};

export const setup = (props: Props, emit: Emit) => {
  const route = useRoute();
  const isCurrent = computed(() => {
    if (props.mode === 'dashboard') {
      return false;
    }
    return (
      route.path.includes(props.menu.value!) ||
      props.menu.value === props.currentInstance?.multiView.id ||
      props.menu.value === props.currentInstance?.singleView.id
    );
  });

  return {
    isCurrent,
    ...useInstanceElementLink(isCurrent, props, emit),
    ...useDashboardElementLink(props),
  };
};
