/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseDatabaseGroupFilter,
  DataBaseInstanceResponseV7,
  SuccessResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getDatabaseFilterListDatabaseInstanceV7ControllerAxios = ({
  tenantId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDatabaseGroupFilter> => {
  const localVarPath = '/api/v7/database/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getV7InstancesDatabaseInstanceV7ControllerAxios = ({
  databaseTypes,
  favoriteOnly,
  fromTime,
  instanceGroupIds,
  period,
  tenantId,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  databaseTypes?: Array<
    | 'altibase'
    | 'cassandra'
    | 'cubrid'
    | 'db2'
    | 'informix'
    | 'mariadb'
    | 'mongodb'
    | 'mysql'
    | 'oracle'
    | 'postgresql'
    | 'ppas'
    | 'redis'
    | 'sqlserver'
    | 'sybase'
    | 'tibero'
    | 'unknown'
  >;
  favoriteOnly?: boolean;
  fromTime?: string;
  instanceGroupIds?: Array<string>;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tenantId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DataBaseInstanceResponseV7> => {
  const localVarPath = '/api/v7/database/instances';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      databaseTypes,
      favoriteOnly,
      fromTime,
      instanceGroupIds,
      period,
      tenantId,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getInstanceGroupListDatabaseInstanceV7ControllerAxios = ({
  tenantId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDatabaseGroupFilter> => {
  const localVarPath = '/api/v7/database/instances/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchUserFavoriteDatabaseInstanceV7ControllerAxios = ({
  favorite,
  instanceId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  favorite: boolean;
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/database/instances/favorite';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      favorite,
      instanceId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getDatabaseFilterListDatabaseInstanceV7ControllerAxios,
  getV7InstancesDatabaseInstanceV7ControllerAxios,
  getInstanceGroupListDatabaseInstanceV7ControllerAxios,
  patchUserFavoriteDatabaseInstanceV7ControllerAxios,
};
