<script setup lang="ts">
import ApiTrace from '@/common/components/organisms/apiTraceWindow/ApiTrace.vue';
import {
  Props,
  Emit,
  setup,
} from '@/common/components/organisms/apiTraceWindow/apiTraceWindow.setup';
import DraggableWindow from '@/common/components/templates/DraggableWindow.vue';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();
const {
  targetFrameInfo,
  isShowWindow,
  subTitle,
  onExpandWindow,
  onChangePath,
  onChangeTargetHistory,
  onChangeApiHistory,
} = setup(props, emit);
</script>

<template>
  <draggable-window
    v-model:visible="isShowWindow"
    :size="{
      width: '1000px',
      height: '700px',
      minWidth: '500px',
      minHeight: '500px',
    }"
    :title="`API Trace ${subTitle}`"
    class-name="api-trace-window"
    size-type="CUSTOM"
    resizable
    maximizable
  >
    <template #header>
      <div class="api-trace-window__header">
        <span class="api-trace-window__header-title">
          {{ `API Trace ${subTitle}` }}
        </span>
        <ev-icon
          icon="ev-icon-external-link"
          class="api-trace-window__header-expand"
          @click="onExpandWindow"
        />
      </div>
    </template>
    <api-trace
      v-if="isShowWindow"
      :is-show="isShowWindow"
      :target-frame-info="targetFrameInfo"
      @change-path="onChangePath"
      @change-api-history="onChangeApiHistory"
      @change-target-history="onChangeTargetHistory"
    />
  </draggable-window>
</template>
<style lang="scss">
.api-trace-window {
  --api-trace-window-header-height: 39px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 68px);
    height: 100%;

    &-title {
      flex: 1;
      padding-right: 10px;

      @include shortening();
    }
  }

  .ev-window-top-right-icon {
    display: flex;
    top: 0 !important;
    align-items: center;
    height: var(--api-trace-window-header-height);
  }

  .ev-window-maximizable {
    margin-top: 2px !important;
  }

  .ev-window-header {
    display: flex;
    align-items: center;
    height: var(--api-trace-window-header-height);
    padding: 0 10px !important;
  }

  .ev-window-content {
    padding: 12px 0 0 !important;
  }
  .ev-icon-external-link {
    cursor: pointer;
  }
}
</style>
