<script setup lang="ts">
import BaseModalWindow from '@/common/components/templates/BaseModalWindow.vue';
import type { Props, Emits } from './customConfirmWindow.setup';
import { setup } from './customConfirmWindow.setup';

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const { t, isShowWindow, onClickCancel } = setup(props, emits);
</script>

<template>
  <base-modal-window
    v-model:visible="isShowWindow"
    :title="props.title ?? ''"
    :class-name="`custom-confirm-window ${!props.useTitleCloseBtn ? 'hide-title-close-btn' : ''}`"
    size-type="CUSTOM"
    :size="props?.customSize || { width: '560px', height: '160px' }"
  >
    <div class="custom-confirm-window__contents">
      <slot />
    </div>
    <div class="custom-confirm-window__footer">
      <div class="left-wrapper">
        <ev-button
          v-if="props?.useSaveAs"
          shape="border"
          @click="() => emits('save-as')"
        >
          {{ t('WORD.SAVE_AS') }}
        </ev-button>
      </div>
      <div class="right-wrapper">
        <ev-button
          v-if="props?.useCancel"
          @click="onClickCancel"
        >
          {{ t('WORD.CANCEL') }}
        </ev-button>
        <ev-button
          v-if="props?.useConfirm"
          type="primary"
          @click="() => emits('confirm')"
        >
          {{ t('WORD.OK') }}
        </ev-button>
      </div>
    </div>
  </base-modal-window>
</template>

<style lang="scss">
.custom-confirm-window {
  border-radius: 4px !important;

  &__contents {
    width: 100%;
    height: 100%;
  }

  &__footer {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .right-wrapper {
      display: flex;
      gap: 8px;
    }
  }
  /* stylelint-disable */
  &.hide-title-close-btn {
    .ev-window-top-right-icon > span {
      display: none !important;
    }
  }
  /* stylelint-enable */
}
</style>
