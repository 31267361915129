/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  DashboardWidgetMappingPatchRequestV7,
  SuccessResponse,
  WidgetMappingResponseV7,
  WidgetPackPatchRequest,
  WidgetPackRegisterRequestV7,
  WidgetPackResponseV7,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getWidgetMappingInfoDashboardWidgetV7ControllerAxios = ({
  startScreen,
  dashboardId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  startScreen: boolean;
  dashboardId?: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WidgetMappingResponseV7> => {
  const localVarPath = '/api/v7/dashboards/widgets';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      startScreen,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getWidgetPackInfoDashboardWidgetV7ControllerAxios = ({
  packType,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  packType?: 'PresetPack' | 'UserPack';
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WidgetPackResponseV7> => {
  const localVarPath = '/api/v7/dashboards/widgets/pack';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      packType,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchWidgetPackInfoDashboardWidgetV7ControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: WidgetPackPatchRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/widgets/pack';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchWidgetMappingInfoDashboardWidgetV7ControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: DashboardWidgetMappingPatchRequestV7;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/widgets';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const registerWidgetPackInfoDashboardWidgetV7ControllerAxios = ({
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  request: WidgetPackRegisterRequestV7;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/widgets/pack';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const exportWidgetPackAttributeDashboardWidgetV7ControllerAxios = ({
  packId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  packId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/widgets/pack/export';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      packId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const importWidgetPackAttributeDashboardWidgetV7ControllerAxios = ({
  jsonList,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  jsonList: Array<string>;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/widgets/pack/import';
  const httpMethod = 'post';

  const data: any = jsonList;
  const config: any = {
    params: {
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getWidgetMappingInfoDashboardWidgetV7ControllerAxios,
  getWidgetPackInfoDashboardWidgetV7ControllerAxios,
  patchWidgetPackInfoDashboardWidgetV7ControllerAxios,
  patchWidgetMappingInfoDashboardWidgetV7ControllerAxios,
  registerWidgetPackInfoDashboardWidgetV7ControllerAxios,
  exportWidgetPackAttributeDashboardWidgetV7ControllerAxios,
  importWidgetPackAttributeDashboardWidgetV7ControllerAxios,
};
