import { store } from '@/common/store';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { getCurrentInstance } from 'vue';
import { useInternational } from '../locale';
import { showErrorMsg, showLoadingMsg, showSuccessMsg } from '../utils/commonUtils';
import { ExportExcelOption, exportToExcelFromServer } from '../utils/exportExcelUtils';
import { useAbortApi } from '../utils/apiUtils';

export const useExportExcelFromServer = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const { abortApi, getSignal } = useAbortApi();

  const exportDefaultParams = {
    timezone: store.getters['myInfo/getTimeZone'],
    isReport: true,
    pageNum: undefined,
    pageSize: undefined,
    responseType: 'blob',
  };

  return async <RequestParams, Response>(
    exportToExcelApiFn: (params: RequestParams) => PromiseAxiosResponse<Response>,
    params: RequestParams,
    option: ExportExcelOption = {},
  ) => {
    const { hide: hideLoadingMsg } = showLoadingMsg({
      ctx,
      msgStr: t('NOTI.DOWNLOAD.LOADING'),
      showClose: true,
      onLoadingMsgClosed: () => {
        abortApi();
      },
    });
    try {
      await exportToExcelFromServer(
        exportToExcelApiFn,
        { ...exportDefaultParams, ...params, signal: getSignal() },
        option,
      );
      showSuccessMsg(ctx, t('NOTI.DOWNLOAD.SUCCESSFUL'));
    } catch (e) {
      const error = e as Error;
      if (error.message === 'canceled') return;
      console.log(error);
      showErrorMsg(ctx, t('NOTI.DOWNLOAD.FAILED'));
    } finally {
      hideLoadingMsg();
    }
  };
};
