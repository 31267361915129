import axios from 'axios';
import { setInterceptors } from '@/common/api/interceptors';

/**
 * main thread에서만 사용되는 instance들
 */
// 기본 인스턴스
export const baseInstance = axios.create();
// 테넌트 엔트리 - 1차 로그인 완료 후
export const entryAccessTokenInstance = setInterceptors(axios.create(), 'auth/getEntryAccessToken');

// 테넌트 엔트리 - 1차 로그인 완료 후
const createApiInstanceWithEntryAuth = () => {
  const instance = axios.create({
    baseURL: '/api/v1',
  });
  return setInterceptors(instance, 'auth/getEntryAccessToken');
};

export const apiEntryAuthInstance = createApiInstanceWithEntryAuth();
