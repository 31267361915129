<script lang="ts" setup>
interface Props {
  unit?: string;
  pos?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'start' | 'center' | 'end';
}

const props = withDefaults(defineProps<Props>(), {
  unit: '',
  pos: 'left',
  align: 'center',
});
</script>

<template>
  <div
    :class="[
      'unit-container',
      {
        'row-direction': props.pos === 'left' || props.pos === 'right',
        'column-direction': props.pos === 'top' || props.pos === 'bottom',
        'only-content': !props.unit,
      },
    ]"
  >
    <!-- left & top unit -->
    <div
      v-if="unit && (props.pos === 'left' || props.pos === 'top')"
      :class="['unit-container__unit-wrapper', `${props.align}`, `${props.pos}`]"
    >
      <span class="unit-area">
        {{ props.unit }}
      </span>
    </div>

    <!-- content -->
    <div class="unit-container__content">
      <slot />
    </div>

    <!-- right & bottom -->
    <div
      v-if="unit && (props.pos === 'right' || props.pos === 'bottom')"
      :class="[
        'unit-container__unit-wrapper',
        `${props.align}`,
        `${props.pos}`,
        {
          'row-direction': props.pos === 'right',
          'column-direction': props.pos === 'bottom',
        },
      ]"
    >
      <span class="unit-area">
        {{ props.unit }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.unit-container {
  --line-chart-widget-unit-area-size: 15px;

  display: flex;
  width: 100%;
  height: 100%;

  &__unit-wrapper {
    display: flex;
    width: var(--line-chart-widget-unit-area-size);
    height: 100%;
    overflow: visible;

    .unit-area {
      display: inline-block;
      font-size: 10px;
      color: var(--chart-unit-color);
      white-space: nowrap;
    }
  }

  &.row-direction .unit-container__unit-wrapper {
    justify-content: center;
    width: var(--line-chart-widget-unit-area-size);
    height: 100%;

    &.left.start {
      align-items: end;
      .unit-area {
        transform: rotate(-90deg) translateX(50%);
      }
    }
    &.left.center {
      align-items: center;
      .unit-area {
        transform: rotate(-90deg);
      }
    }
    &.left.end {
      align-items: start;
      .unit-area {
        transform: rotate(-90deg) translateX(-50%);
      }
    }

    &.right.start {
      align-items: start;
      .unit-area {
        transform: rotate(90deg) translateX(50%);
      }
    }
    &.right.center {
      align-items: center;
      .unit-area {
        transform: rotate(90deg);
      }
    }
    &.right.end {
      align-items: end;
      .unit-area {
        transform: rotate(90deg) translateX(-50%);
      }
    }
  }
  &.row-direction .unit-container__content {
    width: calc(100% - var(--line-chart-widget-unit-area-size));
    height: 100%;
  }

  &.column-direction {
    flex-direction: column;
  }
  &.column-direction .unit-container__unit-wrapper {
    align-items: center;
    width: 100%;
    height: var(--line-chart-widget-unit-area-size);

    &.start {
      justify-content: start;
    }
    &.center {
      justify-content: center;
    }
    &.end {
      justify-content: end;
    }
  }
  &.column-direction .unit-container__content {
    width: 100%;
    height: calc(100% - var(--line-chart-widget-unit-area-size));
  }

  &.only-content .unit-container__content {
    width: 100%;
    height: 100%;
  }
}
</style>
