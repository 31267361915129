import { computed, reactive, ref } from 'vue';
import { throttle } from 'lodash-es';
import { LabelType } from '@/common/utils/types';

interface Props {
  label?: string;
  type?: LabelType;
  clickItemFn?: null | (() => void);
  tooltipText?: string;
}

const useLabelItem = (props: Props, isHoverItem) => {
  const labelClass = computed<string>(() => {
    switch (props.type) {
      case 'primary':
        return 'label-item__label--primary';
      case 'ghost':
        return 'label-item__label--ghost';
      case 'bold':
        return 'label-item__label--bold';
      default:
        return '';
    }
  });
  const linkedItemClass = reactive<Record<string, boolean>>({
    'label-item--is-linked': !!props.clickItemFn,
  });
  const isValidClickItemFn: boolean = typeof props.clickItemFn === 'function';
  const onClickItem = (): void => {
    isHoverItem.value = false;
    if (isValidClickItemFn) {
      props.clickItemFn!();
    }
  };

  return {
    labelClass,
    linkedItemClass,
    isValidClickItemFn,
    onClickItem,
  };
};

const useTooltip = (props: Props) => {
  const THROTTLE_WAIT_MS = 30;
  const mouseoverEvt = ref<MouseEvent | null>(null);
  const isHoverItem = ref<boolean>(false);

  const isValidClickItemFn = props.clickItemFn && typeof props.clickItemFn === 'function';

  const onMouseoverItem = (e: MouseEvent) => {
    if (!isValidClickItemFn) {
      return;
    }
    mouseoverEvt.value = e;
    isHoverItem.value = true;
  };
  const onMousemoveItem = throttle((e: MouseEvent) => {
    if (!isValidClickItemFn) {
      return;
    }
    mouseoverEvt.value = e;
  }, THROTTLE_WAIT_MS);
  const onMouseleaveItem = () => {
    if (!isValidClickItemFn) {
      return;
    }
    mouseoverEvt.value = null;
    isHoverItem.value = false;
  };

  return {
    mouseoverEvt,
    isHoverItem,
    onMouseoverItem,
    onMousemoveItem,
    onMouseleaveItem,
  };
};

export { Props, useLabelItem, useTooltip };
