<script setup lang="ts">
import { numberWithComma } from '@/common/utils/commonUtils';
import { StatusItem } from '@/infrastructure/components/statusToolbar/statusToolbar.setup';

interface Props {
  statusList: StatusItem[];
}

const props = defineProps<Props>();

const getDisplayValue = (v: any) => {
  const value = numberWithComma(v);

  if (value === false || value === '') {
    return '-';
  }

  return value;
};
</script>

<template>
  <ul class="status-toolbar">
    <li
      v-for="item in props.statusList"
      :key="item.name"
      :class="['status-toolbar__item', 'status-item', `status-item--${item.field.toLowerCase()}`]"
    >
      <ev-icon
        v-if="item.icon"
        :icon="item.icon"
        :style="{
          backgroundColor: item.color,
        }"
      />
      <span class="status-item__name">
        {{ item.name }}
      </span>
      <span class="status-item__value">
        {{ getDisplayValue(item.value) }}
      </span>
    </li>
  </ul>
</template>

<style lang="scss">
.status-toolbar {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 100%;
  color: var(--status-toolbar-color);

  .status-item {
    display: flex;
    align-items: center;

    &--total,
    &__value {
      font-weight: 700;
      color: var(--status-toolbar-count-color);
    }
    &__name {
      margin: 0 6px 0 2px;
    }
  }
}
</style>
