import { XmServerProperty } from '@/openapi/secure/model';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export type PropertyType = XmServerProperty;

export const usePropertyStore = defineStore('property', () => {
  const propertyData = ref({} as PropertyType);
  const isSessionTimerActive = () => !!propertyData.value.sessionTimeoutMode;

  return {
    propertyData,
    isSessionTimerActive,
  };
});
