<template>
  <base-menu />
  <div class="config-viewport">
    <navigation-header use-path />
    <div class="config-viewport__inner">
      <router-view />
    </div>
  </div>
  <ev-loading v-model="isLoading" />
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import NavigationHeader from '@/common/components/molecules/navigationHeader/NavigationHeader.vue';

export default defineComponent({
  name: 'ConfigViewport',
  components: {
    NavigationHeader,
  },
  setup() {
    const store: Store<RootState> = useStore();

    const isLoading = computed(() => store.getters.getIsLoading);

    onBeforeMount(() => {
      document.body.classList.add('no-min-width');
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('no-min-width');
    });

    return {
      isLoading,
    };
  },
});
</script>
