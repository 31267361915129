export const KUBERNETES = {
  KUBERNETES_POD_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_POD_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_NODE_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_NODE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_SERVICE_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_SERVICE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_INGRESS_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_INGRESS_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_PVC_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_PVC_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_PV_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_PV_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_STORAGE_CLASS_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_STORAGE_CLASS_DETAIL_DASHBOARD_SETTING_LIST',

  GRIDCOLUMNS_kubernetes_pod_podList: 'GRIDCOLUMNS_kubernetes_pod_podList',
  GRIDCOLUMNS_kubernetes_podsListDetail_containers:
    'GRIDCOLUMNS_kubernetes_podsListDetail_containers',
  GRIDCOLUMNS_kubernetes_namespace_namespaceList: 'GRIDCOLUMNS_kubernetes_namespace_namespaceList',
  GRIDCOLUMNS_kubernetes_node_nodeList: 'GRIDCOLUMNS_kubernetes_node_nodeList',
  GRIDCOLUMNS_kubernetes_nodeDetail_podList: 'GRIDCOLUMNS_kubernetes_nodeDetail_podList',
  GRIDCOLUMNS_kubernetes_workloads_deploymentsList:
    'GRIDCOLUMNS_kubernetes_workloads_deploymentsList',
  GRIDCOLUMNS_kubernetes_workloads_stateFulSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_stateFulSetsList',
  GRIDCOLUMNS_kubernetes_workloads_daemonSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_daemonSetsList',
  GRIDCOLUMNS_kubernetes_workloads_replicaSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_replicaSetsList',
  GRIDCOLUMNS_kubernetes_deploymentsDetail_podList:
    'GRIDCOLUMNS_kubernetes_deploymentsDetail_podList',
  GRIDCOLUMNS_kubernetes_statefulSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_statefulSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_daemonSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_daemonSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_replicaSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_replicaSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_jobs_jobList: 'GRIDCOLUMNS_kubernetes_jobs_jobList',
  GRIDCOLUMNS_kubernetes_jobsDetail_podList: 'GRIDCOLUMNS_kubernetes_jobsDetail_podList',
  GRIDCOLUMNS_kubernetes_cronJobs_cronJobList: 'GRIDCOLUMNS_kubernetes_cronJobs_cronJobList',
  GRIDCOLUMNS_kubernetes_cronJobsDetail_jobList: 'GRIDCOLUMNS_kubernetes_cronJobsDetail_jobList',
  GRIDCOLUMNS_kubernetes_cronJobsDetail_podList: 'GRIDCOLUMNS_kubernetes_cronJobsDetail_podList',
  GRIDCOLUMNS_kubernetes_service_serviceList: 'GRIDCOLUMNS_kubernetes_service_serviceList',
  GRIDCOLUMNS_kubernetes_serviceDetail_portList: 'GRIDCOLUMNS_kubernetes_serviceDetail_portList',
  GRIDCOLUMNS_kubernetes_serviceDetail_podList: 'GRIDCOLUMNS_kubernetes_serviceDetail_podList',
  GRIDCOLUMNS_kubernetes_ingress_ingressList: 'GRIDCOLUMNS_kubernetes_ingress_ingressList',
  GRIDCOLUMNS_kubernetes_ingressDetail_rulesList: 'GRIDCOLUMNS_kubernetes_ingressDetail_rulesList',
  GRIDCOLUMNS_kubernetes_ingressDetail_loadBalancerList:
    'GRIDCOLUMNS_kubernetes_ingressDetail_loadBalancerList',
  GRIDCOLUMNS_kubernetes_configMaps_configMapsList:
    'GRIDCOLUMNS_kubernetes_configMaps_configMapsList',
  GRIDCOLUMNS_kubernetes_persistentVolumeClaim_persistentVolumeClaimList:
    'GRIDCOLUMNS_kubernetes_persistentVolumeClaim_persistentVolumeClaimList',
  GRIDCOLUMNS_kubernetes_persistentVolume_persistentVolumeList:
    'GRIDCOLUMNS_kubernetes_persistentVolume_persistentVolumeList',
  GRIDCOLUMNS_kubernetes_storageClasses_storageClassesList:
    'GRIDCOLUMNS_kubernetes_storageClasses_storageClassesList',
  GRIDCOLUMNS_kubernetes_storageClassesDetail_persistentVolumesList:
    'GRIDCOLUMNS_kubernetes_storageClassesDetail_persistentVolumesList',
  GRIDCOLUMNS_kubernetes_roles_rolesList: 'GRIDCOLUMNS_kubernetes_roles_rolesList',
  GRIDCOLUMNS_kubernetes_rolesBindings_rolesBindingsList:
    'GRIDCOLUMNS_kubernetes_rolesBindings_rolesBindingsList',
  GRIDCOLUMNS_kubernetes_clusterRoles_clusterRolesList:
    'GRIDCOLUMNS_kubernetes_clusterRoles_clusterRolesList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindings_clusterRolesBindingsList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindings_clusterRolesBindingsList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_referenceList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_referenceList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_bindingsList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_bindingsList',
  GRIDCOLUMNS_kubernetes_serviceAccounts_serviceAccountsList:
    'GRIDCOLUMNS_kubernetes_serviceAccounts_serviceAccountsList',
  GRIDCOLUMNS_kubernetes_detailEventGrid: 'GRIDCOLUMNS_kubernetes_detailEventGrid',
  GRIDCOLUMNS_kubernetes_top10Stat: 'GRIDCOLUMNS_kubernetes_top10Stat',

  FILTER_kubernetes_objectTopology: 'FILTER_kubernetes_objectTopology',
  FILTER_kubernetes_clusterTopology: 'FILTER_kubernetes_clusterTopology',
  FILTER_kubernetes_cluster: 'FILTER_kubernetes_cluster',
  FILTER_kubernetes_pod: 'FILTER_kubernetes_pod',
  FILTER_kubernetes_namespace: 'FILTER_kubernetes_namespace',
  FILTER_kubernetes_node: 'FILTER_kubernetes_node',

  FILTER_kubernetes_workLoad: 'FILTER_kubernetes_workLoad',

  FILTER_kubernetes_workLoad_job: 'FILTER_kubernetes_workLoad_job',
  FILTER_kubernetes_workLoad_cronJob: 'FILTER_kubernetes_workLoad_cronJob',

  FILTER_kubernetes_network_service: 'FILTER_kubernetes_network_service',
  FILTER_kubernetes_network_ingress: 'FILTER_kubernetes_network_ingress',
  FILTER_kubernetes_configStorage_configMap: 'FILTER_kubernetes_configStorage_configMap',
  FILTER_kubernetes_configStorage_persistentVolumeClaim:
    'FILTER_kubernetes_configStorage_persistentVolumeClaim',
  FILTER_kubernetes_configStorage_persistentVolume:
    'FILTER_kubernetes_configStorage_persistentVolume',
  FILTER_kubernetes_configStorage_storageClass: 'FILTER_kubernetes_configStorage_storageClass',
  FILTER_kubernetes_accessControl_role: 'FILTER_kubernetes_accessControl_role',
  FILTER_kubernetes_accessControl_roleBinding: 'FILTER_kubernetes_accessControl_roleBinding',
  FILTER_kubernetes_accessControl_clusterRole: 'FILTER_kubernetes_accessControl_clusterRole',
  FILTER_kubernetes_accessControl_clusterRoleBinding:
    'FILTER_kubernetes_accessControl_clusterRoleBinding',
  FILTER_kubernetes_accessControl_serviceAccount: 'FILTER_kubernetes_accessControl_serviceAccount',
};
