import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { StatInfo } from '@/postgresql/store/singleView/singleViewEnv';
import { DEFAULT_STAT_CHART_OPTION } from '@/common/utils/define';

export interface State {
  statChartOption: StatInfo;
}

export const statChartOption: Module<State, RootState> = {
  namespaced: true,
  state: {
    statChartOption: {
      name: '',
      displayName: '',
      stats: [],
      frameId: '',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
      chartType: undefined,
      comparisonDate: undefined,
    },
  },
  mutations: {
    setStatChartOption(state: State, previewOption: StatInfo) {
      state.statChartOption = previewOption;
    },
  },
  getters: {
    getStatChartOption: (state: State) => state.statChartOption,
  },
};
