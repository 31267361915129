import { WasType } from '@/application/utils/types';
import { getWasInfoApplicationWasControllerAxios } from '@/openapi/application/api/application-was-controller-api';
import { getContainerInfoInfraContainerV7ControllerAxios } from '@/openapi/infra/api/infra-container-v7-controller-api';
import { getHostInfoInfraHostV7ControllerAxios } from '@/openapi/infra/api/infra-host-v7-controller-api';
import { getDeviceInfoNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { getServiceDetailXmServiceControllerAxios } from '@/openapi/service/api/xm-service-controller-api';
import {
  type AdditionForSqlDetailMySQL,
  type AdditionForSqlDetailOracle,
  type AdditionForSqlDetailPG,
  type AdditionForSqlDetailSqlServer,
} from '@/database/components/sqlDetail/instanceSqlDetail.types';
import { FRAME_NAMES } from '../define/apiTrace.define';
import { DATABASE_DETAIL, KUBER_DETAIL } from '../define/slideDetail.define';
import { AddTabPanelInfo, TOTAL_DETAIL } from '../stores/slide-detail';
import { DB_TYPE } from '../utils';
import { standardTimeToUtcZeroTime } from '../utils/commonUtils';

interface DetailInfo {
  targetId: string;
  targetName?: string;
  addition?: Record<string, any>;
}

const getWasTabPanelInfo = async ({
  targetId,
  targetName = '',
  targetType = '',
  frameName = FRAME_NAMES.WAS_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  if (targetId && targetName && targetType) {
    return {
      type: TOTAL_DETAIL.WAS,
      name: targetName,
      was: {
        wasId: targetId,
        type: targetType as WasType,
      },
    };
  }

  const { data } = await getWasInfoApplicationWasControllerAxios({
    wasId: targetId,
    frameName,
  });
  const { wasName, type } = data?.data?.[0] ?? {};
  return {
    type: TOTAL_DETAIL.WAS,
    name: targetName ?? wasName ?? '',
    was: {
      wasId: targetId,
      type: (type as WasType) ?? 'None',
    },
  };
};

interface K8sPanelParam {
  type: (typeof KUBER_DETAIL)[keyof typeof KUBER_DETAIL];
  targetId: string;
  targetName?: string;
}
const getKubernetesTabPanelInfo = async ({
  type,
  targetId,
  targetName = '',
}: K8sPanelParam): Promise<AddTabPanelInfo> => {
  return {
    type,
    name: targetName ?? '',
    addition: {
      uid: targetId,
    },
  };
};

const getPodTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.POD,
    name: targetName ?? '',
    addition: {
      uid: targetId,
    },
  };
};

const getNameSpaceTabPanelInfo = async ({
  targetId,
  targetName = '',
}): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.NAMESPACE,
    name: targetName ?? '',
    addition: {
      uid: targetId,
    },
  };
};

const getNodeTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.NODE,
    name: targetName ?? '',
    addition: {
      uid: targetId,
    },
  };
};

const getDbTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  // instance detail 내에서 targetId 기준으로 조회하여 상세 내역 조회하기에 api 호출 불필요
  return {
    type: TOTAL_DETAIL.INSTANCE,
    name: targetName ?? '',
    instance: {
      instanceId: targetId,
    },
  };
};

const getHostTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.HOST_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getHostInfoInfraHostV7ControllerAxios({
    hostId: targetId,
    frameName,
  });
  const { hostName, server, containerHostId } = data?.data?.[0]?.meta ?? {};
  const addition:
    | {
        server: 'host';
      }
    | { server: 'container_host'; containerHostId: string } =
    server === 'host'
      ? { server: 'host' }
      : { server: 'container_host', containerHostId: containerHostId ?? '' };
  return {
    type: TOTAL_DETAIL.HOST,
    host: targetId,
    name: hostName ?? targetName,
    addition,
  };
};

const getHostProcessTabPanelInfo = async ({
  targetId,
  targetName = '',
}): Promise<AddTabPanelInfo> => {
  const panelInfo = await getHostTabPanelInfo({ targetId, targetName });
  return {
    ...panelInfo,
    state: {
      selectedTab: 'process',
    },
  };
};

const getContainerTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.CONTAINER_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getContainerInfoInfraContainerV7ControllerAxios({
    containerId: targetId,
    frameName,
  });
  const { server } = data?.data?.[0] ?? {};
  const { containerName, hostId } = data?.data?.[0]?.containerHost ?? {};
  const addition:
    | {
        server: 'k8s';
      }
    | { server: 'container_host'; containerHostId: string } =
    server === 'k8s'
      ? { server: 'k8s' }
      : { server: 'container_host', containerHostId: hostId ?? '' };
  return {
    type: TOTAL_DETAIL.CONTAINER,
    name: containerName ?? targetName,
    container: targetId,
    addition,
  };
};

const getNetworkDeviceTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.NETWORK_DEVICE_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getDeviceInfoNdmMonitoringControllerAxios({
    deviceId: targetId,
    frameName,
  });
  const { aliasName, ip } = data?.data?.[0] ?? {};
  return {
    type: TOTAL_DETAIL.NETWORK_DEVICE,
    name: `${aliasName ?? targetName} | IP : ${ip}`,
    init: true,
    addition: {
      deviceId: targetId,
      networkDeviceName: aliasName ?? targetName,
    },
  };
};

const getServiceTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.BUSINESS_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getServiceDetailXmServiceControllerAxios({
    serviceId: targetId,
    frameName,
  });
  const { name } = data?.data?.[0] ?? {};

  return {
    type: TOTAL_DETAIL.SERVICE_LIST,
    name: name ?? targetName,
    addition: {
      serviceId: targetId,
    },
  };
};

const getSqlTabPanelInfo = async (detailInfo: DetailInfo): Promise<AddTabPanelInfo | null> => {
  switch (detailInfo.addition?.dbType) {
    case DB_TYPE.ORACLE: {
      const addition: AdditionForSqlDetailOracle = {
        dbType: DB_TYPE.ORACLE,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        schema: detailInfo.addition.schema,
        module: detailInfo.addition.module,
        planHashValue: detailInfo.addition.planHashValue,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    case DB_TYPE.MYSQL: {
      const addition: AdditionForSqlDetailMySQL = {
        dbType: DB_TYPE.MYSQL,
        digest: detailInfo.addition.digest,
        schema: detailInfo.addition.schema,
        instanceId: detailInfo.addition.instanceId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        sqlId: detailInfo.addition.sqlId,
        sqlTextHashId: detailInfo.addition.sqlTextHashId,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.digest}`,
        addition,
      };
    }
    case DB_TYPE.SQLSERVER: {
      const addition: AdditionForSqlDetailSqlServer = {
        dbType: DB_TYPE.SQLSERVER,
        database: detailInfo.addition.databaseName,
        object: detailInfo.addition.objectName,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        sqlTextHashId: detailInfo.addition.sqlTextHashId,
        sessionId: detailInfo.addition.sessionId,
        sqlHash: detailInfo.addition.sqlHash,
        sqlHandle: detailInfo.addition.sqlHandle,
        statementStartOffset: detailInfo.addition.statementStartOffset,
        statementEndOffset: detailInfo.addition.statementEndOffset,
        planHash: detailInfo.addition.planHash,
        planHandle: detailInfo.addition.planHandle,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    case DB_TYPE.POSTGRESQL: {
      const addition: AdditionForSqlDetailPG = {
        dbType: DB_TYPE.POSTGRESQL,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        queryId: detailInfo.addition.queryId,
        dbId: detailInfo.addition.dbId,
        dbUserId: detailInfo.addition.dbUserId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    default:
      return null;
  }
};

const getSessionTabPanelInfo = async (detailInfo: DetailInfo): Promise<AddTabPanelInfo | null> => {
  switch (detailInfo.addition?.dbType) {
    case DB_TYPE.ORACLE:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.sid}`,
        session: {
          dbType: DB_TYPE.ORACLE,
          [DB_TYPE.ORACLE]: {
            instanceId: detailInfo.addition.instanceId,
            sid: detailInfo.addition.sid,
            serialNumber: detailInfo.addition.serialNumber,
            groupId: '',
            fromTime: detailInfo.addition.fromTime,
            toTime: detailInfo.addition.toTime,
          },
        },
      };
    case DB_TYPE.MYSQL:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.threadId}`,
        session: {
          dbType: DB_TYPE.MYSQL,
          [DB_TYPE.MYSQL]: {
            instanceId: detailInfo.addition.instanceId,
            threadId: detailInfo.addition.threadId,
            digest: detailInfo.addition.digest,
            init: true, // search session을 "처음" 띄울 때 fromTime, toTime을 현재~과거 기준이 아닌 과거~과거로 설정할 경우 true
            fromTime: standardTimeToUtcZeroTime(detailInfo.addition.fromTime),
            toTime: standardTimeToUtcZeroTime(detailInfo.addition.toTime),
          },
        },
      };
    case DB_TYPE.SQLSERVER:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.sessionId}`,
        session: {
          dbType: DB_TYPE.SQLSERVER,
          [DB_TYPE.SQLSERVER]: {
            instanceId: detailInfo.addition.instanceId,
            sessionId: detailInfo.addition.sessionId,
            init: true,
            fromTime: standardTimeToUtcZeroTime(detailInfo.addition.fromTime),
            toTime: standardTimeToUtcZeroTime(detailInfo.addition.toTime),
          },
        },
      };
    default:
      return null;
  }
};

const getActiveTxnTabPanelInfo = async ({
  targetId,
  targetName = '',
  addition: { chartData } = {},
}: DetailInfo): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.ACTIVE_TRANSACTION,
    name: targetName ?? '',
    addition: {
      targetId,
      chartData,
    },
  };
};

export {
  getWasTabPanelInfo,
  getKubernetesTabPanelInfo,
  getPodTabPanelInfo,
  getNameSpaceTabPanelInfo,
  getNodeTabPanelInfo,
  getDbTabPanelInfo,
  getHostTabPanelInfo,
  getHostProcessTabPanelInfo,
  getContainerTabPanelInfo,
  getNetworkDeviceTabPanelInfo,
  getServiceTabPanelInfo,
  getSqlTabPanelInfo,
  getSessionTabPanelInfo,
  getActiveTxnTabPanelInfo,
};
