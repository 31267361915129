<template>
  <div class="label-item-group">
    <slot />
  </div>
</template>

<style lang="scss">
.label-item-group {
  > *:not(:last-child)::after {
    @include label-splitter-style();
  }
}
</style>
