import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { setEvBarChartData } from '@/common/utils/chartUtils';
import { convertMsToSec, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { TopObjectListDeadTupleItem, VacuumingBackendItemV7 } from '@/openapi/postgresqlV7/model';
import { getAdminReferencePostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface TopDeadTuple
  extends Required<Pick<TopObjectListDeadTupleItem, 'schemaName' | 'objectName' | 'deadTuple'>> {
  collectTime: string;
}

export interface AdminRefVacuum {
  topDeadTuple: TopDeadTuple[];
  topVacuum: VacuumingBackendItemV7[];
  errorStatusText: string;
}

export const adminRefVacuum: Module<AdminRefVacuum, RootState> = {
  namespaced: true,
  state: {
    topDeadTuple: [],
    topVacuum: [],
    errorStatusText: '',
  },
  mutations: {
    setTopDeadTuple(state: AdminRefVacuum, data: TopDeadTuple[]) {
      state.topDeadTuple = data;
    },
    setTopVacuum(state: AdminRefVacuum, data: VacuumingBackendItemV7[]) {
      state.topVacuum = data;
    },
    setErrorStatusText: (state: AdminRefVacuum, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchAdminReference({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.ADMIN_REFERENCE_VACUUM;
      try {
        const { data } = await getAdminReferencePostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setTopDeadTuple', data?.topDeadTuple?.data);
        commit('setTopVacuum', data?.topVacuumProcess?.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getTopVacuum: (state: AdminRefVacuum) => state.topVacuum,
    getProcessElapsed: (s: AdminRefVacuum) =>
      setEvBarChartData(s.topVacuum.map((v) => +convertMsToSec(v.elapsedTime!))),
    getProcessPid: (s: AdminRefVacuum) => s.topVacuum.map((v) => v.pid).reverse(),
    getTopDeadTuple: (state: AdminRefVacuum) => state.topDeadTuple,
    getDeadTupleObjectName: (s: AdminRefVacuum) =>
      s.topDeadTuple.map((v) => v.objectName).reverse(),
    getDeadTupleCount: (s: AdminRefVacuum) =>
      setEvBarChartData(s.topDeadTuple.map((v) => v.deadTuple)),
    getErrorStatusText: (state: AdminRefVacuum) => state.errorStatusText,
  },
};
