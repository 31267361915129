import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useAbortApi } from '@/common/utils/apiUtils';
import { InstanceId, useInstanceIds } from '@/database/components/overviewAlert/overviewAlert.uses';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { snakeCase } from 'lodash-es';
import { getCommonAlertCountAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { useRepeat } from '@/worker/composables/useRepeat';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { useStore } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';

interface CurrentAlertCount {
  criticalCount: number;
  warningCount: number;
}

const useAlertCount = ({ currentRouteInfo, instanceIds }) => {
  const { getSignal, abortApi } = useAbortApi();
  const isAlertLoading = ref(false);
  const errorStatusText = ref('');

  const frameName = computed(() => {
    const { dbType, view } = currentRouteInfo.value;
    return FRAME_NAMES[`${dbType.toUpperCase()}_${snakeCase(view).toUpperCase()}`].ALERT_COUNT;
  });

  const currentAlertCount = ref<CurrentAlertCount>({
    criticalCount: 0,
    warningCount: 0,
  });
  const getTotalCount = () => {
    const { criticalCount, warningCount } = currentAlertCount.value;
    return (criticalCount ?? 0) + (warningCount ?? 0);
  };
  const currentTotalCount = computed(() => getTotalCount());
  const totalBadgeCount = computed(() => {
    const total = getTotalCount();
    return total > 99 ? '99+' : `${total}`;
  });

  const initCount = () => {
    currentAlertCount.value = {
      criticalCount: 0,
      warningCount: 0,
    };
  };

  const fetchAlertCount = async () => {
    if (!instanceIds.value?.length) {
      initCount();
      return;
    }
    try {
      abortApi();
      isAlertLoading.value = true;
      const { data } = await getCommonAlertCountAlertCommonControllerAxios({
        targetKind: 'database',
        subTargetKinds: ['database'],
        targetIds: instanceIds.value,
        signal: getSignal(),
        frameName: frameName.value,
      });
      if (data?.data) {
        currentAlertCount.value = data.data as CurrentAlertCount;
        errorStatusText.value = '';
      }
    } catch (e) {
      console.log(e);
      errorStatusText.value = getAPIErrorStatusText(e);
    } finally {
      isAlertLoading.value = false;
    }
  };

  const store = useStore();
  const singleViewAlertsFetch = {
    [DB_TYPE.MYSQL]: async (instanceId: string) => {
      await store.dispatch('postgresqlInstanceAlerts/fetchMysqlInstanceAlerts', instanceId);
    },
    [DB_TYPE.POSTGRESQL]: async (instanceId: string) => {
      await store.dispatch('postgresqlInstanceAlerts/fetchInstanceAlerts', instanceId);
    },
  };
  const multiViewAlertsFetch = {
    [DB_TYPE.MYSQL]: async (instanceIdList: string[]) => {
      await store.dispatch('mysqlRankMetric/fetchAlertList', instanceIdList);
    },
    [DB_TYPE.POSTGRESQL]: async (instanceIdList: string[]) => {
      await store.dispatch('postgresqlRankMetric/fetchAlertList', instanceIdList);
    },
  };
  const fetchAlertLogs = async () => {
    const { dbType, view } = currentRouteInfo.value;
    if (view.toLowerCase().includes('single') && instanceIds.value?.[0]) {
      const instanceId = instanceIds.value?.[0];
      await singleViewAlertsFetch?.[dbType.toLowerCase()]?.(instanceId);
    } else if (view.toLowerCase().includes('multi') && instanceIds.value?.length) {
      await multiViewAlertsFetch?.[dbType.toLowerCase()]?.(instanceIds.value);
    }
  };

  const updatedFetchFlag = ref<number>();
  const fetchFn = () => {
    updatedFetchFlag.value = +new Date();
    fetchAlertCount();
    fetchAlertLogs();
  };

  const { resetFetch, clearFetch } = useRepeat(fetchFn, 5_000);

  return {
    isAlertLoading,
    updatedFetchFlag,
    currentAlertCount,
    currentTotalCount,
    totalBadgeCount,
    errorStatusText,
    resetFetch,
    clearFetch,
  };
};

export const useOverviewAlertStore = defineStore('overviewAlert', () => {
  const { currentRouteInfo, getInstanceIds } = useInstanceIds();
  const instanceIds = computed<InstanceId[]>(() => getInstanceIds());

  const {
    isAlertLoading,
    updatedFetchFlag,
    currentAlertCount,
    currentTotalCount,
    totalBadgeCount,
    errorStatusText,
    resetFetch,
    clearFetch,
  } = useAlertCount({ currentRouteInfo, instanceIds });

  const isShowAlertDetail = ref(false);
  const openAlertSlide = () => {
    isShowAlertDetail.value = true;
  };

  watch(
    instanceIds,
    () => {
      if (!isShowAlertDetail.value) {
        resetFetch();
      } else {
        clearFetch();
      }
    },
    { immediate: true },
  );
  return {
    isAlertLoading,
    updatedFetchFlag,
    currentAlertCount,
    currentTotalCount,
    totalBadgeCount,
    errorStatusText,
    resetFetch,
    clearFetch,

    isShowAlertDetail,
    openAlertSlide,
  };
});
