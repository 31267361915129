import { defineAsyncComponent } from 'vue';

export const KUBER_DETAIL = {
  NODE_LIST: 'Node List',
  NAMESPACE_LIST: 'Namespace List',
  POD_LIST: 'Pod List',
  SERVICE_OBJECT_LIST: 'Service Object List',
  INGRESS_LIST: 'Ingress List',
  PERSISTENT_VOLUMES_LIST: 'Persistent Volumes List',
  PERSISTENT_VOLUMES_CLAIMS_LIST: 'Persistent Volumes Claims List',
  STORAGE_CLASSES_LIST: 'Storage Classes List',
  NAMESPACE: 'Namespace',
  POD: 'Pod',
  NODE: 'Node',
  CRON_JOB: 'CronJobs',
  OWNER_REFERENCES: 'Owner References',
  DEPLOYMENT: 'Deployments',
  STATEFUL_SET: 'Stateful Sets',
  DAEMON_SET: 'Daemon Sets',
  JOB: 'Job',
  REPLICA_SET: 'Replica Sets',
  SERVICE: 'Service',
  ROLES_BINDINGS: 'Roles Bindings',
  SERVICE_ACCOUNTS: 'Service Accounts',
  ROLES: 'Roles',
  INGRESS: 'Ingress',
  CONFIG_MAPS: 'ConfigMaps',
  PERSISTENT_VOLUMES_CLAIMS: 'Persistent Volumes Claims',
  STORAGE_CLASSES: 'Storage Classes',
  CLUSTER_ROLES: 'Cluster Roles',
  CLUSTER_ROLES_BINDINGS: 'Cluster Roles Bindings',
  PERSISTENT_VOLUMES: 'Persistent Volumes',
} as const;

export const INFRA_DETAIL = {
  HOST: 'Host',
  CONTAINER: 'Container',
  NETWORK_DEVICE: 'Network Device',
  PROCESS_DETAIL: 'Process Detail',
  NETWORK_DETAIL: 'Network Detail',
  DISK_DETAIL: 'Disk Detail',
} as const;

export const APPLICATION_DETAIL = {
  WAS: 'WAS',
  TRANSACTION: 'Transaction',
  ACTIVE_TRANSACTION: 'Active Transaction',
} as const;

export const ALERT_DETAIL = {
  ALERT: 'Alert',
} as const;

export const SERVICE_DETAIL = {
  SERVICE_LIST: 'Business List',
} as const;

export const DATABASE_DETAIL = {
  INSTANCE: 'Instance',
  SESSION: 'Session',
  SQL: 'SQL',
  PARAMETER: 'Parameter',
  CLUSTER: 'Cluster',
} as const;

export const TOOL_DETAIL = {
  QUERY: 'Query',
} as const;

export const USAGE_TREND_SLIDE = {
  USAGE_TREND: 'Usage Trend',
} as const;

export const DETAIL_COMPONENTS = {
  // cluster topology - list detail
  [KUBER_DETAIL.NODE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/NodeListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.NAMESPACE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/NamespaceListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.POD_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PodListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE_OBJECT_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/ServiceListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.INGRESS_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/IngressListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_CLAIMS_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PvcListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PvListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STORAGE_CLASSES_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/ScListDetail.vue'),
    ),
  },
  // kuber
  [KUBER_DETAIL.NAMESPACE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/namespaceDetail/NamespaceDetail.vue'),
    ),
  },
  [KUBER_DETAIL.POD]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/podsListDetail/PodsListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.NODE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/nodeDetail/NodeDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/serviceDetail/ServiceDetail.vue'),
    ),
  },
  [KUBER_DETAIL.DEPLOYMENT]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/deploymentsDetail/DeploymentsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STATEFUL_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/statefulSetsDetail/StatefulSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.REPLICA_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/replicaSetsDetail/ReplicaSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.DAEMON_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/daemonSetsDetail/DaemonSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.ROLES_BINDINGS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/rolesBindingsDetail/RolesBindingsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.ROLES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/rolesDetail/RolesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.JOB]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/jobsDetail/JobsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.INGRESS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/ingressDetail/IngressDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CRON_JOB]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/cronJobsDetail/CronJobsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE_ACCOUNTS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/serviceAccountsDetail/ServiceAccountsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CONFIG_MAPS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/configMapsDetail/ConfigMapsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STORAGE_CLASSES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/storageClassesDetail/StorageClassesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CLUSTER_ROLES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/clusterRolesDetail/ClusterRolesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CLUSTER_ROLES_BINDINGS]: {
    component: defineAsyncComponent(
      () =>
        import('@/kubernetes/components/clusterRolesBindingsDetail/ClusterRolesBindingsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/persistentVolumesDetail/PersistentVolumesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_CLAIMS]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/kubernetes/components/persistentVolumesClaimsDetail/PersistentVolumesClaimsDetail.vue'
        ),
    ),
  },
  // infra
  [INFRA_DETAIL.HOST]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/HostDetail.vue'),
    ),
  },
  [INFRA_DETAIL.CONTAINER]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/containerView/containerDetail/ContainerDetail.vue'),
    ),
  },
  [INFRA_DETAIL.NETWORK_DEVICE]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/infrastructure/views/networkDeviceView/networkDeviceDetail/NetworkDeviceDetail.vue'
        ),
    ),
  },
  [INFRA_DETAIL.PROCESS_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/ProcessDetail.vue'),
    ),
  },
  [INFRA_DETAIL.NETWORK_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/NetworkDetail.vue'),
    ),
  },
  [INFRA_DETAIL.DISK_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/DiskDetail.vue'),
    ),
  },
  // application
  [APPLICATION_DETAIL.WAS]: {
    component: defineAsyncComponent(
      () => import('@/application/components/wasDetail/WasDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.TRANSACTION]: {
    component: defineAsyncComponent(
      () => import('@/application/components/transactionDetail/TransactionDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.ACTIVE_TRANSACTION]: {
    component: defineAsyncComponent(
      () => import('@/application/components/activeTransactionDetail/ActiveTransactionDetail.vue'),
    ),
  },
  [ALERT_DETAIL.ALERT]: {
    component: defineAsyncComponent(() => import('@/alert/components/alertDetail/AlertDetail.vue')),
  },
  [SERVICE_DETAIL.SERVICE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/service/views/serviceView/serviceDetail/ServiceDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.INSTANCE]: {
    component: defineAsyncComponent(
      () => import('@/database/components/instanceDetail/InstanceDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.SQL]: {
    component: defineAsyncComponent(
      () => import('@/database/components/sqlDetail/InstanceSqlDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.SESSION]: {
    component: defineAsyncComponent(
      () => import('@/database/components/sessionDetail/InstanceSessionDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.PARAMETER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/parameterDetail/InstanceParameterDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.CLUSTER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/clusterDetail/InstanceClusterDetail.vue'),
    ),
  },
  [TOOL_DETAIL.QUERY]: {
    component: defineAsyncComponent(() => import('@/tool/components/queryDetail/QueryDetail.vue')),
  },
  [USAGE_TREND_SLIDE.USAGE_TREND]: {
    component: defineAsyncComponent(
      () => import('@/config/views/management/dataRetention/components/UsageTrendSlide.vue'),
    ),
  },
};
