import { computed } from 'vue';
import router from '@/common/router';
import { useInstanceStore } from '@/common/stores/instance';
import { MenuInfo } from '../types';

export interface Props {
  menuInfo: MenuInfo;
}

export const setup = (props: Props) => {
  const { changeGNBInstance } = useInstanceStore();
  const isSelected = computed(() => router.currentRoute.value.fullPath === props.menuInfo.path);
  const computedChildrenLength = computed(() => props.menuInfo.subMenuList?.length ?? 0);

  const onClickElement = () => {
    if (!props.menuInfo.path || props.menuInfo.subMenuList) {
      return;
    }

    const [, rootPath, secondPath] = props.menuInfo.path.split('/');
    if (secondPath === 'multiView' || secondPath === 'singleView') {
      changeGNBInstance(rootPath, secondPath);
      return;
    }

    router.push(props.menuInfo.path);
  };

  return { isSelected, computedChildrenLength, onClickElement };
};
