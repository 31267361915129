import { ref } from 'vue';
import { defineStore } from 'pinia';

export interface PopupItem {
  viewName: string;
  popup: Window;
}

export const useBrowserPopupStore = defineStore('browserPopup', () => {
  const isAddedMsgEvent = ref(false);
  const popupList = ref<PopupItem[]>([]);

  const createPopup = (viewName: string, popupWindow: Window) => {
    popupList.value.push({
      viewName,
      popup: popupWindow,
    });
  };

  const closePopup = (viewName: string) => {
    const targetPopupIdx = popupList.value.findIndex((popup) => popup.viewName === viewName);

    if (targetPopupIdx > -1) {
      const targetPopupItem = popupList.value[targetPopupIdx];
      targetPopupItem.popup.close();
      popupList.value.splice(targetPopupIdx, 1);
    }
  };

  const addPopup = (isSingle: boolean, viewName: string, popupWindow: Window) => {
    if (isSingle) {
      closePopup(viewName);
    }
    createPopup(viewName, popupWindow);
  };

  const closeAllPopup = () => {
    popupList.value.forEach((item) => {
      item.popup.close();
    });
    popupList.value = [];
  };

  return {
    isAddedMsgEvent,
    popupList,
    addPopup,
    closeAllPopup,
  };
});
