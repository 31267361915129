import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';

const PREFIX_REPORT = 'report_';

const reportMenu: RouteRecordRaw[] = [
  {
    path: '/report/',
    name: `${PREFIX_REPORT}Report`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REPORT,
    },
    children: [
      {
        path: 'history',
        name: `${PREFIX_REPORT}Report History`,
        component: () => import('../views/historyView/HistoryView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REPORT_ALERT_HISTORY,
        },
      },
      {
        path: 'export',
        name: `${PREFIX_REPORT}Report Export`,
        component: () => import('../views/exportView/ExportView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REPORT_EXPORT,
        },
      },
    ],
  },
];

const getReportNaviMenu = (subMenu?: 'report') => {
  const filteredReportMenu = extractAccessibleRoutes(reportMenu);

  if (subMenu === 'report') return filteredReportMenu.slice(0, 1);
  return [...filteredReportMenu];
};

export { reportMenu, getReportNaviMenu };
