<script setup lang="ts">
interface Props {
  refreshTime: string;
}
const props = withDefaults(defineProps<Props>(), {
  refreshTime: '',
});

interface Emit {
  (e: 'click-refresh'): void;
}
const emit = defineEmits<Emit>();
</script>

<template>
  <div class="refresh-label">
    <ev-icon
      icon="icon-refresh"
      @click="emit('click-refresh')"
    />
    <span class="refresh-label__time">
      {{ props.refreshTime }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.refresh-label {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  font-weight: 400;
  color: var(--status-toolbar-color);

  .icon-refresh {
    cursor: pointer;
    transition: opacity $animate-fast;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
