import { useInternational } from '@/common/locale';

export interface Props {}

export interface Emits {}

export const setup = () => {
  const { t } = useInternational();

  const fileInfoList = [
    // {
    //   label: t('WORD.GNB.HELP.PRODUCT_INTRODUCTION_DOWNLOAD'),
    //   path: `/support/${locale.value}/product_introduction.pdf`,
    //   name: `exemONE_${t('WORD.PRODUCT_INTRODUCTION')}.pdf`,
    // },
    // {
    //   label: t('WORD.GNB.HELP.USER_GUIDE_DOWNLOAD'),
    //   path: `/support/${locale.value}/user_guide.pdf`,
    //   name: `exemONE_${t('WORD.USER_GUIDE')}.pdf`,
    // },
    {
      label: t('WORD.GNB.HELP.OPENAPI_GUIDE'),
      path: '/support/exemOne_openapi_guide.pdf',
      name: `exemONE_${t('WORD.OPENAPI_GUIDE')}.pdf`,
    },
  ];

  return { t, fileInfoList };
};
