import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DEFAULT_STAT_CHART_OPTION } from '@/common/utils/define';
import { StatInfo } from '@/postgresql/store/singleView/singleViewEnv';

interface State {
  statInfoForPostgresql: StatInfo[];
  statInfoForMysql: StatInfo[];
}

export const DEFAULT_PA_STAT_INFO: Record<string, StatInfo[]> = {
  POSTGRESQL: [
    {
      frameId: 'paStatChart0',
      name: 'db_postgresql_os.cpu_usage',
      displayName: '(OS) Cpu Usage (%)',
      stats: ['db_postgresql_os.cpu_usage'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
    {
      frameId: 'paStatChart1',
      name: 'db_postgresql_activebackend',
      displayName: 'Active Backend',
      stats: ['db_postgresql_activebackend'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
    {
      frameId: 'paStatChart2',
      name: 'db_postgresql_blksread',
      displayName: 'Blks Read',
      stats: ['db_postgresql_blksread'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
    {
      frameId: 'paStatChart3',
      name: 'db_postgresql_blkshit',
      displayName: 'Blks Hit',
      stats: ['db_postgresql_blkshit'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
  ],
  MYSQL: [
    {
      frameId: 'mysqlPaStatChart0',
      name: 'Logical + Physical IO',
      displayName: 'Logical + Physical IO',
      stats: ['db_mysql_innodb_buffer_pool_reads', 'db_mysql_innodb_buffer_pool_read_requests'],
      chartType: 'pa',
      chartOption: {
        common: {
          type: 'line',
          showLegend: false,
          tooltipSorting: false,
          stack: true,
          fill: true,
        },
        line: {
          lineWidth: 2,
          point: false,
        },
        bar: {
          thickness: 1,
          borderRadius: 0,
        },
      },
    },
    {
      frameId: 'mysqlPaStatChart1',
      name: 'db_mysql_tps',
      displayName: 'Tps (count/sec)',
      stats: ['db_mysql_tps'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
    {
      frameId: 'mysqlPaStatChart2',
      name: 'db_mysql_buffer_pool_hit_ratio(%)',
      displayName: 'Buffer Pool Hit Ratio (%)',
      stats: ['db_mysql_buffer_pool_hit_ratio(%)'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
    {
      frameId: 'mysqlPaStatChart3',
      name: 'db_mysql_queries',
      displayName: 'Queries (count)',
      stats: ['db_mysql_queries'],
      chartType: 'pa',
      chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
    },
  ],
};

export const paStatChartInfo: Module<State, RootState> = {
  namespaced: true,
  state: {
    statInfoForPostgresql: structuredClone(DEFAULT_PA_STAT_INFO.POSTGRESQL),
    statInfoForMysql: structuredClone(DEFAULT_PA_STAT_INFO.MYSQL),
  },
  mutations: {
    setStatInfoForPostgresql: (state: State, statInfoArr) => {
      state.statInfoForPostgresql = statInfoArr;
    },
    setStatInfoForMysql: (state: State, statInfoArr) => {
      state.statInfoForMysql = statInfoArr;
    },
  },
  getters: {
    getStatInfoForPostgresql: (state: State) => state.statInfoForPostgresql,
    getStatInfoForMysql: (state) => state.statInfoForMysql,
  },
};
