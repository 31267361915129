import { computed } from 'vue';
import { CHECK_BY_COMBINATION } from '@/alert/utils/define';
import { RuleInfo, RuleItem } from '@/alert/components/alertDetail/alertInformation.setup';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { AlertTargetItem } from '@/alert/components/alertTargetList/alertTargetList.types';

export interface Props {
  userAlertType: string;
  ruleInfo: RuleInfo;
  ruleCriteria?: RuleItem['value'];
}
export const setup = (props: Props) => {
  const info = computed(() => props.ruleInfo);
  const isCheckByCombination = computed(() => {
    return props.ruleCriteria === CHECK_BY_COMBINATION;
  });

  const statInfoStore = useStatInfoStore();
  const statInfo = computed(() => {
    if (!Array.isArray(props.ruleInfo.name.value)) {
      return { name: '', unit: '' };
    }
    const [category, dataId] = props.ruleInfo.name.value;
    const selectedStatInfo = statInfoStore.getStatInfo({
      childCategory: category as string,
      statId: dataId as string,
    });

    return {
      name: selectedStatInfo?.name ?? '',
      unit: selectedStatInfo?.unit ?? '',
    };
  });

  const getTargetList = (items: RuleItem['value']) => {
    if (Array.isArray(items)) {
      return items as AlertTargetItem[];
    }
    return [];
  };

  const getEventTypeRow = (conditionData: RuleInfo): RuleInfo => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tags, targets, messageText, level, ...rest } = conditionData;
    return rest;
  };

  return {
    info,
    isCheckByCombination,
    statInfo,
    getTargetList,
    getEventTypeRow,
  };
};
