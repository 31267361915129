<script setup lang="ts">
import { type Props, setup } from './userMenuElement.setup';

defineProps<Props>();

const { t, userInfo } = setup();
</script>

<template>
  <button
    :class="['user-menu menu-element__title', { selected }]"
    :data-menu="info.value"
  >
    <div class="menu-element__title-inner user-menu__title-inner">
      <div class="menu-element__item-icon">
        <span
          class="user-menu__item-icon"
          :class="userInfo.profileImage"
        />
      </div>
      <h3>{{ t('WORD.PROFILE') }}</h3>
      <div class="menu-element__arrow-icon big right" />
    </div>
  </button>
</template>

<style scoped lang="scss">
.user-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;

  &__title {
    width: 100%;
    height: 100%;
  }

  &__title-inner {
    padding-right: 11.5px;
    padding-left: 6px;
  }

  &__item-icon {
    display: block;
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 20px;
    background-size: 28px 28px;

    &::before {
      position: absolute;
      bottom: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      border-radius: 8px;
      background-color: var(--color-d-gray-00);
      box-shadow: var(--gnb-user-icon-cog-shadow);
      content: '';
    }

    &::after {
      display: inline-block;
      position: absolute;
      bottom: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      background-color: var(--profile-user-icon-color);
      content: '';
      mask-image: url('@/common/assets/icon-setting-fill.svg');
      mask-size: 10px 10px;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }

  &.selected {
    background-color: var(--gnb-menu-selected-main-bg-color);
  }
}

@include max-screen() {
  .user-menu {
    height: 42px;

    &__item-icon {
      width: 22px;
      height: 22px;
      background-size: 22px 22px;

      &::before {
        bottom: -1px;
        left: -1px;
        width: 9px;
        height: 9px;
        border-radius: 6px;
      }

      &::after {
        bottom: -1px;
        left: -1px;
        width: 9px;
        height: 9px;
        mask-size: 8px 8px;
      }
    }
  }
}
</style>
