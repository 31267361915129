import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { topObjectIndexScanPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { ObjectScanRatioItem0 } from '@/openapi/postgresqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface ObjectScanRatioState {
  objectScanRatio: ObjectScanRatioItem0;
  errorStatusText: string;
}

export const objectScanRatio: Module<ObjectScanRatioState, RootState> = {
  namespaced: true,
  state: {
    objectScanRatio: {
      index: 0,
      table: 0,
      collectTime: '',
    },
    errorStatusText: '',
  },
  mutations: {
    setObjectScanRatio(state: ObjectScanRatioState, { index, table }: ObjectScanRatioItem0) {
      state.objectScanRatio.index = index;
      state.objectScanRatio.table = table;
    },
    setErrorStatusText: (state: ObjectScanRatioState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchObjectScanRatio({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OBJECT_OBJECT_SCAN;
      try {
        const { data } = await topObjectIndexScanPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setObjectScanRatio', data?.data![0]);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getObjectScanRatio: (state: ObjectScanRatioState) => state.objectScanRatio,
    getTableScanRatio: (state: ObjectScanRatioState) => state.objectScanRatio.table,
    getIndexScanRatio: (state: ObjectScanRatioState) => state.objectScanRatio.index,
    getErrorStatusText: (state: ObjectScanRatioState) => state.errorStatusText,
  },
};
