<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import { type Props, setup } from './subMenuElement.setup';
import { BASE_MENU_VALUE } from '../baseMenu.define';

const props = defineProps<Props>();

const { computedChildrenLength, isChildMenuAvailable, onClickElement } = setup(props);
</script>

<template>
  <li
    :class="['sub-menu-element', { selected }]"
    @click="onClickElement"
  >
    <div class="sub-menu-element__inner-text">
      <span>
        {{ menuInfo.text }}
      </span>
      <badge-item
        v-if="menuInfo.value === BASE_MENU_VALUE.DASHBOARD"
        html-type="div"
        size="xsmall"
        >{{ computedChildrenLength }}
      </badge-item>
      <badge-item
        v-if="menuInfo.value === BASE_MENU_VALUE.ANALYSISBOARD"
        html-type="div"
        size="xsmall"
        >{{ computedChildrenLength }}
      </badge-item>
    </div>
    <div
      v-if="isChildMenuAvailable"
      class="menu-element__arrow-icon right"
    />
  </li>
</template>
