import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { CreateWebhookRequest, UpdateWebhookRequest, WebhookResponse } from '../model';

const deleteV1WebhookByWebhookIdAxios = ({
  webhookId,
}: {
  webhookId: number;
}): PromiseAxiosResponse<string> => axiosInstance.delete(`/api/v1/webhook/${webhookId}`);

const getV1WebhookAxios = (): PromiseAxiosResponse<WebhookResponse> =>
  axiosInstance.get('/api/v1/webhook');

const patchV1WebhookByWebhookIdAxios = ({
  webhookId,
  request,
}: {
  webhookId: number;
  request: UpdateWebhookRequest;
}): PromiseAxiosResponse<WebhookResponse> =>
  axiosInstance.patch(`/api/v1/webhook/${webhookId}`, request);

const postV1WebhookAxios = ({
  request,
}: {
  request: CreateWebhookRequest;
}): PromiseAxiosResponse<WebhookResponse> => axiosInstance.post('/api/v1/webhook', request);

export {
  deleteV1WebhookByWebhookIdAxios,
  getV1WebhookAxios,
  patchV1WebhookByWebhookIdAxios,
  postV1WebhookAxios,
};
