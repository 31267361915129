<script setup lang="ts">
import ProductionModuleInfo from './ProductionModuleInfo.vue';
import { setup } from './userSupportPopup.setup';

const { t, fileInfoList } = setup();
</script>

<template>
  <div class="support-popup">
    <ul class="support-menu">
      <li>
        <span class="support-menu__title">{{ t('WORD.GNB.HELP.TITLE') }}</span>
      </li>
    </ul>
    <ul class="support-menu">
      <li
        v-for="fileInfo in fileInfoList"
        :key="fileInfo.label"
      >
        <a
          :href="fileInfo.path"
          :download="fileInfo.name"
          class="support-menu__item"
        >
          <span>{{ fileInfo.label }}</span>
          <ev-icon
            icon="icon-download"
            size="small"
          />
        </a>
      </li>
      <production-module-info style="padding-left: 16px" />
    </ul>
  </div>
</template>

<style scoped lang="scss">
.support-popup {
  $shadow-color: rgba(0, 0, 0, 0.14);

  display: none !important;
  position: absolute;
  bottom: 0;
  z-index: $on-base-sub-menu;
  width: 260px;
  user-select: none;
  background-color: var(--lnb-bg-color);
  box-shadow:
    0 2px 4px 0 $shadow-color,
    0 1px 10px 0 $shadow-color,
    0 4px 5px 0 $shadow-color;
}
.support-menu {
  &__title {
    display: flex;
    align-items: center;
    height: var(--lnb-header-height);
    min-height: var(--lnb-header-height);
    max-height: var(--lnb-header-height);
    padding: 0 16px;
    background-color: var(--lnb-header-bg-color);
    font-size: 11px;
    font-weight: 700;
    color: var(--lnb-header-font-color);
  }

  a {
    cursor: pointer;
    color: var(--lnb-menu-depth2-font-color);
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--lnb-menu-height);
    padding: 0 16px;
    transition: background-color $animate-base;

    &:hover {
      background-color: var(--lnb-menu-hover-bg-color) !important;
    }
  }
}
</style>
