import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import { showErrorMsg } from '@/common/utils/commonUtils';
import router from '@/common/router';
import { DB_TYPE } from '@/common/utils/define';
import { useI18n } from 'vue-i18n';

const useModel = () => {
  const store: Store<RootState> = useStore();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useI18n();

  const currentDbType = computed(() => {
    const routeName = router.currentRoute.value?.name;
    return typeof routeName === 'string' ? routeName.split('_')[0] : '';
  });

  const singleViewEnvByDbType = ref('');

  const selectedInstanceId = computed(
    () => store.getters[`${singleViewEnvByDbType.value}/getSelectedInstanceId`],
  );
  const selectedDbType = computed(
    () => store.getters[`${singleViewEnvByDbType.value}/getSelectedDbType`],
  );

  const backToDatabaseList = async () => {
    await router.push('/database/instance');
  };
  const initSelectedInfo = async (routerPath) => {
    if (!routerPath) {
      await backToDatabaseList();
      return;
    }

    switch (currentDbType.value) {
      case DB_TYPE.MYSQL:
        singleViewEnvByDbType.value = 'mysqlSingleViewEnv';
        break;
      case DB_TYPE.POSTGRESQL:
        singleViewEnvByDbType.value = 'postgresqlSingleViewEnv';
        break;
      case DB_TYPE.SQLSERVER:
        singleViewEnvByDbType.value = 'sqlserverSingleViewEnv';
        break;
      default:
        return;
    }

    await store.dispatch(`${singleViewEnvByDbType.value}/fetchLastSelectedId`);

    if (!selectedInstanceId.value) {
      showErrorMsg(ctx, t('NOTI.PRODUCT.INVALID_ACCESS'));
      await store.commit(`${singleViewEnvByDbType.value}/initSelectedInfo`);
      await backToDatabaseList();
    }
  };

  onMounted(async () => {
    await initSelectedInfo(router.currentRoute.value.path);
  });

  return {
    currentDbType,
    selectedDbType,
  };
};

export { useModel };
