import { DataStatCategoriesForm, StatItems } from '@/openapi/data/model';
import { convertToStatDataType, StatInfo } from '../components/organisms/statWindow';
import { mapChildCategoryWithParent } from '../components/organisms/statWindow/statContents/statContents.utils';

type ParentCategory = string;
type ChildCategory = string;
type StatId = string;

export const parseStatItems = (categoryList: DataStatCategoriesForm[], statItems: StatItems) => {
  const infoByStatIdAndCategory: Map<ChildCategory, Map<StatId, StatInfo>> = new Map(
    categoryList
      .map(({ pcategory, categories }) =>
        categories
          ? [...categories].map((category) => ({
              category,
              pcategory,
            }))
          : [],
      )
      .flat()
      .map(({ pcategory, category }) => [
        mapChildCategoryWithParent({
          parentCategory: pcategory ?? '',
          childCategory: category ?? '',
        }),
        new Map(),
      ]),
  );

  const infoByCategoryInfo: Map<ParentCategory, Map<ChildCategory, StatInfo[]>> = new Map(
    categoryList.map(({ pcategory, categories }) => [
      pcategory ?? '',
      new Map(
        (categories ? [...categories] : []).map((category) => [
          mapChildCategoryWithParent({
            parentCategory: pcategory ?? '',
            childCategory: category ?? '',
          }),
          [],
        ]),
      ),
    ]),
  );

  const info: Map<StatId, StatInfo> = new Map();

  const { dataIds, favoriteDataIds } = statItems;

  dataIds?.forEach(
    ({
      dataId,
      displayName,
      popular,
      tag,
      recommend,
      category,
      pcategory,
      unit,
      dataType,
      dataKind,
      args,
    }) => {
      if (
        category != null &&
        dataId != null &&
        pcategory != null &&
        displayName != null &&
        dataType != null
      ) {
        const tagList = tag?.split(',').filter((tagData) => !!tagData) ?? [];
        const statInfo: StatInfo = {
          parentCategory: pcategory,
          childCategory: category,
          id: dataId,
          name: displayName,
          tagList: tagList.length > 0 ? [...tagList] : [],
          favorite: !!favoriteDataIds?.includes(dataId),
          popular: popular ?? 0,
          recommend: !!recommend,
          dataType: dataType
            .split(',')
            .filter((type) => !!type)
            .map(convertToStatDataType),
          unit,
          dataKind,
          arguments: args?.filter(
            (arg): arg is NonNullable<StatInfo['arguments']>[0] => !!arg.name,
          ),
        };

        info.set(dataId, statInfo);

        const childCategory = mapChildCategoryWithParent({
          parentCategory: pcategory,
          childCategory: category,
        });

        infoByStatIdAndCategory.get(childCategory)?.set(dataId, statInfo);
        const statInfoList = infoByCategoryInfo.get(pcategory)?.get(childCategory);
        if (statInfoList) {
          statInfoList.push(statInfo);
        }
      }
    },
  );

  return { infoByStatIdAndCategory, infoByCategoryInfo, info };
};
