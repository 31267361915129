import type { InjectionKey } from 'vue';
import type { BreadCrumbMenuInfo } from './breadCrumbs.types';

export const BREAD_CRUMBS_MODAL_CLASS = 'route-paths-modal';

export const BREAD_CRUMBS_CHANGE_FAVORITE_KEY = Symbol(
  'BREAD_CRUMBS_CHANGE_FAVORITE_KEY',
) as InjectionKey<(data: BreadCrumbMenuInfo) => Promise<void>>;

export const DASHBOARD_MENU_TYPE = {
  DASHBOARD: 'dashboard',
  ANALYSIS_DASHBOARD: 'analysisDashboard',
};
