import {
  FilterDropdownInfo,
  FilterDropdownInfoCategories,
  FilterDropdownItem,
  FilterHistory,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { computed } from 'vue';
import { useInternational } from '@/common/locale';
import { getElapsedTime } from '@/common/utils/commonUtils';

export interface Props {
  dropdownListInfo: FilterDropdownInfo;
  hoveredDropdownItemIndex: number;
  showFilterHistoryList: boolean;
  filterHistory: FilterHistory[];
}

export interface Emit {
  (e: 'mouseenter-to-item'): void;
  (e: 'click-list-item', value: FilterDropdownItem): void;
  (e: 'click-history-item', value: FilterHistory): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const getDropdownListTitle = (category: FilterDropdownInfoCategories): string => {
    switch (category) {
      case 'key':
        return t('WORD.FILTER_CATEGORY');

      case 'operator':
        return t('WORD.FILTER_INEQUALITY_SIGN');

      case 'value':
        return t('WORD.FILTER_VALUE');

      default:
        return '';
    }
  };

  const onMouseenter = () => {
    emit('mouseenter-to-item');
  };

  const onClickListItem = (target: FilterDropdownItem) => {
    emit('click-list-item', target);
  };

  const onClickHistoryItem = (target: FilterHistory) => {
    emit('click-history-item', target);
  };

  const filterHistoryList = computed(() => props.filterHistory?.slice()?.reverse());

  const getElapsedTimeSinceHistoryCreated = (createdTime: number): string => {
    const { time, unit } = getElapsedTime(createdTime);

    let result: string = '';
    if (time && unit) {
      const plural = time > 1;
      result = t('WORD.ELAPSED_TIME_FROM_NOW', {
        time,
        unit: plural ? t('WORD.PLURAL_ELAPSED_TIME_UNIT', { value: unit }) : unit,
      });
    } else if (!time && !unit) {
      result = `${t('WORD.JUST_NOW')}`;
    }

    return result;
  };

  return {
    t,
    filterHistoryList,
    getDropdownListTitle,
    getElapsedTimeSinceHistoryCreated,
    onMouseenter,
    onClickListItem,
    onClickHistoryItem,
  };
};
