/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

export class TreeNode {
  public parent: TreeNode | null;

  public children: TreeNode[] = [];

  constructor(parent: TreeNode | null) {
    this.parent = parent;
    if (this.parent) this.parent.children.push(this);
  }
}

export class Tree extends TreeNode {
  public label: string = ''; // unique

  public declare parent: Tree | null;

  public children: Tree[] = [];

  constructor(parent: Tree['parent'], label: string) {
    super(parent);
    this.label = label;
  }

  public addChild(label: string) {
    return new Tree(this, label);
  }

  public removeChild(child: Tree) {
    this.children = this.children.filter((c) => c !== child);
  }

  public findChild(label: string): Tree | null {
    const child = this.children.find((c) => c.label === label);
    if (child) return child;
    for (const c of this.children) {
      const found = c.findChild(label);
      if (found) return found;
    }
    return null;
  }

  public changeLabel(label: string) {
    this.label = label;
  }

  public static isDescendant(parent: Tree, child: Tree) {
    if (parent.label === child.label) return true;
    return parent.children.some((c) => Tree.isDescendant(c, child));
  }
}
