import { ComponentPublicInstance } from 'vue';

type DomEventType = 'focus' | 'blur';
const useDomEvent = () => {
  const focusElement = <T>(elementRef: ComponentPublicInstance<T>) => {
    elementRef?.$el?.querySelector('input').focus();
  };

  const blurElement = <T>(elementRef: ComponentPublicInstance<T>) => {
    elementRef?.$el?.querySelector('input').blur();
  };

  const setElementState: Record<DomEventType, <T>(elementRef: ComponentPublicInstance<T>) => void> =
    {
      focus: focusElement,
      blur: blurElement,
    };

  return {
    setElementState,
  };
};

export { useDomEvent };
