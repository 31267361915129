<script setup lang="ts">
import { useInternational } from '@/common/locale';
import { useRegisterSW } from 'virtual:pwa-register/vue';

const { t } = useInternational();

const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swScriptUrl, swRegistration) {
    if (swRegistration) {
      swRegistration.addEventListener('updatefound', () => {
        swRegistration.update();
      });
    }
  },
});

const reloadPage = () => {
  updateServiceWorker();
  needRefresh.value = false;
};

const closePrompt = () => {
  needRefresh.value = false;
};
</script>

<template>
  <div
    v-if="needRefresh"
    class="release-notification"
    role="alert"
  >
    <div class="release-notification__message">
      <span> {{ t('MESSAGE.APP_UPDATE_DETECTED') }}</span>
    </div>
    <div class="release-notification__button-group">
      <ev-button
        type="primary"
        size="small"
        @click="reloadPage"
        >{{ t('WORD.RELOAD') }}</ev-button
      >
      <ev-button
        type="ghost"
        size="small"
        @click="closePrompt"
        >{{ t('WORD.CLOSE') }}</ev-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.release-notification {
  $middle-gap: 16px;

  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  flex-direction: column;
  gap: $middle-gap;
  padding: $middle-gap;
  margin: $middle-gap;
  border-color: var(--window-contents-bg-color);
  border-radius: 4px;
  background-color: var(--window-contents-bg-color);
  box-shadow: var(--window-shadow);
  color: var(--window-header-font-color);

  &__button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
</style>
