<script setup lang="ts">
import AlertListDetailSlide from '@/common/components/organisms/alertListDetail/AlertListDetailSlide.vue';
import { useOverviewAlertStore } from '@/database/stores/overview-alert';
import { storeToRefs } from 'pinia';

const store = useOverviewAlertStore();
const { openAlertSlide: onClickAlert } = store;
const { totalBadgeCount, isShowAlertDetail } = storeToRefs(store);
</script>

<template>
  <ev-button
    type="icon"
    class="overview-alert-icon"
    @click="onClickAlert"
  >
    <ev-icon icon="icon-bell" />
    <span :class="['overview-alert-icon__count', { none: totalBadgeCount === '0' }]">
      {{ totalBadgeCount }}
    </span>
  </ev-button>
  <alert-list-detail-slide
    v-model:is-visible="isShowAlertDetail"
    type="database"
  />
</template>

<style scoped lang="scss">
.overview-alert-icon {
  position: relative;
  &__count {
    position: absolute;
    top: 100%;
    left: 100%;
    min-width: 16px;
    padding: 0 2px;
    margin: -12px 0 0 -12px;
    border-radius: 50%;
    background-color: var(--alert-count-item-critical-border);
    font-size: 10px;
    line-height: 14px;
    color: var(--color-d-gray-00);
    text-align: center;

    &.none {
      background-color: var(--alert-count-item-default-bg);
    }
  }
}
</style>
