<script setup lang="ts">
import StatusToolbar from '@/infrastructure/components/statusToolbar/StatusToolbar.vue';
import RefreshLabel from '@/common/components/molecules/refreshLabel/RefreshLabel.vue';
import { StatusItem } from '@/infrastructure/components/statusToolbar/statusToolbar.setup';

interface Props {
  title: string;
  refreshTime?: string;
  isShowRefresh?: boolean;
  statusList?: StatusItem[];
}

interface Emit {
  (e: 'click-refresh'): void;
}
const props = withDefaults(defineProps<Props>(), {
  title: 'All List',
  isShowRefresh: false,
  refreshTime: '',
  isData: true,
  statusList: () => [],
});

const emit = defineEmits<Emit>();
</script>

<template>
  <div class="list-header">
    <div class="list-header__wrapper-left">
      <p class="list-header__title">
        {{ props.title }}
      </p>
      <slot name="titleInformation" />
      <refresh-label
        v-if="props.isShowRefresh"
        :refresh-time="props.refreshTime"
        @click-refresh="emit('click-refresh')"
      />
    </div>
    <div class="list-header__wrapper-right">
      <status-toolbar :status-list="props.statusList" />
      <slot name="rowsInfo" />
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  line-height: 20px;

  &__title {
    font-size: 14px;
    font-weight: 700;
  }
  &__wrapper-left {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &__wrapper-right {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
</style>
