import { getCurrentInstance, onUnmounted } from 'vue';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { useI18n } from 'vue-i18n';
import { i18n, MessageSchemaStringType } from '@/common/locale';
import { AxiosError } from 'axios';
import { EvMessage } from 'evui';

const useAbortApi = () => {
  let controller = new AbortController();
  const getSignal = () => controller.signal;
  const abortApi = () => {
    controller.abort();
    controller = new AbortController();
  };

  return {
    abortApi,
    getSignal,
  };
};

// in main thread
const useRtmApi = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { abortApi, getSignal } = useAbortApi();
  const { t } = useI18n();

  const callApi = async <Fn extends (p: any) => PromiseAxiosResponse<any>>({
    fn,
    params,
    isTimeout = true,
    successMsg,
    errorMsg,
    isShowErrorMsg = true,
    frameName,
    validateStatus,
  }: {
    fn: Fn;
    params?: Omit<Parameters<Fn>[0], 'frameName' | 'signal' | 'isTimeout'>;
    isTimeout?: boolean;
    successMsg?: MessageSchemaStringType;
    errorMsg?: MessageSchemaStringType;
    isShowErrorMsg?: boolean;
    frameName?: string;
    validateStatus?: ((status: number) => boolean) | null;
  }) => {
    let state: Awaited<ReturnType<Fn>>['data']['data'];
    let page: any;
    let requestTime: any;
    let error: AxiosError<{
      errorType: string;
      message: string;
    }> | null = null;

    abortApi();
    try {
      const { data } = await fn({
        ...params,
        isTimeout,
        frameName,
        signal: getSignal(),
      });
      state = data.data;
      page = data.page;
      requestTime = data.requestTime;

      if (successMsg) {
        showSuccessMsg(ctx, t(successMsg));
      }
    } catch (e: any) {
      error = e;
      state = [];
      if (isShowErrorMsg && e?.response?.status) {
        if (validateStatus && !validateStatus(e.response.status)) {
          showErrorMsg(ctx, t(errorMsg ?? 'NOTI.PRODUCT.TEMP_SERVER_ERROR'));
        } else if (e.response.status === 406) {
          /*
           * Axios Interceptor에서 406 오류에 대한 처리를 해주기 때문에
           * apiUtils.ts 파일에서는 406 응답을 받더라도 아무런 동작을 하지 않습니다.
           */
        } else if (e.response.status === 409) {
          showErrorMsg(ctx, t('NOTI.VALIDATION.DUPLICATE'));
        } else {
          showErrorMsg(ctx, t(errorMsg ?? 'NOTI.PRODUCT.TEMP_SERVER_ERROR'));
        }
      }
    }

    return {
      data: state,
      page,
      requestTime,
      error,
    };
  };

  onUnmounted(() => {
    abortApi();
  });

  return {
    callApi,
    abortApi,
  };
};

const showEvMessage = (type: string, message: string) => {
  EvMessage({
    type,
    message,
  });
};
const statusController = {
  406: (callback?: () => any) => {
    if (typeof callback === 'function') {
      callback();
    }
  },
};
const exceptionHandlerByErrorStatus = ({
  error,
  callback,
}: {
  error?: AxiosError;
  callback?: () => any;
}) => {
  const { status } = error?.response ?? {};

  if (status) {
    statusController?.[status]?.(callback);
  }
};
const paExceptionHandler = ({
  error,
  isShowRejectMsg = false,
  isTempServerError = false,
}: {
  error?: AxiosError<{ errorType: string; type: string; message: string }>;
  isShowRejectMsg?: boolean;
  isTempServerError?: boolean;
}) => {
  const { status, data } = error?.response ?? {};

  if (status === 406) {
    return;
  }
  if (isShowRejectMsg) {
    const errorType = data?.errorType || data?.type || 'Exception';
    showEvMessage('error', i18n.global.t(`ERROR.API.${errorType}`));
  } else if (isTempServerError) {
    showEvMessage('error', i18n.global.t('NOTI.PRODUCT.TEMP_SERVER_ERROR'));
  }
};

export { useAbortApi, useRtmApi, exceptionHandlerByErrorStatus, paExceptionHandler };
