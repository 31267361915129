<script setup lang="ts">
import BaseModalWindow from '@/common/components/templates/BaseModalWindow.vue';
import TextViewer from '@/common/components/molecules/textViewer/TextViewer.vue';
import { computed } from 'vue';
import type { TextType } from '@/common/components/molecules/textViewer/textViewer.type';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import LabelItemGroup from '@/common/components/templates/labelItemGroup/LabelItemGroup.vue';
import LabelItem from '@/common/components/molecules/labelItem/LabelItem.vue';

interface Props {
  title: string;
  isShow: boolean;
  text: string;
  type?: TextType;
  isLoading?: boolean;
  isNetworkAlert?: boolean;
  showMessageTitle?: boolean;
  networkInfo?: {
    facility?: string;
    severity?: string;
    specificTrapCode?: string;
    genericTrapType?: string;
    enterpriseOid?: string;
  };
  lineNumber?: boolean;
  noDataMsg?: string;
}

interface Emit {
  (e: 'update:is-show', value: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'none',
  isLoading: false,
  showMessageTitle: true,
  lineNumber: true,
  noDataMsg: '',
  networkInfo: () => ({}),
});
const emit = defineEmits<Emit>();

const isShowWindow = computed<boolean>({
  get: () => props.isShow,
  set: (v): void => emit('update:is-show', v),
});
</script>

<template>
  <base-modal-window
    v-model:visible="isShowWindow"
    :title="props.title"
    class-name="text-viewer-window"
  >
    <div
      class="text-viewer-window__content"
      :style="{ height: isNetworkAlert ? '260px' : '100%' }"
    >
      <ev-loading
        v-if="props.isLoading"
        :model-value="props.isLoading"
      />
      <div
        v-if="isNetworkAlert"
        class="text-viewer-window__info"
      >
        <div class="text-viewer-window__info-title">
          <span>Info</span>
        </div>
        <div class="text-viewer-window__info-tag">
          <label-item-group>
            <label-item
              v-for="(value, key) in networkInfo"
              :key="key"
              :label="key"
              class="type"
            >
              <tag-item size="xsmall">
                {{ value }}
              </tag-item>
            </label-item>
          </label-item-group>
        </div>
      </div>
      <div
        v-if="showMessageTitle"
        class="text-viewer-window__info-title"
      >
        <span>Message</span>
      </div>
      <text-viewer
        :text="props.text"
        :type="props.type"
        :use-line-numbers="lineNumber"
        :no-data-msg="props.noDataMsg"
      />
    </div>
  </base-modal-window>
</template>

<style lang="scss">
.text-viewer-window {
  &__content {
    position: relative;

    .text-viewer {
      height: 100%;
    }
  }

  &__info {
    height: 50px;
  }

  &__info-title {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__info-title span {
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
