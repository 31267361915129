import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { openBrowserPopup } from '@/common/utils';
import { POPUP_MIN_SIZE } from '@/common/components/organisms/alertListDetail/alertListDetail.setup';
import { useApiTraceStore } from '@/common/stores/api-trace';
import { MESSAGE_ACTION } from '@/common/define/apiTrace.define';
import {
  ApiHistoryData,
  TargetHistory,
} from '@/common/components/organisms/apiTraceWindow/apiTrace.types';
import { storeToRefs } from 'pinia';

export interface Props {
  isShow: boolean;
}

export interface Emit {
  (e: 'update:isShow', v: boolean): void;
}

export const setup = (props: Props, emit: Emit) => {
  const apiTraceStore = useApiTraceStore();
  const { targetFrameInfo } = storeToRefs(apiTraceStore);

  const isShowWindow = computed<boolean>({
    get: () => props.isShow,
    set: (v) => emit('update:isShow', v),
  });
  const subTitle = ref<string>('');

  let apiTracePopupWindow: Window | null = null;
  const history: {
    path: string;
    api: ApiHistoryData[];
    target: TargetHistory;
  } = {
    path: '',
    api: [],
    target: {
      snapId: '',
      frameId: '',
      subFrameId: '',
      dataSqlToggle: 'sql',
    },
  };

  const onExpandWindow = () => {
    emit('update:isShow', false);
    apiTracePopupWindow = openBrowserPopup({
      query: {
        viewName: 'apiTrace',
        isShow: true,
        isPopup: true,
      },
      popupStyle: { width: POPUP_MIN_SIZE.WIDTH, height: POPUP_MIN_SIZE.HEIGHT },
      isSingle: true,
    });
  };

  const onChangePath = (currPath: string) => {
    subTitle.value = currPath ? `(${currPath})` : '';
    history.path = currPath;
  };

  const onChangeTargetHistory = (data: TargetHistory) => {
    history.target = data;
  };

  const onChangeApiHistory = (data: ApiHistoryData[]) => {
    history.api = data;
  };

  const sendMessageToWindow = (msg: { action: string; data: string }) => {
    apiTracePopupWindow?.postMessage(msg, apiTracePopupWindow.origin);
  };

  const apiTraceListenerCallback = (event: MessageEvent) => {
    if (!apiTracePopupWindow || event.origin !== apiTracePopupWindow.origin) {
      return;
    }

    const action = event.data?.action ?? '';

    switch (action) {
      case MESSAGE_ACTION.GET_API_INFO:
        sendMessageToWindow({
          action,
          data: JSON.stringify(apiTraceStore.getApiTraceInfo()),
        });
        break;
      case MESSAGE_ACTION.INIT:
        sendMessageToWindow({
          action,
          data: JSON.stringify({
            path: history.path,
            parentTargetHistory: history.target,
            apiHistory: history.api,
          }),
        });
        break;
      default:
        break;
    }
  };

  watch(
    () => props.isShow,
    (v) => {
      if (v && apiTracePopupWindow) {
        apiTracePopupWindow.close();
        apiTracePopupWindow = null;
      }

      if (!v) {
        apiTraceStore.setTargetFrameInfo({ type: '', id: '' });
      }
    },
  );

  watch(targetFrameInfo, async (info) => {
    if (!info.id) {
      return;
    }

    if (apiTracePopupWindow && !apiTracePopupWindow.closed) {
      apiTracePopupWindow.focus();
      sendMessageToWindow({
        action: MESSAGE_ACTION.SET_TARGET_FRAME_INFO,
        data: JSON.stringify(info),
      });
    } else {
      isShowWindow.value = true;
    }
  });

  onMounted(() => {
    window.addEventListener('message', apiTraceListenerCallback);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('message', apiTraceListenerCallback);
  });

  return {
    targetFrameInfo,
    isShowWindow,
    subTitle,
    onExpandWindow,
    onChangePath,
    onChangeTargetHistory,
    onChangeApiHistory,
  };
};
