<script setup lang="ts">
import {
  Props,
  Slots,
  setup,
} from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.setup';

const props = withDefaults(defineProps<Props>(), {
  basePos: 'right',
  customClass: '',
  showTail: true,
  teleportTo: '#app',
  text: '',
});
const slots = defineSlots<Slots>();

const { tooltipPosition, tooltipRef, tooltipStyle } = setup(props);
</script>

<template>
  <teleport :to="props.teleportTo">
    <div
      v-if="props.mouseEvent"
      ref="tooltipRef"
      :style="tooltipStyle"
      :class="['text-tooltip', tooltipPosition, { 'show-tail': props.showTail }, props.customClass]"
    >
      <template v-if="slots.default">
        <slot />
      </template>
      <span v-else>
        {{ props.text }}
      </span>
    </div>
  </teleport>
</template>

<style lang="scss">
.text-tooltip {
  position: absolute;
  z-index: 850;
  padding: 6px 8px;
  border-color: transparent;
  border-radius: 2px;
  background-color: var(--common-tooltip-simple-text-bg-color);
  box-shadow: var(--tooltip-shadow);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  text-wrap: none;

  &.show-tail {
    &.right::before {
      position: absolute;
      left: -6px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 8px solid var(--common-tooltip-simple-text-bg-color);
      border-bottom: 7px solid transparent;
      content: '';
    }
    &.left::before {
      position: absolute;
      right: -6px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 8px solid var(--common-tooltip-simple-text-bg-color);
      content: '';
    }
    &.top::before {
      position: absolute;
      bottom: calc(-50% + 6px);
      left: calc(50% - 7px);
      width: 0;
      height: 0;
      border-top: 8px solid var(--common-tooltip-simple-text-bg-color);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      content: '';
    }
    &.bottom::before {
      position: absolute;
      top: calc(-50% + 7px);
      left: calc(50% - 7px);
      width: 0;
      height: 0;
      border-right: 7px solid transparent;
      border-bottom: 8px solid var(--common-tooltip-simple-text-bg-color);
      border-left: 7px solid transparent;
      content: '';
    }
  }
}
</style>
