import dayjs, { Dayjs } from 'dayjs';
import { isNull, isUndefined } from 'lodash-es';
import { MetricChartInfo } from '@/common/components/organisms/metricChartList/metricChartList.types';
import { BY_TARGET_SERIES_TYPE } from '@/common/define/widget.define';
import { ChartData, SeriesData } from '@/common/utils';
import { utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import { MetricChartInfo as CommonMetricChartInfo } from '@/common/utils/types';
import { MetricResponse, MetricResponseItem } from '@/openapi/data/model';

type ChartDataDataType = Record<string, (string | number | null)[]>;
type ChartDataSeriesType = Record<string, SeriesData>;
type SeriesNameFieldType = 'dataId' | 'displayName' | 'targetId' | 'targetName' | 'summaryType';
type SeriesIdType = 'default' | 'targetId' | 'dataId' | 'metric' | 'summaryType';

interface TransformMetricOpt {
  transformType?:
    | 'MetricChart'
    | 'TrendAnalysisChart'
    | 'CommonMetricChart'
    | 'array'
    | 'summaryType';
  seriesNameField?: SeriesNameFieldType;
  seriesIdType?: SeriesIdType;
  seriesDataFormat?: (field: string, index: number) => SeriesData;
  seriesValueFormat?: (value: number | string) => string | number | null;
  passingValue?: number;
  useStatus?: boolean;
}

const getSeriesName = (
  seriesNameField: SeriesNameFieldType,
  dataId: string,
  displayName: string,
  metric: MetricResponseItem | undefined,
  summaryType: string,
) => {
  switch (seriesNameField) {
    case 'dataId':
      return dataId;
    case 'displayName':
      return displayName;
    case 'targetId':
      return metric?.targetId ?? '';
    case 'targetName':
      return metric?.targetName ?? '';
    case 'summaryType':
      return summaryType;
    default:
      return dataId;
  }
};

const getSeriesId = (
  seriesIdType: SeriesIdType,
  defaultId: string,
  targetId: string,
  summaryType: string,
  dataId: string,
): string => {
  switch (seriesIdType) {
    case 'summaryType':
      return summaryType;
    case 'dataId':
      return dataId;
    case 'targetId':
      return targetId;
    case 'metric':
      return 'metric';
    case 'default':
    default:
      return defaultId;
  }
};

const convertMetricResponseWhenAggregationTypeIsByTarget = (
  response: MetricResponse,
  responseId: string,
  option: TransformMetricOpt,
) => {
  const { summaryType, dataDefinition, targetIds, metrics } = response;

  const {
    seriesNameField = 'dataId',
    seriesIdType = 'default',
    seriesDataFormat = () => {},
    seriesValueFormat = (value) => value,
    passingValue,
  } = option;
  const data: ChartDataDataType = {};
  const series: ChartDataSeriesType = {};
  let labels: (number | Dayjs)[] = [];
  const sortedTargetIds = targetIds ? [...targetIds].sort() : [];

  sortedTargetIds.forEach((targetId, targetIdx) => {
    const metricData = metrics?.find(
      ({ targetId: metricTargetId }) => metricTargetId === targetId,
      // HISTORY: API를 통해 들어오는 id 체크 값 분기 삭제 startsWith(targetId)
    );
    let seriesId = getSeriesId(
      seriesIdType,
      `${responseId}_${targetIdx}`,
      targetId,
      summaryType ?? '',
      dataDefinition?.dataId ?? '',
    );
    if ((metrics?.length ?? 0) > 1 && seriesId === 'metric') {
      seriesId = `${dataDefinition?.dataId}__${targetId}`;
    }

    // 시리즈 형식 정의
    series[seriesId] = {
      name: getSeriesName(
        seriesNameField,
        dataDefinition?.dataId ?? '',
        dataDefinition?.displayName ?? '',
        metricData,
        summaryType ?? '',
      ),
      ...seriesDataFormat(seriesId, targetIdx),
    };

    if (typeof passingValue === 'number') {
      series[seriesId].passingValue = passingValue;
    }

    // 메트릭 데이터에 Null 삽입
    if (metricData?.values) {
      labels = metricData.values.map(([time]) =>
        dayjs(utcZeroTimeToStandardTime(time as unknown as string)),
      );

      data[seriesId] = metricData.values.map(([, value]) => {
        const typedValue = value as unknown as number | null;
        if (typedValue === passingValue) {
          return typedValue;
        }
        if (isUndefined(typedValue) || isNull(typedValue)) {
          return null;
        }
        return seriesValueFormat(typedValue);
      });
    } else {
      data[seriesId] = [];
    }
  });
  return { data, series, labels };
};

const convertMetricResponseWhenAggregationTypeIsOthers = (
  response: MetricResponse,
  responseId: string,
  option: TransformMetricOpt,
) => {
  const { summaryType, dataDefinition, targetIds, metrics } = response;

  const {
    seriesIdType = 'default',
    seriesNameField = 'dataId',
    seriesDataFormat = () => {},
    seriesValueFormat = (value) => value,
    passingValue,
  } = option;
  const data: Record<string, (string | number | null)[]> = {};
  const series: Record<string, SeriesData> = {};
  let labels: (number | Dayjs)[] = [];
  const sortedTargetIds = targetIds ? [...targetIds].sort() : [];

  sortedTargetIds.forEach((targetId) => {
    const seriesId = getSeriesId(
      seriesIdType,
      responseId,
      targetId,
      summaryType ?? '',
      dataDefinition?.dataId ?? '',
    );

    // 시리즈 형식 정의
    series[seriesId] = {
      name: getSeriesName(
        seriesNameField,
        dataDefinition?.dataId ?? '',
        dataDefinition?.displayName ?? '',
        metrics?.[0],
        summaryType ?? '',
      ),
      ...seriesDataFormat(seriesId, 0),
    };

    if (typeof passingValue === 'number') {
      series[seriesId].passingValue = passingValue;
    }

    // 메트릭 데이터에 Null 삽입
    if (metrics?.[0]?.values) {
      labels = metrics[0].values.map(([time]) =>
        dayjs(utcZeroTimeToStandardTime(time as unknown as string)),
      );

      data[seriesId] = metrics[0].values.map(([, value]) => {
        const typedValue = value as unknown as number | null;
        if (typedValue === passingValue) {
          return typedValue;
        }
        if (isUndefined(typedValue) || isNull(typedValue)) {
          return null;
        }
        return seriesValueFormat(typedValue);
      });
    } else {
      data[seriesId] = [];
    }
  });

  return { data, series, labels };
};

const convertMetricResponseToChartData = (
  response: MetricResponse,
  responseIndex: number,
  option: TransformMetricOpt,
): { data: ChartDataDataType; series: ChartDataSeriesType; labels: (number | Dayjs)[] } => {
  const { aggregationType, dataDefinition } = response;

  const responseId = `${dataDefinition?.dataId}_${responseIndex}`;
  if (aggregationType === BY_TARGET_SERIES_TYPE) {
    return convertMetricResponseWhenAggregationTypeIsByTarget(response, responseId, option);
  }
  return convertMetricResponseWhenAggregationTypeIsOthers(response, responseId, option);
};

const transformCommonMetricChart = (
  data: MetricResponse[],
  options: TransformMetricOpt,
): CommonMetricChartInfo[] => {
  return data.map<CommonMetricChartInfo>((response, index) => {
    const { unit = '', displayName = '', dataId = '' } = response.dataDefinition ?? {};

    const {
      data: chartData,
      series,
      labels,
    } = convertMetricResponseToChartData(response, index, options);
    return {
      chartData: { data: chartData, series, labels, groups: [] },
      title: displayName,
      chartId: dataId,
      unit,
    };
  });
};

const transformMetricChartArray = (
  data: MetricResponse[],
  options: TransformMetricOpt,
): ChartData[] => {
  return data.map<ChartData>((response, index) => {
    const {
      data: chartData,
      series,
      labels,
    } = convertMetricResponseToChartData(response, index, options);
    return { data: chartData, series, labels, groups: [] };
  });
};

const transformMetricChartAsSummaryType = (
  data: MetricResponse[],
  options: TransformMetricOpt,
): Record<string, ChartData> => {
  const chartInfo: Record<string, ChartData> = {};

  data.forEach((response, index) => {
    const {
      data: chartData,
      series,
      labels,
    } = convertMetricResponseToChartData(response, index, options);

    if (chartInfo[response.summaryType ?? '']) {
      chartInfo[response.summaryType ?? ''].data = {
        ...chartInfo[response.summaryType ?? ''].data,
        ...chartData,
      };
      chartInfo[response.summaryType ?? ''].series = {
        ...chartInfo[response.summaryType ?? ''].series,
        ...series,
      };
    } else {
      chartInfo[response.summaryType ?? ''] = { data: chartData, series, labels, groups: [] };
    }
  });

  return chartInfo;
};

const transformMetricChart = (
  data: MetricResponse[],
  options: TransformMetricOpt,
): MetricChartInfo[] => {
  return data.map<MetricChartInfo>((response, index) => {
    const { unit = '', displayName = '', dataId = '' } = response.dataDefinition ?? {};
    const status = options?.useStatus
      ? {
          status: response.status,
          reason: response.reason,
        }
      : {};

    const {
      data: chartData,
      series,
      labels,
    } = convertMetricResponseToChartData(response, index, options);

    return {
      chartData: { data: chartData, series, labels, groups: [] },
      dataId,
      displayName,
      unit,
      ...status,
    };
  });
};

export const transformMetricChartData = (data: MetricResponse[], options?: TransformMetricOpt) => {
  const { transformType = 'MetricChart' } = options ?? {};

  if (!data || !data.length) return [];
  switch (transformType) {
    case 'CommonMetricChart':
      return transformCommonMetricChart(data, options ?? {});
    case 'array':
      return transformMetricChartArray(data, options ?? {});
    case 'summaryType':
      return transformMetricChartAsSummaryType(data, options ?? {});
    case 'MetricChart':
      return transformMetricChart(data, options ?? {});
    default:
      return data;
  }
};

export const transformMetricChartValues = (data?: any[]) => {
  // For Stat Chart
  if (!data || !data.length) {
    return [];
  }

  return data.map(([time, value]) => [utcZeroTimeToStandardTime(time), value]);
};
