import { PickupPaths, createI18n, useI18n } from 'vue-i18n';
import en from './en.json';
import ko from './ko.json';
// import ja from './ja.json';
// import cn from './zh-CN.json';

function messageResolver(obj, path: string) {
  const paths = path.split('.');
  const msg = paths.reduce((o, p) => o[p], obj);

  // eslint-disable-next-line no-nested-ternary
  return msg !== undefined ? msg : path.includes('API') ? obj.API.ERROR.Exception : null;
}
export type MessageSchema = typeof en;
export type MessageSchemaStringType = PickupPaths<{
  [K in keyof MessageSchema]: MessageSchema[K];
}>;

const supportLanguages = ['en', 'ko' /** , 'ja', 'cn' */] as const;
type SupportLanguage = (typeof supportLanguages)[number];

const i18n = createI18n<[MessageSchema], SupportLanguage, false>({
  legacy: false, // use Composition API
  warnHtmlMessage: false, // disable warning HTML in message
  globalInjection: true, // Refer a global scope Composer instance of i18n
  locale: 'ko',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  warnHtmlInMessage: 'off',
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  messageResolver,
  messages: {
    ko,
    en,
    // ja,
    // cn,
  },
});

const useInternational = () => useI18n<{ message: MessageSchema }>();
const setUserLanguage = (lang: string) => {
  const language = supportLanguages.find((item) => item === lang.toLowerCase()) ?? 'en';
  i18n.global.locale.value = language;
};

export type I18nTranslation = ReturnType<typeof useInternational>['t'];

export { i18n, useInternational, setUserLanguage };
