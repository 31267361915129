<template>
  <Teleport to="body">
    <div
      v-show="props.isShow"
      ref="tooltipWrapper"
      :class="['common-tooltip', props.class]"
      :style="tooltipPosition"
    >
      <template v-if="props.text">
        {{ props.text }}
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useTooltipPosition } from './uses';

interface Props {
  isShow: boolean;
  evt?: MouseEvent | null;
  position?: {
    top: number;
    left: number;
  };
  class?: string;
  text?: string;
}
const props = defineProps<Props>();

const { tooltipWrapper, tooltipPosition } = useTooltipPosition(props);
</script>

<style lang="scss">
.common-tooltip {
  position: absolute;
  z-index: 850;
  box-shadow: var(--chart-tooltip-box-shadow);
  white-space: pre-line;

  &:not(.custom) {
    padding: 14px 12px;
    border: 1px solid var(--common-tooltip-simple-text-bg-color);
    border-radius: 2px;
    background-color: var(--common-tooltip-simple-text-bg-color);
    color: var(--common-tooltip-simple-text-font-color);
  }
}
</style>
