const encrypt = {
  encode: (val) => encodeURIComponent(btoa(val)),
  decode: (val, isJson = false) => {
    if (isJson) {
      try {
        return JSON.parse(atob(decodeURIComponent(val)));
      } catch (e) {
        return '';
      }
    } else {
      return atob(decodeURIComponent(val));
    }
  },
};

const webStorageController = {
  setItem: ({ type, key, value }) =>
    type === 'session' ? sessionStorage.setItem(key, value) : localStorage.setItem(key, value),
  getItem: ({ type, key }) =>
    type === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key),
  removeItem: ({ type, key }) =>
    type === 'session' ? sessionStorage.removeItem(key) : localStorage.removeItem(key),
  clear: (type) => (type === 'session' ? sessionStorage.clear() : localStorage.clear()),
};

export { encrypt, webStorageController };
