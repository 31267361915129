import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';
import { getInstanceByDbType } from '@/common/utils/commonUtils';

export type ObjectMapType =
  | 'tableSizeSpecificMoment'
  | 'tableSizeSpecificPeriod'
  | 'objectScanSpecificMoment'
  | 'objectScanSpecificPeriod'
  | 'tableScanRatioSpecificMoment'
  | 'tableScanRatioSpecificPeriod';

export interface State {
  selectedFolderId: string | null;
  selectedInstanceId: string | null;
  selectedInstanceName: string;
  selectedInstanceType: string;
  selectedFromTime: string;
  selectedToTime: string;
  selectedFromUtc0Time: string;
  selectedToUtc0Time: string;
  selectedObjectName: string;
  selectedMetricName: string;
  selectedObjectMapType: ObjectMapType;
}

export const postgresqlPaCondition: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedFolderId: null,
    selectedInstanceId: null,
    selectedInstanceName: '',
    selectedInstanceType: '',
    selectedFromTime: '',
    selectedToTime: '',
    selectedFromUtc0Time: '',
    selectedToUtc0Time: '',
    selectedObjectName: '',
    selectedMetricName: '',
    selectedObjectMapType: 'tableSizeSpecificMoment',
  },
  mutations: {
    setSelectedFolderId: (state: State, folderId: string | null) => {
      state.selectedFolderId = folderId;
    },
    setSelectedInstanceId: (state: State, instanceId: string) => {
      state.selectedInstanceId = instanceId;
    },
    setSelectedInstanceName: (state: State, instanceName: string) => {
      state.selectedInstanceName = instanceName;
    },
    setSelectedInstanceType: (state: State, instanceType: string) => {
      state.selectedInstanceType = instanceType;
    },
    setSelectedFromTime: (state: State, date: string) => {
      state.selectedFromTime = date;
    },
    setSelectedToTime: (state: State, date: string) => {
      state.selectedToTime = date;
    },
    setSelectedFromUtc0Time: (state: State, date: string) => {
      state.selectedFromUtc0Time = date;
    },
    setSelectedToUtc0Time: (state: State, date: string) => {
      state.selectedToUtc0Time = date;
    },
    setSelectedObjectName: (state: State, objectName: string) => {
      state.selectedObjectName = objectName;
    },
    setSelectedMetricName: (state: State, metricName: string) => {
      state.selectedMetricName = metricName;
    },
    setSelectedObjectMapType: (state: State, objectMapType: ObjectMapType) => {
      state.selectedObjectMapType = objectMapType;
    },
    initSelectedInfo: (state: State) => {
      state.selectedFolderId = null;
      state.selectedInstanceId = null;
      state.selectedInstanceName = '';
      state.selectedInstanceType = '';
      state.selectedFromTime = '';
      state.selectedToTime = '';
      state.selectedMetricName = '';
      state.selectedObjectMapType = 'tableSizeSpecificMoment';
    },
  },
  actions: {},
  getters: {
    getSelectedFolderId: (state: State): string | null => state.selectedFolderId,
    getSelectedInstanceId: (state: State): string | null => state.selectedInstanceId,
    getSelectedInstanceName: (state: State): string => state.selectedInstanceName,
    getSelectedInstanceType: (state: State): string => state.selectedInstanceType,
    getSelectedFromTime: (state: State): string => state.selectedFromTime,
    getSelectedToTime: (state: State): string => state.selectedToTime,
    getSelectedFromUtc0Time: (state: State): string => state.selectedFromUtc0Time,
    getSelectedToUtc0Time: (state: State): string => state.selectedToUtc0Time,
    getSelectedObjectName: (state: State): string => state.selectedObjectName,
    getSelectedMetricName: (state: State): string => state.selectedMetricName,
    getSelectedObjectMapType: (state: State): string => state.selectedObjectMapType,

    // PG 전체 정보 조회
    getAllFolders: (state, getters, rootState, rootGetters) =>
      rootGetters['monitoringFolder/getFolders'].filter(
        (folder) =>
          !!folder.instances.length &&
          folder.instances.some(getInstanceByDbType(DB_TYPE.POSTGRESQL)),
      ),
    getAllInstances: (state, getters, rootState, rootGetters) =>
      rootGetters['monitoringInstance/getInstances'].filter(
        getInstanceByDbType(DB_TYPE.POSTGRESQL),
      ),
    getInstanceNameById: (state, getters) => (id: string) => {
      return getters.getAllInstances.find((instance) => instance.instanceId === id)?.name ?? '';
    },
  },
};
