import { WidgetUUID } from '@/common/utils';
import { uniq } from 'lodash-es';
import { InjectionKey, provide, ref, Ref } from 'vue';

type DataField = string;

export const DATA_FIELD_KEY = Symbol('') as InjectionKey<{
  dataFieldInfo: Ref<Map<WidgetUUID, DataField[]>>;
  setWidgetDataField: (widgetUUID: WidgetUUID, dataFieldList: Readonly<DataField[]>) => void;
  getWidgetDataField: (widgetUUID: WidgetUUID) => DataField[] | undefined;
  deleteWidgetDataField: (widgetUUID: WidgetUUID) => void;
}>;

export const useDataFieldProvide = () => {
  const dataFieldInfo = ref<Map<WidgetUUID, DataField[]>>(new Map());

  const setWidgetDataField = (widgetUUID: WidgetUUID, dataFieldList: Readonly<DataField[]>) => {
    dataFieldInfo.value.set(widgetUUID, uniq(dataFieldList));
  };

  const getWidgetDataField = (widgetUUID: WidgetUUID) => {
    return dataFieldInfo.value.get(widgetUUID);
  };

  const deleteWidgetDataField = (widgetUUID: WidgetUUID) => {
    return dataFieldInfo.value.delete(widgetUUID);
  };

  provide(DATA_FIELD_KEY, {
    dataFieldInfo,
    setWidgetDataField,
    getWidgetDataField,
    deleteWidgetDataField,
  });
};
