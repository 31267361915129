import { computed, onMounted, getCurrentInstance, reactive, watch, ref } from 'vue';
import { Store } from 'vuex';
import { useViewModeStore } from '@/common/stores/view-mode';
import { RootState, useStore } from '@/common/store';
import { PRODUCT_THEMES, USER_GENERAL_SETTING_ITEMS } from '@/common/utils/define';
import { MyInfo } from '@/common/api/resTypes/userInfoRes';
import router from '@/common/router';
import { confirmMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { patchMyInfoUserV7ControllerAxios } from '@/openapi/metaV6/api/user-v7-controller-api';
import { UpdateUserV7Request } from '@/openapi/metaV6/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { LanguageAndThemeInfo } from './myInfoPop.types';

export interface Emit {
  (e: 'click-setting'): void;
}

export const setup = (emit: Emit) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const { productName } = useViewModeStore();

  const store: Store<RootState> = useStore();
  const userInfo = computed<MyInfo>(() => store.getters['myInfo/getAccountInfo']);
  const selectedGroupId = ref<number>();

  const userTheme = computed(() => {
    return userInfo.value?.theme?.toLowerCase() ?? '';
  });

  const userLanguage = computed(() => {
    return (
      USER_GENERAL_SETTING_ITEMS.lang.find(({ value }) => value === userInfo.value.language)
        ?.name ?? USER_GENERAL_SETTING_ITEMS.lang[0].name
    );
  });

  const languageAndThemeInfo = reactive<LanguageAndThemeInfo>({
    lang: userLanguage.value,
    isDarkTheme: (PRODUCT_THEMES.includes(userTheme.value) ? userTheme.value : 'light') === 'dark',
  });

  const onLogout = () => {
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.LOGOUT'),
      okCallback: () => router.push('/logout'),
      confirmBtnText: t('WORD.GNB.PROFILE_LOGOUT'),
    });
  };

  onMounted(() => {
    languageAndThemeInfo.lang = userLanguage.value;
    languageAndThemeInfo.isDarkTheme =
      (PRODUCT_THEMES.includes(userTheme.value) ? userTheme.value : 'light') === 'dark';
    selectedGroupId.value = userInfo.value.groups.find((item) => item.fixed)?.groupId ?? -1;
  });

  const fetchMyInfo = async () => {
    try {
      const language =
        USER_GENERAL_SETTING_ITEMS.lang.find(({ name }) => name === languageAndThemeInfo.lang)
          ?.value ?? USER_GENERAL_SETTING_ITEMS.lang[0].value;
      const request: UpdateUserV7Request = {
        ...userInfo.value,
        email: userInfo.value.email,
        name: userInfo.value.name,
        theme: languageAndThemeInfo.isDarkTheme ? 'dark' : 'light',
        language,
        groups: userInfo.value.groups.map((item) => {
          item.fixed = item.groupId === selectedGroupId.value;
          return item;
        }),
      };
      await patchMyInfoUserV7ControllerAxios({
        request,
        frameName: FRAME_NAMES.CONFIG_MY_INFO.PATCH,
      });
      router.go(0);
    } catch (e) {
      console.log(e);
    }
  };

  const groupItems = computed(() =>
    userInfo.value.groups.map((item) => ({
      value: item.groupId,
      name: item.name,
    })),
  );
  const onChangeGroup = (groupId: number) => {
    selectedGroupId.value = groupId;
    fetchMyInfo();
  };

  const onClickSetting = () => {
    emit('click-setting');
  };

  watch(
    [() => languageAndThemeInfo.lang, () => languageAndThemeInfo.isDarkTheme],
    ([newLanguage, newIsDarkTheme], [oldLanguage, oldIsDarkTheme]) => {
      if (newLanguage !== oldLanguage || newIsDarkTheme !== oldIsDarkTheme) {
        fetchMyInfo();
      }
    },
  );

  return {
    t,
    productName,
    userInfo,
    languageAndThemeInfo,
    groupItems,
    selectedGroupId,
    onChangeGroup,
    onClickSetting,
    onLogout,
  };
};
