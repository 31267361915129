<script setup lang="ts">
import FloatingMenuElement from './FloatingMenuElement.vue';
import FloatingSubMenu from './FloatingSubMenu.vue';
import { type Props, setup } from './floatingMenu.setup';

const props = defineProps<Props>();
const {
  floatingMenuRef,
  floatingSubMenuRef,

  floatingSubMenuStyle,
  subMenuInfo,
  computedDashboardMenu,
  computedAnalysisBoardMenu,

  onHoverSubMenu,
  clearSubMenu,
} = setup(props);
</script>

<template>
  <div
    ref="floatingMenuRef"
    class="floating-menu"
  >
    <div
      v-show="menuInfo?.text"
      class="floating-menu__title"
    >
      <span>{{ menuInfo?.text }}</span>
    </div>
    <ul class="floating-menu__inner">
      <!-- Dashboard -->
      <floating-menu-element
        v-if="menuInfo?.dashboardList"
        :menu-info="computedDashboardMenu!"
        @mouseenter="(e) => onHoverSubMenu(e, computedDashboardMenu!)"
      />

      <!-- Analysis Board -->
      <floating-menu-element
        v-if="
          menuInfo?.analysisBoardList &&
          props.analysisBoardMenuList &&
          props.analysisBoardMenuList.length > 0
        "
        :menu-info="computedAnalysisBoardMenu!"
        @mouseenter="(e) => onHoverSubMenu(e, computedAnalysisBoardMenu!)"
      />

      <!-- children -->
      <template v-for="(menu, menuIdx) in menuInfo?.children">
        <template v-if="!menu?.children?.length && menu.text">
          <floating-menu-element
            :key="`${menu.value}_${menuIdx}`"
            :menu-info="menu"
            @mouseenter="(e) => onHoverSubMenu(e, menu)"
          />
        </template>

        <template v-else-if="menu.children?.length">
          <li
            :key="`${menu.value}_${menuIdx}`"
            class="floating-menu__mid"
          >
            <div
              v-show="menu.text"
              class="floating-menu__mid-title"
            >
              <span>{{ menu.text }}</span>
            </div>
            <ul>
              <floating-menu-element
                v-for="(info, childMenuIdx) in menu.children"
                :key="`${info.value}_${childMenuIdx}`"
                :menu-info="info"
                @mouseenter="(e) => onHoverSubMenu(e, info)"
              />
            </ul>
          </li>
        </template>
      </template>
    </ul>

    <floating-sub-menu
      v-show="subMenuInfo"
      ref="floatingSubMenuRef"
      :menu-info="subMenuInfo"
      :style="floatingSubMenuStyle"
      @mouseleave="clearSubMenu"
    />
  </div>
</template>

<style lang="scss">
.floating-menu {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--gnb-header-height));
  overflow: hidden;

  &__title {
    display: flex;
    align-items: center;
    height: var(--lnb-header-height);
    min-height: var(--lnb-header-height);
    max-height: var(--lnb-header-height);
    padding: 0 16px;
    background-color: var(--lnb-header-bg-color);
    font-size: 11px;
    font-weight: 700;
    color: var(--lnb-header-font-color);
  }

  &__inner {
    flex: 1;
    overflow: auto;
    > li:first-of-type {
      border: none;
    }
  }

  &__mid {
    border-top: 1px solid var(--lnb-menu-border-color);
  }

  &__mid-title {
    display: flex;
    align-items: center;
    height: var(--lnb-menu-height);
    padding: 0 22px;
    font-weight: 700;
    color: var(--lnb-menu-depth1-font-color);
  }

  @include max-screen() {
    &__title {
      height: 36px;
    }
  }
}
</style>
