<template>
  <ev-window
    v-model:visible="mv"
    :title="$props.title"
    :window-class="windowClass"
    :width="windowSize.width"
    :height="windowSize.height"
    :esc-close="true"
  >
    <template
      v-if="!$props.title && slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <div :class="['window-content', { 'window-content--has-tail': slots.tail }]">
      <slot />
    </div>
    <template v-if="slots.tail">
      <slot name="tail">
        <div class="btn-field">
          <ev-button @click="closeWindow"> {{ $t('WORD.CANCEL') }} </ev-button>
          <ev-button
            type="primary"
            @click="clickSave"
          >
            {{ $t('WORD.SAVE') }}
          </ev-button>
        </div>
      </slot>
    </template>
    <template
      v-if="slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </ev-window>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, useSlots } from 'vue';
import { WindowSizeType, WINDOW_SIZE } from '@/common/utils';

export default defineComponent({
  name: 'BaseModalWindow',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    sizeType: {
      type: String as PropType<WindowSizeType | 'CUSTOM'>,
      default: 'MEDIUM',
    },
    size: {
      type: Object as PropType<{ width: string; height: string }>,
      default: () => ({
        width: '',
        height: '',
      }),
    },
    className: {
      type: String,
      default: '',
    },
  },
  emits: ['update:visible', 'click-save'],
  setup(props, { emit }) {
    const slots = useSlots();

    const mv = computed({
      get: () => props.visible,
      set: () => emit('update:visible', false),
    });

    const windowClass = computed(
      () =>
        `base-modal-window window--type-${props.sizeType.toLowerCase().replace('_', '-')} ${
          props.className
        }`,
    );
    const windowSize = computed(() => {
      if (props.sizeType === 'CUSTOM') {
        return {
          width: props.size.width,
          height: props.size.height,
        };
      }
      return WINDOW_SIZE[props.sizeType] ?? 'MEDIUM';
    });

    const closeWindow = () => {
      mv.value = false;
    };

    const clickSave = () => emit('click-save');

    return {
      slots,
      mv,
      windowClass,
      windowSize,
      closeWindow,
      clickSave,
    };
  },
});
</script>

<style lang="scss">
.base-modal-window {
  border-radius: 2px;
  &.ev-window {
    &.window--type-full {
      --vertical-padding: 30px;
      --horizontal-padding: 40px;

      top: var(--vertical-padding) !important;
      left: var(--horizontal-padding) !important;
      width: calc(100% - (var(--horizontal-padding) * 2)) !important;
      height: calc(100% - (var(--vertical-padding) * 2)) !important;
      padding: 56px 4px 42px;

      .ev-window-content {
        padding: 0 !important;
      }
      .config-content__wrapper {
        width: $config-window-wrapper-width;
      }
      .ev-window-close {
        top: 16px !important;
      }
      .config-section {
        margin-bottom: 0;
      }
    }
    .window-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto !important;
      &--has-tail {
        height: calc(100% - 35px - 10px);
        margin-bottom: 10px;
        overflow-y: auto;
      }
    }
  }
}
.ev-window-header {
  border-radius: 2px 2px 0 0;
}
</style>
