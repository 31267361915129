<script setup lang="ts">
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import { setup, Props } from '@/alert/components/alertTargetList/alertTargetList.setup';
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';

const props = withDefaults(defineProps<Props>(), {
  shownCount: undefined,
  isExpand: false,
  isClickable: false,
});
const {
  isBrowserPopup,
  tagWrapperRef,
  visibleTagItems,
  moreCount,
  tooltipList,
  targetsTooltipRef,
  onClickMore,
  onClickTag,
} = setup(props);
</script>

<template>
  <div class="target-list">
    <div
      ref="tagWrapperRef"
      :class="['target-item-wrapper', { 'max-100': !moreCount }]"
    >
      <tag-item
        v-for="(item, idx) in visibleTagItems"
        :key="`target${idx}`"
        size="xsmall"
        :title="item.name"
        :html-type="!isBrowserPopup && item.clickable ? 'button' : 'span'"
        :class="['target-item', { clickable: !isBrowserPopup && item.clickable }]"
        @click.stop="() => onClickTag(item)"
      >
        {{ item.name }}
      </tag-item>
    </div>
    <badge-item
      v-if="moreCount"
      size="xsmall"
      html-type="button"
      class="more-btn"
      @click.stop="onClickMore"
    >
      {{ `+${moreCount}` }}
    </badge-item>
  </div>
  <base-custom-tooltip
    ref="targetsTooltipRef"
    use-deselect
    custom-class="alert-target-tooltip"
  >
    <div
      v-for="(item, idx) in tooltipList"
      :key="`item${idx}`"
      class="alert-target-tooltip__row"
    >
      <tag-item
        :title="item.name"
        size="xsmall"
        :html-type="!isBrowserPopup && item.clickable ? 'button' : 'span'"
        :class="['target-item', { clickable: !isBrowserPopup && item.clickable }]"
        @click.stop="() => onClickTag(item)"
      >
        {{ item.name }}
      </tag-item>
    </div>
  </base-custom-tooltip>
</template>

<style lang="scss">
.target-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  .target-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 40px);
    height: 100%;
    &.max-100 {
      max-width: 100%;
    }
  }
  .target-item {
    max-width: 100%;
    margin: 4px 4px 4px 0;
    &:last-child {
      margin-right: 0;
    }
    &.clickable:hover {
      background-color: var(--badge-active-bg-color);
    }
    span {
      @include shortening();
    }
  }
  .more-btn {
    margin-left: 4px;
  }
}
.alert-target-tooltip {
  min-width: 150px;
  max-width: 200px;
  max-height: 190px;
  padding: 8px 8px 6px;
  overflow-y: auto;
  background-color: var(--alert-grid-tooltip-bg);
  &__row {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .tag-item {
    width: auto !important;
    max-width: 100%;

    @include shortening();

    &.clickable:hover {
      background-color: var(--badge-active-bg-color);
    }
  }
}
</style>
