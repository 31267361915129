import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { postV1UserEnvsAxios } from '@/openapi/meta/api/xm-user-env-controller-api';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getOverviewsMysqlV7ControllerAxios } from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { OverviewItemV7 } from '@/openapi/mysqlV7/model';
import { StatItem } from '@/openapi/data/model';
import { DB_TYPE } from '@/common/utils';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface OverviewState {
  metricNameInfo: StatItem[];
  metricNamesInChartUsed: string[];
  displayNamesInChartUsed: string[];
  metricInfo: OverviewItemV7[];
  errorStatusText: string;
}

const OVERVIEW_METRIC_NAMES_KEY = 'MYSQL_OVERVIEW_METRIC_NAMES';
const FRAME_NAME = 'overview';

// category database stat 중 일부만 보여줘야 해서 하드코딩으로 진행
const DEFAULT_METRIC_NAMES = [
  'db_mysql_os.cpu_usage',
  'db_mysql_os.memory_percent',
  'db_mysql_tps',
  'db_mysql_qps',
  'db_mysql_threads_connected',
  'db_mysql_connection_usage(%)',
  'db_mysql_questions',
  'db_mysql_threads_running',
  'db_mysql_select_full_join',
  'db_mysql_full_table_scan_ratio(%)',
  'db_mysql_thread_pool_usage(%)',
];
// TODO: 카테고리 필요 없음
const DEFAULT_METRIC_INFO: StatItem[] = [
  {
    category: 'Database Stat',
    dataId: 'db_mysql_os.cpu_usage',
    displayName: 'CPU',
    unit: '%',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_os.memory_percent',
    displayName: 'Memory',
    unit: '%',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_tps',
    displayName: 'Tps',
    unit: 'count/sec',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_qps',
    displayName: 'Qps',
    unit: 'count/sec',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_threads_connected',
    displayName: 'Threads Connected',
    unit: 'count',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_connection_usage(%)',
    displayName: 'Connection Usage',
    unit: '%',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_questions',
    displayName: 'Questions',
    unit: 'count',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_threads_running',
    displayName: 'Threads Running',
    unit: 'count',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_select_full_join',
    displayName: 'Select Full Join',
    unit: 'count',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_full_table_scan_ratio(%)',
    displayName: 'Full Table Scan Ratio',
    unit: '%',
  },
  {
    category: 'Database Stat',
    dataId: 'db_mysql_thread_pool_usage(%)',
    displayName: 'Thread Pool Usage',
    unit: '%',
  },
];

export const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state: {
    metricNameInfo: DEFAULT_METRIC_INFO,
    metricNamesInChartUsed: [],
    displayNamesInChartUsed: [],
    metricInfo: [],
    errorStatusText: '',
  },
  mutations: {
    setErrorStatusText: (state: OverviewState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
    setMetricInfo: (state: OverviewState, data: OverviewItemV7[]) => {
      state.metricInfo = data;
    },
    setMetricNamesInChartUsed: (state: OverviewState, metricNames: string[]) => {
      state.metricNamesInChartUsed = metricNames;
    },
    setDisplayNamesInChartUsed: (state: OverviewState, displayNames: string[]) => {
      state.displayNamesInChartUsed = displayNames;
    },
    changeMetricNamesInChartUsed: (
      state: OverviewState,
      { idx, metricName }: { idx: number; metricName: string },
    ) => {
      state.metricNamesInChartUsed[idx] = metricName;
    },
    changeDisplayNamesInChartUsed: (
      state: OverviewState,
      { idx, displayName }: { idx: number; displayName: string },
    ) => {
      state.displayNamesInChartUsed[idx] = displayName;
    },
  },
  actions: {
    initMetricNamesInChartUsed: async ({ commit, rootGetters }) => {
      const metricNamesInUserEnv =
        rootGetters['userEnv/getUserEnvMap'].get(OVERVIEW_METRIC_NAMES_KEY) ?? '{}';
      const parseMetricNamesInUserEnv = JSON.parse(metricNamesInUserEnv);
      if (parseMetricNamesInUserEnv?.metricNames) {
        await commit(
          'setMetricNamesInChartUsed',
          mapMetricNamesToDataIds({
            dbType: DB_TYPE.MYSQL,
            metricNames: parseMetricNamesInUserEnv.metricNames,
          }),
        );
      } else {
        await commit('setMetricNamesInChartUsed', [...DEFAULT_METRIC_NAMES.slice(0, 4)]);
      }

      const defaultDisplayName = DEFAULT_METRIC_INFO.map((v) => v.displayName);
      if (parseMetricNamesInUserEnv?.displayNames) {
        const displayNames = [
          'CPU',
          'Memory',
          ...parseMetricNamesInUserEnv.displayNames.slice(2, 4),
        ];
        await commit('setDisplayNamesInChartUsed', displayNames);
      } else {
        await commit('setDisplayNamesInChartUsed', defaultDisplayName.slice(0, 4));
      }
    },
    async saveMetricNamesInUserEnv(
      { commit },
      { metricNames, displayNames }: { metricNames: string[]; displayNames: string[] },
    ) {
      const metricInfo = {
        metricNames,
        displayNames,
      };
      await postV1UserEnvsAxios({
        request: {
          key: OVERVIEW_METRIC_NAMES_KEY,
          value: JSON.stringify(metricInfo),
        },
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: OVERVIEW_METRIC_NAMES_KEY, value: JSON.stringify(metricInfo) },
        { root: true },
      );
    },
    async fetchMetricInfo(
      { commit },
      { metricNames, instanceIds = [] }: { metricNames: string[]; instanceIds: string[] },
    ) {
      try {
        if (!instanceIds.length) {
          return;
        }
        const { data } = await getOverviewsMysqlV7ControllerAxios({
          metricNames: [...new Set(metricNames)],
          instanceIds,
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.OVERVIEW,
        });

        commit('setMetricInfo', data.data);
        commit('setErrorStatusText', '');
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', FRAME_NAME, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getErrorStatusText: (state: OverviewState) => state.errorStatusText,
    getMetricInfo: (state: OverviewState) => state.metricInfo,
    getMetricNameInfo: (state: OverviewState) => state.metricNameInfo,
    getMetricNamesInChartUsed: (state: OverviewState) => state.metricNamesInChartUsed,
    getDisplayNamesInChartUsed: (state: OverviewState) => state.displayNamesInChartUsed,
  },
};
