<script lang="ts" setup>
import AlertCountList from '@/common/components/organisms/alertListDetail/AlertCountList.vue';
import AlertDetailList from '@/common/components/organisms/alertListDetail/AlertDetailList.vue';
import {
  setup,
  Props,
  Emit,
} from '@/common/components/organisms/alertListDetail/alertListDetail.setup';

const props = withDefaults(defineProps<Props>(), {
  isShow: false,
  viewName: '',
  type: 'dashboard',
});
const emit = defineEmits<Emit>();

const {
  isShowAlertDetail,
  selectedGroupId,
  isFetchingGroupList,
  groupList,
  selectedRuleCardId,
  ruleList,
  onClickGroup,
  onClickRule,
  onClickClear,
  t,
} = setup(props, emit);
</script>

<template>
  <div :class="['dashboard-alert-detail', { 'show-detail': isShowAlertDetail }]">
    <ev-loading v-model="isFetchingGroupList" />
    <alert-count-list
      v-model:selected-id="selectedGroupId"
      :title="t('WORD.ALERT_GROUP')"
      :list="groupList ?? []"
      @click-item="onClickGroup"
    />
    <alert-detail-list
      v-model:selected-id="selectedRuleCardId"
      :title="t('WORD.ALERT_LIST')"
      :selected-group-id="selectedGroupId"
      :list="ruleList"
      @click-item="onClickRule"
      @clear-items="onClickClear"
    />
  </div>
</template>

<style lang="scss">
.dashboard-alert-detail {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .alert-count-list {
    width: 300px;
  }
  .alert-detail-list {
    width: calc(100% - 300px);
    transition: width $animate-base;
  }
  &.show-detail {
    .alert-detail-list {
      width: 300px;
    }
  }
}
</style>
