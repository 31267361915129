import { Module } from 'vuex';
import { RootState } from '@/common/store';
import {
  CheckPointItemV7,
  ReplicationInfoItemV7,
  VacuumAgeItemV7,
} from '@/openapi/postgresqlV7/model';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import {
  getCheckpointPostgresqlV7ControllerAxios,
  getReplicationPostgresqlV7ControllerAxios,
  getVacuumInfoPostgresqlV7ControllerAxios,
} from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface Replication
  extends Required<Pick<ReplicationInfoItemV7, 'primary' | 'standby' | 'lag'>> {
  collectTime: string;
}

export interface StatusState {
  vacuum: VacuumAgeItemV7[];
  vacuumErrorStatusText: string;
  replication: Replication[];
  replicationErrorStatusText: string;
  checkpoint: CheckPointItemV7[];
  checkpointErrorStatusText: string;
}

export const status: Module<StatusState, RootState> = {
  namespaced: true,
  state: {
    vacuum: [],
    vacuumErrorStatusText: '',
    replication: [],
    replicationErrorStatusText: '',
    checkpoint: [],
    checkpointErrorStatusText: '',
  },
  mutations: {
    setVacuum: (state: StatusState, data: VacuumAgeItemV7[]) => {
      state.vacuum = data;
    },
    setVacuumErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.vacuumErrorStatusText = errorStatusText;
    },
    setReplication: (state: StatusState, data: Replication[]) => {
      state.replication = data;
    },
    setReplicationErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.replicationErrorStatusText = errorStatusText;
    },
    setCheckpoint: (state: StatusState, data: CheckPointItemV7[]) => {
      state.checkpoint = data;
    },
    setCheckpointErrorStatusText: (state: StatusState, errorStatusText: string) => {
      state.checkpointErrorStatusText = errorStatusText;
    },
  },
  actions: {
    dispatchVacuum: async ({ commit }, instanceId) => {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OVERVIEW_VACUUM;
      try {
        const { data } = await getVacuumInfoPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setVacuum', data.data);
        commit('setVacuumErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setVacuumErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    dispatchReplication: async ({ commit }, instanceId) => {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OVERVIEW_REPLICATION;
      try {
        const { data } = await getReplicationPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setReplication', data.data);
        commit('setReplicationErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setReplicationErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    dispatchCheckpoint: async ({ commit }, instanceId) => {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OVERVIEW_CHECKPOINT;
      try {
        const { data } = await getCheckpointPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setCheckpoint', data.data);
        commit('setCheckpointErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setCheckpointErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getVacuum: (state: StatusState) => state.vacuum || [],
    getRecentVacuum: (state: StatusState) => state.vacuum[state.vacuum.length - 1] || {},
    getVacuumErrorStatusText: (state: StatusState) => state.vacuumErrorStatusText,
    getReplication: (state: StatusState) => state.replication || [],
    getRecentReplication: (state: StatusState) =>
      state.replication[state.replication.length - 1] || {},
    getReplicationErrorStatusText: (state: StatusState) => state.replicationErrorStatusText,
    getCheckpoint: (state: StatusState) => state.checkpoint || [],
    getRecentCheckpoint: (state: StatusState) =>
      state.checkpoint[state.checkpoint.length - 1] || {},
    getCheckpointErrorStatusText: (state: StatusState) => state.checkpointErrorStatusText,
  },
};
