import { nextTick, onBeforeMount, ref } from 'vue';

export const setup = (props) => {
  const customTooltipEl = ref();
  const isShowTooltip = ref<boolean>(false);
  const tooltipStyle = ref<{ top: string; left: string }>({ top: '0', left: '0' });
  const targetEl = ref<HTMLElement | null>(null);
  const directionClass = ref('');

  const show = async (evt) => {
    if (props.useDeselect && targetEl.value === evt.target) {
      targetEl.value = null;
      return;
    }
    directionClass.value = '';
    isShowTooltip.value = true;
    await nextTick();

    targetEl.value = evt.type === 'click' ? evt?.target : evt?.toElement;
    const targetRect = targetEl.value?.getBoundingClientRect();
    if (customTooltipEl.value && targetRect) {
      const { width, height } = customTooltipEl.value.getBoundingClientRect();

      const docWidth = document.documentElement.clientWidth;
      const docHeight = document.documentElement.clientHeight;
      const { scrollLeft, scrollTop } = document.documentElement;
      const X_PADDING = 2;
      const Y_PADDING = 1;
      let pointX: number =
        evt.pageX - (evt.clientX - targetRect.left) + targetRect.width + X_PADDING;
      if (scrollLeft + docWidth < pointX + width) {
        pointX -= width + targetRect.width + X_PADDING * 2;
        directionClass.value += 'left';
      }

      let pointY: number = evt.pageY - (evt.clientY - targetRect.top) - Y_PADDING;
      if (docHeight + scrollTop < pointY + height) {
        pointY -= height - targetRect.height - Y_PADDING * 2;
        directionClass.value += ' top';
      }

      tooltipStyle.value = {
        top: `${pointY}px`,
        left: `${pointX}px`,
      };
    }
  };

  const hide = (evt?) => {
    isShowTooltip.value = false;
    tooltipStyle.value = { top: '', left: '' };

    if (!props.useDeselect || (props.useDeselect && targetEl.value !== evt?.toElement)) {
      targetEl.value = null;
    }
  };

  const onMouseleave = () => {
    if (props.useHoverEvent) {
      hide();
    }
  };

  const createRootEl = () => {
    const rootId = 'base-custom-tooltip';
    if (!document.getElementById(rootId)) {
      const baseCustomTooltipEl = document.createElement('div');
      baseCustomTooltipEl.id = rootId;
      baseCustomTooltipEl.setAttribute('style', 'position: absolute; top: 0; left: 0;');
      document.body.appendChild(baseCustomTooltipEl);
    }
  };

  onBeforeMount(() => {
    createRootEl();
  });

  return {
    customTooltipEl,
    isShowTooltip,
    tooltipStyle,
    directionClass,
    show,
    hide,
    onMouseleave,
  };
};
