/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  IntegratedLockThreadDetailV7Response,
  LockThreadDetailV7Response,
  ThreadDetailResponse,
  ThreadDetailV7Response,
  ThreadTabV7Response,
  TopLongActiveThreadV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const integratedLockThreadDetailV7ThreadV7ControllerAxios = ({
  threadId,
  digest,
  fromTime,
  instanceId,
  lockType,
  period,
  queryStart,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  threadId: number;
  digest?: string;
  fromTime?: string;
  instanceId?: string;
  lockType?: 'meta' | 'normal';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryStart?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegratedLockThreadDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/integrated-lock/threads/{threadId}'.replace(
    `{${'threadId'}}`,
    encodeURIComponent(String(threadId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      digest,
      fromTime,
      instanceId,
      lockType,
      period,
      queryStart,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const lockThreadDetailV7ThreadV7ControllerAxios = ({
  threadId,
  digest,
  fromTime,
  instanceId,
  period,
  queryStart,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  threadId: number;
  digest?: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryStart?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LockThreadDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/lock/threads/{threadId}'.replace(
    `{${'threadId'}}`,
    encodeURIComponent(String(threadId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      digest,
      fromTime,
      instanceId,
      period,
      queryStart,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const PaThreadDetailThreadV7ControllerAxios = ({
  threadId,
  digest,
  fromTime,
  instanceId,
  period,
  queryStart,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  threadId: number;
  digest?: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryStart?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ThreadDetailResponse> => {
  const localVarPath = '/api/v7/mysql/pa/threads/{threadId}'.replace(
    `{${'threadId'}}`,
    encodeURIComponent(String(threadId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      digest,
      fromTime,
      instanceId,
      period,
      queryStart,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const threadTabThreadV7ControllerAxios = ({
  filter,
  fromTime,
  instanceId,
  isFullText,
  isReport,
  period,
  sqlText,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  filter?: 'Active' | 'DEFAULT' | 'Long';
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  sqlText?: string;
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ThreadTabV7Response> => {
  const localVarPath = '/api/v7/mysql/thread';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      filter,
      fromTime,
      instanceId,
      isFullText,
      isReport,
      period,
      sqlText,
      timezone,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const threadDetailV7ThreadV7ControllerAxios = ({
  threadId,
  digest,
  fromTime,
  instanceId,
  period,
  queryStart,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  threadId: number;
  digest?: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  queryStart?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ThreadDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/threads/{threadId}'.replace(
    `{${'threadId'}}`,
    encodeURIComponent(String(threadId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      digest,
      fromTime,
      instanceId,
      period,
      queryStart,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topLongActiveThreadThreadV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopLongActiveThreadV7Response> => {
  const localVarPath = '/api/v7/mysql/top-long-activethread';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      size,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  integratedLockThreadDetailV7ThreadV7ControllerAxios,
  lockThreadDetailV7ThreadV7ControllerAxios,
  PaThreadDetailThreadV7ControllerAxios,
  threadTabThreadV7ControllerAxios,
  threadDetailV7ThreadV7ControllerAxios,
  topLongActiveThreadThreadV7ControllerAxios,
};
