<script setup lang="ts">
import { type Props, type Emit, setup } from './supportMenuElement.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const { t, onHoverButton, onClickButton } = setup(props, emit);
</script>

<template>
  <li :class="['menu-element', 'support-menu', { selected }]">
    <button
      type="button"
      class="menu-element__title support-menu__title"
      :data-menu="info.value"
      @mouseenter="onHoverButton"
      @click="onClickButton"
    >
      <div class="menu-element__title-inner support-menu__title-inner">
        <div class="menu-element__item-icon">
          <ev-icon
            size="medium"
            icon="ev-icon-help"
          />
        </div>
        <h3>{{ t('WORD.HELP') }}</h3>
        <div class="menu-element__arrow-icon big right" />
      </div>
    </button>
  </li>
</template>

<style scoped lang="scss">
.support-menu {
  padding: 7px 5.5px;

  &__title-inner {
    height: 100%;
    padding-left: 0;

    i::before {
      color: var(--gnb-text-color);
    }
  }

  &.selected {
    .menu-element__title {
      background-color: var(--gnb-menu-selected-main-bg-color);
    }
  }
}

@include max-screen() {
  .support-menu {
    padding: 5px 5.5px;

    &__support-icon {
      width: 28px;
      height: 28px;
      background-size: 20px 20px !important;
    }
  }
}
</style>
