import { webStorageController } from '@/common/utils/webStorage.util';
import { isEqual } from 'lodash-es';
import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { StatCategory, LatestStatInfo } from '../components/organisms/statWindow/statContents';
import { getStatInfoList, useStatInfoStore } from './stat-info';

type ParentCategory = string;
type ChildCategory = string;

export const useLatestStatInfoStore = defineStore('latestStatInfo', () => {
  const localStorageKey = 'stat';
  const statRecord = ref<Record<ParentCategory, Record<ChildCategory, LatestStatInfo[]>>>({});

  const initialStat = webStorageController.getItem({
    type: 'local',
    key: localStorageKey,
  });
  const MAX_LEN = 5;

  if (initialStat) {
    statRecord.value = JSON.parse(initialStat);
  }

  watch(
    statRecord,
    (newStatRecord) => {
      webStorageController.setItem({
        type: 'local',
        key: localStorageKey,
        value: JSON.stringify(newStatRecord),
      });
    },
    {
      deep: true,
    },
  );

  const addLatestStatInfo = (statInfo: LatestStatInfo) => {
    const { parentCategory, childCategory, statId } = statInfo;
    if (!statRecord.value[parentCategory]) {
      statRecord.value[parentCategory] = {};
    }
    if (!statRecord.value[parentCategory][childCategory]) {
      statRecord.value[parentCategory][childCategory] = [];
    }
    const originList = statRecord.value[parentCategory][childCategory];

    if (originList) {
      const index = originList.findIndex(
        (info) =>
          info.parentCategory === parentCategory &&
          info.childCategory === childCategory &&
          info.statId === statId,
      );
      if (index > -1 && index < MAX_LEN - 1) {
        originList.splice(index, 1);
      } else if (originList.length === MAX_LEN) {
        originList.pop();
      }
      statRecord.value[parentCategory][childCategory] = [statInfo, ...originList];
    } else {
      statRecord.value[parentCategory][childCategory] = [statInfo];
    }
  };
  const { statInfoListByCategory } = storeToRefs(useStatInfoStore());

  const getLatestStatInfoListByCategory = (statCategory: StatCategory): LatestStatInfo[] => {
    const currentStatIds = statInfoListByCategory.value
      ? getStatInfoList(statInfoListByCategory.value, statCategory)?.map((statInfo) => statInfo.id)
      : [];

    const { parentCategory, childCategory } = statCategory;
    const filteredStatInfos = statRecord.value[parentCategory]?.[childCategory]?.filter(
      (latestStatInfo) => {
        return currentStatIds?.includes(latestStatInfo.statId);
      },
    );
    if (!isEqual(statRecord.value[parentCategory]?.[childCategory], filteredStatInfos)) {
      statRecord.value = {
        ...statRecord.value,
        [parentCategory]: {
          ...statRecord[parentCategory],
          [childCategory]: filteredStatInfos,
        },
      };
    }
    return filteredStatInfos ?? [];
  };

  const clearStatNameInfo = () => {
    webStorageController.removeItem({ type: 'local', key: localStorageKey });
  };

  return { getLatestStatInfoListByCategory, addLatestStatInfo, clearStatNameInfo };
});
