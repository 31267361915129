import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { MetricRequest, Nullable } from '@/common/utils/types';
import { MetricGroupValue, MetricItem } from '@/openapi/common/model';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { DataMetricResponse, MetricResponse } from '@/openapi/data/model';
import { getMetricData } from '@/common/api/data';
import { getByUserIdAndTenantIdMetricGroupV7ControllerAxios } from '@/openapi/common/api/metric-group-v7-controller-api';
import { checkValidMetric, MetricFailError } from '@/database/utils/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

type MetricGroup = Nullable<MetricGroupValue>;

export interface State {
  metricGroup: MetricGroup[];
  dbMetricInfo: MetricItem[];
  previewDbMetricInfo: MetricItem[];
  errorStatusText: string;
}

const frameName = FRAME_NAMES.DB_METRIC.METRIC;

export const metric: Module<State, RootState> = {
  namespaced: true,
  state: {
    metricGroup: [],
    dbMetricInfo: [],
    previewDbMetricInfo: [],
    errorStatusText: '',
  },
  mutations: {
    initPreviewDbMetricInfo: (state: State) => {
      state.previewDbMetricInfo = [];
    },
    initDbMetricInfo: (state: State) => {
      state.dbMetricInfo = [];
    },
    setMetricGroup: (state: State, metricGroup: MetricGroup[]) => {
      const DEFAULT_GROUP: MetricGroup[] = [
        {
          metricGroupName: 'Logical + Physical IO',
          type: 'postgresql',
          createdTime: null,
          metricNames: ['db_postgresql_blkshit', 'db_postgresql_blksread'],
        },
        {
          metricGroupName: 'Dml Rows',
          type: 'postgresql',
          createdTime: null,
          metricNames: [
            'db_postgresql_tupreturned',
            'db_postgresql_tupfetched',
            'db_postgresql_tupinserted',
            'db_postgresql_tupupdated',
            'db_postgresql_tupdeleted',
          ],
        },
      ];

      state.metricGroup = [...DEFAULT_GROUP, ...metricGroup];
    },
    setDbMetricInfo: (state: State, data: MetricItem[]) => {
      state.dbMetricInfo = data;
    },
    setPreviewDbMetricInfo: (state: State, data: MetricItem[]) => {
      state.previewDbMetricInfo = data;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    fetchMetricGroup: async ({ commit }) => {
      const { data } = await getByUserIdAndTenantIdMetricGroupV7ControllerAxios({} as any);
      commit('setMetricGroup', data.data);
    },
    fetchDbMetricInfo: async (
      { commit },
      {
        metricInfo,
        instanceIds,
      }: { metricInfo: { metricName: string; interval: string }[]; instanceIds: string[] },
    ) => {
      try {
        const deduplicationMetricInfo = metricInfo.filter(
          ({ metricName }, idx, src) =>
            src.findIndex((val) => val.metricName === metricName) === idx,
        );
        const nonMetricData: MetricResponse[] = [];

        let convertedData;
        if (deduplicationMetricInfo.length) {
          // TODO: 추후 interval(수집 주기) 확인 필요
          const oneMinuteInterval = deduplicationMetricInfo.filter(
            ({ interval }) => interval === '1m0s',
          );

          if (oneMinuteInterval.length) {
            const metricRequests = oneMinuteInterval.map<MetricRequest>(({ metricName }) => ({
              aggregationType: 'byTarget',
              period: 'p1h',
              interval: 'I1m',
              category: 'postgresql',
              dataId: metricName,
              targetIds: instanceIds,
              summaryType: 'current',
              interpolateType: 'Linear',
            }));

            const promises: PromiseAxiosResponse<DataMetricResponse>[] = [
              getMetricData({
                metricV7Requests: metricRequests,
                frameName: FRAME_NAMES.DB_METRIC.METRIC_I1M,
                isTimeout: true,
              }),
            ];
            const others = deduplicationMetricInfo.filter(({ interval }) => interval !== '1m0s');
            if (others.length) {
              const othersMetricRequests = others.map<MetricRequest>(({ metricName }) => ({
                aggregationType: 'byTarget',
                period: 'p3m',
                interval: 'I5s',
                category: 'postgresql',
                dataId: metricName,
                targetIds: instanceIds,
                summaryType: 'current',
                interpolateType: 'Linear',
              }));
              promises.push(
                getMetricData({
                  metricV7Requests: othersMetricRequests,
                  frameName: FRAME_NAMES.DB_METRIC.METRIC_I5S,
                  isTimeout: true,
                }),
              );
            }

            const result = await Promise.allSettled(promises);

            result.forEach((value) => {
              if (value.status === 'fulfilled') {
                const { data } = value.value.data;
                if (convertedData?.length) {
                  data?.forEach(({ status, reason, metrics }, idx) => {
                    if (status === 'fail') {
                      throw new MetricFailError({ reason });
                    }
                    convertedData[idx].metrics?.[0].push(...metrics!);
                  });
                } else {
                  convertedData = data;
                }
              } else {
                throw new Error(value.reason);
              }
            });

            commit(
              'postgresqlMultiViewEnv/deleteFramesByFailedApi',
              FRAME_NAMES.DB_METRIC.METRIC_I1M,
              { root: true },
            );
            commit(
              'postgresqlMultiViewEnv/deleteFramesByFailedApi',
              FRAME_NAMES.DB_METRIC.METRIC_I5S,
              { root: true },
            );
          } else {
            const metricRequests = deduplicationMetricInfo.map<MetricRequest>(({ metricName }) => ({
              aggregationType: 'byTarget',
              period: 'p3m',
              interval: 'I5s',
              category: 'postgresql',
              dataId: metricName,
              targetIds: instanceIds,
              summaryType: 'current',
              interpolateType: 'Linear',
            }));

            const { data } = await getMetricData({
              metricV7Requests: metricRequests,
              frameName,
              isTimeout: true,
            });

            checkValidMetric(data?.data ?? []);

            convertedData = data.data;
          }
        } else {
          convertedData = nonMetricData;
        }

        commit('setErrorStatusText', '');
        commit('postgresqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });

        return convertedData;
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
        commit(
          'postgresqlMultiViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
        return [];
      }
    },
  },
  getters: {
    getMetricGroup: (state: State) => state.metricGroup,
    getDbMetricInfo: (state: State): MetricItem[] => state.dbMetricInfo,
    getPreviewDbMetricInfo: (state: State): MetricItem[] => state.previewDbMetricInfo,
    getErrorStatusText: (state: State): string => state.errorStatusText,
    getMetricParams: (state, getters, rootState, rootGetters) => {
      const allMetricNames = rootGetters['dbMetric/getMetricNames'];
      const metricNames: string[] = rootGetters['postgresqlMultiViewEnv/getDbMetricNames'];

      return metricNames.map((metricName) => ({
        metricName,
        interval:
          allMetricNames.find((target) => target.metricName === metricName)?.interval ?? '5s',
      }));
    },
  },
};
