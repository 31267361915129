import axios, { AxiosResponse, CreateAxiosDefaults } from 'axios';
import qs from 'qs';
import BigInt from '@/common/lib/json-bigint';
import { setInterceptors } from '@/worker/commands/config/interceptors';

export type PromiseAxiosResponse<T> = Promise<AxiosResponse<T>>;

const serialize = (params) => qs.stringify(params, { arrayFormat: 'comma' });
const transformResponse = (res) => {
  let result;
  try {
    result = BigInt().parse(res);
  } catch (e) {
    return res;
  }
  return result;
};
const createAxiosDefaults: CreateAxiosDefaults = {
  paramsSerializer: {
    serialize,
  },
  transformResponse,
};

const axiosInstance = axios.create(createAxiosDefaults);

const timeout5SecAxiosInstance = axios.create({
  ...createAxiosDefaults,
  timeout: 4000, // 1초는 여유로 남겨놓음
});

setInterceptors(axiosInstance);
setInterceptors(timeout5SecAxiosInstance);

type Params = {
  key: string;
  value: string | number | null;
}[];

const setHeaderConfig = (params: Params) => {
  [axiosInstance, timeout5SecAxiosInstance].forEach((instance) => {
    params.forEach(({ key, value }) => {
      instance.defaults.headers.common[key] = value;
    });
  });
};

export { axiosInstance, timeout5SecAxiosInstance, setHeaderConfig };
