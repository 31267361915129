import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { RuleInfoProps, TimeParams } from '@/alert/components/alertDetail/alertDetail.types';
import { SelectboxItem } from '@/common/utils/types';
import { capitalize, lowerCase, upperCase } from 'lodash-es';
import { PlatformType } from '@/types/common.types';
import { ALERT_TYPE_VALUE } from '@/alert/utils/define';
import { NotificationIdMap } from '@/config/views/alertGroup/alertRuleWindow.types';
import { NotificationConfigInfo } from '@/openapi/metaV6/model';

/**
 *  Alert Detail > get Time Period Info
 * */
export const getTimeInfoApiParams = (info: TimePeriodInfo, usePeriod = false): TimeParams => {
  if (!info.isPaused) {
    return { period: info.timePeriod };
  }
  return usePeriod
    ? {
        fromTime: info.fromTimeUtc,
        toTime: info.toTimeUtc,
        period: info.timePeriod,
      }
    : {
        fromTime: info.fromTimeUtc,
        toTime: info.toTimeUtc,
      };
};

/**
 * Alert 설정에서 Selectbox 사용하는 항목들 displayName
 * @param items selectbox items
 * @param val value
 */
export const getDisplayNameFromSelectboxItem = (items: SelectboxItem[], val?: string) => {
  if (!val) {
    return '';
  }
  return items.find((v) => v.value === val)?.name ?? val;
};

/**
 * Alert Grid 용 column formatter
 */
export const LAST_ALERT_LEVELS = {
  CRITICAL: 'Critical',
  WARNING: 'Warning',
  NONE: 'No Data',
  NORMAL: 'Normal',
}; // Grid Filter Search 목록 제공 용
export const columnFormatter = {
  activation: (v: boolean) => (v ? 'Active' : 'Inactive'),
  lastAlert: (v: string) => LAST_ALERT_LEVELS[upperCase(v)] ?? capitalize(lowerCase(v)),
  alert: (v: string) => (v === 'NONE' ? 'No Data' : capitalize(v)),
  value: (v: string) => v || '',
};

/**
 * Alert 구분
 * @param type 'System' | 나머지(user)
 */
export const getAlertType = (type: string): 'user' | 'system' =>
  type?.toLowerCase() === 'system' ? 'system' : 'user';

/**
 * System Alert > Rule Name(platformName:eventName) 분리
 * @param name platformName:eventName
 */
export const getSystemAlertInfo = (name: string) => {
  const nameDivider = name?.split(':');
  return {
    platform: (nameDivider?.[0]?.toLowerCase() as PlatformType) ?? undefined,
    event: nameDivider?.[1] ?? '',
  };
};

const detailParamsController = {
  user: (name: string, ruleId?: string): RuleInfoProps => ({
    name,
    type: 'user',
    ruleId,
  }),
  system: (name: string): RuleInfoProps => ({
    name,
    type: 'system',
    ...getSystemAlertInfo(name),
  }),
};
/**
 * Alert Detail 연계 Prop 생성 - user alert / system alert 구분 위함
 * @param name ruleName
 * @param ruleId ruleId (system alert의 경우 ruleId 없어도 됨)
 * @param type 'System' | 나머지(user)
 */
export const getAlertDetailProps = ({
  name,
  ruleId,
  type,
}: {
  name: string;
  ruleId?: string;
  type: string;
}): RuleInfoProps => {
  return detailParamsController?.[getAlertType(type)]?.(name, ruleId) ?? {};
};

/**
 * User Alert Type에 따른 Conditions 컬럼명
 */
export const getConditionsNameByUserAlertType = (userAlertType: string) => {
  const apiColumnNameObj = {
    [ALERT_TYPE_VALUE.METRIC]: 'metricConditions',
    [ALERT_TYPE_VALUE.EVENT]: 'eventConditions',
  };
  return apiColumnNameObj[userAlertType];
};

/**
 * Alert Notification Api 응답 컴포넌트에 맞게 수정
 */
export const convertNotificationForUi = (data: NotificationConfigInfo): NotificationIdMap => {
  const tempObj: NotificationIdMap = {};
  Object.keys(data).forEach((key) => {
    tempObj[key] = data[key]?.ids ?? [];
  });
  return tempObj;
};
