import { Module } from 'vuex';
import { RootState } from '@/common/store';
import {
  DiffParameterItemV7,
  StatsValueV7 as TempBytes,
  TopOldStatisticsObjectItemV7,
  TopTablespaceSizeItemV7,
} from '@/openapi/postgresqlV7/model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getAPIErrorStatusText, utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import { getAdditionalInfoPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

dayjs.extend(utc);

export interface AdminRefAdditionalInfoState {
  tablespace: TopTablespaceSizeItemV7[];
  parameter: DiffParameterItemV7[];
  tempBytes: TempBytes[];
  oldStatisticsObject: TopOldStatisticsObjectItemV7[];
  errorStatusText: string;
}

export const adminRefAdditionalInfo: Module<AdminRefAdditionalInfoState, RootState> = {
  namespaced: true,
  state: {
    tempBytes: [],
    tablespace: [],
    parameter: [],
    oldStatisticsObject: [],
    errorStatusText: '',
  },
  mutations: {
    setOldStatisticsObject(
      state: AdminRefAdditionalInfoState,
      data: TopOldStatisticsObjectItemV7[],
    ) {
      state.oldStatisticsObject = data.map((item) => {
        let { lastAnalyze } = item;
        if (lastAnalyze === null || dayjs(lastAnalyze).diff(item.lastAutoAnalyze, 'second') < 0) {
          lastAnalyze = item.lastAutoAnalyze;
        }
        return {
          ...item,
          lastAnalyze,
        };
      });
    },
    setTempBytes(state: AdminRefAdditionalInfoState, data: TempBytes[]) {
      state.tempBytes = data;
    },
    setTablespace(state: AdminRefAdditionalInfoState, data: TopTablespaceSizeItemV7[]) {
      state.tablespace = data;
    },
    setParameter(state: AdminRefAdditionalInfoState, data: DiffParameterItemV7[]) {
      state.parameter = data;
    },
    setErrorStatusText: (state: AdminRefAdditionalInfoState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchAdditionalInfo({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.ADMIN_REFERENCE_ADDITIONAL_INFO;
      try {
        const { data } = await getAdditionalInfoPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setParameter', data.diffParameter!.data);
        commit('setTablespace', data.topTableSpaceSize!.data);
        commit('setOldStatisticsObject', data.topOldStatisticsObjectResponse!.data);
        commit('setTempBytes', data.tempBytes!.data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getParameter: (state: AdminRefAdditionalInfoState) => state.parameter,
    getTopTablespace: (state: AdminRefAdditionalInfoState) => state.tablespace,
    getOldStatisticsObject: (state: AdminRefAdditionalInfoState) => state.oldStatisticsObject,
    getLabels: (state: AdminRefAdditionalInfoState) =>
      state.tempBytes.map((v) => +dayjs(utcZeroTimeToStandardTime(v.collectTime))),
    getTempBytes: (state: AdminRefAdditionalInfoState) => state.tempBytes.map((v) => v.tempBytes),
    getErrorStatusText: (state: AdminRefAdditionalInfoState) => state.errorStatusText,
  },
};
