import { provide } from 'vue';

export const useTargetDetailProvide = (emit?) => {
  const emitOpenDetail = () => {
    if (emit) {
      emit('openDetail');
    }
  };

  provide('emitOpenDetail', emitOpenDetail);
};
