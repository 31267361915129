import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { useUserEnvStore } from '@/common/stores/user-env';
import { getByKeysXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

interface State {
  hasUserEnvInfo: boolean;
  userEnvMap: Map<string, string>;
}

export const userEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    hasUserEnvInfo: false,
    userEnvMap: new Map(),
  },
  mutations: {
    setHasUserEnvInfo: (state: State, flag: boolean) => {
      state.hasUserEnvInfo = flag;
    },
    initUserEnvMap: (state: State) => {
      state.hasUserEnvInfo = false;
      state.userEnvMap.clear();
    },
    setUserEnvMap: (state: State, { key, value }) => {
      state.userEnvMap.set(key, value);
    },
  },
  actions: {
    fetchUserEnvInfo: async ({ commit }, options = { global: false, refresh: true }) => {
      try {
        const { data } = await getByKeysXmUserEnvControllerAxios({ global: options.global });
        commit('setHasUserEnvInfo', true);

        data?.data?.forEach((userEnvInfo) => {
          const { key, value } = userEnvInfo;
          commit('setUserEnvMap', { key, value });
        });
        if (options.refresh) {
          useUserEnvStore().fetchAllEnvData(data?.data);
        } else {
          useUserEnvStore().addNewEnvData(data?.data);
        }
      } catch (e) {
        console.log(e);
        commit('setHasUserEnvInfo', false);
      }
    },
  },
  getters: {
    getHasUserEnvInfo: (state: State) => state.hasUserEnvInfo,
    getUserEnvMap: (state: State) => state.userEnvMap,
  },
};
