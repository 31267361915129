import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/common/router';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { usePlatformDashboardStore } from '@/common/stores/platform-dashboard';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { MenuInfo } from '../types';

export interface Props {
  dashboardInfo: MenuInfo;
  selected?: boolean;
}

export const setup = (props: Props) => {
  const favoriteRef = ref<HTMLButtonElement | null>(null);

  const { updateFavoriteDashboardList } = useDashboardListViewStore();
  const platformDashboardStore = usePlatformDashboardStore();
  const { getDashboardByPlatformList } = platformDashboardStore;
  const { menuByPlatform, menuByAnalysisPlatform } = storeToRefs(platformDashboardStore);
  const { selectPanelMenu } = useBaseMenuStore();

  const getQueryParam = (search: string, path: string) => {
    const params = new URL(`http://${path}`).searchParams.get(search);
    return params === 'kubernetes' ? 'k8s' : params;
  };

  const getDashboardId = (path: string) => {
    const url = new URL(`http://${path}`);
    const pathSegments = url.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  const isFavorited = computed(() => {
    const { path } = props.dashboardInfo;
    if (!path) return false;

    const platform = getQueryParam('rootMenu', path);
    const dashboardId = getDashboardId(path);
    if (!platform || !dashboardId || !menuByPlatform.value[platform]) {
      return false;
    }
    return !!menuByPlatform.value[platform][dashboardId]?.favoriteId;
  });

  const isAnalysisFavorited = computed(() => {
    const { path } = props.dashboardInfo;
    if (!path) return false;

    const platform = getQueryParam('rootMenu', path);
    const dashboardId = getDashboardId(path);
    if (!platform || !dashboardId || !menuByAnalysisPlatform.value[platform]) {
      return false;
    }
    return !!menuByAnalysisPlatform.value[platform][dashboardId]?.favoriteId;
  });

  const onClickElement = async (e: MouseEvent) => {
    const favoriteEl = favoriteRef.value;
    const targetEl = e.target as HTMLElement;

    if (!favoriteEl) {
      return;
    }
    if (favoriteEl.contains(targetEl)) {
      try {
        const dashboardId = props.dashboardInfo.path?.split('/')[3].split('?')[0];
        if (!dashboardId) return;
        await updateFavoriteDashboardList([Number(dashboardId)]);
        await getDashboardByPlatformList();
      } catch (err) {
        console.log(err);
      }
      return;
    }
    if (!props.dashboardInfo.path) {
      return;
    }
    selectPanelMenu(props.dashboardInfo.value);
    router.push(props.dashboardInfo.path);
  };

  return { isFavorited, isAnalysisFavorited, favoriteRef, onClickElement };
};
