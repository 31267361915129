import { usePropertyStore } from '@/common/stores/property';
import { LOGIN_CUSTOMER, VIEW_MODE } from '@/common/define/common.define';
import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

export type ViewMode = (typeof VIEW_MODE)[keyof typeof VIEW_MODE];

export const useViewModeStore = defineStore('viewMode', () => {
  const viewMode = ref<ViewMode>(VIEW_MODE.EXEMONE);
  const { propertyData } = storeToRefs(usePropertyStore());
  const productName = computed(() => {
    if (viewMode.value === VIEW_MODE.EXEMONE) return 'exemONE';
    if (viewMode.value === VIEW_MODE.MAXGAUGE) return 'MaxGauge';
    if (viewMode.value === VIEW_MODE.INTERMAX) return 'Intermax';
    if (viewMode.value === VIEW_MODE.CLOUDMOA) return 'CloudMOA';
    return 'exemONE';
  });
  const isMaxgaugeMode = computed(() => viewMode.value === VIEW_MODE.MAXGAUGE);
  const loginCustomer = computed(() => propertyData.value?.loginCustomer ?? LOGIN_CUSTOMER.EXEMONE);

  const setViewMode = (mode?: ViewMode) => {
    viewMode.value = mode ?? VIEW_MODE.EXEMONE;
  };

  return {
    viewMode,
    productName,
    isMaxgaugeMode,
    loginCustomer,
    setViewMode,
  };
});
