import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { DB_TYPE, DEFAULT_STAT_CHART_OPTION } from '@/common/utils/define';
import { StatInfo } from '@/postgresql/store/singleView/singleViewEnv';
import { getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { SlowQueryOption } from '@/common/components/organisms/slowQuery/useSlowQuery';
import { postV1UserEnvsAxios } from '@/openapi/meta/api/xm-user-env-controller-api';
import { InstanceV2Item } from '@/openapi/meta/model';
import { mapMetricNamesToDataIds } from '@/database/utils/utils';

interface State {
  selectedInstanceId: string | null;
  selectedInstanceInfo: InstanceV2Item;
  statInfoForMysql: StatInfo[];
  slowQueryOption: SlowQueryOption;
  framesByFailedApi: Map<string, string>;
}

const STAT_INFO_KEY = 'MYSQL_STAT_INFO';
const SELECTED_ID_KEY = 'MYSQL_SINGLE_VIEW_SELECTED_ID';
const SLOW_QUERY_OPTION_KEY = 'MYSQL_SINGLE_VIEW_SLOW_QUERY_OPTION';
const SLOW_QUERY_DEFAULT_OPTION: SlowQueryOption = {
  isSetMaxValue: true,
  pointSize: 2,
  maxValue: 60,
};
export const DEFAULT_STAT_INFO_MYSQL: StatInfo[] = [
  {
    frameId: 'mysqlStatChart0',
    name: 'Logical + Physical IO',
    displayName: 'Logical + Physical IO',
    stats: ['db_mysql_innodb_buffer_pool_reads', 'db_mysql_innodb_buffer_pool_read_requests'],
    chartOption: {
      common: {
        type: 'line',
        showLegend: false,
        tooltipSorting: false,
        stack: true,
        fill: true,
      },
      line: {
        lineWidth: 2,
        point: false,
      },
      bar: {
        thickness: 1,
        borderRadius: 0,
      },
    },
  },
  {
    frameId: 'mysqlStatChart1',
    name: 'db_mysql_tps',
    displayName: 'Tps (count/sec)',
    stats: ['db_mysql_tps'],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
  {
    frameId: 'mysqlStatChart2',
    name: 'db_mysql_buffer_pool_hit_ratio(%)',
    displayName: 'Buffer Pool Hit Ratio (%)',
    stats: ['db_mysql_buffer_pool_hit_ratio(%)'],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
  {
    frameId: 'mysqlStatChart3',
    name: 'db_mysql_queries',
    displayName: 'Queries (count)',
    stats: ['db_mysql_queries'],
    chartOption: structuredClone(DEFAULT_STAT_CHART_OPTION),
  },
];
export const singleViewEnv: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedInstanceId: null,
    selectedInstanceInfo: {},
    statInfoForMysql: [],
    slowQueryOption: {} as SlowQueryOption,

    framesByFailedApi: new Map(),
  },
  mutations: {
    setSelectedInstanceId(state: State, id: string) {
      state.selectedInstanceId = id;
    },
    setSelectedInstanceInfo(state: State, info: State['selectedInstanceInfo']) {
      state.selectedInstanceInfo = info;
    },
    setStatInfoForMysql: (state: State, statInfoArr: StatInfo[]) => {
      state.statInfoForMysql = statInfoArr;
    },
    setFramesByFailedApi: (state: State, failedApiInfo) => {
      state.framesByFailedApi.set(failedApiInfo.frameName, failedApiInfo.statusText);
    },
    setSlowQueryOption: (state: State, slowQueryOption: SlowQueryOption) => {
      state.slowQueryOption = slowQueryOption;
    },
    deleteFramesByFailedApi: (state: State, frameNameOfFailedApi: string) => {
      if (state.framesByFailedApi.has(frameNameOfFailedApi)) {
        state.framesByFailedApi.delete(frameNameOfFailedApi);
      }
    },
    initFramesByFailedApi: (state: State) => {
      state.framesByFailedApi = new Map();
    },
    initSelectedInfo: (state: State) => {
      state.selectedInstanceId = null;
      state.selectedInstanceInfo = {};
    },
  },
  actions: {
    fetchStatInfoForMysql: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(STAT_INFO_KEY);

      if (data) {
        const statInfo = JSON.parse(data).map((info) => ({
          ...info,
          stats: mapMetricNamesToDataIds({ dbType: DB_TYPE.MYSQL, metricNames: info.stats }),
        }));
        commit('setStatInfoForMysql', statInfo);
        return;
      }

      commit('setStatInfoForMysql', DEFAULT_STAT_INFO_MYSQL);
    },
    saveStatInfoForMysql: async ({ commit }, statInfoArr: StatInfo[]) => {
      try {
        await commit('setStatInfoForMysql', statInfoArr);
        await postV1UserEnvsAxios({
          request: {
            key: STAT_INFO_KEY,
            value: JSON.stringify(statInfoArr),
          },
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: STAT_INFO_KEY, value: JSON.stringify(statInfoArr) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchLastSelectedId: async ({ commit, dispatch, rootGetters }) => {
      try {
        const data = rootGetters['userEnv/getUserEnvMap'].get(SELECTED_ID_KEY);
        const idInfo = JSON.parse(data ?? '{}');
        const folderList = rootGetters['monitoringFolder/getFolders'];
        const { folderId, instanceId, selectedInfo } = getRtmSelectedInfo({
          idInfo,
          folderList,
          dbType: DB_TYPE.MYSQL,
        });

        // userEnv에서 조회한 instanceId와 실제 화면에 보여져야 할 instanceId가 다른 경우
        // e.g. userEnv에서는 A라는 인스턴스가 저장되어 있지만 A라는 인스턴스가 disable로 변경된 상태인 경우
        if (idInfo.instanceId !== instanceId) {
          await dispatch('saveLastSelectedId', {
            folderId,
            instanceId,
          });
        }

        commit('setSelectedInstanceId', instanceId);
        commit('setSelectedInstanceInfo', selectedInfo);
      } catch (e: any) {
        console.log(e);
      }
    },
    saveLastSelectedId: async ({ commit, rootGetters }, selectedInfo: Record<string, string>) => {
      if (!selectedInfo.folderId || !selectedInfo.instanceId) {
        return;
      }
      try {
        await commit('setSelectedInstanceId', selectedInfo.instanceId);
        const selectedInstanceInfo = rootGetters['monitoringFolder/getFolders']
          .find((item) => item.folderId === selectedInfo.folderId)
          .instances.find((item) => item.instanceId === selectedInfo.instanceId);
        await commit('setSelectedInstanceInfo', selectedInstanceInfo);
        await postV1UserEnvsAxios({
          request: {
            key: SELECTED_ID_KEY,
            value: JSON.stringify(selectedInfo),
          },
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SELECTED_ID_KEY, value: JSON.stringify(selectedInfo) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
    fetchSlowQueryOption: ({ commit, rootGetters }) => {
      const data = rootGetters['userEnv/getUserEnvMap'].get(SLOW_QUERY_OPTION_KEY);
      commit('setSlowQueryOption', data ? JSON.parse(data) : SLOW_QUERY_DEFAULT_OPTION);
    },
    saveSlowQueryOption: async ({ commit }, slowQueryOption: SlowQueryOption) => {
      try {
        await commit('setSlowQueryOption', slowQueryOption);
        await postV1UserEnvsAxios({
          request: {
            key: SLOW_QUERY_OPTION_KEY,
            value: JSON.stringify(slowQueryOption),
          },
        });
        commit(
          'userEnv/setUserEnvMap',
          { key: SLOW_QUERY_OPTION_KEY, value: JSON.stringify(slowQueryOption) },
          { root: true },
        );
      } catch (e: any) {
        console.log(e);
      }
    },
  },
  getters: {
    getSelectedInstanceId: (state: State) => state.selectedInstanceId,
    getSelectedInstanceInfo: (state: State) => state.selectedInstanceInfo,
    getSelectedDbType: (state: State) => state.selectedInstanceInfo.type,
    getSelectedDbVersion: (state: State) => state.selectedInstanceInfo.instanceState?.version,

    getStatInfoForMysql: (state) => state.statInfoForMysql,

    isExistFramesByFailedApi: (state: State): boolean => !!state.framesByFailedApi.size,
    getFramesByFailedApi: (state: State) => state.framesByFailedApi,
    getSlowQueryOption: (state: State) => state.slowQueryOption,
  },
};
