import { useLoginErrorHandler } from '@/login/components/loginForm/loginForm.service';
import { useDomEvent } from '@/login/utils/domEvent.utils';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/common/stores/auth';
import {
  ComponentPublicInstance,
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue';
import { STORAGE_KEY, VIEW_TYPE } from '@/common/utils/define';
import { confirmMsg, showErrorMsg } from '@/common/utils/commonUtils';
import router from '@/common/router';
import { webStorageController } from '@/common/utils/webStorage.util';
import { getWidgetMappingInfoDashboardWidgetV6ControllerAxios } from '@/openapi/metaV6/api/dashboard-widget-v6-controller-api';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import { MyInfo } from '@/common/api/resTypes/userInfoRes';
import { storeToRefs } from 'pinia';
import { useRedirectToFrom } from '@/login/views/login.uses';
import type { LoginViewType, InputRefs, LoginData, LoginErrorMsg } from './loginForm.types';

export interface Props {
  isShowGroupWindow: boolean;
}
export interface Emits {
  (e: 'change-view-type', value: LoginViewType): void;
  (e: 'set-old-password', value: string): void;
  (e: 'update:isShowGroupWindow', value: boolean): void;
}

const useLoginRoute = () => {
  const getStartDashboardId = async () => {
    let startScreenId: number;
    try {
      const { data } = await getWidgetMappingInfoDashboardWidgetV6ControllerAxios({
        startScreen: true,
      });
      startScreenId = data.data?.[0].dashboardId ?? -1;
    } catch (e) {
      startScreenId = -1;
    }
    return startScreenId;
  };

  const goDashboard = async () => {
    const startScreenId = await getStartDashboardId();
    if (startScreenId > 0) {
      await router.push({
        name: 'dashboard_Dashboard View',
        params: {
          id: startScreenId,
        },
      });
    } else {
      await router.push('/dashboard/list');
    }
  };

  return {
    getStartDashboardId,
    goDashboard,
  };
};
const setup = (props: Props, emit: Emits) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useI18n();
  const authStore = useAuthStore();
  const userStore: Store<RootState> = useStore();
  const userInfo = computed<MyInfo>(() => userStore.getters['myInfo/getAccountInfo']);
  const { redirectToFrom } = useRedirectToFrom();
  const { setElementState } = useDomEvent();
  const { handleLoginError } = useLoginErrorHandler();

  const isShowGroupWindow = computed<boolean>({
    get: () => props.isShowGroupWindow,
    set: (val): void => emit('update:isShowGroupWindow', val),
  });

  const inputRefs = reactive<InputRefs<'idRef' | 'passwordRef'>>({
    idRef: {} as ComponentPublicInstance<HTMLDivElement>,
    passwordRef: {} as ComponentPublicInstance<HTMLDivElement>,
  });
  const loginData = reactive<LoginData>({
    id: '',
    password: '',
    isRememberCheck: false,
  });
  const errorMsg = reactive<LoginErrorMsg>({
    login: '',
    id: '',
    password: '',
  });

  const onCheckIdValidation = () => {
    if (loginData.id?.length < 1) {
      errorMsg.id = t('MESSAGE.VALIDATION_CHARACTER_DIGIT_MORE', { value: 1 });
    } else {
      errorMsg.id = '';
    }
  };
  const { isPasswordChange, isPasswordExpired, passwordExpireDay } = storeToRefs(authStore);

  const showPasswordChangeConfirmation = ({ msgStr }: { msgStr: string }) => {
    confirmMsg(ctx, {
      msgStr,
      okCallback: () => {
        emit('set-old-password', loginData.password);
        emit('change-view-type', VIEW_TYPE.CHANGE);
      },
      showCancelBtn: false,
    });
  };

  const loginUser = async () => {
    try {
      errorMsg.login = '';
      setElementState.blur(inputRefs.idRef);
      setElementState.blur(inputRefs.passwordRef);

      await authStore.login(loginData);
      if (loginData.isRememberCheck) {
        webStorageController.setItem({
          type: 'local',
          key: STORAGE_KEY.LAST_LOGIN_ID,
          value: JSON.stringify({ id: loginData.id }),
        });
      } else {
        webStorageController.removeItem({
          type: 'local',
          key: STORAGE_KEY.LAST_LOGIN_ID,
        });
      }
      if (isPasswordChange.value) {
        // 최초 로그인
        showPasswordChangeConfirmation({
          msgStr: `${t('MESSAGE.TEMP_PASSWORD_LOGIN')} ${t('MESSAGE.TO_CHANGE_PASSWORD')}`,
        });
      } else if (isPasswordExpired.value) {
        showPasswordChangeConfirmation({
          msgStr: `${t('MESSAGE.PASSWORD_NOT_CHANGED', { value: passwordExpireDay.value })} ${t(
            'MESSAGE.CHANGE_PASSWORD',
          )}`,
        });
      } else {
        await userStore.dispatch('myInfo/dispatchMyInfo');
        if (!userInfo.value.loginPopup) {
          isShowGroupWindow.value = true;
        } else {
          await redirectToFrom();
        }
      }
    } catch (e: any) {
      const { isShowMsgBox, msg: errMsg } = handleLoginError(e, t);
      setElementState.focus(inputRefs.passwordRef);
      loginData.password = '';
      authStore.logout();

      if (isShowMsgBox) {
        showErrorMsg(ctx, errMsg);
      } else {
        errorMsg.login = errMsg;
      }
    }
  };

  const initLoginForm = () => {
    const { id } = JSON.parse(
      webStorageController.getItem({
        type: 'local',
        key: STORAGE_KEY.LAST_LOGIN_ID,
      }) || '{}',
    );

    loginData.id = id ?? '';
    loginData.isRememberCheck = !!id;

    const targetRef = loginData.id ? inputRefs.passwordRef : inputRefs.idRef;
    setElementState.focus(targetRef);
  };

  const disableLogin = computed(() => {
    return !loginData.id || !loginData.password || !!errorMsg.id || !!errorMsg.password;
  });

  const onOpenForgetPasswordConfirm = () => {
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.PASSWORD_CONTACT_ADMIN'),
      okCallback: () => {},
      showCancelBtn: false,
    });
  };

  onMounted(() => {
    initLoginForm();
  });

  return {
    t,
    loginData,
    errorMsg,
    ...toRefs(inputRefs),
    loginUser,
    onCheckIdValidation,
    disableLogin,
    onOpenForgetPasswordConfirm,
  };
};

export { useLoginRoute, setup };
