import { computed } from 'vue';

export const setup = (props) => {
  const typeToClass = computed(() => {
    if (props.type.includes(' ')) {
      return props.type.split(' ').join('-').toLocaleLowerCase();
    }
    return props.type.toLocaleLowerCase();
  });

  return {
    typeToClass,
  };
};
