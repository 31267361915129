<script setup lang="ts">
import BreadCrumbsButton from './BreadCrumbsButton.vue';
import BreadCrumbsModal from './breadCrumbsModal/BreadCrumbsModal.vue';
import { setup, type Props } from './breadCrumbs.setup';

const props = withDefaults(defineProps<Props>(), {
  mode: 'common',
});

const {
  t,
  isShowModal,
  isBreadCrumbModalLoading,

  menuList,
  currentBreadCrumbId,
  filteredPaths,
  menuPosition,
  isMenuToggle,
  menuToggleIndex,

  instanceTitleByViewMode,
  currentInstance,
  instanceTypeButtons,

  monitoringDashboard,
  currentRootMenu,

  menuMouseOver,
  menuMouseClick,
  menuMouseLeave,
  menuClose,
} = setup(props);
</script>

<template>
  <!-- Common -->
  <template v-if="mode === 'common'">
    <template
      v-for="(menu, i) in filteredPaths"
      :key="menu.value"
    >
      <span
        v-if="i === 0"
        class="path-name"
      >
        {{ menu.text }}
      </span>
      <bread-crumbs-button
        v-else
        :bread-crumb-id="menu.value"
        :is-active="isMenuToggle && i === menuToggleIndex"
        :index="i"
        @mouseover="menuMouseOver"
        @click="menuMouseClick"
        @mouseleave="menuMouseLeave"
      >
        {{ menu.text }}
      </bread-crumbs-button>
    </template>
  </template>

  <!-- Instance -->
  <template v-if="mode === 'instance'">
    <span class="path-name">{{ instanceTitleByViewMode }}</span>
    <bread-crumbs-button to="/database/instance">{{ t('WORD.INSTANCE') }}</bread-crumbs-button>
    <bread-crumbs-button
      v-for="(menu, i) in instanceTypeButtons"
      :key="menu.instanceType"
      :bread-crumb-id="menu.instanceType"
      :is-active="isMenuToggle && i === menuToggleIndex"
      :index="i"
      :instance-type="menu.instanceType"
      @mouseover="menuMouseOver"
      @click="menuMouseClick"
      @mouseleave="menuMouseLeave"
    >
      {{ menu.text }}
    </bread-crumbs-button>
    <span
      v-if="props.dbInstanceType"
      class="view-name"
    >
      {{ props.dbInstanceType === 'multiView' ? t('WORD.MULTI_VIEW') : t('WORD.SINGLE_VIEW') }}
    </span>
  </template>

  <!-- Dashboard -->
  <template v-if="props.mode === 'dashboard'">
    <span class="path-name">{{ currentRootMenu }}</span>
    <bread-crumbs-button
      :bread-crumb-id="`${monitoringDashboard.dashboardId}`"
      :is-active="isMenuToggle"
      @mouseover="menuMouseOver"
      @click="menuMouseClick"
      @mouseleave="menuMouseLeave"
    >
      {{ monitoringDashboard.dashboardName }}
    </bread-crumbs-button>
  </template>

  <bread-crumbs-modal
    v-if="isShowModal"
    :current-bread-crumb-id="currentBreadCrumbId"
    :menu-list="menuList"
    :mode="props.mode"
    :is-menu-toggle="isMenuToggle"
    :menu-position="menuPosition"
    :current-instance="currentInstance"
    :loading="isBreadCrumbModalLoading"
    @leave="menuMouseLeave"
    @close="menuClose"
  />
</template>
