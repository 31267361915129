/* eslint-disable */
import { json_parse } from './parse.js';
import json_stringfy from './stringify.js';

export default function (options) {
    return {
        parse: json_parse(options),
        stringify: json_stringfy.stringify,
    }
}

export const parse = json_parse();
export const stringify = json_stringfy.stringify;
